import React, { useEffect, useState } from 'react';
import { Form, Input, Avatar, Comment, List } from 'antd';
import moment from 'moment';
import {
  ChatRoom,
  DeleteMessageRequest,
  DisconnectUserRequest,
  SendMessageRequest,
} from 'amazon-ivs-chat-messaging';
import { connect, useDispatch } from 'react-redux';
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';
import '../../css/chat.scss';
import placeholder from '../../images/user.png';
import { saveChat } from '../../redux/actions/chat';
const { TextArea } = Input;

function Chat({ myChatToken, eventId, chat, meeting }) {
  const dispatch = useDispatch();

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState('');
  const [connected, setConnected] = useState(false);
  const [chatRoom, setChatRoom] = useState([]);
  // const [messages, setMessages] = useState([]);

  const scrollToBottomMethod = useScrollToBottom();

  const scrollToBottom = () => {
    scrollToBottomMethod();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value) return;
    setSubmitting(true);
    setTimeout(() => {
      setSubmitting(false);
      sendMessage(value);
      setValue('');
      // setComments([
      // 	...comments,
      // 	{
      // 		author: "Han Solo",
      // 		avatar: "https://joeschmoe.io/api/v1/random",
      // 		content: <p>{value}</p>,
      // 		datetime: moment().fromNow(),
      // 	},
      // ]);
    }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (chat) {
      setComments(chat);
    }
  }, [chat]);

  useEffect(() => {
    if (myChatToken?.token) {
      const room = new ChatRoom({
        regionOrUrl: 'us-east-1',
        tokenProvider: () => ({
          token: myChatToken.token,
          sessionExpirationTime: new Date(myChatToken.sessionExpirationTime),
          tokenExpirationTime: new Date(myChatToken.tokenExpirationTime),
        }),
      });
      setChatRoom(room);
      // Connect to the chat room
    }
  }, [myChatToken]);

  useEffect(() => {
    // If chat room listeners are not available, do not continue
    if (!chatRoom.addListener) {
      return;
    }
    /**
     * Called when room is establishing the initial connection or reestablishing
     * connection after socket failure/token expiration/etc
     */
    const unsubscribeOnConnecting = chatRoom.addListener(
      'connecting',
      () => {}
    );

    /** Called when connection has been established. */
    const unsubscribeOnConnected = chatRoom.addListener('connect', (a, b) => {
      setConnected(true);
      // console.log('a', a);
      // console.log('b', b);
    });

    /** Called when a room has been disconnected. */
    const unsubscribeOnDisconnected = chatRoom.addListener(
      'disconnect',
      () => {}
    );

    /** Called when a chat message has been received. */
    const unsubscribeOnMessageReceived = chatRoom.addListener(
      'message',
      (message) => {
        const data = {
          author: message?.attributes?.name,
          avatar: message?.attributes?.image,
          user_id: message?.attributes?.user_id,
          content: message.content,
          accountType: message?.attributes?.accountType,
          datetime: moment(message?.sendTime).format(),
        };
        setComments((preState) => [...preState, data]);

        {
          message?.attributes?.user_id === userInfo?._id &&
            eventId &&
            dispatch(saveChat(eventId, data));
        }

        scrollToBottom();
        /* Example message:
         * {
         *   id: "5OPsDdX18qcJ",
         *   sender: { userId: "user1" },
         *   content: "hello world",
         *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
         *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de"
         * }
         */
      }
    );

    /** Called when a chat event has been received. */
    const unsubscribeOnEventReceived = chatRoom.addListener(
      'event',
      (event) => {
        /* Example event:
         * {
         *   id: "5OPsDdX18qcJ",
         *   eventName: "customEvent,
         *   sendTime: new Date("2022-10-11T12:46:41.723Z"),
         *   requestId: "d1b511d8-d5ed-4346-b43f-49197c6e61de",
         *   attributes: { "Custom Attribute": "Custom Attribute Value" }
         * }
         */
      }
    );

    /** Called when `aws:DELETE_MESSAGE` system event has been received. */
    const unsubscribeOnMessageDelete = chatRoom.addListener(
      'messageDelete',
      (deleteMessageEvent) => {
        /* Example delete message event:
         * {
         *   id: "AYk6xKitV4On",
         *   messageId: "R1BLTDN84zEO",
         *   reason: "Spam",
         *   sendTime: new Date("2022-10-11T12:56:41.113Z"),
         *   requestId: "b379050a-2324-497b-9604-575cb5a9c5cd",
         *   attributes: { MessageID: "R1BLTDN84zEO", Reason: "Spam" }
         * }
         */
      }
    );

    /** Called when `aws:DISCONNECT_USER` system event has been received. */
    const unsubscribeOnUserDisconnect = chatRoom.addListener(
      'userDisconnect',
      (disconnectUserEvent) => {
        /* Example event payload:
         * {
         *   id: "AYk6xKitV4On",
         *   userId": "R1BLTDN84zEO",
         *   reason": "Spam",
         *   sendTime": new Date("2022-10-11T12:56:41.113Z"),
         *   requestId": "b379050a-2324-497b-9604-575cb5a9c5cd",
         *   attributes": { UserId: "R1BLTDN84zEO", Reason: "Spam" }
         * }
         */
      }
    );

    chatRoom.connect();
    return () => {
      unsubscribeOnConnected();
      unsubscribeOnDisconnected();
      unsubscribeOnUserDisconnect();
      unsubscribeOnConnecting();
      unsubscribeOnMessageReceived();
      unsubscribeOnEventReceived();
      unsubscribeOnMessageDelete();
    };
  }, [chatRoom]);

  const sendMessage = async (message) => {
    const content = `${message.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}`;
    const request = new SendMessageRequest(content);
    try {
      await chatRoom.sendMessage({
        ...request,
        Attributes: {
          user_id: userInfo?._id,
          name: userInfo?.nick_name ? userInfo?.nick_name : userInfo?.name,
          image: userInfo?.profile_image_url,
          accountType: userInfo?.account_type,
        },
      });
    } catch (error) {
      alert(JSON.stringify(error));
      // handleError(error);
    }
  };


  function dateToFromNowDaily( myDate ) {
    var fromNow = moment( myDate ).format('LLL');

    return moment( myDate ).calendar( null, {
        lastWeek: 'LLL',
        lastDay:  '[Yesterday] h:mm A',
        sameDay:  '[Today] h:mm A',
        sameElse: function () {
            return "[" + fromNow + "]";
        }
    });
}

  if (!connected) {
    return (
      <div className="chat-loading">
        <h3>...loading</h3>
      </div>
    );
  }
  return (
    <div className="live-chat-comp">
      <div id="frame">
        <div className="content">
          <ScrollToBottom className="messages">
            <ul className="message-ul">
              {comments.map((it) => {
                return (
                  <li
                    className={
                      userInfo?._id === it.user_id ? 'replies' : 'sent'
                    }
                  >
                    <img src={it.avatar ? it.avatar : placeholder} alt="" />
                    <div className="msg-c-box message-box-main">
                      {userInfo?._id !== it.user_id ? (
                        <>
                          <span class="user-name">{it.author}</span>
                          <p className="msg-content">
                            <span class="user-message">{it.content}</span>
                          </p>
                        </>
                      ) : (
                        <p className="msg-content">
                          <span class="user-message">{it.content}</span>
                          {/* <span class="user-name">{it.author}</span> */}
                        </p>
                      )}
                      <div className="msg-date-main">
                        <small className="msg-date">
                          {/* {moment(it.datetime).format('LLL')} */}
                          {dateToFromNowDaily(it.datetime)}
                        </small>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </ScrollToBottom>
          <div className="message-input">
            <div className="wrap">
              <form onSubmit={handleSubmit} className="chat-message-input-box">
                <input
                  className="message-field"
                  value={value}
                  type="text"
                  placeholder="Write your message..."
                  onChange={handleChange}
                />
                {/* <i className="fa fa-paperclip attachment" aria-hidden="true"></i> */}
                <button
                  onSubmit={handleSubmit}
                  className="submit message-button"
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* {comments.length > 0 && <CommentList comments={comments} />}
			<Comment avatar={<Avatar src={userInfo?.profile_image_url} alt={userInfo?.username} />} content={<Editor onChange={handleChange} onSubmit={handleSubmit} submitting={submitting} value={value} />} /> */}
    </div>
  );
}
function mapStateToProps({ myChatToken }) {
  return { myChatToken };
}
export default connect(mapStateToProps)(Chat);
