import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

function Logout() {
const history = useNavigate();

useEffect(() => {
  localStorage.removeItem("userInfo");
  // history("/");
   window.location.reload();
}, []);

    return (
      <></>
    );
}
export default Logout;

