import { GET_INSTA_POST } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const createMedia = (data) => async (dispatch) => {
  // const token = localStorage.getItem("token");
  const body = new FormData();
  {data.title && body.append("title", data.title)};
  body.append("image", data.image);
  body.append("media_type", data.media_type);

  const res = await axios.post(`${BASEURL}library/reserve`, body,{headers: { Authorization: `Bearer ${userInfo?.token}`},});
  return res;
};

export const getMedia =
  (status, page = 1, limit = 8) =>
  async (dispatch) => {
    // const token = localStorage.getItem("token");

    const res = await axios.get(
      `${BASEURL}library/receive?status=${status}&limit=${limit}&page=${page}`,{headers: { Authorization: `Bearer ${userInfo?.token}`},}
    );
    dispatch({
      type: GET_INSTA_POST,
      payload: res.data.message,
    });
  };

export const deleteMedia = (id) => async (dispatch) => {
  // const token = localStorage.getItem("token");

  const res = await axios.delete(`${BASEURL}library/remove/${id}`,{headers: { Authorization: `Bearer ${userInfo?.token}`},});
  return res;
};
