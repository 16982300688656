import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import initFacebookSDK from './fbSDK/initFacebookSDK';

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

const renderApp = () => {
  ReactDOM.render(root.render(<App />));
};

initFacebookSDK().then(() => {
  renderApp();
});
