import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStepsForm } from 'sunflower-antd';
import step1 from '../../../images/step1.svg';
import step2 from '../../../images/step2.svg';
import step3 from '../../../images/step3.svg';
import previewImg from '../../../images/upload-banner.png';
import sampleImg from '../../../images/banner.jpg';
import AsyncProduct from '../scheduleEvent/asyncProduct';
import AsyncHost from '../scheduleEvent/asyncHost';
import AsyncHost2 from '../scheduleEvent/asyncHost2';
import AsyncHost3 from '../scheduleEvent/asyncHost3';
import AsyncHost4 from '../scheduleEvent/asyncHost4';
import {
  InboxOutlined,
  UploadOutlined,
  PlusOutlined,
  CalendarFilled,
} from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { postEvent } from '../../../redux/actions/event.action';
import { getParentCategories } from '../../../redux/actions/category.action';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getEventsBy_Id,
  UpdateEvents,
} from '../../../redux/actions/event.action';
import {
  faAngleLeft,
  faCalendarPlus,
  faCaretRight,
  faShare,
  faTriangleExclamation,
  faXmark,
  faVideo,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
} from 'antd';
import moment from 'moment';
import useMediaQuery from '../../../reUsable/useMediaQuery';

const columns = [
  {
    title: 'Image',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
    width: '4%',
    render: (text, record) => {
      return (
        <div className="listing-image">
          <img src={record.mediaUrl} />
        </div>
      );
    },
  },
  {
    title: 'SKU',
    dataIndex: 'ProductSku',
    key: 'ProductSku',
    width: '4%',
  },
  {
    title: 'Description',
    dataIndex: 'productDesc',
    width: '10%',
    key: 'productDesc',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: '4%',
  },
  // {
  //   title: 'Stock',
  //   dataIndex: 'stock',
  //   key: 'stock',
  //   width: '4%',
  // },
];
const data = [
  {
    key: 1,
    image: `https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287`,
    sku: 60,
    description: 'New York No. 1 Lake Park',
    price: 160,
    stock: 260,
  },
  {
    key: 2,
    image: `https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287`,
    sku: 32,
    description: 'Sidney No. 1 Lake Park',
    price: 160,
    stock: 260,
  },
  {
    key: 3,
    image: `https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287`,
    sku: 32,
    description: 'Sidney No. 1 Lake Park',
    price: 160,
    stock: 260,
  },
  {
    key: 3,
    image: `https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287`,
    sku: 32,
    description: 'Sidney No. 1 Lake Park',
    price: 160,
    stock: 260,
  },
  {
    key: 3,
    image: `https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287`,
    sku: 32,
    description: 'Sidney No. 1 Lake Park',
    price: 160,
    stock: 260,
  },
]; // rowSelection objects indicates the need for row selection

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};

const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export default function ScheduleEvent(props) {
  const matches = useMediaQuery('(min-width: 1024px)');
  const deadline = '2018-12-20T07:37:50.886Z';

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCheckedOpen, setIsCheckedOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const OpenSubmit = () => {
    setIsCheckedOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false);

    history('/events');
  };

  const handleCancelInfo = () => {
    setIsCheckedOpen(false)
  }

  const [top, setTop] = useState(30);

  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useNavigate();
  const { parentCategory } = useSelector((state) => {
    return state;
  });

  const [spinner, setSpin] = useState(false);
  const [dateTime, setDate] = useState('');
  const [formState, setFormState] = useState('add');
  const [sku, setSku] = useState([]);
  const [banner, setBanner] = useState([]);
  const [host, setHost] = useState([]);
  const [hostError, setHostError] = useState(false);
  const [host2, setHost2] = useState([]);
  const [hostError2, setHostError2] = useState(false);
  const [host3, setHost3] = useState([]);
  const [hostError3, setHostError3] = useState(false);
  const [host4, setHost4] = useState([]);
  const [hostError4, setHostError4] = useState(false);
  const [skuError, setSkuError] = useState(false);
  const [parent, setParent] = useState('');

  const [imageUpload, setImageUpload] = useState(false);
  const [sampleBanner, setSampleBanner] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [note, setEventNote] = useState('');
  const [thankyou, setEventThankyou] = useState('');
  const [infoTitle, setInfoTitle] = useState('');
  const [infoDesc, setInfoDesc] = useState('');
  const [activeKey, setActiveKey] = useState('1');
  const [platform, setPlatform] = useState(false);


  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { getEventById, updateEvent } = useSelector((state) => {
    return state;
  });
  const params = useParams();
  const id = params.id;

  const onDateTimeChange = (e) => {
    if (e !== null) {
      setDate(e._d);
    } else {
      setDate('');
    }
  };

  const getSku = (skuData) => {
    setSkuError(false);
    //console.log(skuData, "skuData");
    setSku(skuData);
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );
    setParent(parent[0]?.category_name);
  };

  const onChangeTitle = (e) => {
    setInfoTitle(e.target.value)
  }

  const onChangeDescription = (e) => {
    setInfoDesc(e.target.value)
  }

  const getHostPrimary = (hostData) => {
    setHostError(false);
    // console.log(skuData, "skuData");
    if (hostData[0] == null) {
      setHost([]);
    } else {
      setHost(hostData);
    }
  };
  const getHost2 = (hostData) => {
    setHostError2(false);
    if (hostData[0] == null) {
      setHost2([]);
    } else {
      setHost2(hostData);
    }
  };
  const getHost3 = (hostData) => {
    setHostError3(false);
    // console.log(skuData, "skuData");
    if (hostData[0] == null) {
      setHost3([]);
    } else {
      setHost3(hostData);
    }
  };
  const getHost4 = (hostData) => {
    setHostError4(false);
    // console.log(skuData, "skuData");
    if (hostData[0] == null) {
      setHost4([]);
    } else {
      setHost4(hostData);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    listType: 'picture',
    // fileList={fileList}
    maxCount: 1,
    action: 'https://httpbin.org/post',

    beforeUpload: (file) => {
      const isPNG =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/svg+xml' ||
        file.type === 'image/svg';

      if (!isPNG) {
        message.error(`${file?.name} is not a valid format`);
      }

      return isPNG || Upload.LIST_IGNORE;
    },

    onChange(info) {
      setImageUpload(true);
      const { status } = info?.file;

      if (status !== 'uploading') {
        // console.log(info.file.originFileObj, "file");
        setBanner(info?.file?.originFileObj);
      }

      if (status === 'done') {
        message.success(`${info?.file.name} file uploaded successfully.`);
        setImageUpload(false);
        setPreviewImage(info?.file?.response?.files?.banner);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },

    onRemove(e) {
      // console.log(e, "onRemove");
      // return Upload.LIST_IGNORE;
      setPreviewImage('');
    },
  };

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      setSpin(true);
      const { title, category_id } = values;
      let data = {
        primary_host: host,
      };
      let data2 = {
        host1: host2,
      };
      let data3 = {
        host2: host3,
      };
      let data4 = {
        host3: host4,
      };
      let formData = new FormData();
      formData.append('image', banner);
      formData.append('id', sku[0]?.id);
      formData.append('primary_host', JSON.stringify(host));
      formData.append('host1', JSON.stringify(host2));
      formData.append('host2', JSON.stringify(host3));
      formData.append('host3', JSON.stringify(host4));
      formData.append('products', JSON.stringify(sku));
      formData.append('title', title);
      formData.append('notes', note);
      formData.append('thankyou_message', thankyou);
      formData.append('start_date', dateTime.toISOString());
      formData.append('category_id', category_id);
      formData.append('default_banner', sampleBanner);
      // formData.append('social_platform', platform);
      formData.append('is_facebook_live', platform);
      if (platform) {
        formData.append('facebook', JSON.stringify({ title: infoTitle, description: infoDesc }));
      }
      if (formState === 'edit') {
        dispatch(UpdateEvents(formData, id)).then((res) => {
          if (res.type == 'UPDATE_EVENT_SUCCESS') {
            notification.success({
              message: 'Event Updated Successfully',
              className: 'toast-success',
            });

            showModal();
            setSpin(false);

            // handleClose();
            // history("/events");
          } else {
            setSpin(false);
            notification.error({
              message: 'Event Failed',
              className: 'toast-error',
            });
          }
        });
      } else {
        dispatch(postEvent(formData)).then((res) => {
          if (res.type == 'POST_EVENT_SUCCESS') {
            notification.success({
              message: res?.payload?.message,
              className: 'toast-success',
            });
            showModal();
            setSpin(false);

            // handleClose();
            //history("/home");
          } else {
            setSpin(false);
            notification.error({
              message: 'Event Failed',
              className: 'toast-error',
            });
          }
        });
      }
    },
    total: 3,
  });

  useEffect(() => {
    if (id) {
      dispatch(getEventsBy_Id(id)).then(async (event) => {
        if (event.type === 'GET_EVENT_ID_SUCCESS') {
          form.setFieldsValue({
            title: event.payload.message[0]?.event?.title,
            notes: event.payload.message[0]?.event?.notes,
            primary:
              event.payload?.message[0]?.hosts[1]?.primary === true
                ? event.payload?.message[0]?.hosts[1].user_email
                : [],
            host2:
              event.payload?.message[0]?.hosts[2]?.host1 === true
                ? event.payload?.message[0]?.hosts[2].user_email
                : [],
            host3:
              event.payload?.message[0]?.hosts[3]?.host2 === true
                ? event.payload?.message[0]?.hosts[3].user_email
                : [],
            host4:
              event.payload?.message[0]?.hosts[4]?.host3 === true
                ? event.payload?.message[0]?.hosts[4].user_email
                : [],

            thankyou_message: event.payload.message[0]?.event?.thankyou_message,
            datetime: moment(
              moment(event.payload.message[0]?.event?.start_date).format(
                'MM/DD/YYYY hh:mm a'
              )
            ),
            banner: event.payload?.message[0]?.event?.banner,
            category_id: event.payload?.message[0]?.event?.category_id,
            default_banner: event.payload?.message[0]?.event?.default_banner,
          });
          setSampleBanner(
            event.payload?.message[0]?.event?.default_banner ? true : false
          );

          setPreviewImage(event.payload?.message[0]?.event?.banner);
          setEventThankyou(event.payload.message[0]?.event?.thankyou_message);
          setEventTitle(event.payload.message[0]?.event?.title);
          setEventNote(event.payload.message[0]?.event?.notes);
          setDate(moment(moment(event.payload.message[0]?.event?.start_date)));
          setBanner(event.payload?.message[0]?.event?.banner);
          setSku(event.payload.message[0]?.event?.products);

          let primary_data =
            (await event.payload?.message[0]?.hosts[1]?.primary) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let primary_host = await primary_data.filter(
            (num) => num.primary === true
          );

          let host2_data =
            (await event.payload?.message[0]?.hosts[2]?.host1) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let host_2 = await host2_data.filter((num) => num.host1 === true);

          let host3_data =
            (await event.payload?.message[0]?.hosts[3]?.host2) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let host_3 = await host3_data.filter((num) => num.host2 === true);

          let host4_data =
            (await event.payload?.message[0]?.hosts[4]?.host3) === true
              ? event.payload?.message[0]?.hosts
              : [];
          let host_4 = await host4_data.filter((num) => num.host3 === true);

          setHost(primary_host);
          setHost2(host_2);
          setHost3(host_3);
          setHost4(host_4);
          setFormState('edit');

          let parent = await parentCategory?.payload?.message.filter(
            (item) =>
              item.category_id == event.payload?.message[0]?.event?.category_id
          );
          setParent(parent[0]?.category_name);
        }
      });
    } else {
      form.setFieldsValue({
        title: null,
        datetime: null,
        category_id: undefined,
        banner: null,
      });
      setFormState('add');
      console.log(formState, '2');
    }
    dispatch(getParentCategories()).then((res) => {
      if (res.payload.success) {
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  }, [id]);

  function startDateLimit(current) {
    return current && current < moment().startOf('day');
  }

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  // const getDisabledMinutes = (selectedHour) => {
  //   var minutes = [];
  //   if (selectedHour === moment().hour()) {
  //     for (var i = 0; i < moment().minute(); i++) {
  //       minutes.push(i);
  //     }
  //   }
  //   return minutes;
  // }

  // const getDisabledHours = () => {
  //   var hours = [];
  //   for (let i = 0; i < moment().hour(); i++) {
  //     hours.push(i);
  //   }
  //   return hours;
  // }
  const now = moment();
  const disabledDateTime = (current) => ({
    disabledHours: () => range(0, now.get('hours')),
    disabledMinutes: () => range(0, now.get('minutes') + 30),
    // disabledSeconds: () => [55, 56],
    // if (moment(current).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD"))
    //   return {
    //     disabledHours: () => hours,
    //     disabledMinutes: () => minutes,
    //   }
  });

  const changeSampleBanner = (e) => {
    if (e.target.checked) {
      setSampleBanner(true);
      setPreviewImage(
        'https://static.konnect.bio/eventdefaultbanner/banner.png'
      );
    } else {
      setSampleBanner(false);
      setPreviewImage('');
      form.setFieldsValue({
        banner: null,
      });
    }
  };
  function dateToFromNowDaily(myDate) {
    // get from-now for this date
    var fromNow = moment(myDate).fromNow();

    // ensure the date is displayed with today and yesterday
    return moment(myDate).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: '[Last] dddd',
      lastDay: '[Yesterday]',
      sameDay: '[Today]',

      nextDay: '[Tomorrow]',
      nextWeek: 'dddd',
      // when the date is further away, use from-now functionality
      sameElse: function () {
        return '[' + fromNow + ']';
      },
    });
  }

  const changePlatform = (e) => {
    setPlatform(e.target.checked);
    if (e.target.checked == true) {
      setIsCheckedOpen(true);
    }

  };

  const formList = [
    <>
      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Event Information</h5>
          <p>
            Make a major impact with your opening line! Give your event an
            engaging title.
            {/* Make a bold statement with your introduction line ! The event title
            will appear on your tab, your social share and the homescreen name.
            So stand out, make your entrance to the world 😃 😃{" "} */}
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Event Title"
            initialValue={
              formState === 'add' ? null : form.getFieldValue().title
            }
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter event title',
              },
            ]}
          >
            <Input
              placeholder="e.g. MKG1402 -LIVE- Winter 2020 Clearance"
              size="large"
              showCount
              maxLength={80}
              onChange={(e) => setEventTitle(e.target.value)}
            />
          </Form.Item>

          {/* <div className="event-url-box">
            <h3>Event URL</h3>
            <div className="form-styling event-url-main">
              <div className="row no-gutters">
                <Form.Item className="col-md-5 mb-md-0" name="url">
                  <Input
                    disabled
                    placeholder="https://www.live.kbshop.com/"
                    size="large"
                  />
                </Form.Item>
                <Form.Item className="col-md-7 mb-md-0">
                  <Input placeholder="spring-summer" size="medium" />
                </Form.Item>
              </div>
            </div>
            <p className="condition-txt">
              No space or special characters except "_", lowercase only
            </p>
            <div className="yellow-warning-txt">
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <p>
                Once the event is created, these information CANNOT be modified
                anymore. Be sure that the values are correct!
              </p>
            </div>
          </div> */}
        </div>
      </div>

      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Categories</h5>
          <p>Select category from provided list.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            // initialValue={
            //   formState === "add" ? undefined : form.getFieldValue().category_id
            // }
            initialValue={form.getFieldValue().category_id}
            label="Category"
            name="category_id"
            rules={[
              {
                required: true,
                message: 'Please select a category',
              },
            ]}
          >
            <Select
              className="select-style dark-field"
              placeholder="Select Category"
              size="large"
              focus={false}
              onChange={handleCategory}
              onBlur={false}
              loading={parentCategory?.loading ? true : false}
              disabled={parentCategory?.loading ? true : false}
            >
              {parentCategory?.payload?.message.map((item, i) => {
                return (
                  <Option value={item.category_id}>{item.category_name}</Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column flex-stretch">
          <h5 className="rounded">Details</h5>
          <p>
            This information will be visible to your audience in ‘Details’ tab
            when event is Live.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Details"
            initialValue={
              formState === 'add' ? null : form.getFieldValue().notes
            }
            name="notes"
          >
            <TextArea
              showCount
              maxLength={200}
              // onChange={onChange}
              className="dark-field"
              placeholder="Get rid of 2021 inventory. Use Promo Code : WINTER2022 - Promo Code description : 20% OFF of the entire collection"
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => setEventNote(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Date and Time</h5>
          <p>Set the date and time of the event.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          {/* <Form.Item name="datetime">
              <DatePickergit 
                format="YYYY-MM-DD HH:mm:ss"
                // disabledDate={disabledDate}
                // disabledTime={disabledDateTime}
                showTime={{ use12Hours: true, format: "HH:mm" }}
                defaultValue={moment("00:00:00", "HH:mm:ss")}
                onChange={onDateTimeChange}
              />
            </Form.Item> */}
          <Form.Item
            name="datetime"
            initialValue={
              formState === 'add' ? null : form.getFieldValue().datetime
            }
            rules={[
              {
                required: true,
                message: 'Please select data & time',
              },
              {
                validator: async (_, datetime) => {
                  var travelTime = moment().add(30, 'minutes');

                  var diffTime = travelTime.diff(moment(datetime));
                  if (diffTime > 30) {
                    return Promise.reject(
                      new Error('Schedule the event after 30 minutes')
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
            labelCol={{ span: 24 }}
            label="Date and Time"
          >
            <DatePicker
              style={{
                width: '100%',
              }}
              showTime={{ use12Hours: true, format: 'HH:mm' }}
              // disabledTime={disabledDateTime}
              onChange={(e) => onDateTimeChange(e)}
              disabledDate={startDateLimit}
              // disabledHours={getDisabledHours}
              // disabledMinutes={getDisabledMinutes}
              // disabledTime={getDisabledHours}

              format="YYYY-MM-DD h:mm A"

            // disabledDate={(current) => {
            //   let customDate = moment().format("YYYY-MM-DD HH:mm:ss");
            //   return (
            //     current &&
            //     current < moment(customDate, "YYYY-MM-DD HH:mm:ss")
            //   );
            // }}
            // defaultValue={moment("00:00:00", "HH:mm:ss")}
            />
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Platform</h5>
          <p>Set the platform to which the event will be published.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling date-time-mai">
          <Form.Item 
          name="platform" 
          labelCol={{ span: 24 }} 
          valuePropName="checked"
          label="Platform"
          rules={[
            // {
            //   required: true,
            //   message: 'Please set the platform',
            // },
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Please set the platform')),
            },
          ]}
          >
            <Checkbox checked={platform} onChange={changePlatform}>
              Facebook
            </Checkbox>
          </Form.Item>
        </div>
      </div>

      <div className="row d-flex mb-30">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Assign Host</h5>
          <p>
            Specify event Host. Please note these Hosts’ event addresses must be
            registered on kbshop.com as a Shopper or an Influencer.
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          {/* <Form.Item
            labelCol={{ span: 24 }}
            // initialValue={"Host"}
            label="Link a host"
            name="host"
            rules={[
              {
                required: true,
                message: "Please select a host",
              },
            ]}
          >
            <Select
              className="select-style dark-field"
              placeholder="Select a Host"
              size="large"
            >
              <Option value="HostName">Host Name</Option>
            </Select>
          </Form.Item> */}

          <div className="row">
            <div className="col-md-6 col-xl-6">
              <Form.Item labelCol={{ span: 24 }} label="Brand" name="producer">
                <Input
                  placeholder="Producer"
                  size="large"
                  disabled
                  defaultValue={userInfo?.name}
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                labelCol={{ span: 24 }}
                label="Producer"
                name="producer"
              >
                <Input
                  placeholder="Producer"
                  size="large"
                  disabled
                  defaultValue={userInfo?.email}
                />
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="primary"
                label="Primary Host"
                labelCol={{ span: 24 }}
                className="field-style2"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select Primary Host",
              //   },
              // ]}
              >
                {host.length > 0 && formState == 'edit' ? (
                  <>
                    <AsyncHost
                      getHostPrimary={getHostPrimary}
                      host={host}
                      form={formState}
                    />
                    {hostError && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Please select a host
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <AsyncHost
                      getHostPrimary={getHostPrimary}
                      host={host}
                      form={formState}
                    />
                    {hostError && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Enter a host Email
                      </div>
                    )}
                  </>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="host2"
                label="Host 2"
                labelCol={{ span: 24 }}
                className="field-style2"
              // className="d-flex flex-column sku-field"
              >
                {host2.length > 0 && formState == 'edit' ? (
                  <>
                    <AsyncHost2
                      getHost2={getHost2}
                      host2={host2}
                      form={formState}
                    />
                    {hostError2 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Please select a host
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <AsyncHost2
                      getHost2={getHost2}
                      host2={host2}
                      form={formState}
                    />
                    {hostError2 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Enter a host Email
                      </div>
                    )}
                  </>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="host3"
                label="Host 3"
                labelCol={{ span: 24 }}
                className="field-style2"
              // className="d-flex flex-column sku-field"
              >
                {host3.length > 0 && formState == 'edit' ? (
                  <>
                    <AsyncHost3
                      getHost3={getHost3}
                      host3={host3}
                      form={formState}
                    />
                    {hostError3 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Please select a host
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <AsyncHost3
                      getHost3={getHost3}
                      host3={host3}
                      form={formState}
                    />
                    {hostError3 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Enter a host Email
                      </div>
                    )}
                  </>
                )}
              </Form.Item>
            </div>
            <div className="col-md-6 col-xl-6">
              <Form.Item
                name="host4"
                label="Host 4"
                labelCol={{ span: 24 }}
                className="field-style2"
              // className="d-flex flex-column sku-field"
              >
                {host4.length > 0 && formState == 'edit' ? (
                  <>
                    <AsyncHost4
                      getHost4={getHost4}
                      host4={host4}
                      form={formState}
                    />
                    {hostError4 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Please select a host
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <AsyncHost4
                      getHost4={getHost4}
                      host4={host4}
                      form={formState}
                    />
                    {hostError4 && (
                      <div role="alert" className="ant-form-item-explain-error">
                        Enter a host Email
                      </div>
                    )}
                  </>
                )}
              </Form.Item>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Guest Access</h5>
          <p>Give to a specific guest access to the event. This will send an invitation to notify the guest.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling">
        
        <Form.Item
            labelCol={{ span: 24 }}
            initialValue={"Guest"}
            label="Link a guest"
            rules={[
              {
                required: true,
                message: "Please select a category",
              },
            ]}
          >
            <Select
              className="select-style dark-field"
              placeholder="Select a Guest"
              size="large"
            >
              <Option value={"Guest Name"}>Guest Name</Option>
              
            </Select>
          </Form.Item>
        </div>
      </div> */}

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="#" className="prev-next-link ff-nunito">
            {/* <FontAwesomeIcon icon={faAngleLeft} />
            <span onClick={() => prevStep()}> Previous</span> */}
          </Link>
          {/*           
          <Link
            to="#"
            className="default-btn d-inline-flex m-0 align-items-center justify-content-center"
            onClick={showModal}
          >
            Show Modal

          </Link> */}

          <Button
            // disabled
            className="default-btn d-flex align-items-center justify-content-center"
            type="primary"
            size="large"
            icon={
              <FontAwesomeIcon
                size="sm"
                className="event-icon"
                icon={faCaretRight}
              />
            }
            // onClick={() => gotoStep(current + 1)}
            onClick={() => {
              if (host.length === 0) {
                if (formState == 'edit') {
                  gotoStep(current + 1);
                }
                setHostError(true);
              } else {
                gotoStep(current + 1);
              }
            }}
          >
            Next
          </Button>
        </div>
      </Form.Item>
    </>,

    <>
      <div className="row d-flex mb-60">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Pre LIVE</h5>
          <p>Upload event banner and let your audience identify your brand.</p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <h5>Banner</h5>
          <p className="color-light">1280px x 720px (jpg or png format)</p>
          {!sampleBanner && (
            <>
              <Form.Item>
                <Form.Item
                  name="banner"
                  valuePropName={formState !== 'edit' && 'fileList'}
                  //initialValue={formState === "add" ? null : form.getFieldValue().banner}
                  //isImageUrl={formState === "add" ? null : "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"}
                  getValueFromEvent={normFile}
                  noStyle
                  multiple={false}
                  rules={[
                    {
                      required: true,
                      message: 'Please Upload Banner Image',
                    },
                  ]}
                >
                  <Upload.Dragger
                    className="upload-image-main"
                    {...uploadProps}
                    name="banner"
                    // defaultFileList={
                    //   formState === "edit" &&
                    //   form.getFieldValue()?.banner === null
                    //     ? null
                    //     : [
                    //         {
                    //           url: form.getFieldValue()?.banner,
                    //         },
                    //       ]
                    // }
                    defaultFileList={
                      formState === 'edit'
                        ? sampleBanner || form.getFieldValue()?.banner === null
                          ? form.getFieldValue()?.banner
                          : form.getFieldValue()?.banner?.length === 1
                            ? form.getFieldValue()?.banner
                            : [
                              {
                                url: form.getFieldValue()?.banner,
                              },
                            ]
                        : null
                    }
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  >
                    {/* <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p> */}
                    <p className="ant-upload-text">drag files here to upload</p>
                    {/* <p className="ant-upload-hint">
                      Support for a single or bulk upload.
                    </p> */}
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </>
          )}
          {sampleBanner && (
            <Form.Item className="preview-image-main" name="banner">
              <Image
                // width={100}
                // src="https://static.konnect.bio/eventdefaultbanner/banner.jpg"
                // src="https://static.konnect.bio/900000005/event/dddc020e-215a-410c-9fda-7d3ab8d61b54.png"
                src={previewImage}
              />
            </Form.Item>
          )}
          <Checkbox checked={sampleBanner} onChange={changeSampleBanner}>
            Use a sample image
          </Checkbox>
          {/* <Form.Item
            name="banner"
            rules={[
              {
                required: true,
                message: "Please Upload Banner Image",
              },
            ]}
          >
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload.
              </p>
            </Dragger>
          </Form.Item> */}
        </div>
      </div>
      <div className="row d-flex mb-30">
        <div className="col-lg-4 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Post LIVE</h5>
          <p>
            Thank everyone for attending the event and bring them back to your
            store in future!
          </p>
        </div>
        <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Thank you message"
            className="mb-40"
            initialValue={
              formState === 'add' ? null : form.getFieldValue().thankyou_message
            }
            name="thankyou_message"
          >
            <TextArea
              showCount
              maxLength={250}
              // onChange={onChange}
              className="dark-field"
              placeholder="The live is now over. Thank you for coming to our show."
              autoSize={{ minRows: 4, maxRows: 6 }}
              onFocus={() => setActiveKey('3')}
              onChange={(e) => {
                setEventThankyou(e.target.value);
              }}
            />
          </Form.Item>
        </div>
      </div>
      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          {imageUpload && !sampleBanner ? (
            <Link to="#" className="prev-next-link ff-nunito" disabled>
              <FontAwesomeIcon icon={faAngleLeft} />
              <span> Previous</span>
            </Link>
          ) : (
            <Link to="#" className="prev-next-link ff-nunito">
              <FontAwesomeIcon icon={faAngleLeft} />
              <span onClick={() => gotoStep(current - 1)}> Previous</span>
            </Link>
          )}

          <Button
            // disabled
            className="default-btn d-flex align-items-center justify-content-center"
            type="primary"
            size="large"
            icon={
              <FontAwesomeIcon
                size="sm"
                className="event-icon"
                icon={faCaretRight}
              />
            }
            onClick={() => gotoStep(current + 1)}
            disabled={imageUpload && !sampleBanner ? true : false}
          >
            Next
          </Button>
        </div>
      </Form.Item>
    </>,

    <>
      <div className="row d-flex mb-30">
        <div className="col-12 col-xl-3  pr-xl-4 guide-column  flex-stretch">
          <h5 className="rounded">Add product SKU</h5>
          <p>
            Create product list to live event by using SKU numbers as added on
            the ecommerce platform.
          </p>
        </div>
        <div className="col-12 col-xl-9 pl-xl-5 flex-stretch form-styling">
          <Form.Item>
            <Form.Item
              name="productsku"
              label="Product SKU"
              className="d-flex flex-column sku-field"
            >
              <AsyncProduct getSku={getSku} sku={sku} />
              {skuError && (
                <div role="alert" className="ant-form-item-explain-error">
                  Please Select Product SKU
                </div>
              )}
            </Form.Item>
          </Form.Item>

          <div className="row d-flex mb-20">
            <div className="col">
              {sku?.length > 0 && (
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  //  rowSelection={{ ...rowSelection }}
                  dataSource={sku}
                  scroll={
                    sku.length > 4
                      ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                      : false
                  }
                />
              )}

              {/* <div className="style-table table-responsive">
                <table class="table table-fixed  table-bordered mt-5">
                  <thead>
                    <tr>
                      <th className="col-1 text-center image">Image</th>
                      <th className="col-2 sku">SKU</th>
                      <th className="col-5 description">Description</th>
                      <th className="col-2 price">Price</th>
                      <th className="col-2 stock">Stock</th>

                    </tr>
                  </thead>
                  <tbody className="scrollbar-style">
                    <tr>

                      <td className="col-1 text-center image">
                        <div className="listing-image">
                          <img
                            src={'https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287'}
                            alt={'product name'}

                          />
                        </div>
                      </td>
                      <td className="col-sm-2 align-self-stretch sku">1245</td>
                      <td className="col-5 align-self-stretch description">Description text</td>
                      <td className="col-sm-2 align-self-stretch price">128.00</td>
                      <td className="col-sm-2 align-self-stretch stock">250</td>
                    </tr>
                    <tr>

                      <td className="col-1 text-center image">
                        <div className="listing-image">
                          <img
                            src={'https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287'}
                            alt={'product name'}

                          />
                        </div>
                      </td>
                      <td className="col-sm-2 align-self-stretch sku">1245</td>
                      <td className="col-5 align-self-stretch description">Description text</td>
                      <td className="col-sm-2 align-self-stretch price">128.00</td>
                      <td className="col-sm-2 align-self-stretch stock">250</td>
                    </tr>
                    <tr>

                      <td className="col-1 text-center image">
                        <div className="listing-image">
                          <img
                            src={'https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287'}
                            alt={'product name'}

                          />
                        </div>
                      </td>
                      <td className="col-sm-2 align-self-stretch sku">1245</td>
                      <td className="col-5 align-self-stretch description">Description text</td>
                      <td className="col-sm-2 align-self-stretch price">128.00</td>
                      <td className="col-sm-2 align-self-stretch stock">250</td>
                    </tr>
                    <tr>

                      <td className="col-1 text-center image">
                        <div className="listing-image">
                          <img
                            src={'https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287'}
                            alt={'product name'}

                          />
                        </div>
                      </td>
                      <td className="col-sm-2 align-self-stretch sku">1245</td>
                      <td className="col-5 align-self-stretch description">Description text</td>
                      <td className="col-sm-2 align-self-stretch price">128.00</td>
                      <td className="col-sm-2 align-self-stretch stock">250</td>
                    </tr>

                    <tr>

                      <td className="col-1 text-center image">
                        <div className="listing-image">
                          <img
                            src={'https://cdn.shopify.com/s/files/1/2397/3099/products/20220122_DL1961_12151_KARLIE_PURO_006.jpg?v=1643732287'}
                            alt={'product name'}

                          />
                        </div>
                      </td>
                      <td className="col-sm-2 align-self-stretch sku">1245</td>
                      <td className="col-5 align-self-stretch description">Description text</td>
                      <td className="col-sm-2 align-self-stretch price">128.00</td>
                      <td className="col-sm-2 align-self-stretch stock">250</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <Form.Item>
        <div className="d-flex justify-content-between align-items-center">
          <Link to="#" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span onClick={() => gotoStep(current - 1)}> Previous</span>
          </Link>

          <Modal
            className="modal-generic modal-500"
            centered
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={[
              // <Button key="back" onClick={handleCancel}>
              //   Cancel
              // </Button>,
              <Button
                key="submit"
                type="primary"
                className="d-table m-auto"
                // onClick={handleOk}
                onClick={() => history('/events')}
              >
                Go to Event
              </Button>,
            ]}
          >
            <h2 className="modal-hd1">Your event is ready!</h2>
            <div className="preview-content-main-modal">
              <h3 className="preview-title">
                {eventTitle === '' ? '[Preview Event Title]' : eventTitle}
              </h3>
              <div className="preview-date">
                <span className="text-uppercase">
                  {' '}
                  {dateTime === '' ? 'Today' : dateToFromNowDaily(dateTime)}
                </span>
                <br></br>
                {dateTime === ''
                  ? '11:40 AM'
                  : moment(dateTime).format('hh:mm A')}
              </div>
              <div className="preview-txt p-2">
                The live event should start soon !
              </div>
            </div>
          </Modal>

          <div className="d-flex ">
            {formState == 'edit' ? (
              // disabled

              <Link
                className="default-btn outline text-center link-btn res-btn1"
                type="primary"
                size="large"
                icon={<FontAwesomeIcon size="sm" className="event-icon" />}
                to="/events?type=Upcoming"
              >
                Cancel
              </Link>
            ) : (
              <></>
            )}
            <Spin spinning={spinner}>
              <Button
                // disabled
                className="default-btn d-flex align-items-center justify-content-center  res-btn2"
                type="primary"
                size="large"
                icon={
                  <FontAwesomeIcon
                    size="sm"
                    className="event-icon"
                    icon={faCaretRight}
                  />
                }
                onClick={() => {
                  if (sku.length === 0) {
                    setSkuError(true);
                  } else {
                    submit();
                  }
                }}
              >
                {formState == 'add' ? <>Create Event</> : <>Update Event</>}
              </Button>
            </Spin>
          </div>
        </div>
      </Form.Item>
    </>,
  ];

  return (
    <>
      <div className="create-event-top-area mb-4 d-flex justify-content-center">
        {/* <div className="top-area-box">
          <Link to="/events?type=Upcoming" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span> Go back</span>
          </Link>
        </div> */}
        <div className="top-area-box ">
          <div className="title-area mb-0 pb-0">
            <h1 className="mb-0 pb-0">Create Event</h1>
          </div>
        </div>
        <div className="top-area-box">
          &nbsp;
          {/* <a className="prev-next-link ff-nunito" href="#">
            <FontAwesomeIcon icon={faCaretRight} />
            <span> How to create an event</span>
          </a> */}
        </div>
      </div>

      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left">
          <Steps {...stepsProps} className="event-stepss" responsive={false}>
            <Step
              title={
                <>
                  <img className="step-img" src={step1} alt="step1" />
                  <div className="setup-hd">Setup</div>
                </>
              }
            />
            <Step
              title={
                <>
                  <img className="step-img" src={step2} alt="step2" />
                  <div className="setup-hd">Design</div>
                </>
              }
            />
            <Step
              title={
                <>
                  <img className="step-img" src={step3} alt="step3" />
                  <div className="setup-hd">Products</div>
                </>
              }
            />
          </Steps>

          <Form {...formProps}>{formList[current]}</Form>
        </div>
        <div className="create-event-box-right">
          {matches ? (
            <Affix offsetTop={top}>
              <div>
                <h2 className="preview-hd">Preview</h2>
                <Tabs
                  activeKey={activeKey}
                  onChange={(k) => setActiveKey(k)}
                  centered
                  size="medium"
                  className="preview-tabs-main"
                >
                  <TabPane tab="Before" key="1">
                    <div className="preview-mobile-box">
                      <div className="preview-box">
                        <div
                          className={`preview-img ${sampleBanner ? 'sample-image' : ''
                            }`}
                        >
                          {/* <img src={previewImg} alt="Upload your banner" /> */}
                          <img
                            src={
                              previewImage === '' ? previewImg : previewImage
                            }
                            alt="Upload your banner"
                          />
                        </div>
                        <div className="preview-content-main">
                          <h3 className="preview-title">
                            {eventTitle === ''
                              ? '[Preview Event Title]'
                              : eventTitle}
                          </h3>
                          <div className="preview-date">
                            <span className="text-uppercase">
                              {' '}
                              {dateTime === ''
                                ? 'Today'
                                : dateToFromNowDaily(dateTime)}
                            </span>
                            <br></br>
                            {dateTime === ''
                              ? '11:40 AM'
                              : moment(dateTime).format('hh:mm A')}
                          </div>
                          <div className="preview-txt p-2">
                            The live event should start soon !
                          </div>

                          <div className="buttons-main mt-4 mb-4">
                            {/* <Button
                              className="default-btn color-secondary ml-0 mr-0 text-uppercase d-flex align-items-center justify-content-center mb-3"
                              block
                              type="primary"
                              // size="medium"
                              icon={
                                <FontAwesomeIcon
                                  size="sm"
                                  className="event-icon"
                                  icon={faCalendarPlus}
                                />
                              }
                            >
                              Remind Me
                            </Button>

                            <Button
                              className="default-btn ml-0 mr-0 light text-uppercase d-flex align-items-center justify-content-center"
                              block
                              type="primary"
                              // size="medium"
                              icon={
                                <FontAwesomeIcon
                                  size="sm"
                                  className="event-icon"
                                  icon={faShare}
                                />
                              }
                            >
                              Share Event
                            </Button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>

                  {/* <TabPane tab="Live" key="2">
                    <div className="preview-mobile-box">Live Preview</div>
                  </TabPane> */}
                  <TabPane tab="After" key="3">
                    <div className="preview-mobile-box">
                      <div className="preview-box">
                        {previewImage && (
                          <div
                            className={`preview-img ${sampleBanner ? 'sample-image' : ''
                              }`}
                          >
                            <img src={previewImage} alt="preview Image" />
                          </div>
                        )}
                        {!previewImage && (
                          <div
                            className={`preview-img ${sampleBanner ? 'sample-image' : ''
                              }`}
                          >
                            <img src={previewImg} alt="preview Image" />
                          </div>
                        )}
                        <div className="preview-content-main">
                          <h3 className="preview-title">{eventTitle}</h3>
                        </div>
                        <div className="preview-content-main">
                          <h3 className="preview-title">
                            {thankyou === ''
                              ? 'The live is now over. Thank you for coming to our show.'
                              : thankyou}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Affix>
          ) : (
            <div>
              <h2 className="preview-hd">Preview</h2>
              <Tabs
                centered
                size="medium"
                activeKey={activeKey}
                onChange={(k) => setActiveKey(k)}
                className="preview-tabs-main"
              >
                <TabPane tab="Before" key="1">
                  <div className="preview-mobile-box">
                    <div className="preview-box">
                      <div
                        className={`preview-img ${sampleBanner ? 'sample-image' : ''
                          }`}
                      >
                        {/* <img src={previewImg} alt="Upload your banner" /> */}
                        <img
                          src={previewImage === '' ? previewImg : previewImage}
                          alt="Upload your banner"
                        />
                      </div>
                      <div className="preview-content-main">
                        <h3 className="preview-title">
                          {eventTitle === ''
                            ? '[Preview Event Title]'
                            : eventTitle}
                        </h3>
                        <div className="preview-date">
                          <span className="text-uppercase">
                            {' '}
                            {dateTime === ''
                              ? 'Today'
                              : dateToFromNowDaily(dateTime)}
                          </span>
                          <br></br>
                          {dateTime === ''
                            ? '11:40 AM'
                            : moment(dateTime).format('hh:mm A')}
                        </div>
                        <div className="preview-txt p-2">
                          The live event should start soon !
                        </div>

                        <div className="buttons-main mt-4 mb-4">
                          {/* <Button
                            className="default-btn color-secondary ml-0 mr-0 text-uppercase d-flex align-items-center justify-content-center mb-3"
                            block
                            type="primary"
                            // size="medium"
                            icon={
                              <FontAwesomeIcon
                                size="sm"
                                className="event-icon"
                                icon={faCalendarPlus}
                              />
                            }
                          >
                            Remind Me
                          </Button>

                          <Button
                            className="default-btn ml-0 mr-0 light text-uppercase d-flex align-items-center justify-content-center"
                            block
                            type="primary"
                            // size="medium"
                            icon={
                              <FontAwesomeIcon
                                size="sm"
                                className="event-icon"
                                icon={faShare}
                              />
                            }
                          >
                            Share Event
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>

                {/* <TabPane tab="Live" key="2">
                  <div className="preview-mobile-box">Live Preview</div>
                </TabPane> */}
                <TabPane tab="After" key="3">
                  <div className="preview-mobile-box">
                    <div className="preview-box">
                      {previewImage && (
                        <div
                          className={`preview-img ${sampleBanner ? 'sample-image' : ''
                            }`}
                        >
                          <img src={previewImage} alt="preview Image" />
                        </div>
                      )}
                      {!previewImage && (
                        <div
                          className={`preview-img ${sampleBanner ? 'sample-image' : ''
                            }`}
                        >
                          <img src={previewImg} alt="preview Image" />
                        </div>
                      )}
                      <div className="preview-content-main">
                        <h3 className="preview-title">{eventTitle}</h3>
                      </div>
                      <div className="preview-content-main">
                        <h3 className="preview-title">
                          {' '}
                          {thankyou === ''
                            ? 'The live is now over. Thank you for coming to our show.'
                            : thankyou}
                        </h3>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          )}
        </div>
      </div>


      <Modal
        className="modal-generic modal-500"
        centered
        visible={isCheckedOpen}
        onOk={OpenSubmit}
        onCancel={handleCancelInfo}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button key="submit" type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={handleCancelInfo}
          >
            Submit
          </Button>,
        ]}

      >
        <h2 className="modal-hd1">Add Info</h2>
        {/* <p>Please note these email addresses must be registered on kbshop.com as a Shopper.
            By submitting this, the role will have an access to control room chat.
          </p> */}
        <h5 className="rounded">Title</h5>
        <Input
          placeholder="Enter Title"
          className='mb-20'
          value={infoTitle}
          label="Title"
          size="large"
          onChange={onChangeTitle}
        />
        <h5 className="rounded">Description</h5>
        <TextArea
          value={infoDesc}
          label="Description"
          size="large"

          placeholder='Description.....'
          onChange={onChangeDescription}
        />
      </Modal>




      <Modal
        className="modal-generic form-new modal-800 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isModalOpen}
        onOk={handleOk}
        //onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={''} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button
            key="submit"
            type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={() => history('/events')}
          >
            Go to Event
          </Button>,
        ]}
      >
        {formState == 'edit' ? (
          <h2 className="modal-hd1">Event has been updated</h2>
        ) : (
          <h2 className="modal-hd1">Event has been created</h2>
        )}
        <div className="row">
          <div className="col-lg-4">
            <h5 className="border-d">Banner</h5>
            {previewImage && (
              <div
                className={`preview-img ${sampleBanner ? 'sample-image' : ''}`}
              >
                <img src={previewImage} alt="preview Image" />
              </div>
            )}
            {!previewImage && (
              <div
                className={`preview-img ${sampleBanner ? 'sample-image' : ''}`}
              >
                <img src={previewImg} alt="preview Image" />
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-md-6 mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Event</h4>
                  <h5 className="preview-date">{eventTitle}</h5>
                  <div className="event-detail-txt">
                    <CalendarFilled /> LIVE-SHOPPING-EVENT
                  </div>
                </div>
              </div>
              <div className="col-md-6  mb-20">
                <div class="preview-date-main">
                  <h4 class="date-txt">Date</h4>
                  <h5 class="preview-date">
                    {' '}
                    {moment(dateTime).utc().format('YYYY-MM-DD')}
                  </h5>
                  <h6 class="preview-time">
                    At {moment(dateTime).format('hh:mm:ss A')}
                  </h6>
                </div>
              </div>
            </div>

            <div className="row flex-stretch">
              <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main">
                  <h4 className="date-txt">Category</h4>
                  <h5 className="preview-date">{parent}</h5>
                </div>
              </div>
              <div className="col-md-6 col-xl-6  mb-20">
                <div className="preview-date-main producer-style">
                  <h4 className="date-txt">Attendee</h4>

                  <h5 className="preview-date">
                    <FontAwesomeIcon className="" icon={faUserTie} /> Producer:{' '}
                    <span className="fw-normal">{userInfo?.name}</span>
                  </h5>
                  {host.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} />{' '}
                          Primary:{' '}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host2.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host1:{' '}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host3.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host2:{' '}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                  {host4.map((item, i) => {
                    return (
                      <>
                        <h5 className="preview-date">
                          <FontAwesomeIcon className="" icon={faUser} /> Host3:{' '}
                          <span className="fw-normal">{item?.label}</span>
                        </h5>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="row d-flex mb-20">
              <div className="col-12">
                <h5 className="border-hd">Product List</h5>
              </div>
              <div className="col">
                <Table
                  pagination={false}
                  columns={columns}
                  size="small"
                  className="product-listing-table"
                  //  rowSelection={{ ...rowSelection }}
                  dataSource={sku}
                  scroll={
                    sku.length > 4
                      ? { y: 270, x: window.innerWidth <= 1440 ? 900 : 0 }
                      : false
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="preview-content-main-modal">
          <h3 className="preview-title">

            {eventTitle === ""
              ? "[Preview Event Title]"
              : eventTitle}
          </h3>
          <div className="preview-date">
            <span className="text-uppercase">
              {" "}
              {dateTime === ""
                ? "Today"
                : dateToFromNowDaily(dateTime)}
            </span>
            <br></br>
            {dateTime === ""
              ? "11:40 AM"
              : moment(dateTime).format("hh:mm A")}
          </div>
          <div className="preview-txt p-2">
            The live event should start soon !
          </div>


        </div> */}
      </Modal>
    </>
  );
}
