import React, { useEffect, useState } from 'react';
import '../../css/sales.scss';
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
} from 'antd';
import {
  Route,
  Link,
  Routes,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getEvents } from '../../redux/actions/event.action';
import { getOrderReport } from '../../redux/actions/salesReport.action';
import moment from 'moment';
import numeral from "numeral";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowLeft, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from "react-router-dom";
import unfulfilledicon from "../../images/unfulfilled-icon.jpg";
import fulfilledicon from "../../images/fulfilled-icon.jpg";

const { Option } = Select;

const { RangePicker } = DatePicker;

export default function Order() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(true);
  const [searchEvent, setSearchEvent] = useState('Upcoming');
  const [getEvent, setGetEvent] = useState([]);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});

  const params = useParams();
  const id = params.id;
  const startDate = params.stdate;
  const endDate = params.endate
  const group = params.group;


  useEffect(() => {
    setLoading(true);
    dispatch(getOrderReport(limit, 1, id, startDate, endDate, group)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  }, [id]);

  // const onFinish = (values) => {
  //   setLoading(true);
  //   dispatch(getSalesReport(limit, 1, values.event)).then((res) => {
  //     setLoading(false);
  //     setGetEvent(res?.payload?.message);
  //     setTotalEvent(res?.payload?.message?.total_records);
  //   });
  // };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };



  const showModal = (orderDetail) => {
    setIsModalOpen(true);
    console.log({orderDetail});
    setOrderDetail(orderDetail);

  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

  };
  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getOrderReport(limit, page + 1,id, startDate, endDate, group)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  // const changeEvent = (e) => {
  //   setSearchEvent(e);
  // };

  return (
    <>
      <div className="sales-page-main">
        <div class="title-area mb-10">
          <h1>Orders</h1>
        </div>

        <div className="control-top-box">
          <Link to="/account/sales" className="prev-next-link ff-nunito">
            <FontAwesomeIcon icon={faAngleLeft} />
            <span>Go back</span>
          </Link>
        </div>
        <div>&nbsp;</div>
        <div className="brand_container_main aff-payment">
        
          <div className="row">
            <div className="col-md-12">
             

              {!loading ? (
                <div class="table-responsive-sm">

                  <table class="transactions-box table">
                    <thead class="table_heading">
                      <tr>
                        <th>S.#</th>
                        <th>Order</th>
                        <th>Date</th>
                        <th>Customer</th>

                        <th>Total</th>



                        <th>Items</th>
                        <th>Delivery Method </th>
                        <th>Fulfillment Status</th>
                        {/* <th>QTY</th>
                      <th>Amount</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {/* {!loading ? ( */}
                      {!getEvent.data.length ? (
                        <tr className="text-center">
                          <td colspan="10">No Data Available</td>
                        </tr>
                      ) : (
                        getEvent.data.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td
                                onClick={() => showModal(item?.order_detail)}
                              >
                                <span className='order-number-btn'>
                                  {item?.order_detail?.name}
                                </span>
                              </td>
                              <td>
                                {moment(item?.order_detail?.updated_at)

                                  .format('YYYY-MM-DD')}
                              </td>
                              <td>{item?.order_detail?.customer?.first_name + ' ' + item?.order_detail?.customer?.last_name}</td>

                              <td
                              // style={{"text-align" : "right"}}
                              >  {item?.order_detail?.total_price
                                ? numeral(item?.order_detail?.total_price).format("$0,0.0'")
                                : "$0.00"} </td>



                              <td>
                                {item?.order_detail?.line_items?.length}
                              </td>
                              <td>
                                {item?.order_detail?.shipping_lines[0]?.title}
                              </td>
                              <td>

                                <div className={`fulfil-status ${item?.order_detail?.fulfillment_status === null ? 'unfulfilled' : 'fulfilled'}`}>
                                  {item?.order_detail?.fulfillment_status === null ? 'Unfulfilled' : 'fulfilled'}
                                </div>

                              </td>
                            </tr>
                          );
                        })
                      )
                      }
                    </tbody>
                  </table>
                </div>)
                :
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              }

              {totalEvent > 8 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getEvent?.next
                      ? getEvent?.next?.page
                      : getEvent?.previous
                        ? getEvent?.previous?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={'pagination custom-paginate'}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}

              {/* {!getEvent.data?.length ? (
                <tr className="text-center">
                  <td colspan="10">No Data Available</td>
                </tr>
              ) : (
                getEvent.summary.map((item, i) => {
                  return (


                    <div class="sales-summery">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="summarry-box">
                            <h5>Report Summary test</h5>
                            <div>Total Amount  : {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Discount : {item?.total_discounts
                                ? numeral(item?.total_discounts).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Paid Amount : {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : "$0.00"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )

              } */}



            </div>
          </div>
        </div>
      </div>


      <Modal className="modal-generic form-new modal-1000 form-styling"
        centered
        // visible={false}
        // visible={true}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}

        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button key="submit" type="primary"
            className="d-table m-auto"
          // onClick={handleOk}
           onClick={handleCancel}
          >
            Go Back
          </Button>,
        ]}
      >
        <h2 className="modal-hd1">Order Details</h2>
        <div className='order-detail-title-area'>
          <Button className='back-btn default-btn outline small-btn'>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className='title-info-main'>
            <div className='order-number'>
              {
                `${orderDetail.name}`
              }
            </div>
            {/* <div className='fulfil-status paid'>
              Paid
            </div> */}
            <div className='fulfil-status unfulfilled'>
              {
                orderDetail?.fulfillment_status === null ? 'Unfulfilled' : orderDetail?.fulfillment_status
              }
            </div>


          </div>
          <div className='order-date'>
            {
              moment(orderDetail.created_at).format("YYYY-MM-DD")
            }
          </div>
        </div>
        <div className='scroll-area overflow-y-scroll scrollbar-style'>
          <div className='row'>
            <div className='col-xl-8 col-lg-7'>
              <div className='order-detail-box'>
                <div className='status-heading'>
                  <img src={unfulfilledicon} alt="" />
                  <div className='txt'>  Unfulfilled (1)</div>
                </div>

                <div className='row order-details-listing-main'>

                  <div className='col-xl-8 left-content'>
                    <div className='order-pic-wrapper'>
                      <div className='order-pic-main'>
                        <div className='order-count'>1</div>
                        <div className='order-pic'>
                          <img src="https://cdn.shopify.com/s/files/1/0637/3231/8428/products/8510_1_160x160.jpg?v=1665139679" alt="" />
                        </div>
                      </div>
                    </div>


                    <div className='order-points'>
                      <div className='order-name'>
                        <a href='#'>73Rd & Park Regular | White
                        </a>
                      </div>
                      <div className='order-style'>
                        M / White
                      </div>
                      <div className='order-sku'>
                        SKU: 8510
                      </div>
                      <div className='order-discount'>
                        asad discount applied
                      </div>
                    </div>

                  </div>
                  <div className='col-xl-4 d-flex justify-content-between'>
                    <div className='order-price-status'>
                      <div className='cut-price'>
                        <del>$64.00</del>

                      </div>
                      <div className='priceby'>$0.00 * 1</div>
                    </div>

                    <div className='order-price'>
                      $0.00
                    </div>
                  </div>








                </div>


              </div>
              <div className='order-detail-box'>
                <div className='status-heading'>
                  <img src={fulfilledicon} alt="" />
                  <div className='txt'>  Fulfilled (2)</div>
                </div>

                <div className='row order-details-listing-main'>

                  <div className='col-xl-8 left-content'>
                    <div className='order-pic-wrapper'>
                      <div className='order-pic-main'>
                        <div className='order-count'>1</div>
                        <div className='order-pic'>
                          <img src="https://cdn.shopify.com/s/files/1/0637/3231/8428/products/8510_1_160x160.jpg?v=1665139679" alt="" />
                        </div>
                      </div>
                    </div>


                    <div className='order-points'>
                      <div className='order-name'>
                        <a href='#'>73Rd & Park Regular | White
                        </a>
                      </div>
                      <div className='order-style'>
                        M / White
                      </div>
                      <div className='order-sku'>
                        SKU: 8510
                      </div>
                      <div className='order-discount'>
                        asad discount applied
                      </div>
                    </div>

                  </div>
                  <div className='col-xl-4 d-flex justify-content-between'>
                    <div className='order-price-status'>
                      <div className='cut-price'>
                        <del>$64.00</del>

                      </div>
                      <div className='priceby'>$0.00 * 1</div>
                    </div>

                    <div className='order-price'>
                      $0.00
                    </div>
                  </div>








                </div>



              </div>
              <div className='order-detail-box paid-rows'>
                <div className='status-heading'>
                  <img src={fulfilledicon} alt="" />
                  <div className='txt'>  Paid</div>
                </div>

                <div className='paid-top-row'>
                  <div className='left-txt'>
                    Original order • October 18, 2022
                  </div>
                  <div className='right-txt'>
                    $0.00
                  </div>
                </div>
                <div className='paid-mid-row'>
                  <div className='left-txt multi-content'>
                    <div className='left-hd'>
                      Discount
                    </div>
                    <div className='left-info'>
                      asad
                    </div>

                  </div>
                  <div className='right-txt'>
                    $0.00
                  </div>
                </div>
                <div className='paid-mid-row'>
                  <div className='left-txt multi-content'>
                    <div className='left-hd'>
                      Subtotal
                    </div>
                    <div className='left-info'>
                      3 items
                    </div>

                  </div>
                  <div className='right-txt'>
                    $0.00
                  </div>
                </div>
                <div className='paid-mid-row'>
                  <div className='left-txt multi-content'>
                    <div className='left-hd'>
                      Shipping
                    </div>
                    <div className='left-info'>
                      Economy (2.65 lb)
                    </div>

                  </div>
                  <div className='right-txt'>
                    $0.00
                  </div>
                </div>
                <div className='paid-mid-row'>
                  <div className='left-txt multi-content '>
                    <div className='left-hd fw-bold'>
                      Total
                    </div>


                  </div>
                  <div className='right-txt fw-bold'>
                    $0.00
                  </div>
                </div>

                <div className='paid-bottom-row'>
                  <div className='left-txt'>
                    Paid by customer
                  </div>
                  <div className='right-txt'>
                    $0.00
                  </div>
                </div>






              </div>
            </div>
            <div className='col-xl-4 col-lg-5'>

              <div className='order-detail-box customer-box'>
                <div className='status-heading'>
                  <div className='txt'>  Customer</div>
                </div>
                <div className='customer-info'>
                  <div className='customer-name'>{orderDetail?.customer?.first_name + ' ' + orderDetail?.customer?.last_name}</div>
                  {/* <div className='number-of-orders'>10 orders</div> */}
                </div>
                <div className='customer-info'>
                  <div className='customer-hd'>  Contact Information</div>
                  <div className='customer-link'>
                    {/* <a href='#'>email@testemail.com</a> */}
                    {
                      orderDetail?.customer?.email
                    }
                  </div>
                  <div className='number-of-orders'>
                    {
                      orderDetail?.customer?.phone
                    }
                  </div>
                </div>
                <div className='customer-info'>
                  <div className='customer-hd'> SHIPPING ADDRESS</div>
                  <div className='number-of-orders'>
                    {orderDetail?.shipping_address?.first_name + ' ' + orderDetail?.shipping_address?.last_name}<br />
                    {orderDetail?.shipping_address?.address1}<br />
                    {orderDetail?.shipping_address?.address2}<br />
                    {orderDetail?.shipping_address?.city + ' ' + orderDetail?.shipping_address?.province_code + ' ' + orderDetail?.shipping_address?.zip}<br />
                    {orderDetail?.shipping_address?.country}<br />
                    {orderDetail?.shipping_address?.phone}<br />

                  </div>
                  {/* <div className='customer-link'>
                    <a href='#'>View map</a>
                  </div> */}
                </div>
                <div className='customer-info last-box'>
                  <div className='customer-hd'>BILLING ADDRESS</div>
                  <div className='number-of-orders'>
                    Same as shipping address
                  </div>
                </div>
              </div>


            </div>
          </div>

        </div>

      </Modal>

    </>
  );
}
