import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Select, Tabs } from 'antd';
import { Button } from 'antd';

import GalleryGrid from '../../components/mediaGalleryGrid/galleryGrid';

const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const { TabPane } = Tabs;

export default function MediaGallery() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get('type') === null ? 'Dashboard' : searchParams.get('type')
  );

  const [eventStatus, setEventStatus] = useState(123);

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  // useEffect(() => {
  //   return () => {
  //     let url = new URL(window.location.href);
  //     console.log(url, 'url');
  //     url.searchParams.delete('type');
  //     setEventTitle('Upcoming');
  //   };
  // }, []);

  return (
    <>


      <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          {/* <h1 className="hd2">All Events</h1> */}

          {/* <Select
            className="select-style"
            defaultValue="Events"
            size="large"
            focus="false"
            onChange={handleChange}
            onBlur={false}
          >
            { <Option value="All">All</Option> }
            <Option value="Events">Events</Option>
            <Option value="Recorded Events">Recorded Events</Option>
          </Select> */}

          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Dashboard" key="Dashboard"></TabPane>
            <TabPane tab="Upload Media" key="UploadMedia"></TabPane>
            <TabPane tab="Active" key="Active"></TabPane>
            <TabPane tab="In-Active" key="InActive"></TabPane>

            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>
      </div>

      {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

      <GalleryGrid
        className={gridActive}
        title={eventTitle}
        eventStatus={eventStatus}
      />
      </>
  );
}
