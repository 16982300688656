import React, { useEffect, useState } from 'react';
import '../../css/sales.scss';
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
} from 'antd';
import {
  Route,
  Link,
  Routes,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getEvents } from '../../redux/actions/event.action';
import { getSalesReport } from '../../redux/actions/salesReport.action';
import moment from 'moment';
import numeral from "numeral";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

export default function ReferralFees() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(true);
  const [searchEvent, setSearchEvent] = useState('Upcoming');
  const fromDate = moment().startOf("month").format("YYYY-MM-DD");
  const toDate = moment(new Date()).format("YYYY-MM-DD");
  const [getEvent, setGetEvent] = useState([]);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    setLoading(true);
    dispatch(getSalesReport(limit, 1,startDate,endDate)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  }, []);

  // const onFinish = (values) => {
  //   setLoading(true);
  //   dispatch(getSalesReport(limit, 1, values.event)).then((res) => {
  //     setLoading(false);
  //     setGetEvent(res?.payload?.message);
  //     setTotalEvent(res?.payload?.message?.total_records);
  //   });
  // };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getSalesReport(limit, page + 1,startDate,endDate)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };


  const handleSubmit = (e) => {
   
    e.preventDefault();
    const page = e.selected;
    setCurrentPage(0);
    setLoading(true);
    dispatch(getSalesReport(limit, 1 ,startDate,endDate)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  // const changeEvent = (e) => {
  //   setSearchEvent(e);
  // };

     const navigateOrder = (id) => {
       alert('sss')
      return(
        <>
        <Link to={`/account/orders/${id}/${startDate}/${endDate}`}/>
        </>
      )
   };

  return (
    <>
      <div className="sales-page-main">
        <div class="title-area mb-10">
          <h1>Referral Fees</h1>
        </div>

    
        <div className="brand_container_main aff-payment">
          {/* <div class="filter-date mb-3 row">
            <div class="col-md-12">
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                Today
              </Button>
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                MTD
              </Button>
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                YTD
              </Button>
            </div>
          </div> */}


<div className="row">

          <div className="col-xl-3 col-md-6 col-12">
                    <Form.Item
                      name="datetime"
                      initialValue={'date'}
                      labelCol={{ span: 24 }}
                      label="Select Start Date / End Date"
                    >
                        <RangePicker
                        size="large"
                        className='w-100'
                      key={4}
                      defaultValue={
                        startDate && endDate
                          ? [moment(startDate), moment(endDate)]
                          : []
                      }
                      allowClear={false}
                      ranges={{
                        Today: [moment(), moment()],
                        Tomorrow: [
                          moment().add(1, "days"),
                          moment().add(1, "days"),
                        ],
                        Yesterday: [
                          moment().subtract(1, "days"),
                          moment().subtract(1, "days"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      format={dateFormat}
                      onChange={dateRangePickerChanger}
                    />
                    </Form.Item>
      

          
                 
                  </div> 
                  <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end">
                  <Form.Item className="d-flex align-items-end">
                  <Button onClick={handleSubmit}
                  className='default-btn fltr-hpr  medium-btn'
                  type="primary" htmlType="submit" loading={loading}>
                    Search
                  </Button>
                </Form.Item>
                    </div>
                    </div>
                  {/* <div className="col-xl-3 col-md-6 col-12">
                    <Form.Item
                      labelCol={{ span: 24 }}
                      label="Group By"
                      name="category_id"
                    >
                      <Select
                        className="select-style dark-field"
                        placeholder="Summary"
                        size="large"
                        focus={false}
                        onBlur={false}
                      >
                        <Option value={'option 1'}>Summary</Option>
                        <Option value={'option 2'}>Detailed</Option>
                        <Option value={'option 3'}>Date</Option>
                        <Option value={'option 4'}>Campaign</Option>
                        <Option value={'option 5'}>Influencer</Option>
                      </Select>
                    </Form.Item>
                  </div> */}
          <div className="row">
            <div className="col-md-12">
              {/* <form className="form-styling" onSubmit={handleSubmit}> */}
              {/* <Form
                name="basic"
               // onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="inline"
                className="mb-3 form-styling"
                initialValues={{
                  event: 'Upcoming',
                }}
              >
                <Form.Item className="d-flex align-items-end">
                  <Button
                  className='default-btn fltr-hpr  medium-btn'
                  type="primary" htmlType="submit" loading={loading}>
                    Search
                  </Button>
                </Form.Item>
          
              </Form> */}

              {!loading ? (
                <div className="table-responsive">

                  <table className="transactions-box table">
                    <thead className="table_heading">
                      <tr>
                        <th>S.#</th>
                        <th>Date</th>
                        <th>Event</th>
                        <th>No. Of Orders</th>

                        <th>Total Amount</th>
                        <th>Total Discount</th>
                        <th>Total Paid Amount</th>
                  
                      </tr>
                    </thead>
                    <tbody>
                    <tr className="text-center">
                          <td colspan="10">No Data Available</td>
                        </tr>
                  
                      {/* {!getEvent.data.length ? (
                        <tr className="text-center">
                          <td colspan="10">No Data Available</td>
                        </tr>
                      ) : (
                        getEvent.data.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item?.date)
                                  
                                  .format('YYYY-MM-DD')}
                              </td>
                              <td className='sales-event-link'>
                                <a href={`/account/orders/${item.event_id}/${startDate}/${endDate}`}> {item?.event_title}</a></td>
                               
                              <td>{item?.order_count}</td>

                              <td
                            
                              >  {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format("$0,0.00'")
                                : "$0.00"} </td>
                              <td
                           
                              >  {item?.total_discounts
                                ? numeral(item?.total_discounts).format("$0,0.00'")
                                : "$0.00"} </td>

                              <td
                             
                              >  {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : "$0.00"} </td>
                          
                            </tr>
                          );
                        })
                      )
                      } */}
                    </tbody>
                  </table>
                </div>)
                :
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              }

              {totalEvent < 8 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getEvent?.next
                      ? getEvent?.next?.page
                      : getEvent?.previous
                        ? getEvent?.previous?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={'pagination custom-paginate'}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
{/* 
              {!getEvent.data?.length ? (
                <tr className="text-center">
                  <td colspan="10">No Data Available</td>
                </tr>
              ) : (
                getEvent.summary.map((item, i) => {
                  return (


                    <div class="sales-summery">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="summarry-box">
                            <h5>Report Summary</h5>
                            <div>Total Amount  : {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Discount : {item?.total_discounts
                                ? numeral(item?.total_discounts).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Paid Amount : {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : "$0.00"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )

              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
