import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Select, Tabs } from 'antd';
import { Button } from 'antd';

import UGCGridProfile from '../../../components/ugcGrid/ugcGridProfile';
// import GridListing from "../../components/gridListingBar/gridListingBar"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrip, faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import FB from '../../../components/ConnectFb/connFB';

const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const { TabPane } = Tabs;

export default function MonitorMentions() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [gridActive, setGridActive] = useState(true);
  const [eventTitle, setEventTitle] = useState(
    searchParams.get('type') === null ? 'AddProfile' : searchParams.get('type')
  );
  const [token, setToken] = useState('');
  const [fbPage, setFbpage] = useState('');

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('userInfo')).fb_token;
    const fbPage = JSON.parse(localStorage.getItem('userInfo')).page_token;
    setToken(token);
    setFbpage(fbPage);
  }, []);


  const [eventStatus, setEventStatus] = useState(123);

  const callbackTabClicked = (key, event) => {
    const url = new URL(window.location.href);
    url.searchParams.set('type', key);
    window.history.replaceState(null, null, url);
    setEventTitle(key);
    setEventStatus(event.timeStamp);
  };

  // useEffect(() => {
  //   return () => {
  //     let url = new URL(window.location.href);
  //     console.log(url, 'url');
  //     url.searchParams.delete('type');
  //     setEventTitle('Upcoming');
  //   };
  // }, []);

  return (
    <>

{fbPage === '' && token === '' ?
            <FB />
            :
            <>


      <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1">
          {/* <h1 className="hd2">All Events</h1> */}

          {/* <Select
            className="select-style"
            defaultValue="Events"
            size="large"
            focus="false"
            onChange={handleChange}
            onBlur={false}
          >
            { <Option value="All">All</Option> }
            <Option value="Events">Events</Option>
            <Option value="Recorded Events">Recorded Events</Option>
          </Select> */}

          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={eventTitle}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Add Profile" key="AddProfile"></TabPane>
            <TabPane tab="Monitor" key="Search"></TabPane>

            {/* <TabPane tab="Expired" key="Expired">
            </TabPane> */}
          </Tabs>
        </div>
      </div>

      {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

      <UGCGridProfile
        className={gridActive}
        title={eventTitle}
        eventStatus={eventStatus}
      />
      </>
}
    </>
  );
}
