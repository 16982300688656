import React from "react";

export default function Dashboard() {
 

  return (
    <>
      <div className="home-intro-box-main">
        No Content
      </div>
    </>
  );
}
