import {
  GET_SOCIAL_STORE_REQUEST,
  GET_SOCIAL_STORE_SUCCESS,
  GET_SOCIAL_STORE_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getSocialStoreSales =
  (group_by = '', page, limit, startDate, endDate, type) =>
  (dispatch) => {
    dispatch({ type: GET_SOCIAL_STORE_REQUEST });

    return axios({
      method: 'GET',
      url: `${BASEURL}affiliate/getsales?group_by=${group_by}&page=${page}&limit=${limit}&from_date=${startDate}&to_date=${endDate}&type=${type}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
    })
      .then((response) => {
        return dispatch({
          type: GET_SOCIAL_STORE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_SOCIAL_STORE_ERROR,
          payload: error.response,
        });
      });
  };
