import React, { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import logo from "../images/kblivelogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStore,
  faThList,
  faUsers,
  faPlus,
  faXmark,
  faGears,
  faUserTie,
  faUserPen,
  faUser,
  faChartLine,
  faAt,
  faPeopleRoof,
} from "@fortawesome/free-solid-svg-icons";
// import "./index.css";
import { createBrowserHistory } from "history";
import {
  HomeFilled,
  CalendarFilled,
  AreaChartOutlined,
  SignalFilled,
  UnorderedListOutlined,
  MessageOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import Home from "../pages/home/home";
import BroadCast from "../pages/broadcast/broadcast";
import { Layout, Menu } from "antd";
import Analytics from "../pages/analytics/analytics";
import Events from "../pages/events/events";
import HostEvents from "../pages/events/host-event";
import CreateEvent from "../pages/create-event/create-event";
import ScheduleEvent from "../pages/create-event/scheduleEvent/scheduleEvent";
import LiveEvent from "../pages/create-event/liveEvent/liveEvent";
import StoreSettings from "../pages/store-settings/store-settings";
import ControlRoom from "../pages/control-room/control-room";
import Ecommerce from "../pages/account/ecommerce/ecommerce";
import Footer from "../components/footer/footer";
import MainHeader from "../components/header/header";
import useEscape from "../reUsable/useEscapeKey";
import UserProfile from "../pages/userProfile/userprofile";
import RecordedEvent from "../pages/recordEvent-room/recordEvent";
import Dashboard from "../pages/dashboard/dashboard";
import Sales from "../pages/sales/sales";
import EventSales from "../pages/sales/eventSales";
import ReferralDeposit from "../pages/sales/referralDeposit";
import ReferralFees from "../pages/sales/referralFees";
import StoreSales from "../pages/sales/storeSales";
import Order from "../pages/sales/order";
import OrderDetail from "../pages/sales/orderDetail";
import MonitorMentions from "../pages/ugc/monitorMentions/monitorMentions";
import MonitorHashTag from "../pages/ugc/monitorHastag/index";
import MonitorProfile from "../pages/ugc/monitorProfile/index";
import CategorySetup from "../pages/categorySetup/categorySetup";
import BioShopSetup from "../pages/bioShopSetup/BioShopSetup";
import LinkinBio from "../pages/linkinbio/LinkinBio";
import SocialSetup from "../pages/socialSetup/socialSetup";
import AffiliateSales from "../pages/sales/affiliateSales";
import MediaGallery from "../pages/mediaGallery/index";
import StreamSetup from "../pages/streamSetup/ecommerce/streamSetup";
import PostingEvent from "../pages/postingContent/postingContent";
import PublicPrivacyPolicy from "../pages/public/public-privacy-policy";
import PublicTermsUse from "../pages/public/public-terms-use";
import AccountDelete from "../pages/accountDelete/accountDelete";
import Logout from "../pages/logout/logout";
import AllPost from "../pages/allPosts/allPosts";
import DirectMessaging from "../pages/directMessaging/directMessaging";
import StoryInsight from "../pages/storyInsight/storyinsight";

const { SubMenu } = Menu;

const { Sider, Content } = Layout;
// const location = window.location.pathname.split("/")[1];
export const history = createBrowserHistory({
  forceRefresh: true,
});
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const App = () => {
  const ref = useRef();
  const location = useLocation();
  const [storeMenu, setStoreMenu] = useState(false);

  const [toggle, setToggle] = useState(false);

  const [title, setTitle] = useState(location.pathname);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  let instagramCodeUrl = new URL(window.location.href);
  let code = new URLSearchParams(instagramCodeUrl.search);

  useEffect(() => {
    if (
      location.pathname === "/account/sales" ||
      location.pathname === "/account/ecommerce" ||
      location.pathname === "/account/stream" ||
      location.pathname === "/account/referral-sales" ||
      location.pathname === "/account/referral-fees" ||
      location.pathname === "/account/referral-deposit" ||
      location.pathname === "/account/event-sales" ||
      location.pathname === "/account/store-sales" ||
      location.pathname === "/ugc/monitor-mentions" ||
      location.pathname === "/ugc/monitor-hashtags" ||
      location.pathname === "/ugc/monitor-profile" ||
      location.pathname === "/bioshop/bio-setup" ||
      location.pathname === "/bioshop/bioStore" ||
      location.pathname === "/account/categories" ||
      location.pathname === "/account/social" ||
      location.pathname === "/account/orders" ||
      location.pathname === "/media/gallery"
    ) {
      changetitle(location.pathname);
    } else {
      changetitle(location.pathname.split("/")[1]);
    }
  }, [location]);

  useEffect(() => {
    mobileSidebar(toggle);
  }, [toggle]);

  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  const storeClick = () => {
    if (storeMenu) {
      setStoreMenu(false);
    } else {
      setStoreMenu(true);
    }
  };

  const mobileSidebar = (action) => {
    setToggle(action);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (storeMenu && ref.current && !ref.current.contains(e.target)) {
        setStoreMenu(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [storeMenu]);

  const changetitle = (title) => {
    if (title === "create-event") {
      setTitle("Create Event");
    } else if (title === "schedule-event") {
      setTitle("Schedule Event");
    } else if (title === "live-event") {
      setTitle("Live Event");
    } else if (title === "home") {
      setTitle("Home");
    } else if (title === "events") {
      setTitle("Events");
      setSelectedKeys(["events"]);
    } else if (title === "allpost") {
      setTitle("All Post");
      setSelectedKeys(["allpost"]);
    } else if (title === "host-event") {
      setTitle("Host Events");
    } else if (title === "analytics") {
      setTitle("Analytics");
    } else if (title === "broadcast") {
      setTitle("Beta");
      setSelectedKeys(["broadcast"]);
    } else if (title === "store-settings") {
      setTitle("Store Settings");
    } else if (title === "control-room") {
      setTitle("Control Room");
    } else if (title === "external-control-room") {
      setTitle("External Room");
    } else if (title === "/account/ecommerce") {
      setTitle("Settings");
      setOpenKeys(["settings"]);
      setSelectedKeys(["ecommerce"]);
    } else if (title === "/account/social") {
      setTitle("Settings");
      setOpenKeys(["settings"]);
      setSelectedKeys(["socialsetup"]);
    } else if (title === "/account/stream") {
      setTitle("Settings");
      setOpenKeys(["settings"]);
      setSelectedKeys(["streamsetup"]);
    } else if (title === "/account/categories") {
      setTitle("Settings");
    } else if (title === "account-delete") {
      setTitle("Settings");
      setOpenKeys(["settings"]);
      setSelectedKeys(["accountdelete"]);
    } else if (title === "schedule-event-id") {
      setTitle("Events");
    } else if (title === "usertabs") {
      setTitle("Account Type");
    } else if (title === "user-profile") {
      setTitle("User Roles");
    } else if (title === "recorded-event") {
      setTitle("Recorded Event");
    } else if (title === "/account/sales") {
      setTitle("Accounting");
    } else if (title === "/ugc/monitor-mentions") {
      setTitle("UGC");
      setOpenKeys(["ugc"]);
      setSelectedKeys(["monitorMentions"]);
    } else if (title === "/ugc/monitor-profile") {
      setTitle("UGC");
      setOpenKeys(["ugc"]);
      setSelectedKeys(["ugcProfile"]);
    } else if (title === "/ugc/monitor-hashtags") {
      setTitle("UGC");
      setOpenKeys(["ugc"]);
      setSelectedKeys(["monitorHashTags"]);
    } else if (title === "/account/event-sales") {
      setTitle("Accounting");
    } else if (title === "/account/store-sales") {
      setTitle("Accounting");
    } else if (title === "/account/referral-sales") {
      setTitle("Accounting");
    } else if (title === "/account/referral-fees") {
      setTitle("Accounting");
    } else if (title === "/account/referral-deposit") {
      setTitle("Accounting");
    } else if (title === "account") {
      setTitle("Order Details");
    } else if (title === "/bioshop/bio-setup") {
      setTitle("Reorder Store");
    } else if (title === "/bioshop/bioStore") {
      setTitle("Social Store");
    } else if (title === "/media/gallery") {
      setTitle("Meida Gallery");
    } else if (title === "/ugc/profiles") {
      setTitle("Profile Monitoring");
    } else if (title === "/account/order-detail") {
      setTitle("Order Detail");
    } else if (title === "dashboard") {
      setTitle("Dashboard");
    } else if (title === "posting-content") {
      setTitle("Posting Content");
      setSelectedKeys(["posting-content"]);
    } else if (title === "privacy-policy") {
      setTitle("Privacy Policy");
    } else if (title === "terms-use") {
      setTitle("Terms & Conditions");
    } else if (title === "direct-messaging") {
      setTitle("Messaging");
      setSelectedKeys(["direct-messaging"]);
    } else if (title === "story-insights") {
      setTitle("Story Mention");
      setSelectedKeys(["story-insights"]);
    }
  };

  const rootSubmenuKeys = [
    "accounting",
    "settings",
    "ugc",
    "social-store-management",
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  // const logout = () => {
  //   localStorage.removeItem("userInfo");
  //   history.push("/");
  //   window.location.reload();
  // };

  useEscape(() => setToggle(false));

  return (
    <Layout className="generic-style">
      {userInfo?.account_type === "brand" ? (
        <>
          <Sider className={`sidebar-main ${!toggle ? "toggle-sidebar" : ""} `}>
            <div className="logo-area">
              <div className="logo">
                <Link
                  to="/home"
                  onClick={() => {
                    changetitle("home");
                    setToggle(false);
                  }}
                >
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="menu-close d-xl-none">
                <Link
                  to="#"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Link>
              </div>
            </div>

            <h5 className="wordspace-hd">Workspace</h5>

            <div className="workspace-menu" ref={ref}>
              <div className="store-menu">
                <div className="workspace-icon">
                  <FontAwesomeIcon icon={faStore} />
                </div>
                <div className="workspace-menu-item">
                  <div className="store-name-txt">{userInfo?.name}</div>
                  {/* <div className="store-owner-txt">{userInfo?.name}</div> */}
                </div>
              </div>
              {/* {storeMenu && (
                <div className="workspace-drop-box">
                  <Link
                    to="/store-settings"
                    className="drop-item"
                    onClick={() => {
                      changetitle('schedule-event');
                      setToggle(false);
                    }}
                  >
                    <FontAwesomeIcon className="drop-icon" icon={faStore} />
                    <span className="drop-txt">Store Settings</span>
                  </Link>
                  <Link className="drop-item" to="#">
                    <FontAwesomeIcon className="drop-icon" icon={faThList} />
                    <span className="drop-txt">Billing & Plan</span>
                  </Link>
                  <Link className="drop-item" to="user-profile">
                    <FontAwesomeIcon className="drop-icon" icon={faUsers} />
                    <span className="drop-txt">User Roles</span>
                  </Link>
                </div>
              )} */}
            </div>

            <Menu
              // theme="dark"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onOpenChange={onOpenChange}
              mode="inline"
              className="menu-style-new"
              defaultSelectedKeys={location.pathname.split("/")[1]}
            >
              <Menu.Item key="events">
                <Link
                  to="events1"
                  onClick={() => {
                    changetitle("events");
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon className="" icon={faUserTie} />
                  <span className="nav-text">Events</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="allpost">
                <Link
                  to="allpost"
                  onClick={() => {
                    changetitle("allpost");
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon className="" icon={faUserTie} />
                  <span className="nav-text">All Posts</span>
                </Link>
              </Menu.Item>

              {/* <Menu.Item key="broadcast">
                <Link
                  to="/broadcast"
                  onClick={() => {
                    changetitle("broadcast");
                    setToggle(false);
                  }}
                >
                  <SignalFilled />
                  <span className="nav-text">Stream</span>
                </Link>
              </Menu.Item> */}

              <SubMenu
                className="submenu-main"
                key="ugc"
                title={
                  <span>
                    {/* <FontAwesomeIcon icon={faAt} /> */}
                    <span className="fa fa-at  menu-icon-direct"></span>
                    <span>UGC</span>
                  </span>
                }
              >
                <Menu.Item key="monitorMentions">
                  <Link
                    to="/ugc/monitor-mentions"
                    onClick={() => {
                      changetitle("monitorMentions");
                      setToggle(false);
                    }}
                  >
                    Monitor Mentions
                  </Link>
                </Menu.Item>
                <Menu.Item key="monitorHashTags">
                  <Link
                    to="/ugc/monitor-hashtags"
                    onClick={() => {
                      changetitle("monitorHashTags");
                      setToggle(false);
                    }}
                  >
                    Monitor Hashtags
                  </Link>
                </Menu.Item>
                <Menu.Item key="ugcProfile">
                  <Link
                    to="/ugc/monitor-profile"
                    onClick={() => {
                      changetitle("ugcProfile");
                      setToggle(false);
                    }}
                  >
                    Monitor Profiles
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="posting-content">
                <Link
                  to="/posting-content"
                  onClick={() => {
                    changetitle("posting-content");
                    setToggle(false);
                  }}
                >
                  <UnorderedListOutlined />
                  <span className="nav-text">Posting Content</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="direct-messaging">
                <Link
                  to="/direct-messaging"
                  onClick={() => {
                    changetitle("Messaging");
                    setToggle(false);
                  }}
                >
                  <MessageOutlined />
                  <span className="nav-text">Messaging</span>
                </Link>
              </Menu.Item>

              <Menu.Item key="story-insights">
                <Link
                  to="/story-insights"
                  onClick={() => {
                    changetitle("Story Insights");
                    setToggle(false);
                  }}
                >
                  <AppstoreOutlined />
                  <span className="nav-text">Story Mention</span>
                </Link>
              </Menu.Item>

              <SubMenu
                className="submenu-main bottom-fixed-aut"
                // className="submenu-main bottom-fixed-normal"
                key="settings"
                title={
                  <span>
                    <FontAwesomeIcon icon={faGears} />
                    <span>Settings</span>
                  </span>
                }
              >
                {/* <Menu.Item>
                  <Link
                    to="/account/categories"
                    onClick={() => {
                      changetitle('categories');
                      setToggle(false);
                    }}
                  >
                    Category Setup
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="ecommerce">
                  <Link
                    to="/account/ecommerce"
                    onClick={() => {
                      changetitle("ecommerce");
                      setToggle(false);
                    }}
                  >
                    Ecommerce Setup
                  </Link>
                </Menu.Item>
                <Menu.Item key="socialsetup">
                  <Link
                    to="/account/social"
                    onClick={() => {
                      changetitle("socialsetup");
                      setToggle(false);
                    }}
                  >
                    Social Setup
                  </Link>
                </Menu.Item>
                <Menu.Item key="accountdelete">
                  <Link
                    to="/account-delete"
                    onClick={() => {
                      changetitle("accountdelete");
                      setToggle(false);
                    }}
                  >
                    Delete Account
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="streamsetup">
                  <Link
                    to="/account/stream"
                    onClick={() => {
                      changetitle('streamsetup');
                      setToggle(false);
                    }}
                  >
                    Stream Setup
                  </Link>
                </Menu.Item> */}
              </SubMenu>

              {/* <Menu.Item key="host-event">
            <Link
              to="host-event"
              onClick={() => {
                changetitle("host-event");
                setToggle(false);
              }}
            >
              <FontAwesomeIcon className="fspx-15" icon={faUser} />
              <span className="nav-text">Host</span>
            </Link>
          </Menu.Item> */}

              {/* <Menu.Item key="analytics">
            <Link
              to="analytics"
              onClick={() => {
                changetitle("analytics");
                setToggle(false);
              }}
            >
              <AreaChartOutlined />
              <span className="nav-text">Analytics</span>
            </Link>
          </Menu.Item> */}

              {/* <Menu.Item
            key="create-event"
            className="event-btn multi-btn space-top d-flex align-items-center justify-content-center"
          >
            <Link
              to="/schedule-event"
            >
              <span className="nav-text">Producer</span>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="create-event"
            className="event-btn multi-btn d-flex align-items-center justify-content-center"
          >
            <Link
              to="/schedule-event"
            >
              <span className="nav-text">Host</span>
            </Link>
          </Menu.Item> */}

              {/* <Menu.Item key="broadcast" className="beta-link">
            <Link
              to="/broadcast"
              onClick={() => {
                changetitle("broadcast");
                setToggle(false);
              }}
            >
              <SignalFilled />
              <span className="nav-text">Beta</span>
            </Link>
          </Menu.Item> */}
              {/* <Menu.Item key="usertabs">
            <Link
              to="usertabs"
              onClick={() => {
                changetitle("usertabs");
                setToggle(false);
              }}
            ></Link>
          </Menu.Item> */}

              {/* <div className="submenu-wrapper"> */}
              {/* <SubMenu
                className="submenu-main"
                key="accounting"
                title={
                  <span>
                    <span class="fa fa-line-chart menu-icon-direct"></span>
                    <span>Accounting</span>
                  </span>
                }
              >
                <Menu.Item>
                  <Link
                    to="/account/sales"
                    onClick={() => {
                      changetitle('sales');
                      setToggle(false);
                    }}
                  >
                    Total Event Sales
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/account/store-sales"
                    onClick={() => {
                      changetitle('storeSales');
                      setToggle(false);
                    }}
                  >
                    Total Social Store Sales
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    to="/account/affiliate-sales"
                    onClick={() => {
                      changetitle('affiliateSales');
                      setToggle(false);
                    }}
                  >
                    Total Affiliate Sales
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      changetitle('referralFees');
                      setToggle(false);
                    }}
                  >
                    Referral Fees
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    onClick={() => {
                      changetitle('referralDeposit');
                      setToggle(false);
                    }}
                  >
                    Referral Deposit
                  </Link>
                </Menu.Item>
              </SubMenu> */}

              {/* 
            <Menu.SubMenu title="Settings"  className="submenu-main">
              
  
            </Menu.SubMenu> */}
              {/* </div> */}
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <MainHeader
              title={title}
              toggle1={toggle}
              mobileSidebar={mobileSidebar}
            />
            <Content className="dashboard-content-container">
              <div className="dashboard-content-full">
                <div
                  className={`dashboard-inner-content ${
                    title === "Recorded Event" ? "recorded-room-width" : ""
                  } ${title === "Social Store" ? "store-style" : ""}`}
                >
                  <Routes>
                    {/* <Route path="/home" exact element={<Home />} /> */}
                    <Route path="/dashboard" exact element={<Dashboard />} />
                    <Route path="/broadcast" exact element={<BroadCast />} />
                    <Route path="/events" exact element={<Events />} />
                    <Route path="/allpost" exact element={<AllPost />} />
                    <Route
                      path="/schedule-event"
                      exact
                      element={<ScheduleEvent />}
                    />
                    <Route
                      path="/control-room/:id"
                      exact
                      element={<ControlRoom />}
                    />
                    <Route
                      path="/account/ecommerce"
                      exact
                      element={<Ecommerce />}
                    />
                    <Route
                      path="/account/social"
                      exact
                      element={<SocialSetup />}
                    />
                    <Route
                      path="/account/stream"
                      exact
                      element={<StreamSetup />}
                    />
                    <Route
                      path="/ugc/monitor-profile"
                      exact
                      element={<MonitorProfile />}
                    />
                    <Route
                      path="/ugc/monitor-mentions"
                      exact
                      element={<MonitorMentions />}
                    />
                    <Route
                      path="/ugc/monitor-hashtags"
                      exact
                      element={<MonitorHashTag />}
                    />
                    <Route
                      path="/posting-content"
                      exact
                      element={<PostingEvent />}
                    />
                    <Route
                      path="/direct-messaging"
                      exact
                      element={<DirectMessaging />}
                    />

                    <Route
                      path="/story-insights"
                      exact
                      element={<StoryInsight />}
                    />

                    <Route
                      path="/events1"
                      element={<Redirect to="/events" />}
                    />
                    {instagramCodeUrl.href.includes("code") ? (
                      <Route
                        path="*"
                        element={<Redirect to={`/account/social/?${code}`} />}
                      />
                    ) : (
                      <Route path="*" element={<Redirect to="/events" />} />
                    )}
                    <Route
                      path="/schedule-event-id/:id"
                      exact
                      element={<ScheduleEvent />}
                    />
                    <Route
                      path="/recorded-event/:id"
                      exact
                      element={<RecordedEvent />}
                    />
                    <Route
                      path="/privacy-policy"
                      element={<PublicPrivacyPolicy />}
                    />
                    <Route path="/terms-use" element={<PublicTermsUse />} />
                    <Route path="/account-delete" element={<AccountDelete />} />
                    <Route path="/logout" element={<Logout />} />
                    {/* <Route path="/host-event" exact element={<HostEvents />} /> */}
                    {/* <Route path="/analytics" exact element={<Analytics />} /> */}
                    {/* <Route
                      path="/create-event"
                      exact
                      element={<CreateEvent />}
                    />
                    <Route
                      path="/user-profile"
                      exact
                      element={<UserProfile />}
                    /> */}

                    {/* <Route
                      path="/schedule-event"
                      exact
                      element={<ScheduleEvent />}
                    />
                    
                    <Route path="/live-event" exact element={<LiveEvent />} />
                    <Route
                      path="/store-settings"
                      exact
                      element={<StoreSettings />}
                    /> */}
                    {/* <Route path="/control-room" exact element={<ControlRoom />} /> */}

                    {/* <Route
                      path="/account/categories"
                      exact
                      element={<CategorySetup />}
                    /> */}

                    {/* <Route
                      path="/media/gallery"
                      exact
                      element={<MediaGallery />}
                    />
                    <Route
                      path="/bioshop/bioStore"
                      exact
                      element={<LinkinBio />}
                    />
                    <Route
                      path="/bioshop/bio-setup"
                      exact
                      element={<BioShopSetup />}
                    /> */}

                    {/* <Route path="/account/sales" exact element={<Sales />} />
                    <Route
                      path="/account/event-sales"
                      exact
                      element={<EventSales />}
                    />
                    <Route
                      path="/account/store-sales"
                      exact
                      element={<StoreSales />}
                    />
                    <Route
                      path="/account/affiliate-sales"
                      exact
                      element={<AffiliateSales />}
                    />
                    <Route
                      path="/account/referral-fees"
                      exact
                      element={<ReferralFees />}
                    />
                    <Route
                      path="/account/referral-deposit"
                      exact
                      element={<ReferralDeposit />}
                    />
                    <Route
                      path="/account/orders/:id/:stdate/:endate/:group"
                      exact
                      element={<Order />}
                    />
                    <Route
                      path="/account/order-detail/:id"
                      exact
                      element={<OrderDetail />}
                    />
                    */}
                  </Routes>
                </div>

                <Footer />
              </div>
            </Content>
          </Layout>
        </>
      ) : (
        <>
          <Sider className={`sidebar-main ${!toggle ? "toggle-sidebar" : ""} `}>
            <div className="logo-area">
              <div className="logo">
                <Link
                  to="/home"
                  onClick={() => {
                    changetitle("home");
                    setToggle(false);
                  }}
                >
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="menu-close d-xl-none">
                <Link
                  to="#"
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </Link>
              </div>
            </div>

            <h5 className="wordspace-hd">Workspace</h5>

            <div className="workspace-menu" ref={ref}>
              <div className="store-menu">
                <div className="workspace-icon">
                  <FontAwesomeIcon icon={faStore} />
                </div>
                <div className="workspace-menu-item">
                  <div className="store-name-txt">{userInfo?.name}</div>
                </div>
              </div>
            </div>

            <Menu
              // theme="dark"
              mode="inline"
              defaultSelectedKeys={location.pathname.split("/")[1]}
            >
              <Menu.Item key="host-event">
                <Link
                  to="host-event1"
                  onClick={() => {
                    changetitle("host-event");
                    setToggle(false);
                  }}
                >
                  <FontAwesomeIcon className="fspx-15" icon={faUser} />
                  <span className="nav-text">Events</span>
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <MainHeader
              title={title}
              toggle1={toggle}
              mobileSidebar={mobileSidebar}
            />
            <Content className="dashboard-content-container">
              <div className="dashboard-content-full">
                <div className={`dashboard-inner-content`}>
                  <Routes>
                    <Route path="/host-event" exact element={<HostEvents />} />
                    <Route
                      path="/control-room/:id"
                      exact
                      element={<ControlRoom />}
                    />
                    <Route
                      path="/host-event1"
                      element={<Redirect to="/host-event" />}
                    />
                    <Route path="*" element={<Redirect to="/host-event" />} />
                    <Route
                      path="/recorded-event/:id"
                      exact
                      element={<RecordedEvent />}
                    />
                  </Routes>
                </div>

                <Footer />
              </div>
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default App;
