import React, { useState } from 'react';
import {
  ControlBar,
  AudioInputControl,
  VideoInputControl,
  ContentShareControl,
  AudioOutputControl,
  useMeetingManager,
  ControlBarButton,
  useUserActivityState,
  useMeetingStatus,
  Dots,
  Modal,
  ModalBody,
  ModalHeader,
  ModalButton,
  ModalButtonGroup,
  useLocalVideo,
  VideoInputBackgroundBlurControl,
  useLocalAudioOutput,
  Cog,
  LeaveMeeting,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import { useNavigate } from 'react-router-dom';
import { StyledP } from './EndMeeting/Styled';
import stream from '../../images/stream-icon.png';
import EndMeetingControl from './EndMeeting/EndMeetingControl';
// import { useNavigation } from '../../providers/NavigationProvider';
import { StyledControls } from './StyleControl';
import { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import DeviceSetup from './deviceSetup';

const MeetingControls = ({
  leaveMeeting,
  host,
  endMeeting,
  onGolive,
  meetingData,
  live,
  setLeaveMet,
  ratio,
  setRatio,
  goLive,
  stopLiveEvent,
  onLocalVideo
}) => {
  const history = useNavigate();
  const {
    tileId,
    isVideoEnabled,
    hasReachedVideoLimit,
    toggleVideo,
    setIsVideoEnabled,
  } = useLocalVideo();
  // const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const conts = useAudioVideo();

  const [loading, setLoading] = useState(false);

  const meetingManager = useMeetingManager();

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const [showSettingModal, setShowSettingModal] = useState(null);
  // const [saveSettingModal, setSaveSettingModal] = useState(false);
  const [videoState, setVideoState] = useState(false);

  // const [color, setColor] = useState(true)
  const toggleModal = () => setShowModal(!showModal);
  const toggleModal2 = () => setShowModal2(!showModal2);

  // const closeSettingModal = () => {
  //   setShowSettingModal(!showSettingModal);
  //   // const settingTag = document.getElementById('setting');
  //   // settingTag.classList.add('d-none');
  // };
  const onSaveSettingModal = () => {
    setIsVideoEnabled(false)
    setShowSettingModal(!showSettingModal);
    // setSaveSettingModal(!saveSettingModal);


    // const settingTag = document.getElementById('setting');
    // settingTag.classList.add('d-none');
  };
  const openSettingModal = () => {
    setShowSettingModal(!showSettingModal);
    // var settingTag = document.getElementById('setting');
    // settingTag.classList.remove('d-none');
  };
  //   const handleToggle = () => {
  //     if (showRoster) {
  //       closeRoster();
  //     }

  //     toggleNavbar();
  //   };

  if (!isVideoEnabled && !videoState) {
    toggleVideo();
  }

  useEffect(() => {
    setTimeout(() => {
      setVideoState(true);
      setTimeout(() => {
        onLocalVideo()
      }, 1000)
    }, 1000);
  }, []);

  useEffect(() => {
    if (showSettingModal != null && showSettingModal != true) {
      setTimeout(() => {
        toggleVideo()
      }, 500);
    }
  }, [showSettingModal]);

  useEffect(() => {
    conts?.chooseVideoInputQuality(ratio.w * 100, ratio.h * 100, 15);
  }, [ratio]);

  // useLayoutEffect(()=>{
  //   ()
  // },[])
  const settingButtonProps = {
    icon: <Cog />,
    // onClick: () => setShowSettingModal(!showSettingModal),
    onClick: () => openSettingModal(),
    label: 'Setting',
  };

  const endMeetingForAll = async () => {
    try {
      if (endMeeting) {
        setLeaveMet(true);
        setLoading(true);
        endMeeting(() => {
          meetingManager.leave().then(() => {
            setLoading(false);
            history({
              pathname: `/events`,
            });
          });
        }, stopLiveEvent);
      }
    } catch (e) {
      console.log('Could not end meeting', e);
    }
  };
  return (
    <>
      <StyledControls className="controls" active={true}>
        <ControlBar
          className="controls-menu video-controls-main"
          layout="undocked-horizontal"
          showLabels
        >
          {/* <ControlBarButton
          className="mobile-toggle"
          icon={<Dots />}
          onClick={handleToggle}
          label="Menu"
        /> */}

          {/* <span onClick={toggleAudio}> */}
          {/* <AudioInputControl  className={`controls-color ${isAudioOn?"video-enable":"video-disable"}`}/> */}
          {/* </span> */}
          <AudioInputControl />
          {/* <VideoInputBackgroundBlurControl  className={`controls-color ${isVideoEnabled?"video-enable":"video-disable"}`} /> */}
          <VideoInputControl />
          {/* <ContentShareControl /> */}
          <AudioOutputControl />
          {host && (
            <EndMeetingControl
              setLeaveMet={setLeaveMet}
              live={live}
              host={host}
              endMeeting={endMeeting}
              leaveMeeting={leaveMeeting}
            />
          )}

          {host &&
            <ControlBarButton {...settingButtonProps} />
          }
          {!host && (
            <>
              {live != 'live' ? (
                <div>
                  <button
                    className="btn-sm btn btn-danger hide-mob-text-main golive"
                    // onClick={() => onGolive()}
                    onClick={() => goLive()}
                  >
                    <span className="hide-mob-text">Go Live</span>

                    <img src={stream} alt="stream" className="show-mob-btn" />
                  </button>
                </div>
              ) : null}
              {!host && live != 'live' && (
                <div>
                  <button
                    className="btn-sm btn btn-danger ml-2 hide-mob-text-main"
                    onClick={toggleModal2}
                  >
                    <span className="hide-mob-text">Exit</span>

                    <img src={stream} alt="stream" className="show-mob-btn" />
                  </button>
                </div>
              )}
              {live == 'live' && (
                <div>
                  <button
                    className="btn-sm btn btn-danger ml-2 hide-mob-text-main"
                    // onClick={() => endMeetingForAll()}
                    onClick={toggleModal}
                    disabled={loading}
                  >
                    <span className="hide-mob-text"> {'End Event'}</span>
                    <LeaveMeeting
                      height="1.7em"
                      className="show-mob-btn leave-btn"
                    />
                  </button>
                </div>
              )}
              {showModal && (
                <Modal size="md" onClose={toggleModal} rootId="modal-root">
                  <ModalHeader title={host ? `Leave event` : 'End event'} />
                  <ModalBody>
                    <StyledP>
                      {host
                        ? 'Are you sure you want to leave event?'
                        : 'End event for all. The event cannot be used once it ends.'}
                    </StyledP>
                  </ModalBody>
                  <ModalButtonGroup
                    primaryButtons={[
                      <ModalButton
                        // onClick={()=>{
                        //   leaveMeeting().then(()=>{
                        //     history({
                        //       pathname: `/events`,
                        //     });
                        //   })
                        // }}
                        onClick={() => endMeetingForAll()}
                        variant="primary"
                        label={host ? 'Leave event' : 'End event'}
                        closesModal
                      />,
                      <ModalButton
                        variant="secondary"
                        label="Cancel"
                        closesModal
                      />,
                    ]}
                  />
                </Modal>
              )}
              {showModal2 && (
                <Modal size="md" onClose={toggleModal2} rootId="modal-root">
                  <ModalHeader title={'Exit Event'} />
                  <ModalBody>
                    <StyledP>
                      {/* {host ? "Are you sure you want to leave event?" : ("End event for all. The event cannot be used once it ends.")} */}
                      Do you want to exit the event?
                    </StyledP>
                  </ModalBody>
                  <ModalButtonGroup
                    primaryButtons={[
                      <ModalButton
                        onClick={() => {
                          endMeeting();
                          // leaveMeeting().then(()=>{
                          //   history({
                          //     pathname: `/events`,
                          //   });
                          // })
                        }}
                        variant="primary"
                        className="default-btn medium-btn padb-3  fw-normal"
                        label={'Exit event'}
                        closesModal
                      />,
                      <ModalButton
                        variant="secondary"
                        label="Cancel"
                        closesModal
                        className="default-btn medium-btn padb-3 outline  fw-normal"
                      />,
                    ]}
                  />
                </Modal>
              )}
            </>
          )}
        </ControlBar>
      </StyledControls>
      {showSettingModal && (
        <Modal
          size="lg"
          // onClose={() => closeSettingModal()}
          rootId="modal-root1"
          dismissible={false}
        // id="setting"
        // className="d-none"
        >
          <ModalHeader title="Device Setting" />
          <ModalBody>
            <DeviceSetup ratio={ratio} setRatio={setRatio} forSetting={true} />
          </ModalBody>
          <ModalButtonGroup
            primaryButtons={[
              //   <button
              //   className="btn-sm btn btn-danger ml-2 hide-mob-text-main"
              //   onClick={closeSettingModal}
              // >
              //   <span className="hide-mob-text">Exit</span>

              // </button>,
              <button
                className="default-btn small-btn fw-normal"
                onClick={onSaveSettingModal}
              >
                <span className="hide-mob-text">Save</span>

              </button>

            ]}
          />
        </Modal>
      )}
    </>
  );
};

export default MeetingControls;
