import {
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_ERROR,
  GET_ORDER_REQUEST,
  GET_ORDER_SUCCESS,
  GET_ORDER_ERROR,

} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getSalesReport = (limit, page, st_date, end_date, group_key, catg_id) => (dispatch) => {

  if (catg_id != "" && group_key === "category") {
    console.log(group_key,catg_id,"---");
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        category_id: catg_id,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (catg_id != "" && group_key === "event") {

    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        category_id: catg_id,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (catg_id != "" && group_key === "date") {

    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        category_id: catg_id,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (group_key === "" && catg_id === "") {
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (group_key === "date") {
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (group_key === "event") {
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (catg_id != "" && group_key === "") {
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        category_id: catg_id
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
  if (group_key === "category") {
    dispatch({ type: GET_REPORT_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalessummary?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: st_date,
        to_date: end_date,
        category_id: catg_id,
        group_by: group_key
      },

    })
      .then((response) => {
        return dispatch({
          type: GET_REPORT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REPORT_ERROR,
          payload: error.response,
        });
      });
  }
};


export const getOrderReport = (limit, page, id, st_date, end_date, group_key) => (dispatch) => {

  if (group_key === "date") {
    dispatch({ type: GET_ORDER_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalesdetail?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        from_date: end_date,
        to_date: end_date
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ORDER_ERROR,
          payload: error.response,
        });
      });
  }

  if (group_key === "category") {
    dispatch({ type: GET_ORDER_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalesdetail?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        category_id: id,
        from_date: st_date,
        to_date: end_date
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ORDER_ERROR,
          payload: error.response,
        });
      });
  }

  if (group_key === "event" || group_key === "default") {
    dispatch({ type: GET_ORDER_REQUEST });
    return axios({
      method: 'POST',
      url: `${BASEURL}livesales/getsalesdetail?page=${page}&limit=${limit}`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        event_id: id,
        from_date: st_date,
        to_date: end_date
      },
    })
      .then((response) => {
        return dispatch({
          type: GET_ORDER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ORDER_ERROR,
          payload: error.response,
        });
      });
  }
};