import {
  GET_BROADCAST_CLIENT_REQUEST,
  GET_BROADCAST_CLIENT_SUCCESS,
  GET_BROADCAST_CLIENT_ERROR,
  POST_GOLIVE_REQUEST,
  POST_GOLIVE_SUCCESS,
  POST_GOLIVE_ERROR,
  POST_STOPLIVE_REQUEST,
  POST_STOPLIVE_SUCCESS,
  POST_STOPLIVE_ERROR,
  SEND_METADATA_REQUEST,
  SEND_METADATA_SUCCESS,
  SEND_METADATA_ERROR,
  GET_METADATA_REQUEST,
  GET_METADATA_SUCCESS,
  GET_METADATA_ERROR,
  SAVE_CONTROL_REQUEST,
  SAVE_CONTROL_SUCCESS,
  SAVE_CONTROL_ERROR,
  GET_CONTROL_REQUEST,
  GET_CONTROL_SUCCESS,
  GET_CONTROL_ERROR,
  START_MEETING_REQUEST,
  START_MEETING_SUCCESS,
  START_MEETING_ERROR,
  END_MEETING_REQUEST,
  END_MEETING_SUCCESS,
  END_MEETING_ERROR
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getBroadcastClientRequest = (event_id) => (dispatch) => {
  dispatch({ type: GET_BROADCAST_CLIENT_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}live/getchannels`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_BROADCAST_CLIENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_BROADCAST_CLIENT_ERROR,
        payload: error.response,
      });
    });
};

export const goLive = (id) => (dispatch) => {
  dispatch({ type: POST_GOLIVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/golive`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: { id },
  })
    .then((response) => {
      return dispatch({
        type: POST_GOLIVE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_GOLIVE_ERROR,
        payload: error.response,
      });
    });
};
export const goFbLive = (id) => {
  return axios({
    method: "POST",
    url: `${BASEURL}events/golivefacebook`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: { id },
  })
};
export const stopLive = (id) => (dispatch) => {
  dispatch({ type: POST_STOPLIVE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/endlive`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: { id },
  })
    .then((response) => {
      return dispatch({
        type: POST_STOPLIVE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_STOPLIVE_ERROR,
        payload: error.response,
      });
    });
};

export const sendMetaData = (data) => (dispatch) => {
  dispatch({ type: SEND_METADATA_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/putMetaDataOnLive`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: SEND_METADATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SEND_METADATA_ERROR,
        payload: error.response,
      });
    });
};

export const getMetaData = (id) => (dispatch) => {
  dispatch({ type: GET_METADATA_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}public/events/getLiveStreamingMetaData/${userInfo?._id}`,
    // headers: {
    //   Authorization: `Bearer ${userInfo?.token}`,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_METADATA_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_METADATA_ERROR,
        payload: error.response,
      });
    });
};

export const saveControl = (id, data) => (dispatch) => {
  dispatch({ type: SAVE_CONTROL_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/createbroadcastsetting`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: {
      event_id: id,
      broadcast_settings: data,
    },
  })
    .then((response) => {
      return dispatch({
        type: SAVE_CONTROL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_CONTROL_ERROR,
        payload: error.response,
      });
    });
};

export const getControl = (id) => (dispatch) => {
  dispatch({ type: GET_CONTROL_REQUEST });
  return axios({
    method: "GET",
    url: `${BASEURL}events/getbroadcastsetting/${id}`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_CONTROL_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CONTROL_ERROR,
        payload: error.response,
      });
    });
};

export const startMeetingRequest = (event) => (dispatch) => {
  dispatch({ type: START_MEETING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/createmeeting`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { event },
  })
    .then((response) => {
      return dispatch({
        type: START_MEETING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: START_MEETING_ERROR,
        payload: error.response,
      });
    });
};

export const endMeetingRequest = (event, cb, cb2) => (dispatch) => {
  dispatch({ type: END_MEETING_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}events/deletemeeting`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { event },
  })
    .then((response) => {
      if (cb) cb()
      if (cb2) cb2()
      return dispatch({
        type: END_MEETING_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: END_MEETING_ERROR,
        payload: error.response,
      });
    });
};