import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Modal, notification } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUpcomingEvents,
  deleteEvent,
} from '../../redux/actions/event.action';
import searchEventImg from '../../images/search-event.png';
import moment from 'moment';
import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faAngleLeft,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import NoEventFound from '../../reUsable/noEventFound';
import defaultImage from '../../images/user.png';

const { confirm } = Modal;
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export default ({ className }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { upcomingEvent } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 8;

  useEffect(() => {
    dispatch(getUpcomingEvents(limit, 1)).then((res) => {
      if (res.payload.success) {
        setPageCount(res.payload.message?.next?.page);
      } else {
        console.log('err');
      }
    });
  }, []);

  const controlRoom = (val) => {
    history({
      pathname: `/control-room/${val._id}`,
    });
  };

  const handleButtonClick = (e, item) => {
    if (e.key === 'cancel') {
      confirm({
        wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
        title: 'Warning',
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: 'Are you sure you want to cancel this event ?',
        okText: ' Yes, cancel',
        okType: 'danger',
        cancelText: " No, I don't want to cancel ",

        onOk() {
          return new Promise((resolve, reject) => {
            dispatch(deleteEvent(item._id)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: res.payload?.message,
                  className: 'toast-success',
                });
                dispatch(getUpcomingEvents());
                setTimeout(resolve, 100);
              } else {
                console.log(res.payload.data.message, 'err');
                notification.error({
                  message: res.payload.data.message,
                  className: 'toast-error',
                });
              }
            });
          });
        },

        onCancel() {
          console.log('Cancel');
        },
      });
    }
    if (e.key === 'edit') {
      history({
        pathname: `/schedule-event-id/${item._id}`,
      });
    }
  };

  const load = upcomingEvent.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    dispatch(getUpcomingEvents(limit, page + 1));
  };

  function roomDisable(item) {
    const dateIsAfter = moment(item.start_date).isAfter(
      moment(moment.utc().format())
    );
    if (dateIsAfter) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      {!load ? (
        <>
          <div
            className={`upcoming-events-wrapper mb-30 ${
              className ? 'grid-block' : 'list-block'
            }`}
          >
            {!upcomingEvent.loading ? (
              <>
                {upcomingEvent?.payload?.message?.data.length > 0 ? (
                  <div className="upcoming-events-grid">
                    {upcomingEvent?.payload?.message?.data.map((item, i) => {
                      return (
                        <>
                          <div className="upcoming-events-box">
                            {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                            <div className="event-banner">
                              <div className="event-box w-100 h-100 overflow-hidden">
                                <div className="event-banner-img">
                                  <img src={item.banner} alt="banner" />
                                </div>

                                <div>
                                  <div className="schedule-badge schedule">
                                    {item.event_status}
                                  </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                  <div className="ribbon-small">
                                    <FontAwesomeIcon icon={faFlask} />
                                    <span>Event </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                              <div>
                                <div className="event-date-main top-date">
                                  <div className="event-date">
                                    <FontAwesomeIcon
                                      className="event-icon"
                                      icon={faCalendarDays}
                                    />
                                    <div className="event-txt">
                                      {moment(item.start_date)
                                        .utc()
                                        .format('YYYY-MM-DD')}
                                      {/* {new Date(item.start_date).toLocaleDateString()} */}
                                    </div>
                                  </div>
                                  <div className="event-date">
                                    <FontAwesomeIcon
                                      className="event-icon"
                                      icon={faClock}
                                    />
                                    <div className="event-txt">
                                      {moment(item.start_date).format(
                                        'hh:mm a'
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="event-date d-inline-flex">
                            <FontAwesomeIcon className="event-icon" icon={faCalendarDays} />
                            <div className="event-txt">
                                FRIDAY, JULY 29, 2022
                            </div>
                            <FontAwesomeIcon className="event-icon" icon={faClock} />
                            <div className="event-txt">
                                3:10 PM
                            </div>
                        </div> */}

                                <h3 className="event-hd">{item.title}</h3>
                              </div>
                              <div className="event-date-main">
                                {/* <div className="event-date bottom-style">
                                                    <div className="event-txt">
                                                    </div>
                                                </div> */}
                                <div className="event-date bottom-style">
                                  <FontAwesomeIcon
                                    className="event-icon"
                                    icon={faUser}
                                  />
                                  <div className="event-txt">
                                    {userInfo?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="event-card-footer">
                              <>
                                <Button
                                  className="default-btn d-flex align-items-center justify-content-center"
                                  type="primary"
                                  size="large"
                                  onClick={() => controlRoom(item)}
                                  icon={
                                    <FontAwesomeIcon
                                      size="sm"
                                      className="event-icon"
                                      icon={faVideo}
                                    />
                                  }
                                  // disabled={
                                  //   roomDisable(item) ||
                                  //   item.event_status === "previous"
                                  //     ? true
                                  //     : false
                                  // }
                                >
                                  Control Room
                                </Button>
                              </>

                              <>
                                <Dropdown.Button
                                  trigger="click"
                                  overlayClassName="control-room-drowpdown"
                                  size="large"
                                  // onClick={() => handleButtonClick(item)}
                                  // overlay={menu}
                                  overlay={
                                    <Menu
                                      onClick={(e) =>
                                        handleButtonClick(e, item)
                                      }
                                    >
                                      <Menu.Item
                                        key="url"
                                        // disabled={
                                        //   roomDisable(item) ||
                                        //   item.event_status === "previous"
                                        //     ? true
                                        //     : false
                                        // }
                                      >
                                        <span>Public URL</span>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="edit"
                                        // disabled={
                                        //   roomDisable(item) ||
                                        //   item.event_status === "previous"
                                        //     ? true
                                        //     : false
                                        // }
                                      >
                                        <span>Edit</span>
                                      </Menu.Item>
                                      <Menu.Item key="cancel">
                                        <span>Cancel</span>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                ></Dropdown.Button>
                              </>
                              {/* )} */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-result-found">
                    <div className="no-result-img">
                      <img
                        src={
                          userInfo?.profile_image_url
                            ? userInfo?.profile_image_url
                            : defaultImage
                        }
                        alt="searchEventImg"
                      />
                    </div>
                    <h3 className="no-event-hd"> No Event At This Time.</h3>
                    <p className="event-para">Please check back later.</p>
                    {/* <p>No Event At This Time. Please check back later.</p> */}
                  </div>
                )}
              </>
            ) : (
              <>{null}</>
            )}

            {/* {page === "home"
              ? null
              : upcomingEvent?.payload?.message?.data.length > 0 && (
                  <ReactPaginate
                    nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    forcePage={currentPage}
                    previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                    pageClassName="page-item d-none"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item d-none"
                    breakLinkClassName="page-link"
                    containerClassName={"pagination custom-paginate"}
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                )} */}
          </div>
        </>
      ) : (
        // <Spin className="m-auto d-table" indicator={antIcon} />
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      )}
    </>
  );
};
