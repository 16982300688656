import { GET_SCHEDULE_POST  } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const schedulePost = (data) => async (dispatch) => {
    const res= axios.post(`${BASEURL}graph/ig/publishmedia/create`,data,{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    return res
};

export const getSchedulePosts = () => async (dispatch) => {
    const res= await axios.post(`${BASEURL}graph/ig/publishmedia/getall`,{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    dispatch({
        type:GET_SCHEDULE_POST,
        payload:res.data
    })
};

// export const getSchedulePost = (media_id) => async (dispatch) => {
//     const res= axios.post(`${config.hostApi}/v1/graph/ig/publishmedia/getone`,{media_id})
//     return res
// };


export const deleteSchedulePost = (media_id) => async (dispatch) => {
    const res= axios.post(`${BASEURL}graph/ig/publishmedia/delete`,{media_id},{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    return res
};

export const updateSchedulePost = (data) => async (dispatch) => {
    const res= axios.post(`${BASEURL}graph/ig/publishmedia/update`,data,{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    return res
};

export const directPublish = (data) => async (dispatch) => {
    const res= axios.post(`${BASEURL}graph/ig/publishmedia/publish`,data,{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    return res
};

export const directPublishFB = (data) => async (dispatch) => {
    const res= axios.post(`${BASEURL}social/fb/publishmedia`,data,{headers: { Authorization: `Bearer ${userInfo?.token}`},})
    return res
};