import axios from "axios";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/Loader/Loader";
import Chat from "./chat";
import { getStoryInsights } from "../../redux/actions/storyInsights";
import { useDispatch } from "react-redux";
import { Spin } from "antd";

export default function Content({ logOut, pageId, accessToken }) {
  const dispatch = useDispatch();

  const [innerTabs, setInnerTabs] = useState(1);
  const [chatList, setChatList] = useState([]);
  const [PAT, setPAT] = useState("");
  const [loading, setloading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [mesLoading, setMesLoading] = useState(false);
  const [currentChat, setCurrentChat] = useState({});
  const [uName, setUName] = useState("");

  useEffect(() => {
    getPAT();
  }, []);

  function getPAT() {
    fetch(`https://graph.facebook.com/${pageId}?
        fields=access_token&
        access_token=${accessToken}`)
      .then((pro) => pro.json())
      .then((res) => {
        setPAT(res?.access_token);
        fetch(
          `https://graph.facebook.com/v12.0/${pageId}/conversations?platform=instagram&access_token=${res?.access_token}`
        )
          .then((pro2) => pro2.json())
          .then(async (res2) => {
            // console.log("res2", res2);
            const chatPromises = res2.data.map((item) => {
              return axios.get(
                `https://graph.facebook.com/v12.0/${item.id}?fields=id,updated_time,participants&access_token=${res?.access_token}`
              );
            });

            const { page_token, fb_token } = JSON.parse(
              localStorage.getItem("userInfo")
            );
            // console.log("fetchmoredata");
            axios
              .get(
                `https://graph.facebook.com/v12.0/${page_token}?fields=instagram_business_account&access_token=${fb_token}`
              )
              .then((idRes) => {
                axios
                  .get(
                    `https://graph.facebook.com/v12.0/${idRes?.data?.instagram_business_account?.id}?fields=username&access_token=${accessToken}`
                  )
                  .then((nameRes) => {
                    // console.log("nameRes", nameRes);
                    setUName(nameRes.data.username);
                    //  if (chatPromises.length > 0) {
                    Promise.all(chatPromises).then((chat) => {
                      //   console.log("tttt", chat);
                      const chats = chat
                        .map((item) => item?.data)
                        .map((item) => {
                          //   console.log("item", item);
                          return {
                            ...item,
                            participants: undefined,
                            user: item?.participants?.data.filter(
                              (itemN) =>
                                itemN.username != nameRes.data?.username ||
                                item.participants?.data?.length == 1
                            )[0],
                          };
                        });
                      //   console.log("chats", chats);
                      setChatList(chats);
                      //   getChat(chats?.[0]?.id, res?.access_token);
                      getInsights(chats?.[0]?.user?.id);
                      setCurrentChat(chats[0]);
                      setloading(false);
                    });

                    // }
                  });
              });
          });
      });
  }

  function getChat(id, token) {
    setMesLoading(true);
    fetch(
      `https://graph.facebook.com/v12.0/${id}?fields=messages&access_token=${
        token ? token : PAT
      }`
    )
      .then((pro) => pro.json())
      .then(async (res) => {
        const messagePromises = res?.messages?.data?.map(({ id }) => {
          return axios.get(
            `https://graph.facebook.com/v12.0/${id}?fields=id,created_time,from,to,message,reactions,is_unsupported&access_token=${
              token ? token : PAT
            }`
          );
        });
        // if (messagePromises.length > 0) {
        Promise.all(messagePromises).then((chat) => {
          const messagesRes = chat
            ?.map((item) => item?.data)
            .map((item) => {
              return {
                ...item,
                from: item?.from?.username,
              };
            });
          setMessages(messagesRes);
          setMesLoading(false);
        });

        // }
      });
  }

  function getInsights(senderid) {
    setMesLoading(true);
    dispatch(getStoryInsights(senderid, "17841456670947463")).then((res) => {
      setMesLoading(false);
      console.log(res.payload.message, "payload");
      setMessages(res.payload.message);
      // if (res.payload.success) {
      // //   setMessages(messagesRes);
      // } else {
      //   console.log('err');
      // }
    });
  }

  function renderChats() {
    return chatList?.map((item) => {
      //   console.log("item", item);
      return (
        <a
          key={item?.id}
          onClick={() => {
            getInsights(item?.user?.id);
            setCurrentChat(item);
          }}
          class="list-group-item list-group-item-action list-group-item-light"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                textTransform: "uppercase",
                fontWeight: "bold",
                height: 40,
                width: 40,
                borderRadius: 20,
                background: "grey",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
              }}
            >
              {item?.user?.username.slice(0, 1)}
            </div>
            <p style={{ margin: 0, marginLeft: 10 }}>{item?.user?.username}</p>
          </div>
        </a>
      );
    });
  }

  function sendMessage(text) {
    return axios
      .post(
        `https://graph.facebook.com/v12.0/me/messages?access_token=${PAT}`,
        {
          recipient: {
            id: currentChat?.user?.id,
          },
          message: {
            text: text,
          },
        }
      )
      .then((res) => {
        setMessages([
          {
            created_time: new Date().toUTCString(),
            from: uName,
            id: res.data?.message_id,
            message: text,
          },
          ...messages,
        ]);
      });
  }
  if (!loading) {
    return (
      <>
        <Row>
          <Col lg={10}>
            <h4>Story Mention</h4>
          </Col>
          {/* <Col lg={2}>
                    <button
                        onClick={logOut}
                        className="btn btn-primary"
                    >
                        Disconnect
                    </button>
                </Col> */}
        </Row>
        <Row className=" main-containe">
          <Col className="" md="5" xs="12" xl="3">
            <ul class="list-group">{renderChats()}</ul>
          </Col>

          <Col md="7" xs="12" xl="9">
            <div className={`bg-white fb-right-content h-100`}>
              {mesLoading ? (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              ) : (
                <div className="insights-box row">
                  {!messages.length
                    ? null
                    : messages.map((item, index) => {
                        return (
                          // <div className="insights-box-inner">{item.entry[0]?.messaging[0]?.message?.attachments[0]?.payload?.url}</div>
                          <div className="col-12 col-sm-6  col-md-4 col-xl-3 insights-box-grid">
                            <div className="insights-box-inner">
                              <img
                                src={
                                  item.entry[0]?.messaging[0]?.message
                                    ?.attachments[0]?.payload?.url
                                }
                                alt="imag2"
                                className="img-flui insight-img"
                              />
                            </div>
                          </div>
                        );
                      })}
                </div>
                //   <Chat
                //     sendMessage={sendMessage}
                //     currentChat={currentChat}
                //     uName={uName}
                //     messages={messages}
                //   />
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div style={{ height: 400 }}>
        <Loader size={30} />
      </div>
    );
  }
}
