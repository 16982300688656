import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import * as instaAction from "../../redux/actions/instaPost";
import * as schedulePostActions from "../../redux/actions/schedulePost";
import * as categoriesActions from "../../redux/actions/category.action";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
//import { Button, Paper } from "@mui/material";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFlask,
    faCalendarDays,
    faClock,
    faUser,
    faVideo,
    faXmark,
    faAngleLeft,
    faAngleRight,
    faPlay,
    faUserTie,
    faShareNodes,
  } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
// import Loader from "../../../components/Loader/Loader";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { Label, Input } from "reactstrap";
import Select from "react-select";
import { DatePicker,Spin,notification } from "antd";
import axios from "axios";
import { BASEURL } from '../../config';
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

function AllGallery({
  title,
  getMedia,
  gallery,
  deleteMedia,
  schedulePost,
  getUserCategories2,
  categories,
  directPublish,
  name,
}) {
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [showBio, setShowBio] = useState(false);
  const [showPost, setShowPost] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [videoIcon, setVideoIcon] = useState(false);
  const [videoId, setVideoId] = useState();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const previousValue = useRef(null);

  const [fields, setFields] = useState({
    caption: "",
    publish_date: "",
    redirected_url: "",
    start_date: "",
    end_date: "",
    categories: { value: "", label: "Please Select" },
  });

  const [currentPage, setCurrentPage] = useState(0);
  const limit = 8;

  useEffect(() => {
   
    // getUserCategories2(userInfo.user_id);
    getMedia(name, 1, limit).then(() => setLoading(false));
  }, []);
  useEffect(() => {
    // previousValue.current = videoId;
    // var oldVideo = document.getElementById(videoId);
    // console.log(previousValue.current, "prevID");
    // console.log(videoId, "videoId");
    // if (previousValue.current) {
    // } else {
    // }
  }, [videoId]);

  const onDelete = async (item) => {
    let page = gallery.data.length === 1 ? currentPage : currentPage + 1;
    setLoading(false);
    if (currentPage === 0) page = 1;
    Swal.fire({
      title: "Are You Sure You Want To Delete This Media?",
      text: "This Will Remove Your Media From BioShop As Well!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        // setDeleteLoading(true);
        deleteMedia(item.media_library_id).then(() => {
          setLoading(false);
          setCurrentPage(0);
          getMedia(name, 1, limit);
          // setDeleteLoading(false);
          // if (gallery.data.length === 1) {
          //   setCurrentPage(currentPage - 1);
          // }
        });
      }
    });
  };

  const dateRangePickerChanger = async (value, dataString) => {
    let startDate = dataString[0];
    let endDate = dataString[1];
    setFields({ ...fields, start_date: startDate, end_date: endDate });
    // this.setState({ startDate: startDate });
    // this.setState({ endDate: endDate });
  };

  const toggleMedia = async (status, mediaId) => {
    let page = gallery.data.length === 1 ? currentPage : currentPage + 1;
    if (currentPage === 0) page = 1;

    let statusName = status ? "In-Active" : "Active";
    Swal.fire({
      title: `Are You Sure You Want To ${statusName} This Media?`,
      text:
        statusName === "disable"
          ? "This Will Remove Your Media From BioShop As Well!"
          : "",
      icon: "warning",
      cancelButtonText: "No",
      showCancelButton: true,
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        // setToggleLoading(true);
        axios
          .put(`${BASEURL}library/revise/status`, {
            is_active: !status,
            media_library_id: mediaId,
          },{headers: { Authorization: `Bearer ${userInfo?.token}`},})
          .then((res) => {
            setLoading(true);
            setCurrentPage(0);
            getMedia(name, 1, limit).then(() => setLoading(false));
            // // setToggleLoading(false);
            // if (gallery.data.length === 1) {
            //   setCurrentPage(currentPage - 1);
            // }
            // let data1 = [...data];
            // let objIndex = data1.findIndex(
            //   (obj) => obj.campaign_id === mediaId
            // );
            // data1[objIndex].is_active = !status;
            // setData(data1);
            // setTimeout(() => {
            //   let data2 = [...data].filter(function (item) {
            //     return item.campaign_id !== mediaId;
            //   });
            //   setData(data2);
            //   const page = Math.ceil(data2.length / perPage) - 1;
            //   const selectedPage = page;
            //   const offset = selectedPage * perPage;
            //   setPageCount(page + 1);
            //   setCurrentPage(selectedPage);
            //   setOffset(offset);
            // }, 300);
            
            notification.success({
                message: res.data.message,
               className: 'toast-success',
             });
            
          })
          .catch((err) => {
            
            notification.success({
                message: err.response?.data.message,
               className: 'toast-success',
             });
          });
      }
    });
  };

  function Pauseplay(e, id) {
    e.preventDefault();
    // var oldVideo = document.getElementById(videoId);

    // if (oldVideo) {
    //   oldVideo.pause();
    // }
    // console.log(videoId, "videoId");
    // console.log(videoId, "videoId");

    setVideoId(id);

    var testvideo = document.getElementById(id);

    if (testvideo.paused) {
      testvideo.play();
      setVideoIcon(true);
    } else {
      testvideo.pause();
      setVideoIcon(false);
    }
  }

  function renderContent() {
    if (!loading) {
      return (
        <Row className="post-analytics-tab-boxes-ift">
          {gallery.data.length > 0 ? (
            gallery.data.map((item, i) => (
              <Col xs={12} xl={3} md={6}>
                <div
                  key={i} 
                  className={`card any_bx analytic-box campaign-box pb-0`}
                >
                  <div className="camp-row row">
                    <div className="campaign-header col-12">
                      <h6>
                        {item.title.length > 20
                          ? item.title.slice(0, 20) + "..."
                          : item.title}
                      </h6>
                      <div className="cmp-h-right">
                        {/* {toggleLoading && <Loader />} */}
                        {toggleLoading ? (
                          <div class="form-check custom-switch custom-switch-md">
                             <span className="spinclass loader-center position-relative">
          <Spin size="small" />
        </span>
                          </div>
                        ) : (
                          <div class="form-check custom-switch custom-switch-md">
                            <input
                              type="checkbox"
                              checked={name === "active" ? true : false}
                              onClick={() => {
                                toggleMedia(
                                  item.is_active,
                                  item.media_library_id
                                );
                              }}
                              class="custom-control-input"
                              id={`customSwitch` + i}
                              readOnly
                            />
                            <label
                              class="custom-control-label"
                              htmlFor={`customSwitch` + i}
                            ></label>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="any-post-img-col col-12"
                      style={{ marginBottom: 50 }}
                    >
                      <div className="any-post-image media-post-new">
                        <div className="any-image-box">
                          <div className="any-image-box-iner">
                            {new URL(item?.media_url).pathname.split(".")[1] ===
                            "mp4" ? (
                              <>
                                {/* <button
                                  onClick={(e) =>
                                    Pauseplay(e, item.media_library_id)
                                  }
                                  className="btn-link btn-play"
                                >
                                  {videoId === item.media_library_id ? (
                                    !videoIcon ? (
                                      <i
                                        class="fa fa-play"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <i
                                        class="fa fa-pause"
                                        aria-hidden="true"
                                      ></i>
                                    )
                                  ) : (
                                    <i
                                      class="fa fa-play"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </button> */}
                                <video
                                  className="gallery-video media-image"
                                  // controlsList="nodownload"
                                  controls={true}
                                  id={item.media_library_id}
                                  alt={item.title}
                                >
                                  <source
                                    src={item.media_url}
                                    type="video/mp4"
                                  ></source>
                                </video>
                              </>
                            ) : (
                              <img
                                src={item.media_url}
                                className="img-fluid media-image"
                                alt={item.title}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cam-buttons col-12">
                    {
                      // publishLoading && currentData.media_library_id==item.media_library_id?(
                      //   <button
                      //   className="btn"
                      // >
                      //   <Loader/>
                      // </button>
                      // ):(
                      //   <button
                      //   className="btn"
                      //   onClick={()=>onPublish(item)}
                      // >
                      //   <i className="fa fa-check" /> Publish
                      // </button>
                      // )
                    }
                    {/* <button
                      className="btn"
                      onClick={() => {
                        setCurrentData(item);
                        setModal(true);
                      }}
                    >
                      <i class="fa fa-calendar-check-o" /> Schedule
                    </button> */}
                    {deleteLoading ? (
                      <button className="btn">
                          <span className="spinclass loader-center position-relative">
          <Spin size="small" />
        </span>
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => {
                          onDelete(item);
                        }}
                      >
                        <i className="fa fa-trash" /> Delete
                      </button>
                    )}{" "}
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div className="col-md-12">
              <NoDataFound />
            </div>
          )}
        </Row>
      );
    } else {
      return (
        <div style={{ height: 300 }}>
            <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
        </div>
      );
    }
  }

  function renderSchedule() {
    return (
      <div>
        <h5 className="mt-2">Schedule Post</h5>
        <hr style={{ margin: 0, marginBottom: 5, borderTopColor: "gray" }} />
        <Row>
          <Col lg={12}>
            <div className="date-range-aff">
              <label>Date & Time</label>
              <DatePicker
                disabledDate={startDateLimit}
                style={{ width: "100%" }}
                size={"medium"}
                showTime={{ format: "HH:mm" }}
                onChange={(e) => {
                  setFields({ ...fields, publish_date: e._d });
                }}
              />
              {submit && !fields.publish_date ? (
                <small style={{ color: "red" }}>Please Fill</small>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  function startDateLimit(current) {
    return current && current < moment().startOf("day");
  }
  function renderBioShop() {
    return (
      <div>
        <h5 className="mt-4">Add to Bioshop</h5>
        <hr style={{ margin: 0, marginBottom: 5, borderTopColor: "gray" }} />
        <Row>   
          <Col lg={12}>
            <div>
              <label>URL</label>  
              <Input
                className=""
                placeholder="Please Enter Website Address"
                type="text"
                id="website"
                required
                name="website"
                trigger="change"
                validations="isUrl"
                validationError={{
                  isUrl: "This value should be a valid url.",
                }}
                onChange={(e) =>
                  setFields({ ...fields, redirected_url: e.target.value })
                }
              />
              {submit && !fields.redirected_url ? (
                <small style={{ color: "red" }}>Please Fill</small>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="select-categories">
              <label>Category</label>
              <Select
                key={Date.now()}
                value={fields.categories}
                options={categories.map((item) => {
                  return {
                    value: item.category_id,
                    label: item.category_name,
                  };
                })}
                onChange={(item) => {
                  setFields({ ...fields, categories: item });
                }}
                style={{ width: "100%" }}
                placeholder="Category"
                disabled={true}
              ></Select>
              {submit && !fields.categories.value ? (
                <small style={{ color: "red" }}>Please Fill</small>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <div className="date-range-aff">
              <label>Select Start Date / End Date</label>
              <RangePicker
                key={1}
                // value={[
                //     moment.utc(this.state.startDate),
                //     moment.utc(this.state.endDate),
                // ]}
                defaultPickerValue={moment(new Date(), "YYYY-MM-DD")}
                allowClear={false}
                ranges={{
                  Today: [moment(), moment()],

                  Tomorrow: [moment().add(1, "days"), moment().add(1, "days")],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                }}
                style={{ width: "100%" }}
                // format={dateFormat}
                // showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD"
                // onChange={this.dateRangePickerChanger}
                onChange={dateRangePickerChanger}
              />
              {submit && !fields.start_date && !fields.end_date ? (
                <small style={{ color: "red" }}>Please Fill</small>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  function onSubmit() {
    setSubmit(true);
    if (showPost) {
      if (showBio) {
        if (
          fields.publish_date &&
          fields.redirected_url &&
          fields.categories.value &&
          fields.start_date &&
          fields.end_date
        ) {
          setLoading(true);
          schedulePost({
            ...fields,
            media_url: currentData.media_url,
            media_library_id: currentData.media_library_id,
            caption: currentData.title,
            is_link_in_bioshop: true,
            categories: [fields.categories.value],
          })
            .then(() => {
              setModal(false);
              setLoading(false);
              notification.success({
                message: "Successfully Scheduled",
               className: 'toast-success',
             });
              
            })
            .catch((err) => {
              setModal(false);
              setLoading(false);
              notification.error({
                message: err.response.message,
               className: 'toast-error',
             });
              
            });
        }
      } else {
        if (fields.publish_date) {
          setLoading(true);
          schedulePost({
            ...fields,
            media_url: currentData.media_url,
            media_library_id: currentData.media_library_id,
            caption: currentData.title,
            is_link_in_bioshop: false,
          })
            .then(() => {
              setModal(false);
              setLoading(false);
              notification.success({
                message: "Successfully Scheduled",
               className: 'toast-success',
             });
             
            })
            .catch((err) => {
              setModal(false);
              setLoading(false);
              notification.success({
                message: err.response.message,
               className: 'toast-success',
             });

            });
        }
      }
    }
  }

  function onPublish(seletedItem) {
    setCurrentData(seletedItem);
    Swal.fire({
      title: "Are You Sure?",
      text: "You Want To Publish This Post On Instagram?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#010b40",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        setPublishLoading(true);
        directPublish({
          media_url: seletedItem.media_url,
          media_library_id: seletedItem.media_library_id,
          media_id: seletedItem.media_id,
          caption: seletedItem.title,
        })
          .then(() => {
            setPublishLoading(false);
            notification.success({
                message: "Successfully Published",
               className: 'toast-success',
             });
           })
          .catch((err) => {
            notification.error({
                message: err.response.message,
               className: 'toast-error',
             });  
        });
      }
    });
  }

  const handlePageClick = (e) => {
    setLoading(true);
    const page = e.selected;
    setCurrentPage(page);
    getMedia(name, page + 1, limit).then(() => setLoading(false));
  };

  return (
    <div className="container-fluid">
      <h4 className="page-title">{title}</h4>
      {renderContent()}
      {gallery?.data?.length > 0 && !loading && (
        <ReactPaginate
        previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
          nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
          pageClassName="page-item "
          pageLinkClassName="page-link custom-paginate-link btn btn-primary"
          previousClassName="page-item"
          previousLinkClassName="page-link custom-paginate-prev btn btn-primary"
          nextClassName="page-item"
          nextLinkClassName="page-link custom-paginate-next btn btn-primary"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={currentPage}
          pageCount={Math.ceil(gallery.total_count / limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
          onPageChange={handlePageClick}
          containerClassName={
            "pagination justify-content-center mt-2 custom-paginate"
          }
          // subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      )}
      <Modal
        show={modal}
        onHide={() => {
          setSubmit(false);
          setModal(false);
        }}
        className="edit-campaign linkin-bio"
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white affiliate-model image-edit-box p-3">
          <Row>
            <Col lg={4}>
              <div class="form-group">
                <label for="exampleInputEmail1">Caption</label>
                <input
                  value={currentData.title}
                  onChange={(e) =>
                    setCurrentData({ ...currentData, title: e.target.value })
                  }
                  type="text"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter Caption"
                />
                {submit && !currentData.title ? (
                  <small style={{ color: "red" }}>Please Fill</small>
                ) : null}
              </div>
              <img
                style={{
                  width: "100%",
                  height: "75%",
                  objectFit: "cover",
                  borderRadius: "0.4em",
                }}
                src={currentData.media_url}
              />
            </Col>
            <Col lg={8}>
              <Row>
                {/* <Col lg={4} sm={6}>
                                    <div className="checkbox abc-checkbox abc-checkbox-primary">
                                        <Input
                                            name="schedule_post"
                                            value="Monthly"
                                            checked={showPost}
                                            className="mt-0"
                                            id="checkbox1"
                                            type="checkbox"
                                            onChange={(e) => {
                                                setShowPost(e.target.checked)
                                            }}
                                        />{" "}
                                        <Label for="checkbox1" />
                                        Schedule Post
                                    </div>
                                </Col> */}
                <Col lg={4} sm={6}>
                  <div className="checkbox abc-checkbox abc-checkbox-primary">
                    <Input
                      name="bioshop"
                      value="bioshop"
                      checked={showBio}
                      className="mt-0"
                      id="checkbox2"
                      type="checkbox"
                      onChange={(e) => {
                        setShowBio(e.target.checked);
                      }}
                    />{" "}
                    <Label for="checkbox2" />
                    Add to Bioshop
                  </div>
                </Col>
              </Row>
              <div>
                {showPost ? renderSchedule() : null}
                {showBio ? renderBioShop() : null}
                <Row>
                  <Col>
                    {loading ? (
                      <button className="custom_btns_ift btn btn-primary mt-3">
                          <span className="spinclass loader-center position-relative">
          <Spin size="small" />
        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => onSubmit()}
                        className="custom_btns_ift btn btn-primary mt-3"
                      >
                        Schedule
                      </button>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
function mapStateProps({ gallery, categories }) {
  return { gallery, categories };
}
export default connect(mapStateProps, {
  ...instaAction,
  ...schedulePostActions,
  ...categoriesActions,
})(AllGallery);
