import {
  GET_STORY_INSIGHTS_REQUEST,
  GET_STORY_INSIGHTS_SUCCESS,
  GET_STORY_INSIGHTS_ERROR,
} from "../types/types";
import { BASEURL1 } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const getStoryInsights = (senderid, recipientid) => (dispatch) => {
  dispatch({ type: GET_STORY_INSIGHTS_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL1}webhook/Instagram`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { senderid, recipientid },
  })
    .then((response) => {
      return dispatch({
        type: GET_STORY_INSIGHTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_STORY_INSIGHTS_ERROR,
        payload: error.response,
      });
    });
};
