import React from "react";
import GallerySummaryComponent from "./gallerySummaryComponent";

class GalleryDashboard extends React.Component {
  state = {
    username: this.props.username,
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-flui">
        <div class="title-area"><h1>Dashboard</h1></div>
          <div className="brand_container_main container">
            <div className="row">
              <GallerySummaryComponent
                username={this.state.username}
                className="col-md-8"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default GalleryDashboard;
