import { ThemeProvider } from 'styled-components';
import {
  MeetingProvider,
  lightTheme,
  UserActivityProvider,
  useRemoteVideoTileState,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRosterState,
  useMeetingEvent,
  MeetingManager,
  VideoGrid,
  VideoTile,
  LocalVideo,
  ContentShare,
  useContentShareControls,
  Roster,
  useContentShareState,
  useMediaStreamMetrics,
  RosterGroup,
  RosterAttendee,
  useAudioVideo,
  AudioVideoProvider
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingManager,
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import {
  VideoTileGrid,
  BackgroundBlurProvider,
} from 'amazon-chime-sdk-component-library-react';
import Control from './Control';
import DeviceSetup from './deviceSetup/index';
import { NavigationProvider } from './NotificationProvider';
import Notifications from './Notification';
import { StyledLayout, StyledContent } from './MeetingStyled';
import { useEffect, useRef, useState } from 'react';
import { AudioOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import NoRemote from './NoRemote';
import { notification, Modal, Button } from 'antd';
import stream from '../../images/stream-icon.png';
import streamcross from '../../images/stream-icon-cross.png';
import ReactRouterPrompt from 'react-router-prompt';
import { ErrorBoundary } from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoStreamMerger } from 'video-stream-merger'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const MyApp = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
}) => {
  const history = useNavigate();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [leavemet, setLeaveMet] = useState(false);
  const { tiles, attendeeIdToTileId, tileIdToAttendeeId } =
    useRemoteVideoTileState();
  const [ratio, setRatio] = useState({ w: 16, h: 9 });

  const [currentTileId, setCurrentTileId] = useState('');
  const { isVideoEnabled } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { sharingAttendeeId } = useContentShareState();
  const metrics = useMediaStreamMetrics();
  const audioVideo = useAudioVideo();

  const canvasRef = useRef()
  const localVideoRef = useRef()

  const joinMeeting = async () => {
    // Fetch the meeting and attendee data from your server application
    // const response = await fetch('/my-attendees-endpoint');
    //   const user = await response.json();

    //   console.log(user,"user");

    // Initalize the `MeetingSessionConfiguration`
    // console.log("meeting",meetingData)

    // console.log("meeting",meetingData?.meeting_info?.Meeting)
    // console.log("meeting234324",meetingData?.meeting_info?.Attendees.filter(it=>it.ExternalUserId === userInfo?.user_id)[0])
    // console.log("meetingData23094709238409283094",userInfo?.user_id );

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meetingData[0]?.meeting_info?.Meeting,
      {
        Attendee: meetingData[0]?.meeting_info?.Attendees.filter(
          (it) => it.ExternalUserId === userInfo?._id
        )[0],
      }
      // {
      //   "MeetingId": "26cd743a-3091-44a2-b950-fc2ca6c62713",
      //   "MeetingHostId": null,
      //   "ExternalMeetingId": "talha123",
      //   "MediaRegion": "ap-south-1",
      //   "MediaPlacement": {
      //     "AudioHostUrl": "ac1c95950ffcbc0a216d9785df0b6f3c.k.m2.ao1.app.chime.aws:3478",
      //     "AudioFallbackUrl": "wss://haxrp.m2.ao1.app.chime.aws:443/calls/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "SignalingUrl": "wss://signal.m2.ao1.app.chime.aws/control/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "TurnControlUrl": "https://2713.cell.us-east-1.meetings.chime.aws/v2/turn_sessions",
      //     "ScreenDataUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/v2/screen/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "ScreenViewingUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "ScreenSharingUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/v2/screen/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "EventIngestionUrl": "https://data.svc.ue1.ingest.chime.aws/v1/client-events"
      //   },
      //   "PrimaryMeetingId": null,
      //   "TenantIds": [],
      //   "MeetingArn": "arn:aws:chime:us-east-1:210751258169:meeting/26cd743a-3091-44a2-b950-fc2ca6c62713"
      // },
      // {
      //   "Attendee":{
      //     "ExternalUserId": "e7014f93#talha",
      //     "AttendeeId": "d9927038-7af0-e34f-dedd-f56e4bbba4b2",
      //     "JoinToken": "ZDk5MjcwMzgtN2FmMC1lMzRmLWRlZGQtZjU2ZTRiYmJhNGIyOmJkM2ExM2FlLTIxZjUtNGE1Ni1iZmUyLThhYmZkY2YzYWU4Yg",
      //     "Capabilities": {
      //       "Audio": "SendReceive",
      //       "Video": "SendReceive",
      //       "Content": "SendReceive"
      //     }
      //   }
      // }
    );

    // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
    return meetingManager.join(meetingSessionConfiguration).then(() => { });

    // At this point you could let users setup their devices, or by default
    // the SDK will select the first device in the list for the kind indicated
    // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)
    // ...

    // Start the `MeetingSession` to join the meeting
  };

  useEffect(() => {
    // if (!host) {
    //   if (meetingData) {
    //     joinMeeting().then(async () => {
    //       await meetingManager.start()
    //     })
    //   }
    // } else {
    joinMeeting();
    // }
  }, [meetingData]);

  function ab() {
    audioVideo.getCurrentMeetingAudioStream().then(res => {
      console.log("m", res)
    })
    // console.log(tiles[0])
    // console.log(audioVideo.captureVideoTile(tiles[0]))
  }

  useEffect(() => {
    return () => {
      meetingManager.leave();
    };
  }, []);

  const leaveMeeting = () => {
    return meetingManager.leave();
  };

  useEffect(() => {
    if (meetingStatus == 3) {
      meetingManager.leave().then(() => {
        // notification.success({
        //   message: "Event has ended",
        //   className: "toast-success",
        // });
        history({
          pathname: `/events`,
        });
      });
    }
  }, [meetingStatus]);

  function getName(tId) {
    const at_id = tileIdToAttendeeId[tId];
    const ex_id = meetingData[0]?.meeting_info?.Attendees?.filter(
      (it) => it.AttendeeId == at_id
    )[0];
    return meetingData[0]?.hosts?.filter(
      (it) => it.user_id == ex_id.ExternalUserId
    )[0]?.user_name;
  }

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  if (meetingStatus == 0) {
    return (
      <UserActivityProvider>
        <DeviceSetup
          joinMeeting={async (cb) => {
            await meetingManager.start();
            // cb()
          }}
          checkEvent={checkEvent}
          meetingData={meetingData}
          ratio={ratio}
          setRatio={setRatio}
          meetingChecker={meetingData ? null : 'Waiting for producer to start event'}
          leaveMeeting={leaveMeeting}
          host={host}
          endMeeting={endMeeting}
        />
      </UserActivityProvider>
    );
  }


  // if (sharingAttendeeId) {
  //   return (
  //     <div>
  //       <VideoTileGrid className="content-share-grid" />
  //       <Control
  //         ratio={ratio}
  //         setRatio={setRatio}
  //         setLeaveMet={setLeaveMet}
  //         live={
  //           meetingData[0]?.event_status == 'live'
  //             ? meetingData[0]?.event_status
  //             : live
  //         }
  //         onGolive={onGolive}
  //         host={host}
  //         leaveMeeting={leaveMeeting}
  //         endMeeting={endMeeting}
  //         meetingData={meetingData}
  //       />

  //       {!endMeetingFlag &&
  //         meetingStatus != 3 &&
  //         !leavemet &&
  //         (meetingData[0]?.event_status == 'live'
  //           ? meetingData[0]?.event_status
  //           : live) == 'live' && (
  //           <ErrorBoundary FallbackComponent={ErrorFallback}>
  //             <ReactRouterPrompt when={meetingData}>
  //               {({ isActive, onConfirm, onCancel }) =>
  //                 isActive && (
  //                   <Modal
  //                     className="modal-generic modal-500"
  //                     centered
  //                     visible={isActive}
  //                     onOk={() => {
  //                       endMeeting();
  //                       onConfirm();
  //                     }}
  //                     onCancel={onCancel}
  //                     closeIcon={<FontAwesomeIcon icon={faXmark} />}
  //                     footer={[
  //                       <Button type="primary" onClick={onCancel}>
  //                         Cancel
  //                       </Button>,
  //                       <Button
  //                         key="submit"
  //                         type="primary"
  //                         // onClick={handleOk}
  //                         onClick={() => {
  //                           endMeeting ? endMeeting() : meetingManager.leave();
  //                           onConfirm();
  //                         }}
  //                       >
  //                         Ok
  //                       </Button>,
  //                     ]}
  //                   >
  //                     <h2 className="modal-hd1">Warning</h2>
  //                     <h5>
  //                       {host
  //                         ? 'Are you sure you want to leave event?'
  //                         : 'Are you sure you want to end event?'}
  //                     </h5>
  //                   </Modal>
  //                   // <div className="lightbox">
  //                   //   <div className="container">
  //                   //     <p>Do you really want to leave?</p>
  //                   //     <button onClick={onCancel}>Cancel</button>
  //                   //     <button onClick={onConfirm}>Ok</button>
  //                   //   </div>
  //                   // </div>
  //                 )
  //               }
  //             </ReactRouterPrompt>
  //           </ErrorBoundary>
  //         )}
  //       {(meetingData[0]?.event_status == 'live'
  //         ? meetingData[0]?.event_status
  //         : live) != 'live' &&
  //         meetingStatus != 3 && (
  //           <ErrorBoundary FallbackComponent={ErrorFallback}>
  //             <ReactRouterPrompt when={meetingData}>
  //               {({ isActive, onConfirm, onCancel }) =>
  //                 isActive && (
  //                   <Modal
  //                     className="modal-generic modal-500"
  //                     centered
  //                     visible={isActive}
  //                     onOk={() => {
  //                       endMeeting();
  //                       onConfirm();
  //                     }}
  //                     onCancel={onCancel}
  //                     closeIcon={<FontAwesomeIcon icon={faXmark} />}
  //                     footer={[
  //                       <Button type="primary" onClick={onCancel}>
  //                         Cancel
  //                       </Button>,
  //                       <Button
  //                         key="submit"
  //                         type="primary"
  //                         // onClick={handleOk}
  //                         onClick={() => {
  //                           endMeeting ? endMeeting() : meetingManager.leave();
  //                           // endMeeting()
  //                           onConfirm();
  //                         }}
  //                       >
  //                         Ok
  //                       </Button>,
  //                     ]}
  //                   >
  //                     <h2 className="modal-hd1">Warning</h2>
  //                     <h5>
  //                       {!host
  //                         ? 'Do you want to exit the event'
  //                         : 'Do you want to leave this event'}
  //                     </h5>
  //                   </Modal>
  //                   // <div className="lightbox">
  //                   //   <div className="container">
  //                   //     <p>Do you really want to leave?</p>
  //                   //     <button onClick={onCancel}>Cancel</button>
  //                   //     <button onClick={onConfirm}>Ok</button>
  //                   //   </div>
  //                   // </div>
  //                 )
  //               }
  //             </ReactRouterPrompt>
  //           </ErrorBoundary>
  //         )}
  //       {!host && (
  //         <div className="host-box">
  //           {attendees.map((itP, i) => {
  //             return (
  //               <div key={i} className="host-info">
  //                 <div className="host-name">
  //                   {
  //                     meetingData[0]?.hosts?.find(
  //                       (it) => it?.user_id == itP?.externalUserId
  //                     )?.user_name
  //                   }
  //                 </div>
  //                 <div className="host-control">
  //                   <button className="host-button">
  //                     <AudioOutlined />
  //                   </button>
  //                   <button className="host-button">
  //                     <VideoCameraOutlined />
  //                   </button>
  //                   <button className="host-button go-live-btn">
  //                     <img src={stream} alt="stream" className="img-fluid" />
  //                   </button>
  //                 </div>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       )}
  //     </div>
  //   );
  // }

  async function sendStream() {
    // var merger = new VideoStreamMerger({
    //   width: 800,   // Width of the output video
    //   height: 450,  // Height of the output video
    //   fps: 30
    // })
    // var mediaStream = document.getElementsByClassName("ch-video") // 30 FPS

    // var streams = Array.prototype.map.call(mediaStream, function (it) {
    //   const st = new MediaRecorder(it.captureStream(30), {
    //     mimeType: 'video/webm;codecs=h264',
    //     videoBitsPerSecond: 3000000
    //   });
    //   return st
    // })


    // const rtmp = encodeURIComponent('rtmps://39804a10586c.global-contribute.live-video.net:443/app/sk_us-west-2_8K6gilNyrMsH_uvr7sIUmvYCTl9cWW6tvLC4ewFYveC')
    // const ws = new WebSocket(`ws://localhost:7000/rtmp/${rtmp}`)

    // ws.addEventListener('open', (e) => {
    //   console.log('WebSocket Open', e);

    //     streams.forEach(((it, i) => {
    //       it.start(1000);
    //       it.addEventListener('dataavailable', (e) => {
    //         merger.start()
    //         merger.addStream(new Blob([e.data]).stream(), {
    //           x: 0,
    //           y: 800,
    //           width: 450,
    //           height: 225,
    //         })
    //       });
    //     }))
    //     ws.send(merger.result);


    // });

    // ws.addEventListener('close', (e) => {
    //   console.log('WebSocket Close', e);
    // });







    var mediaStream = document.getElementById("myVideo").firstChild.captureStream(30); // 30 FPS
    var mediaStream2 = document.getElementById("myVideo1").firstChild.captureStream(30); // 30 FPS

    var merger = new VideoStreamMerger({
      width: 1600,   // Width of the output video
      height: 900,  // Height of the output video
      fps: 30
    })

    merger.addStream(mediaStream, {
      x: 0, // position of the topleft corner
      y: 0,
      width: 800,
      height: 900
    })

    merger.addStream(mediaStream2, {
      x: 800, // position of the topleft corner
      y: 0,
      width: 800,
      height: 900
    })

    merger.start()

    console.log("res", merger.result)


    const audioStream = await audioVideo.getCurrentMeetingAudioStream()
    // const ss = new MediaStream([merger.result.getTracks()[0], audioStream.getTracks()[0]])


    // console.log("mediaStream",mediaStream.getTracks()[0])
    // console.log("mediaStream2",audioStream.getTracks()[0])



    var mediaRecorder = new MediaRecorder(merger.result, {
      mimeType: 'video/webm;codecs=h264',
      videoBitsPerSecond: 3000000
    });


    const rtmp = encodeURIComponent('rtmps://862720a0b228.global-contribute.live-video.net:443/app/sk_us-east-1_r4JvhKOuYStA_crUdaAPSqhX5ToeJ5K2npmUgWHWuoo')
    const ws = new WebSocket(`ws://localhost:7000/rtmp/${rtmp}`)

    ws.addEventListener('open', (e) => {
      console.log('WebSocket Open', e);

      mediaRecorder.addEventListener('dataavailable', (e) => {
        console.log("hellow", e.data)
        ws.send(e.data);
      });

      mediaRecorder.addEventListener('stop', ws.close.bind(ws));

      mediaRecorder.start(1000); // Start recording, and dump data every second

    });

    ws.addEventListener('close', (e) => {
      console.log('WebSocket Close', e);
      mediaRecorder.stop();
    });
  }

  return (
    <>
      <UserActivityProvider>
        <div className={`host-box-wrapper ${host && 'no-gutters'}`}>
          <div
            className={`video-box localvideosingle ${ratio.w === 8 ? 'ratio-4' : 'ratio-16'
              } ${tiles?.length > 0 && isVideoEnabled ? 'ratio-4-multiview' : ''
              } `}
          >
            {(meetingData[0]?.event_status == 'live'
              ? meetingData[0]?.event_status
              : live) == 'live' && (
                <div style={{ position: 'absolute', zIndex: 10 }}>
                  <div
                    style={{ backgroundColor: 'red', color: 'white', padding: 5 }}
                  >
                    Live
                  </div>
                </div>
              )}
            <div
              style={!host ? { height: '100%', boxSizing: 'border-box' } : {}}
            >
              {!isVideoEnabled && tiles?.length < 1 ? (
                <div className="no-video-style" style={{}}>
                  <p style={{ color: 'white' }}>{'No Video Found'}</p>
                </div>
              ) : (
                <>
                  {isVideoEnabled ? (
                    <div className="single-grid row no-gutters">
                      {/* <ContentShare
                                        style={{
                                          border: '1px solid white',
                                          // minHeight:300,
                                          backgroundColor: 'black',
                                          // gridArea: 'ft'
                                        }}
                                      /> */}
                      {isVideoEnabled && (
                        <LocalVideo
                          id='myVideo'
                          onClick={() => {
                            if (currentTileId == 'me') {
                              setCurrentTileId('');
                            } else {
                              setCurrentTileId('me');
                            }
                          }}
                          style={
                            {
                              // border: '1px solid white',
                              // gridArea: currentTileId=="me"?'ft':""
                            }
                          }
                          nameplate="Me"
                          className={`col-${tiles.length == 0 ? '12' : '6'
                            } videoMirror`}
                        />
                      )}
                      {tiles.map((tileId) => (
                        <>
                          <RemoteVideo
                            onClick={() => {
                              if (tileId == currentTileId) {
                                setCurrentTileId('');
                              } else {
                                setCurrentTileId(tileId);
                              }
                            }}
                            style={{
                              // border: '1px solid white',
                              // minHeight:300,
                              backgroundColor: 'black',
                              // gridArea: tileId == currentTileId ? 'ft' : ''
                            }}
                            id={"myVideo1"}
                            name={getName(tileId)}
                            tileId={tileId}
                            className="col-6 videoMirror"
                          />
                        </>
                      ))}
                    </div>
                  ) : (
                    <div className="single-grid row no-gutters">
                      {tiles.map((tileId) => (
                        <>
                          <RemoteVideo
                            id={`remoteVideo${tileId}`}
                            onClick={() => {
                              if (tileId == currentTileId) {
                                setCurrentTileId('');
                              } else {
                                setCurrentTileId(tileId);
                              }
                            }}
                            style={{
                              // border: '1px solid white',
                              // minHeight:300,
                              backgroundColor: 'black',
                              // gridArea: tileId == currentTileId ? 'ft' : ''
                            }}
                            className={`col-${tiles.length == 1 ? '12' : '6'
                              } videoMirror`}
                            name={getName(tileId)}
                            tileId={tileId}
                          />
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>

          </div>
        </div>
        <Control
          ratio={ratio}
          setRatio={setRatio}
          setLeaveMet={setLeaveMet}
          live={
            meetingData[0]?.event_status == 'live'
              ? meetingData[0]?.event_status
              : live
          }
          onGolive={onGolive}
          host={host}
          leaveMeeting={leaveMeeting}
          endMeeting={endMeeting}
          meetingData={meetingData}
        />

        {!endMeetingFlag &&
          meetingStatus != 3 &&
          !leavemet &&
          (meetingData[0]?.event_status == 'live'
            ? meetingData[0]?.event_status
            : live) == 'live' && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {host
                          ? 'Are you sure you want to leave event?'
                          : 'Are you sure you want to end event?'}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
        {(meetingData[0]?.event_status == 'live'
          ? meetingData[0]?.event_status
          : live) != 'live' &&
          meetingStatus != 3 && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            // endMeeting()
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {!host
                          ? 'Do you want to exit the event'
                          : 'Do you want to leave this event'}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
      </UserActivityProvider>

      {!host && (
        <div className="host-box">
          {attendees.map((itP, i) => {
            return (
              <div key={i} className="host-info">
                <div className="host-name">
                  {
                    meetingData[0]?.hosts?.find(
                      (it) => it?.user_id == itP?.externalUserId
                    )?.user_name
                  }
                </div>
                <div className="host-control">
                  <button className="host-button">
                    <AudioOutlined />
                  </button>
                  <button className="host-button">
                    <VideoCameraOutlined />
                  </button>
                  <button className="host-button go-live-btn">
                    <img src={stream} alt="stream" className="img-fluid" />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};




const Root = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <NotificationProvider>
        <Notifications />
        <BackgroundBlurProvider>
          <MeetingProvider>
            <NavigationProvider>
              <AudioVideoProvider>
                <MyApp
                  checkEvent={checkEvent}
                  meetingData={meetingData}
                  endMeetingFlag={endMeetingFlag}
                  onGolive={onGolive}
                  host={host}
                  endMeeting={endMeeting}
                  live={live}
                />
              </AudioVideoProvider>
            </NavigationProvider>
          </MeetingProvider>
        </BackgroundBlurProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default Root;