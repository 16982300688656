import { CLEAR_NEW_BIO_POSTS, GET_NEW_BIO_POST } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

var userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getNewBioPost =
  (page, id, clr, limit = 15) =>
  async (dispatch) => {
    try {
      if (id === "videos") {
        const res = await axios.get(
          `${BASEURL}profile/posts/${userInfo.pid}`,
          {
            params: {
              limit: limit,
              page,
              post_type: "video",
            },
            headers: { Authorization: `Bearer ${userInfo?.token}` },
          }
        );
        if (clr) clr();
        dispatch({
          type: GET_NEW_BIO_POST,
          payload: res.data?.message?.result,
        });
      } else {
        if (id) {
          const res = await axios.get(
            `${BASEURL}profile/filter/${userInfo.pid}`,
            {
              params: {
                limit: limit,
                page,
                post_type: "image,campaign,video",
                id,
              },
            }
          );
          if (clr) clr();
          dispatch({
            type: GET_NEW_BIO_POST,
            payload: res.data?.message?.result,
          });
        } else {
          const res = await axios.get(
            `${BASEURL}profile/posts/${userInfo.pid}`,
            {
              params: {
                limit: limit,
                page,
                post_type: "image,campaign,video",
              },
              headers: { Authorization: `Bearer ${userInfo?.token}` },
            }
          );
          if (clr) clr();

          dispatch({
            type: GET_NEW_BIO_POST,
            payload: res.data?.message?.result,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

export const clearNewBioPost = () => async (dispatch) => {
  dispatch({
    type: CLEAR_NEW_BIO_POSTS,
    payload: {
      data: [],
      next: {},
    },
  });
};
