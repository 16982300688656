// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {
  Button,
} from "antd";
import {
  PrimaryButton,
  Flex,
  Label,
  useMeetingManager,
  Modal,
  ModalBody,
  ModalButton,
  ModalHeader,ModalButtonGroup
} from 'amazon-chime-sdk-component-library-react';

// import routes from '../constants/routes';
import Card from './Card';
import { useAppState } from './AppStateProvider';
import stream from "../../../images/stream-icon.png";
import { StyledP } from '../EndMeeting/Styled';

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const MeetingJoinDetails = ({ joinMeeting, meetingChecker, checkEvent, meetingData, host, endMeeting }) => {
  const meetingManager = useMeetingManager();
  //   const history = useHistory();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showModal2, setShowModal2] = useState(false);
  const { meetingId, localUserName } = useAppState();
  const toggleModal2 = () => setShowModal2(!showModal2);


  useEffect(() => {
    let intervalId

    if (meetingData && intervalId) {
      clearInterval(intervalId)
    } else {
      if (checkEvent && meetingChecker) {
        intervalId = setInterval(() => {
          checkEvent()
        }, 5000);

        return () => {
          console.log("clear")
          clearInterval(intervalId)
        };
      }
    }
  }, [meetingData])

  const handleJoinMeeting = async () => {
    setIsLoading(true);

    try {
      // joinMeeting(()=>setIsLoading(false))
      joinMeeting()
      //   history.push(`${routes.MEETING}/${meetingId}`);
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  return (
    <>
      <Flex container alignItems="center" flexDirection="column" className='mt-3'>
        <div>
          <PrimaryButton
            className='default-btn start-meeting-btn'
            label={isLoading ? 'Loading...' : host ? 'Start Hosting' : "Start Event"}
            disabled={meetingChecker}
            onClick={handleJoinMeeting}
          />
          {!host && 
            <PrimaryButton
            className='default-btn start-meeting-btn btn-exit'
            label="Exit"
            onClick={toggleModal2}
          />
          
          }
          {meetingChecker ? (
            <p>{meetingChecker}</p>
          ) : null}
        </div>
        <Label style={{ margin: '.75rem 0 0 0' }}>
          {/* Joining meeting <b>{meetingId}</b> as <b>{localUserName}</b> */}
          Joining event <b>{meetingId}</b> as <b>{userInfo?.name}</b>
        </Label>
      </Flex>
      {error && (
        <Modal size="md" onClose={() => setError('')}>
          <ModalHeader title={`Meeting ID: ${meetingId}`} />
          <ModalBody>
            <Card
              title="Unable to join meeting"
              description="There was an issue in joining this meeting. Check your connectivity and try again."
              smallText={error}
            />
          </ModalBody>
        </Modal>
      )}
      {showModal2 && (
              <Modal size="md" onClose={toggleModal2} rootId="modal-root">
                <ModalHeader title={"Exit Event"} />
                <ModalBody>
                  <StyledP>
                    {/* {host ? "Are you sure you want to leave event?" : ("End event for all. The event cannot be used once it ends.")} */}
                    Do you want to exit the event?
                  </StyledP>
                </ModalBody>
                <ModalButtonGroup
                  primaryButtons={[
                    <ModalButton
                      onClick={() => {
                        endMeeting()
                        .then(()=>{
                          history({
                            pathname: `/events`,
                          })
                        })
                        // leaveMeeting().then(()=>{
                        //   history({
                        //     pathname: `/events`,
                        //   });
                        // })
                      }}
                      variant="primary"
                      className='default-btn  medium-btn padb-3 fw-normal'
                      label={"Exit event"}
                      closesModal
                    />,
                    <ModalButton variant="secondary" label="Cancel" closesModal
                    className='default-btn medium-btn padb-3 outline  fw-normal'
                     />
                  ]}
                />
              </Modal>
            )}
    </>
  );
};

export default MeetingJoinDetails;
