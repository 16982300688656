export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_ERROR = "POST_LOGIN_ERROR";

export const POST_LOGIN_OPEN_REQUEST = "POST_LOGIN_OPEN_REQUEST";
export const POST_LOGIN_OPEN_SUCCESS = "POST_LOGIN_OPEN_SUCCESS";
export const POST_LOGIN_OPEN_ERROR = "POST_LOGIN_OPEN_ERROR";

export const GET_BROADCAST_CLIENT_REQUEST = "GET_BROADCAST_CLIENT_REQUEST";
export const GET_BROADCAST_CLIENT_SUCCESS = "GET_BROADCAST_CLIENT_SUCCESS";
export const GET_BROADCAST_CLIENT_ERROR = "GET_BROADCAST_CLIENT_ERROR";

export const GET_PARENT_CATEGORIES_REQUEST = "GET_PARENT_CATEGORIES_REQUEST";
export const GET_PARENT_CATEGORIES_SUCCESS = "GET_PARENT_CATEGORIES_SUCCESS";
export const GET_PARENT_CATEGORIES_ERROR = "GET_PARENT_CATEGORIES_ERROR";

export const GET_SUB_CATEGORIES_REQUEST = "GET_SUB_CATEGORIES_REQUEST";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_ERROR = "GET_SUB_CATEGORIES_ERROR";

export const POST_EVENT_REQUEST = "POST_EVENT_REQUEST";
export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";
export const POST_EVENT_ERROR = "POST_EVENT_ERROR";

export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const GET_EVENT_ID_REQUEST = "GET_EVENT_ID_REQUEST";
export const GET_EVENT_ID_SUCCESS = "GET_EVENT_ID_SUCCESS";
export const GET_EVENT_ID_ERROR = "GET_EVENT_ID_ERROR";

export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

export const POST_GOLIVE_REQUEST = "POST_GOLIVE_REQUEST";
export const POST_GOLIVE_SUCCESS = "POST_GOLIVE_SUCCESS";
export const POST_GOLIVE_ERROR = "POST_GOLIVE_ERROR";

export const POST_STOPLIVE_REQUEST = "POST_STOPLIVE_REQUEST";
export const POST_STOPLIVE_SUCCESS = "POST_STOPLIVE_SUCCESS";
export const POST_STOPLIVE_ERROR = "POST_STOPLIVE_ERROR";

export const SEND_METADATA_REQUEST = "SEND_METADATA_REQUEST";
export const SEND_METADATA_SUCCESS = "SEND_METADATA_SUCCESS";
export const SEND_METADATA_ERROR = "SEND_METADATA_ERROR";

export const GET_METADATA_REQUEST = "GET_METADATA_REQUEST";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_ERROR = "GET_METADATA_ERROR";

export const UPCOMING_EVENT_REQUEST = "UPCOMING_EVENT_REQUEST";
export const UPCOMING_EVENT_SUCCESS = "UPCOMING_EVENT_SUCCESS";
export const UPCOMING_EVENT_ERROR = "UPCOMING_EVENT_ERROR";

export const SAVE_CONTROL_REQUEST = "SAVE_CONTROL_REQUEST";
export const SAVE_CONTROL_SUCCESS = "SAVE_CONTROL_SUCCESS";
export const SAVE_CONTROL_ERROR = "SAVE_CONTROL_ERROR";

export const GET_CONTROL_REQUEST = "GET_CONTROL_REQUEST";
export const GET_CONTROL_SUCCESS = "GET_CONTROL_SUCCESS";
export const GET_CONTROL_ERROR = "GET_CONTROL_ERROR";

export const GET_VIDEO_REQUEST = "GET_VIDEO_REQUEST";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";

export const SAVE_ROLE_REQUEST = "SAVE_ROLE_REQUEST";
export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
export const SAVE_ROLE_ERROR = "SAVE_ROLE_ERROR";

export const START_MEETING_REQUEST = "START_MEETING_REQUEST";
export const START_MEETING_SUCCESS = "START_MEETING_SUCCESS";
export const START_MEETING_ERROR = "START_MEETING_ERROR";

export const END_MEETING_REQUEST = "END_MEETING_REQUEST";
export const END_MEETING_SUCCESS = "END_MEETING_SUCCESS";
export const END_MEETING_ERROR = "END_MEETING_ERROR";

export const GET_SHOPIFY_DETAIL_REQUEST = "GET_SHOPIFY_DETAIL_REQUEST";
export const GET_SHOPIFY_DETAIL_SUCCESS = "GET_SHOPIFY_DETAIL_SUCCESS";
export const GET_SHOPIFY_DETAIL_ERROR = "GET_SHOPIFY_DETAIL_ERROR";

export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const POST_SHOPIFY_REQUEST = "POST_SHOPIFY_REQUEST";
export const POST_SHOPIFY_SUCCESS = "POST_SHOPIFY_SUCCESS";
export const POST_SHOPIFY_ERROR = "POST_SHOPIFY_ERROR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const GET_URL_REQUEST = "GET_URL_REQUEST";
export const GET_URL_SUCCESS = "GET_URL_SUCCESS";
export const GET_URL_ERROR = "GET_URL_ERROR";

export const POST_COUNTRY_REQUEST = "POST_COUNTRY_REQUEST";
export const POST_COUNTRY_SUCCESS = "POST_COUNTRY_SUCCESS";
export const POST_COUNTRY_ERROR = "POST_COUNTRY_ERROR";

export const POST_STATE_REQUEST = "POST_STATE_REQUEST";
export const POST_STATE_SUCCESS = "POST_STATE_SUCCESS";
export const POST_STATE_ERROR = "POST_STATE_ERROR";

export const POST_CITY_REQUEST = "POST_CITY_REQUEST";
export const POST_CITY_SUCCESS = "POST_CITY_SUCCESS";
export const POST_CITY_ERROR = "POST_CITY_ERROR";

export const POST_SELECTED_COUNTRY_REQUEST = "POST_SELECTED_COUNTRY_REQUEST";
export const POST_SELECTED_COUNTRY_SUCCESS = "POST_SELECTED_COUNTRY_SUCCESS";
export const POST_SELECTED_COUNTRY_ERROR = "POST_SELECTED_COUNTRY_ERROR";

export const POST_BRAND_REGISTER_REQUEST = "POST_BRAND_REGISTER_REQUEST";
export const POST_BRAND_REGISTER_SUCCESS = "POST_BRAND_REGISTER_SUCCESS,";
export const POST_BRAND_REGISTER_ERROR = "POST_BRAND_REGISTER_ERROR";

export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_ERROR = "GET_REPORT_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_CHAT_TOKEN = "GET_CHAT_TOKEN";

export const PUBLISH_EVENT_REQUEST = "PUBLISH_EVENT_REQUEST";
export const PUBLISH_EVENT_SUCCESS = "PUBLISH_EVENT_SUCCESS";
export const PUBLISH_EVENT_ERROR = "PUBLISH_EVENT_ERROR";

export const GET_TAGS = "GET_TAGS";
export const GET_TAGS_PAGINATION = "GET_TAGS_PAGINATION";

export const GET_HASHTAGS = "GET_HASHTAGS";
export const GET_HASHTAG = "GET_HASHTAG";
export const HASH_PAGINATION = "HASH_PAGINATION";

export const CLEAR_POST = "CLEAR_POST";
export const GET_POST = "GET_POST";
export const UPDATE_POST = "UPDATE_POST";

export const CLEAR_NEW_BIO_POSTS = "CLEAR_NEW_BIO_POSTS";
export const GET_NEW_BIO_POST = "GET_NEW_BIO_POST";

export const GET_LINK = "GET_LINK";

export const GET_MOBILE_DROPDOWN = "GET_MOBILE_DROPDOWN";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_USER_CATEGORIES = "GET_USER_CATEGORIES";
export const GET_USER_CATEGORIES2 = "GET_USER_CATEGORIES2";

export const GET_USER_INFO = "GET_USER_INFO";

export const GET_PROMO_REQUEST = "GET_PROMO_REQUEST";

export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const FILTER_PROFILE_MEDIA = "FILTER_PROFILE_MEDIA";
export const SEARCH_PROFILE = "SEARCH_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const DELETE_PROFILES = "DELETE_PROFILES";

export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR";

export const POST_SAVE_CATEGORIES_REQUEST = "POST_SAVE_CATEGORIES_REQUEST";
export const POST_SAVE_CATEGORIES_SUCCESS = "POST_SAVE_CATEGORIES_SUCCESS";
export const POST_SAVE_CATEGORIES_ERROR = "POST_SAVE_CATEGORIES_ERROR";

export const VERIFY_CATEGORIES_REQUEST = "VERIFY_CATEGORIES_REQUEST";
export const VERIFY_CATEGORIES_SUCCESS = "VERIFY_CATEGORIES_SUCCESS";
export const VERIFY_CATEGORIES_ERROR = "VERIFY_CATEGORIES_ERROR";

export const GET_INSTAGRAM_URL_REQUEST = "GET_INSTAGRAM_URL_REQUEST";
export const GET_INSTAGRAM_URL_SUCCESS = "GET_INSTAGRAM_URL_SUCCESS";
export const GET_INSTAGRAM_URL_ERROR = "GET_INSTAGRAM_URL_ERROR";

export const GET_INSTAGRAM_DATA_REQUEST = "GET_INSTAGRAM_DATA_REQUEST";
export const GET_INSTAGRAM_DATA_SUCCESS = "GET_INSTAGRAM_DATA_SUCCESS";
export const GET_INSTAGRAM_DATA_ERROR = "GET_INSTAGRAM_DATA_ERROR";

export const UPDATE_ACCESS_TOKEN_REQUEST = "UPDATE_ACCESS_TOKEN_REQUEST";
export const UPDATE_ACCESS_TOKEN_SUCCESS = "UPDATE_ACCESS_TOKEN_SUCCESS";
export const UPDATE_ACCESS_TOKEN_ERROR = "UPDATE_ACCESS_TOKEN_ERROR";

export const DISCONNECT_INSTAGRAM_REQUEST = "DISCONNECT_INSTAGRAM_REQUEST";
export const DISCONNECT_INSTAGRAM_SUCCESS = "DISCONNECT_INSTAGRAM_SUCCESS";
export const DISCONNECT_INSTAGRAM_ERROR = "DISCONNECT_INSTAGRAM_ERROR";

export const GET_CHAT_REQUEST = "GET_CHAT_REQUEST";
export const GET_CHAT_SUCCESS = "GET_CHAT_SUCCESS";
export const GET_CHAT_ERROR = "GET_CHAT_ERROR";

export const SAVE_CHAT_REQUEST = "SAVE_CHAT_REQUEST";
export const SAVE_CHAT_SUCCESS = "SAVE_CHAT_SUCCESS";
export const SAVE_CHAT_ERROR = "SAVE_CHAT_ERROR";

export const GET_SOCIAL_STORE_REQUEST = "GET_SOCIAL_STORE_REQUEST";
export const GET_SOCIAL_STORE_SUCCESS = "GET_SOCIAL_STORE_SUCCESS";
export const GET_SOCIAL_STORE_ERROR = "GET_SOCIAL_STORE_ERROR";

export const GET_INSTA_POST = "GET_INSTA_POST";
export const GET_SCHEDULE_POST = "GET_SCHEDULE_POST";

export const GET_MEDIA_SUMMARY = "GET_MEDIA_SUMMARY";

export const GET_STREAM_SETTING_REQUEST = "GET_STREAM_SETTING_REQUEST";
export const GET_STREAM_SETTING_SUCCESS = "GET_STREAM_SETTING_SUCCESS";
export const GET_STREAM_SETTING_ERROR = "GET_STREAM_SETTING_ERROR";

export const POST_STREAM_SETTING_REQUEST = "POST_STREAM_SETTING_REQUEST";
export const POST_STREAM_SETTING_SUCCESS = "POST_STREAM_SETTING_SUCCESS";
export const POST_STREAM_SETTING_ERROR = "POST_STREAM_SETTING_ERROR";

export const HOST_EVENT_REQUEST = "HOST_EVENT_REQUEST";
export const HOST_EVENT_SUCCESS = "HOST_EVENT_SUCCESS";
export const HOST_EVENT_ERROR = "HOST_EVENT_ERROR";

export const ACCOUNT_DELETE_REQUEST = "ACCOUNT_DELETE_REQUEST";
export const ACCOUNT_DELETE_SUCCESS = "ACCOUNT_DELETE_SUCCESS";
export const ACCOUNT_DELETE_ERROR = "ACCOUNT_DELETE_ERROR";

export const GET_STORY_INSIGHTS_REQUEST = "GET_STORY_INSIGHTS_REQUEST";
export const GET_STORY_INSIGHTS_SUCCESS = "GET_STORY_INSIGHTS_SUCCESS";
export const GET_STORY_INSIGHTS_ERROR = "GET_STORY_INSIGHTS_ERROR";
