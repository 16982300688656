import { GET_PROMO_REQUEST } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getPromoRequest = () => async (dispatch) => {
    let promise = new Promise((resolve, reject) => {
      axios
        .get(`${BASEURL}campaigns/receive/getpromocodes`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
        .then((res) => {
          dispatch({
            type: GET_PROMO_REQUEST,
            payload: res.data,
          });
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        });
    });
    return promise;
  };