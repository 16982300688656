import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getStreamSetting,
  saveStreamSetting,
} from '../../../redux/actions/stream.action';
import { Select, Slider, Col, Row, notification, Spin } from 'antd';
import "../../../css/stream.scss";

const { Option } = Select;

function StreamSetup() {
  const dispatch = useDispatch();
  const [brightness, setBrightness] = useState(0);
  const [volume, setVolume] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [aspectRatio, setAspectRatio] = useState();
  const [resolution, setResolution] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    dispatch(getStreamSetting()).then((res) => {
      if (res.payload.success) {
        setLoading(false)
        if(res.payload?.message) {
          setSaturation(res.payload?.message?.saturation)
          setBrightness(res.payload?.message?.brightness)
          setVolume(res.payload?.message?.volume)
          setContrast(res.payload?.message?.contrast)
          setResolution(res.payload?.message?.resolution)
          setAspectRatio(res.payload?.message?.aspectRatio)
        }
      } else {
        setLoading(false)
      }
    });
  }, []);

  const handleAspectRatio = (value) => {
    setAspectRatio(value);
  };

  const handleResolution = (value) => {
    setResolution(value);
  };

  const changeBrightness = (value) => {
    setBrightness(value);
  };
  const changeVolume = (value) => {
    setVolume(value);
  };
  const changeContrast = (value) => {
    setContrast(value);
  };
  const changeSaturation = (value) => {
    setSaturation(value);
  };

  const onSaveSetting = () => {
    const data = {
      aspectRatio,
      resolution,
      saturation,
      brightness,
      contrast,
      volume
    }
    dispatch(saveStreamSetting(data)).then((res) => {
      if (res.payload.success) {
        notification.success({
          message: res.payload?.message,
          className: 'toast-success',
        });
      } else {
        notification.error({
          message: res.payload.data.message,
          className: 'toast-error',
        });
      }
    });

  };

  return (
    <>
      <div className="title-area">
        <h1 className="page-title">IVS Output Video Setting</h1>
      </div>
      <div className='container-fluid m-0 p-0'>
        <div className='row no-gutters'>
          <div className='col-12 col-xl-6'>
            <div className={`stream-box-main ${loading ? "loadingstream": ""}`}>
              {!loading ? <>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Output Aspect
                    </div>

                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Select
                      className="select-style dark-field w-100"
                      placeholder="Select Aspect Ratio"
                      size="large"
                      focus="false"
                      onChange={handleAspectRatio}
                      onBlur={false}
                      value={aspectRatio}
                    >
                      <Option value="16:9">16:9</Option>
                      <Option value="9:16">9:16</Option>


                    </Select>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Resolution
                    </div>

                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Select
                      className="select-style dark-field w-100"
                      placeholder="Select Resolution"
                      size="large"
                      focus="false"
                      onChange={handleResolution}
                      onBlur={false}
                      value={resolution}
                    >
                      <Option value="1080">1080p</Option>
                      <Option value="576">576p</Option>
                      <Option value="480">480p</Option>
                      <Option value="320">320p</Option>
                      <Option value="240">240p</Option>


                    </Select>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Brightness
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Row className='d-flex align-items-center'>
                      <Col span={22}>
                        <Slider
                          min={-100}
                          max={100}
                          defaultValue={brightness}
                          onChange={changeBrightness}
                        />
                      </Col>
                      <Col span={2} className="text-right">
                        {brightness}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Contrast
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Row className='d-flex align-items-center'>
                      <Col span={22}>
                        <Slider
                          min={-100}
                          max={100}
                          defaultValue={contrast}
                          onChange={changeContrast}
                        />
                      </Col>
                      <Col span={2} className="text-right">
                        {contrast}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Saturation
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Row className='d-flex align-items-center'>
                      <Col span={22}>
                        <Slider
                          min={0}
                          max={300}
                          defaultValue={saturation}
                          onChange={changeSaturation}
                        />
                      </Col>
                      <Col span={2} className="text-right">
                        {saturation}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="row d-flex align-items-center mb-20">
                  <div className="col-lg-4 col-xl-3 mb-2 mb-lg-0">
                    <div className="">
                      Volume
                    </div>
                  </div>
                  <div
                    className="col-lg-8 col-xl-9 flex-stretch form-styling"
                  >
                    <Row className='d-flex align-items-center'>
                      <Col span={22}>
                        <Slider
                          defaultValue={volume}
                          onChange={changeVolume}
                        />
                      </Col>
                      <Col span={2} className="text-right">
                        {volume}
                      </Col>
                    </Row>
                  </div>
                </div>
                <button type="button" className="default-btn ml-0" onClick={() => onSaveSetting()}><span>Save</span></button>
              </> :
                <>
                  <span className="spinclass loader-center" >
                    <Spin size="large" />
                  </span>
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
  
}
export default StreamSetup;
