import React from "react";
import { useNavigate } from 'react-router-dom';
import Posts from "./Posts";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

function AllPost() {
  const history = useNavigate();

  if (!userInfo?.access_token) {
    return (
      <button
        className="default-btn"
        onClick={() => history({
          pathname: `/account/social`,
        })}
      >
        Connect Instagram
      </button>)
  }
  return (
    <Posts />
  )
}
export default AllPost; 