import { Spin } from 'antd'
import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { BASEURL } from '../../config'

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export default function Posts() {
    const [posts, setPost] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.post(`${BASEURL}social/ig/media/${userInfo?.access_token}`,
            {
                username: userInfo?.username
            },
            {
                headers: {
                    Authorization: `Bearer ${userInfo?.token}`,
                }
            })
            .then((res) => {
                setPost(res.data?.data)
                setLoading(false)
            })
    }, [])
    if (loading) {
        return (
            <span className="spinclass loader-center position-relative">
                <Spin size="large" />
            </span>
        )
    }
    return (
        <>
            <div className='post-info'>

                <h6>
                    <a target="_blank" href={`https://www.instagram.com/${userInfo?.username}`}>
                        {userInfo?.username}
                    </a>
                </h6>
            </div>
            <div className="row allpost-box">
                {!posts.length ? <div className='text-center w-100'>No Posts</div> : posts.map((item, i) => {
                    return (

                        <div className="col-md-6 col-lg-4 col-xl-3 mb-20 allpost-inner" key={i}>
                            <div className='post-listing-main-box'>
                                <div className='post-listing-image'>
                                    {item?.media_type === "VIDEO" ? (
                                        <>

                                            {/* <button className="btn-link btn-play">
                                    <i class="fa fa-play" aria-hidden="true"></i>
                                </button> */}
                                            <video
                                                // id={`post-video-${item?.post_id}`}
                                                // id={item.post_id}
                                                //autoPlay
                                                controls={false}
                                                controlsList="nodownload"
                                            >
                                                <source
                                                    src={item?.media_url + "#t=0.001"}
                                                    type="video/mp4"
                                                ></source>
                                            </video>

                                        </>
                                    ) : (

                                        <img
                                            src={item?.media_url}
                                            alt="post-img"
                                            className="post-image"
                                        />
                                    )}
                                </div>
                                <p className='post-para'>{item?.caption}</p>

                            </div>
                        </div>

                    )
                })}
            </div >
        </>
    )
}
