import { ACCOUNT_DELETE_REQUEST, ACCOUNT_DELETE_SUCCESS, ACCOUNT_DELETE_ERROR } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

var userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const accountDelete = (id) => (dispatch) => {
    dispatch({ type: ACCOUNT_DELETE_REQUEST });
    return axios({
        method: "PUT",
        url: `${BASEURL}users/revise/accountdelete/${id}`,
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      })
        .then((response) => {
            return dispatch({
                type: ACCOUNT_DELETE_SUCCESS,
                payload: response.data,
            });
        })
        .catch((error) => {
            return dispatch({
                type: ACCOUNT_DELETE_ERROR,
                payload: error.response,
            });
        });
};