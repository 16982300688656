import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../css/social.scss";
import { Button } from "antd";
import { Modal } from "react-bootstrap";

import {
  getInstagramUrl,
  getInstagramData,
  updateAccessToken,
  disconnectInstagram,
} from "../../redux/actions/instagram.action";
import { notification } from "antd";
import ConnectToFb from "./ConnectToFb";
// import { createBrowserHistory } from 'history';
// export const history = createBrowserHistory({
//   forceRefresh: true,
// });

function SocialSetup() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [loading, setLoading] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [url, setUrl] = useState("");
  const [disconnectLoading, setDisconnectLoading] = useState(false);

  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);

  useEffect(() => {
    if (searchParams.get("code") !== null) {
      dispatch(
        getInstagramData(searchParams.get("code"), userInfo?.email)
      ).then((res) => {
        if (res.type == "GET_INSTAGRAM_DATA_SUCCESS") {
          const parseUserInformation = userInfo;
          parseUserInformation.username = res.payload?.data?.username;
          parseUserInformation.access_token = res.payload?.data?.access_token;
          const storeUserInformation = JSON.stringify(parseUserInformation);
          localStorage.setItem("userInfo", storeUserInformation);
          query.searchParams.delete("code");
          window.history.replaceState(null, null, query);
          dispatch(
            updateAccessToken(
              userInfo?._id,
              res.payload?.data?.username,
              res.payload?.data?.access_token
            )
          ).then((res) => {
            history({
              pathname: `/account/social`,
            });
            window.location.reload();
            // history.push('/account/social');
          });
        } else {
          console.log(res.payload.data.message, "fail");
          notification.error({
            message: "Something went wrong!",
            className: "toast-error",
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    dispatch(getInstagramUrl()).then((res) => {
      setUrl(res.payload.data);
    });
  }, []);

  const disconnect = () => {
    setDisconnectLoading(true);
    dispatch(disconnectInstagram(userInfo?._id)).then((res) => {
      if (res.type == "DISCONNECT_INSTAGRAM_SUCCESS") {
        setDisconnectLoading(false);
        setDisconnectModal(false);
        userInfo.access_token = "";
        userInfo.username = "";
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        // history({
        //   pathname: `/account/social`,
        // });
        // history.push('/account/social');
        window.location.reload();
      } else {
        setDisconnectLoading(false);
        console.log(res.payload.data.message, "fail");
        notification.error({
          message: "Something went wrong!",
          className: "toast-error",
        });
      }
    });
    // props.loading(true);
    // await axios
    //   .put(`/users/revise/disconnectinstagram/${props.userId}`)
    //   .then((response) => {
    //     // localStorage.removeItem("access_token");
    //     // localStorage.removeItem("fbToken");
    //     // localStorage.removeItem("fbPage");
    //     props.modal(false);
    //     props.loading(false);
    //     let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //     // userInfo.page_token = "";
    //     // userInfo.fb_token = "";
    //     userInfo.access_token = '';
    //     userInfo.instagram_id = '';
    //     userInfo.username = '';
    //     localStorage.setItem('userInfo', JSON.stringify(userInfo));
    //     history.push('/app/account/setup');
    //     history.go(0);
    //   })
    //   .catch((err) => {
    //     props.loading(true);
    //   });
  };

  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">Social Setup</h1>
        </div>
        <div className="social-container-main container p-0 m-0">
          <div class="row">
            {/* <div class=" col-md-6 mb-20">
              <div className="social_box_main">
                <div className="social_box_header d-flex justify-content-between">
                  <h5>Instagram Connection</h5>
                  
                  {userInfo?.username !== '' ? (
                    <span className="connection-status-badge-green">
                      Connected
                    </span>
                  ) : (
                    <span className="connection-status-badge-red">
                      Not Connected
                    </span>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="dp_fields-setup mb-3">
                      <div className="category_count">Connection Status</div>
                    </div>
                    <div className="dp_fields-setup">
                      {userInfo?.username !== '' ? (
                        <>
                          <div className="connected-text text-left mb-2">
                            Connected: @{userInfo?.username}
                          </div>

                          <Button
                            className="default-btn medium-btn w-100 m-0 padb-3"
                            // type="primary"
                            // size="medium"
                            onClick={() => setDisconnectModal(true)}
                          >
                            <i className="fa fa-instagram" />
                            &nbsp;&nbsp; Disconnect Instagram
                          </Button>
                        </>
                      ) : (
                        <div className="dp_fields-setup">
                          <div className="connected-text text-left mb-2 text-danger">
                            Not Connected
                          </div>
                          <Button
                            onClick={() => {
                              window.location.replace(url);
                            }}
                            variant="primary"
                            className="default-btn medium-btn w-100"
                          >
                            <i className="fa fa-instagram" />
                            &nbsp;&nbsp; Connect Instagram
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="col-md-6 mb-20">
              <div className="social_box_main">
                <ConnectToFb
                  username={userInfo?.username}
                  userId={userInfo?._id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={disconnectModal}
        onHide={() => setDisconnectModal(false)}
        className="modal-instagram"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Disconnect Instagram</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          Are you sure you want to disconnect
          <span className="strong"> @{userInfo?.username}</span> account from
          Konnect.bio?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setDisconnectModal(false)}>Close</Button>
          <Button
            className="disconnect-btn btn-danger"
            onClick={() => {
              disconnect();
            }}
            disabled={disconnectLoading}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default SocialSetup;
