import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export default function PublicPrivacyPolicy() {
  return (
    <>
      {!userInfo && <PublicHeader />}

      <div className={`general-section ${userInfo && "p-0"}`}>
        <div className="mid-width max-1140">
          <div className="privacy_header">
            <h3>Privacy Policy</h3>
            <p>(Effective July 1, 2022)</p>
            <h5>Welcome!</h5>
            <p>Welcome to KBLIVE! We hope you will enjoy and appreciate using our “Services”, which may be visiting or using the website at https://kblive.com or any subdomain thereof (the “Website”), or participating in a live shopping “Event” (which you may be running) that may occur on a variety of platforms. We want you to know we take your privacy and protection of personal data very seriously. We are providing this Privacy Policy (the “Policy”) to tell you about who we are, what personal data we collect from you and about you, and what we do with your personal data, all while you use the Services or otherwise interact with us. The Policy also explains your rights under the law, and how you can contact us and the necessary authorities to enforce those rights. We ask that you please read it carefully.</p>
            {/* <h5>Key Elements of this Policy</h5>
            <p>Here are the key elements of this Policy so you can know the important parts right away to make an informed decision about your consent for our collection, use and disclosure of your personal data. By submitting any personal data to us via any means, you consent to such collection, use and disclosure. You can find the details in the rest of the Policy.</p>
            <div className="table-responsive table-simple">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Personal data we collect from you but only
                      with your consent</th>
                    <th>What we do with it</th>
                    <th>Third parties we share it with</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contact Information</td>
                    <td>Communicate with you and manage our relationship, depending on your relationship with us</td>
                    <td>Companies that provide our communications services, such as SendGrid and HubSpot</td>
                  </tr>
                  <tr>
                    <td>Account Information</td>
                    <td>Manage your account, and enable logging in to the Services and running Events</td>
                    <td>Companies that provide the infrastructure for the Services, such as Amazon AWS</td>
                  </tr>
                  <tr>
                    <td>Billing Information</td>
                    <td>Charge you the Fees for using the Services</td>
                    <td>Payment processors such as Shopify Payments</td>
                  </tr>
                </tbody>
              </table>
            </div> */}

            {/* <h5>Some Terms</h5>
            <p> Before we get started with the details, here are a few terms we think you should know as you read this Policy.</p>
            <p><strong>“Data Protection Laws”</strong> refers to the laws that are designed to protect your personal data and privacy in the place where you live. These include: (1) the <strong>“GDPR”</strong>, the European Data Protection Law which stands for “General Data Protection Regulation”, with the official name Regulation (EU) 2016/679 of the European Parliament and of the Council; (2) <strong>“PIPEDA”</strong> (Personal Information Protection and Electronic Documents Act), which is the Canadian Data Protection Law that applies to our activities in Canada; and (3) the California Consumer Privacy Act (<strong>“CCPA”</strong>) which applies to our activities in the United States in certain circumstances. KBLIVE is committed to adhering to all these applicable Data Protection laws.</p>
            <p><strong>“Personal data”</strong> - this is information we collect from you or about you and which is defined in the GDPR as “any information relating to an identified or identifiable natural person.” It can be as simple as your name or your email, or something more complicated like an online identifier (usually a string of letters and / or numbers) that gets attached to you. Under PIPEDA and the CCPA, the equivalent concept is “personal information”, which is roughly the same. Any mention of “personal data” in this Policy shall also mean personal information.</p>
            <p>Other terms and definitions used in this Policy may be found in our Terms of Use, and will have the same meaning in this Policy as they do there.</p> */}
            <h5>Your Rights</h5>
            <p>You have the following rights regarding your personal data held by KBLIVE, and other privacy rights. Please note that not necessarily all of these rights may be available to you; this depends on the Data Protection Laws where you are located that apply to you. These rights may be exercised without affecting the price you pay for any of the Services. Notwithstanding that, exercising certain of these rights may affect your ability to use some or all of the Services</p>
            <ul className="list-simple">
              <li> The right to withdraw at any time your consent for KBLIVE to process your personal data;</li>
              <li>The right to have your personal data erased from KBLIVE’s records;</li>
              <li>The right to access your personal data and any relevant information around its processing and use;</li>
              <li>The right to have a copy of your personal data given to you in an easy to read format so that you can transfer it to another data processor;</li>
              <li>The right to have your personal data corrected or updated if you believe it is inaccurate or out of date;</li>
              <li>The right to opt out of marketing communications we send you, at any time;</li>
              <li>The right to know whether KBLIVE sells or shares your personal data (and if so, who gets it). Please refer to that information elsewhere in this Policy, though you can contact </li>
              <li>our Privacy Officer if you need additional information or clarifications;</li>
              <li>The right to demand that KBLIVE not sell your personal data;</li>
              <li>The right to restrict the processing of your personal data if it is inaccurate or if our processing or use of it is against the law; and</li>
              <li>The right to refuse any marketing or advertising targeted at you by KBLIVE.</li>
            </ul>
            <p>If you wish to exercise any of these rights, please contact our Privacy Officer at the contact information above, or refer to certain relevant sections further in this Policy.</p>
            <h5>Personal Data Collected from You and What We Use It For</h5>
            <p>In the table below, please find all the personal data we may collect from you directly, what we use it for, and the legal basis under the GDPR for us having and processing this personal data. Under PIPEDA, the legal basis is your informed consent, and by submitting this personal data you acknowledge having granted this consent to KBLIVE.</p>
            <div className="table-responsive table-simple">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Personal data category</th>
                    <th>Personal data processed</th>
                    <th>What we use it for (the “purpose” of processing)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contact information</td>
                    <td>Your name and email address and optionally, your phone number</td>
                    <td>To communicate with you</td>
                  </tr>
                  <tr>
                    <td>Account information</td>
                    <td>Name, email address, and business name and address</td>
                    <td>To create an account for you, to communicate with you, and to provide you with certain Services (creating and running Events)</td>
                  </tr>
                  <tr>
                    <td>Event chat information</td>
                    <td>Any personal data submitted during an Event via the chat function</td>
                    <td>Transfer it to the Merchant running the Event</td>
                  </tr>
                  <tr>
                    <td>Web chat information</td>
                    <td>Any personal data submitted via the chat function on the Website</td>
                    <td>Respond to your inquiry</td>
                  </tr>
                  <tr>
                    <td>Billing information</td>
                    <td>Credit card holder name, number, expiration date, CVV number and billing address</td>
                    <td>To allow you to pay for the Fees for use of Services, if paying Fees online</td>
                  </tr>
                  <tr>
                    <td>Analytics information</td>
                    <td>An analytics identifier</td>
                    <td>Transfer it to the Merchant running the Event</td>
                  </tr>
                </tbody>
              </table>
            </div>


            <p>Where you have provided personal data further to the contract between you and us, if you fail to provide such data or withdraw your consent to use such data, we will no longer be able to provide certain Services to you.</p>
            <h5>Personal Data Collected About You from Third Parties and What We Use It For</h5>
            <p>Sometimes we get personal data about you from third parties. This table explains the details about this personal data – what it is, where it came from, what we do with it, and legal basis for us having and processing this personal data under the GDPR. Under PIPEDA, the legal basis is your informed consent.</p>

            <div className="table-responsive table-simple">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Personal data category</th>
                    <th>Personal data processed</th>
                    <th>Who we get the data from</th>
                    <th>What we use it for (the “purpose” of processing)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Account Information, when you sign up via an E-Commerce Platform</td>
                    <td>Name, email address, and business name and address</td>
                    <td>The E-Commerce Platform through which you are accessing the Services</td>
                    <td>To offer you the Services</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>

            <h5>Sensitive Personal Data</h5>
            <p>We do not collect any of what the GDPR considers sensitive personal data from you, unless you voluntarily submit it through the chat function during an Event or on the Website, which we encourage you not to do.</p>
            <h5>Who We Transfer Your Personal Data To</h5>
            <p>We routinely share some of your personal data with certain types of third parties who are identified in the table below along with what they do with it. Some of those third-party recipients may be based outside your home jurisdiction. If you are in the European Economic Area — please see the “Transfer of Your Personal Data Outside of the European Economic Area” further down in this Policy for more information including on how we safeguard your personal data when this occurs.</p>
            <p>We will share personal data with law enforcement or other public authorities if:  </p>
            <p>  (1) we are required by applicable law in response to lawful requests, including to meet national security or law enforcement requirements;</p>

            <p>  (2) if we believe it is necessary in order to investigate, prevent, or take action regarding illegal activities, fraud, or situations involving potential threats to the safety of any person, or any violation of KBLIVE’s Terms of Use or other contract that governs your relationship with us; or </p>
            <p>  (3) if we believe it is necessary to investigate, prevent, or take action regarding situations that involve abuse of the Services infrastructure or the Internet in general (such as voluminous spamming or denial of service attacks).</p>
            <p>We will never share your personal data with other third parties except under these circumstances. We do not sell your personal data to any third party for direct marketing purposes or any other purpose.</p>

            {/* <div className="table-responsive table-simple">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Personal data category</th>
                    <th>Who we transfer it to</th>
                    <th>What they do with it</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contact information</td>
                    <td>Companies that provide email services, such as HubSpot as detailed more fully in the Email Communications section below</td>
                    <td>Send you emails</td>
                  </tr>
                  <tr>
                    <td>Account information (including contact information)</td>
                    <td>Companies providing technical infrastructure for the Services, such as Microsoft Azure, AWS, and Google Cloud Platform</td>
                    <td>Control your logging in to the Services so they can be provided to you, and record-keeping</td>
                  </tr>
                  <tr>
                    <td>Billing information</td>
                    <td>Payment processing companies, such as Stripe</td>
                    <td>Process your payments for the fees you pay for the Services, when paying online</td>
                  </tr>
                  <tr>
                    <td>Event chat information</td>
                    <td>The Merchant you are interacting with during an Event</td>
                    <td>Respond to your queries and chat with you</td>
                  </tr>
                  <tr>
                    <td>Web chat information</td>
                    <td>Companies that provide web chat services such as Drift</td>
                    <td>Operate the chat service</td>
                  </tr>
                  <tr>
                    <td>Advertising identifiers</td>
                    <td>Companies that provide online advertising networks, such as Google and Facebook, and as further detailed in the KBLIVE Advertising section below</td>
                    <td>Show you ads for KBLIVE and the Services when you are on the internet</td>
                  </tr>
                  <tr>
                    <td>Analytics identifiers</td>
                    <td>Companies that provide data analytics, such as
                      <br/><a className="highlight-txt" href="https://google.com/analytics/" target="_blank">Google Analytics</a></td>
                    <td>Provide us with analytics as to how the Services are used, both on the Website and during Events, and to trace fraudulent activities</td>
                  </tr>
                  <tr>
                    <td>KBLIVE analytics identifiers</td>
                    <td>The Merchant running the Event</td>
                    <td>Analysis as to how the Event performed</td>
                  </tr>
                </tbody>
              </table>
            </div> */}

            <h5>Tracking Technology (“Cookies” and Related Technologies)</h5>
            <p>KBLIVE uses tracking technology (“cookies” and related technology such as tags, pixels and web beacons) in the Services and by interacting with the Services you agree to their use. Cookies are small text files placed on your computer or device when you visit a website or use an online service, in order to track use of the site or service and to improve the user experience by storing certain data on your computer or device.</p>
            <p>Specifically, we use cookies and related technologies for the following functions:</p>
            <ul className="list-simple">
              <li>  to enable your signing-in to the Services;</li>
              <li>for the proper functioning of the Events;</li>
              <li>to provide general internal and user analytics during Events and on the Website and to conduct research to improve the content of the Services using analytics programs listed above in this Policy;</li>
              <li>to facilitate online advertising, as described more fully below in this Policy; and</li>
              <li>to assist in identifying possible fraudulent activities.</li>
            </ul>
            <p>Your browser can be set to refuse cookies or delete them after they have been stored. You can refer to your browser’s help section for instructions, but here are instructions for the most commonly-used browsers and operating systems:</p>
            <ul className="list-simple">
              <li>Google Chrome</li>
              <li>Mozilla Firefox</li>
              <li>Microsoft Edge</li>
              <li>Microsoft Internet Explorer</li>
              <li>Opera</li>
              <li>Apple Safari</li>
              <li>iOS</li>
            </ul>
            <p>Please note that deleting or blocking certain cookies may reduce your user experience by requiring you to re-enter certain information, including information required to use our Services. Furthermore, deleting certain cookies may prevent certain functions, or the entirety of the Services, from working at all.</p>
            {/* <h5>Email Communications and Compliance with Anti-Spam Laws</h5>

            <p>KBLIVE uses <span className="highlight-txt">  HubSpot</span> to manage our mailing list and send out promotional emails, and  Amazon SES to send out emails related to various Services functions (HubSpot and<a className="highlight-txt" href="https://aws.amazon.com/ses/" target="_blank"> Amazon SES</a>, collectively the “Email Service Providers”). Personal data is transferred to the Email Service Providers in order to manage the mailing list and for the emails to be sent out properly. Your Contact Information is only used to send out emails; the Email Service Providers do not use this Personal Information for any other purpose, and will not transfer or sell your Personal Information to any other third party. For more information, please refer to <span className="highlight-txt">HubSpot’s Privacy Policy</span> and <a className="highlight-txt" href="https://aws.amazon.com/privacy/" target="_blank">Amazon's Privacy Policy</a></p>
            <p>You may unsubscribe from KBLIVE’s mailing list at any time, by following the link at the bottom of all KBLIVE emails. Other types of emails, such as transactional, relational, and other emails related to the Services will not have an opt-out option as they are necessary for the use of the Services.</p>
            <p>KBLIVE’s practices in regards to its email are designed to be compliant with anti-spam laws, specifically the law unofficially called “CASL”, or Canada’s Anti-Spam Law (S.C. 2010, c. 23). If you believe you have received email in violation of these laws, please contact us using the contact information further up in this Policy.</p> */}
            <h5>KBLIVE Advertising and Opting Out</h5>

            <p>KBLIVE currently uses certain advertising networks to provide advertising to you. These include but may not necessarily be limited to the networks listed in this section of the Policy. For example, we use <a className="highlight-txt" href="https://adwords.google.com/" target="_blank"> Google AdWords and Display Network</a> and by using our Services you consent to this use. Specifically, KBLIVE uses the remarketing features of interest-based advertising of Google AdWords that delivers you advertisements that will be of particular interest to you, based on your browsing and activity history interacting with the Services. These advertisements will appear on third-party websites around the web. Google uses specific cookies to allow them to serve these ads around the web. You may prevent this type of advertising by deleting the appropriate Google cookie through your browser, though this may not be permanent. For a more permanent solution, you may opt out of such Google advertising by <a className="highlight-txt" href="https://adssettings.google.com/" target="_blank">adjusting your Google ad settings</a> or using the <span className="highlight-txt">WebChoices online opt-out tool.</span></p>
            <p>KBLIVE also advertises using <a className="highlight-txt" href="https://www.facebook.com/business/a/online-sales/custom-audiences-website" target="_blank">Facebook Custom Audience</a>, to display advertisements to you on Facebook or in Facebook Messenger that will be of interest to you, and by using the Services you consent to this use. Facebook may collect or receive information from our Services and other applications and websites and use that information to provide measurement services and targeted ads. If you do not want to receive such Facebook advertisements, you can opt-out of such advertising by <a className="highlight-txt" href="https://www.facebook.com/ads/preferences" target="_blank">adjusting your Ad Preferences settings</a> while logged in to Facebook.</p>
            <p>KBLIVE also uses the following advertising services, and we have provided instructions about how you can opt out:</p>
            <ul className="list-simple">
              <li><a className="highlight-txt" href="https://www.linkedin.com/help/linkedin/answer/85787/linkedin-ads-and-marketing-solutions-overview" target="_blank">LinkedIn Ads</a>. You may opt out of such LinkedIn advertising by <a className="highlight-txt" href="https://www.linkedin.com/help/linkedin/answer/90274/manage-your-linkedin-ads-settings" target="_blank">following LinkedIn's instructions</a> or using the <span className="highlight-txt">WebChoices online opt-out tool</span>; and
              </li>
              <li><a className="highlight-txt" href="https://business.instagram.com/advertising/" target="_blank"> Instagram Ads</a>. You can block or opt out of Instagram Ads by <a className="highlight-txt" href="https://help.instagram.com/615366948510230" target="_blank">following Instagram's instructions.</a>
              </li>
            </ul>
            <h5>How We Protect Your Personal Data</h5>
            <p>We have implemented very strict technical and organisational procedures for ensuring that, by default, only personal data which are necessary for each specific purpose of the processing are processed by us. These procedures prevent your personal data from being lost; or used or accessed in any unauthorised way.</p>
            <p>We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable supervisory authority of a suspected data security breach where the Data Protection Laws requires us to do so, and within the time frame required by the applicable Data Protection Law.</p>
            <p>KBLIVE uses only industry best practices (physical, electronic and procedural) in keeping any data collected (including personal data) secure. In addition, we use third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate the Services, and these third parties have been selected for their high standards of security, both electronic and physical. For example, KBLIVE uses <a className="highlight-txt" href="https://azure.microsoft.com/" target="_blank">Microsoft Azure, Amazon AWS</a>, and <a className="highlight-txt" href="https://cloud.google.com/" target="_blank">Google Cloud</a>, all recognized leaders in secure data, for hosting of the Services and related data, and storage of data including personal data.</p>
            <p>Finally, all information, including personal data, is transferred with encryption using Secure Sockets Layer (“SSL”) or Transport Layer Security (“TLS”), robust security standards for Internet data transfer and transactions. You can use your browser to check KBLIVE’s valid SSL security certificate.</p>
            <h5>Transfer of Your Personal Data Outside of the European Economic Area (EEA)</h5>
            <p>For our European users, we endeavour to keep your personal data inside the EEA. However, certain of our data processors (and KBLIVE) are in other countries where your personal data may be transferred. However, these countries are limited to countries with particular circumstances that protect your data, specifically:</p>

            <ul className="list-simple">
              {/* <li> Canada. Canada has been determined to have an “adequate level of protection” for your personal data <a className="highlight-txt" href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" target="_blank">under European data protection law</a>. </li> */}
              <li>
                The United States. Your personal data is only transferred to companies in the United States that:
                <br />
                (1) participate in the <a className="highlight-txt" href="https://www.privacyshield.gov/welcome" target="_blank">Privacy Shield</a>; and
                <br />
                (2) have signed agreements with us or have informed us that they are GDPR-compliant. </li>
            </ul>
            <p>That's it! You have the right, however, to refuse to have your data transferred outside the EEA. Please contact our Privacy Officer to make that request. Please note that making this request may prevent you from being able to use a portion or all of the Services.</p>
            <h5>Data Retention</h5>
            <p>Your personal data will only be kept for as long as it is necessary for the purpose needed for that processing. For example, we will only retain your Account Information for as long as you have an account with us.</p>
            <h5>Automated Decision-Making</h5>
            <p>KBLIVE does not use any automated decision-making processes in providing the Services.</p>
            <h5>Children’s Privacy Statement</h5>
            <p>The Services are not intended for children under the age of 16. We do not knowingly collect any personal data from a child under 16. If we become aware that we have inadvertently received personal data from a person under the age of 16 through the Services, we will delete such information from our records.</p>
            <h5>Changes to This Privacy Policy</h5>
            <p>The date at the top of this page indicates when this Policy was last updated. Every now and then, we will have to update this Policy, and we will update it no less than once every 12 months. You can always find the most updated version at this URL, and we will always post a notice on the Services if we make significant changes. If you have a KBLIVE account, we will also email you to tell you the Policy has been updated, and what the important changes are.</p>
          </div>
        </div>
      </div>

      {!userInfo &&  <PublicFooter />}
    </>
  );
}
