import {
  SAVE_ROLE_REQUEST,
  SAVE_ROLE_SUCCESS,
  SAVE_ROLE_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const saveRoles = (data) => (dispatch) => {
  dispatch({ type: SAVE_ROLE_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}roles/createrole`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: SAVE_ROLE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_ROLE_ERROR,
        payload: error.response,
      });
    });
};
