import { CLEAR_POST, GET_POST, UPDATE_POST } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const configSubs = () => async (dispatch) => {
  const res = await axios.post(`${BASEURL}subscribe/config`,{},{headers: { Authorization: `Bearer ${userInfo?.token}` },});
  return res.data;
};

export const makePayment = (data) => async (dispatch) => {
  const res = await axios.post(`${BASEURL}subscribe/checkout`, data,{headers: { Authorization: `Bearer ${userInfo?.token}` },});
  return res.data.message;
};

export const updateSubscription = (data) => async (dispatch) => {
  const res = await axios.post(`${BASEURL}subscribe/upgrade`, data,{headers: { Authorization: `Bearer ${userInfo?.token}` },});
  return res.data;
};


export const subscribeServices = (val,priceId,module,interval,package_id) => async (dispatch) => {
  const res = await axios.post(`${BASEURL}subscribe/addon`, {
    package_id:package_id,
    recurring_payment_type:interval,
    addon:module,
    quantity:val,
    price_id:priceId
  },{headers: { Authorization: `Bearer ${userInfo?.token}` },});
  return res.data;
};