import React, { useState, useEffect } from "react";
import "../../css/broadcast.scss";
import { Button } from "antd";
// import videoPlacehodler from "../../images/video-placehodler.png";

import IVSBroadcastClient from "amazon-ivs-web-broadcast";

export default function BroadCast() {
  // const [preview, setPreview] = useState(false);
  const [error, setError] = useState("");
  const client = IVSBroadcastClient.create({
    streamConfig: IVSBroadcastClient.BASIC_PORTRAIT,

    ingestEndpoint:
      "rtmps://0b0151e57d8d.global-contribute.live-video.net:443/app/",
  });

  useEffect(() => {
    handlePermissions();
  }, []);

  async function handlePermissions() {
    let permissions = {
      audio: false,
      video: false,
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      for (const track of stream.getTracks()) {
        track.stop();
      }
      permissions = { video: true, audio: true };
    } catch (err) {
      permissions = { video: false, audio: false };
      console.error(err.message);
    }
    // If we still don't have permissions after requesting them display the error message
    if (!permissions.video) {
      console.error("Failed to get video permissions.");
      setError("Failed to get video.");
    } else if (!permissions.audio) {
      console.error("Failed to get audio permissions.");
      setError("Failed to get audio.");
    }
  }

  async function startBroadcast() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    window.videoDevices = devices.filter((d) => d.kind === "videoinput");
    window.audioDevices = devices.filter((d) => d.kind === "audioinput");

    const streamConfig = IVSBroadcastClient.BASIC_PORTRAIT;
    console.log(streamConfig, "streamConfig");

    if (navigator.mediaDevices.getUserMedia) {
      window.cameraStream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: window.videoDevices[0].deviceId,
          width: {
            ideal: streamConfig.maxResolution.width,
            max: streamConfig.maxResolution.width,
          },
          height: {
            ideal: streamConfig.maxResolution.height,
            max: streamConfig.maxResolution.height,
          },
        },
      });
    }

    client.addVideoInputDevice(
      window.cameraStream,
      window.videoDevices[0].deviceId,
      { index: 0 }
    );
    client.addAudioInputDevice(
      window.microphoneStream,
      window.audioDevices[0].deviceId
    );

    client
      .startBroadcast(
        "sk_us-west-2_xkA116L8oGgu_nFbBUfg8reKULbf5nauFIVosJzm8bc"
      )
      .then((result) => {
        console.log("I am successfully broadcasting!");
        const videoTag = document.getElementById("preview");
        videoTag.srcObject = window.cameraStream;
      })
      .catch((error) => {
        console.error(
          "Something drastically failed while broadcasting!",
          error
        );
        setError("Something drastically failed while broadcasting!");
      });
  }
  async function stopBroadcast() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    window.videoDevices = devices.filter((d) => d.kind === "videoinput");
    window.audioDevices = devices.filter((d) => d.kind === "audioinput");
    client.stopBroadcast();
    client.removeVideoInputDevice(window.videoDevices[0].deviceId);
    client.removeAudioInputDevice(window.audioDevices[0].deviceId);
  }

  return (
    <>
      <div className="broadcast_container_main">
        <h1 className="hd1">Stream</h1>

        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}

        <div className="video_preview">
          {/* {!preview ? (
            <img
              className="img-fluid video-img"
              src={videoPlacehodler}
              alt="video"
            />
          ) : (
            <canvas id="preview" className="img-fluid video-img"></canvas>
          )} */}

          {/* <canvas id="preview" className="img-fluid video-img"></canvas> */}
          <video
            autoplay="true"
            id="preview"
            className="img-fluid video-img"
          ></video>

          {!error && (
            <div className="d-flex justify-content-center">
              <Button
                className="default-btn align-items-center justify-content-center"
                type="primary"
                size="large"
                onClick={startBroadcast}
              >
                Start
              </Button>
              <Button
                className="default-btn align-items-center justify-content-center"
                type="primary"
                size="large"
                onClick={stopBroadcast}
              >
                Stop
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
