import React from "react";
import AsyncSelect from "react-select/async";
import axios from "axios";
// import { toast } from "react-toastify";
import "../../../css/asyncProduct.scss";
import { BASEURL } from "../../../config";
import { notification } from "antd";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
let arrsku = [];
class AsyncProduct extends React.Component {
  state = {
    sku: this.props.sku,
    allSku: "",
    // default_value: this.props.defaultValue,
  };

  loadOptions = async (input, callback) => {
    await this.smartSearchFilter(input);

    const result = arrsku.map((item) => {
      return {
        value: `${item.value}`,
        label: `${item.label}`,
        price: `${item.price}`,
        title: `${item.title}`,
        id: item?._id,
        ProductName: item?.title,
        ProductSku: item?.ProductSku,
        ProductUrl: item.ProductUrl,
        mediaUrl: item?.mediaUrl,
        productAmount: item?.productAmount,
        productDesc: item.productDesc,
      };
    });
    callback(result);
  };

  smartSearchFilter = async (value) => {
    if (value.length > 2 && value.trim()) {
      await axios
        .post(
          `${BASEURL}campaigns/receive/searchsku`,
          {
            sku: value.trim(),
          },
          {
            headers: { Authorization: `Bearer ${userInfo?.token}` },
          }
        )
        .then((response) => {
          const loadSku = [];
          const sku = response.data.message;
          if (sku.length === 0) {
            notification.error({
              message: "No Product Found",
              className: "toast-error",
            });
          }

          this.setState({ allSku: sku });
          sku.map(({ _source }) => {
            const productUrl =
              "https://" + _source?.domain + "/products/" + _source?.handle;
            let description = _source?.body_html
              ? _source?.body_html.replace(/<\/?[^>]+(>|$)/g, "")
              : "";
              
            return loadSku.push({
              value: _source?.variants[0]?.sku,
              label: _source?.variants[0]?.sku,
              price: _source?.variants[0]?.price,
              title: _source?.title,
              _id: _source?.id,
              ProductName: _source?.title,
              ProductSku: _source?.variants[0]?.sku,
              ProductUrl: productUrl,
              mediaUrl: _source?.image?.src,
              productAmount: _source?.variants[0]?.price,
              productDesc: description.replace(50, "$1…")
            });
          });
          // this.setState({ sku: loadSku });
          arrsku = loadSku;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  handleMultiSelect = (e) => {
    if (e.length <= 6) {
      this.props.getSku(e);
      this.setState({
        sku: e,
      });
    } else {
      let lastRemoved = e.slice(0, -1);
      this.props.getSku(lastRemoved);
      this.setState({
        sku: lastRemoved,
      });
      notification.error({
        message: "6 Products are allowed",
        className: "toast-error",
      });
    }
  };

  handleOnPaste = async (e) => {
    const getData = e.clipboardData.getData("text");
    await this.smartSearchFilter(getData);
  };
  formatOptionLabel = ({ label, title, price }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>&nbsp;-&nbsp;
      <div style={{ marginLeft: "10px" }}>{title}</div>&nbsp;-&nbsp;
      <div style={{ marginLeft: "10px" }}>${price}</div>
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <div onPaste={(e) => this.handleOnPaste(e)}>
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            // delimiter=","
            loadOptions={this.loadOptions}
            placeholder="Select product SKU"
            name={"productsku"}
            // defaultValue={this.state.sku}
            value={this.state.sku}
            onChange={(e, options) => {
              this.handleMultiSelect(e, options);
            }}
            formatOptionLabel={this.formatOptionLabel}
          />
        </div>
        {/* <div className="row mt-3">
          {this.state.sku &&
            this.state.sku.map((item, i) => {
              return (
                <>
                  <div className="col-6 col-md-3 col-lg-4 col-xl-3">
                    <div className="product-box-main">
                      <div className="product-content-top">
                        <div className="product-image">
                          <img
                            src={item.mediaUrl}
                            alt={item.title}
                            className="img-fluid"
                          />
                        </div>
                        <p>{item.title}</p>
                      </div>

                      <div className="product-content">
                        <h4>{item.price}</h4>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div> */}
      </React.Fragment>
    );
  }
}

export default AsyncProduct;
