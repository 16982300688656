import { GET_LINK } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

var userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getLinks = (username) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${BASEURL}profile/posts/${username}`,
      {
        params: {
          post_type: "link",
        },
        headers: { Authorization: `Bearer ${userInfo?.token}` },
      }
    );

    dispatch({
      type: GET_LINK,
      payload: res.data?.message.result.data,
    });
  } catch (err) {
    console.log(err);
  }
};
