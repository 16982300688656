function NoEventFound() {
  return (
    <div className="no-result-found">
      <div className="no-result-img">
        <img
          src={
            "https://cdn.konnect.bio/dl1961denim/profile/0d1df87e-25ed-407b-af89-374a536f6678.jpg"
          }
          alt="searchEventImg"
        />
      </div>
      {/* <h5 className="no-event-hd"> No Event At This Time.</h5> */}
      <p>No Event At This Time. Please check back later.</p>
    </div>
  );
}

export default NoEventFound;
