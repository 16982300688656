import axios from "axios";
import { GET_MOBILE_DROPDOWN } from "../types/types";


export const getMobileDropdown = (v) => async (dispatch) => {
    dispatch({
        type:GET_MOBILE_DROPDOWN,
        payload:v
    })
};
