import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import Box from './Box/box';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { DatePicker, Spin, Form } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as tagActions from '../../../redux/actions/tags';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

function CaptionsContainer({ title, getHashtag, tags, getTags, createTags }) {
  const [searchLoading, setSearchLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  const [spinner, setLoading] = useState(true);

  const [brand, setBrand] = useState({ value: 'all', label: 'All' });
  const [sortBy, setSortBy] = useState({
    value: 'date',
    label: 'DATE',
  });
  const [orderBy, setOrderBy] = useState({ value: 'desc', label: 'DESC' });
  // const fromDate = moment().subtract(4, "year").format("YYYY-MM-DD");
  const fromDate = moment().startOf('year').format('YYYY-MM-DD');
  const toDate = moment(new Date()).format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  useEffect(() => {
    setSearchLoading(true);
    setLoading(true);
    createTags().then(() => {
      getTags(
        {
          type: 'caption',
          from_date: startDate.toString(),
          to_date: endDate.toString(),
          sort: sortBy.value,
          order_by: orderBy.value,
        },
        1
      ).then((res) => {
        setLoading(false);
        setSearchLoading(false);
      });
    });
  }, []);

  function onSubmitData(e) {
    e.preventDefault();
    setSearchLoading(true);
    getTags(
      {
        type: 'caption',
        from_date: startDate.toString(),
        to_date: endDate.toString(),
        sort: sortBy.value,
        order_by: orderBy.value,
      },
      1
    ).then((res) => {
      setSearchLoading(false);
    });
  }

  const clearMarketPlace = (e) => {
    setClearLoading(true);
    setBrand({ value: 'all', label: 'ALL' });
    setSortBy({ value: 'date', label: 'DATE' });
    setOrderBy({ value: 'desc', label: 'DESC' });
    getTags({
      type: 'caption',
      from_date: fromDate.toString(),
      to_date: toDate.toString(),
      sort: 'date',
      order_by: 'desc',
    }).then(() => {
      setClearLoading(false);
    });
  };

  const style = {
    control: (base) => ({
      ...base,
      height: '40px',
      boxShadow: 'none',
      borderRadius: '0px',
      border: '1px solid black',
      '&:hover': {
        border: '1px solid black',
      },
    }),
  };

  const sortByOptions = [
    { value: 'date', label: 'DATE' },
    { value: 'followers', label: 'MOST INFLUENTIAL' },
    // { value: "likes", label: "LIKES" },
  ];
  const sortOrderOptions = [
    { value: 'asc', label: 'ASC' },
    { value: 'desc', label: 'DESC' },
  ];

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  return (
    <>
      <div className="container-flui">
        <div className="title-area">
          <h1 className="page-title">Captions</h1>
        </div>
        <Row className="post-analytics-tab">
          <Col xs={12} xl={12} md={12}>
            <form onSubmit={onSubmitData}>
              <Row>
                <Col className="col-xl-3" xs={12} md={4}>
                  {/* <Form.Item
                    name="datetime"
                    initialValue={'date'}
                    labelCol={{ span: 24 }}
                    label="Select Start Date / End Date"
                  > */}
                  <p>Select Start Date / End Date</p>
                  <RangePicker
                    size="large"
                    className="w-100"
                    key={4}
                    value={
                      startDate && endDate
                        ? [moment(startDate), moment(endDate)]
                        : []
                    }
                    allowClear={false}
                    ranges={{
                      Today: [moment(), moment()],
                      Tomorrow: [
                        moment().add(1, 'days'),
                        moment().add(1, 'days'),
                      ],
                      Yesterday: [
                        moment().subtract(1, 'days'),
                        moment().subtract(1, 'days'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      'Last Month': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                      ],
                    }}
                    format={dateFormat}
                    onChange={dateRangePickerChanger}
                  />
                  {/* </Form.Item> */}
                </Col>
                <Col className="col-xl-3" xs={12} md={4}>
                  {/* <Form.Item
                    labelCol={{ span: 24 }}
                    label="Sort By"
                    name="sort"
                  > */}
                  <p>Sort By</p>
                  <Select
                    size="large"
                    value={sortBy}
                    name="sort"
                    className="selectCustomization select-style dark-field"
                    options={sortByOptions}
                    onChange={(e) => {
                      setSortBy(e);
                    }}
                    placeholder="Sort By"
                    styles={style}
                    isSearchable={false}
                  />
                  {/* </Form.Item> */}
                </Col>

                <Col className="d-flex align-items-end col-xl-3" xs={12} md={4}>
                  {searchLoading ? (
                    // <Spin spinning={spinner}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{' '}
                      Search
                    </Button>
                  ) : (
                    // </Spin>
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn"
                    >
                      Search
                    </Button>
                  )}

                  {clearLoading ? (
                    // <Spin spinning={spinner}>
                    <Button
                      type="submit"
                      variant="primary"
                      className="default-btn fltr-hpr  medium-btn d-flex justify-content-center"
                      disabled
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-2"
                      />{' '}
                      Reset
                    </Button>
                  ) : (
                    // </Spin>
                    <Button
                      onClick={clearMarketPlace}
                      className="default-btn outline fltr-hpr  medium-btn  res-btn2"
                      type="primary"
                      size="large"
                    >
                      Reset
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
        <hr className="separator-line" />
        <div style={{ marginTop: 20 }}>
          {searchLoading || clearLoading ? (
            <div
              style={{
                height: 300,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className="spinclass loader-center position-relative">
                <Spin size="large" />
              </span>
            </div>
          ) : tags?.message?.length > 0 ? (
            <InfiniteScroll
              dataLength={tags?.message?.length}
              next={() => {
                getTags(
                  {
                    type: 'caption',
                    from_date: startDate.toString(),
                    to_date: endDate.toString(),
                    sort: sortBy.value,
                    order_by: orderBy.value,
                  },
                  tags?.pagination?.next?.page,
                  true
                );
              }}
              // hasMore={tags.pagination.next ? true : false}
              hasMore={
                tags?.pagination?.next
                  ? tags.pagination.next?.page >= 1
                    ? false
                    : true
                  : false
              }
              loader={
                <div
                  style={{
                    height: 100,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span className="spinclass loader-center position-relative">
                    <Spin size="small" />
                  </span>
                </div>
              }
            >
              <div>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    350: 1,
                    700: 2,
                    1100: 3,
                    1500: 4,
                  }}
                >
                  <Masonry gutter="15px">
                    {tags.message.map((item) => {
                      return <Box data={item} />;
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </InfiniteScroll>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
}

function mapStateToProps({ tags }) {
  return { tags };
}

export default connect(mapStateToProps, tagActions)(CaptionsContainer);
