import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../images/kblivelogo.svg';

export default function PublicHeader() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const [page, setPage] = useState(splitLocation[1] === '' ? '' : 'about');

  return (
    <>
      <div className="public-header">
        <div className="mid-width">
          <div className="public-header-content">
            <div className="header-left">
              <div className="public-logo">
                <Link to="/home">
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <nav className="public-menu">
                <ul>
                  <li
                    className={page === '' ? 'active' : ''}
                    onClick={() => setPage('')}
                  >
                    <Link to="/">Brands</Link>
                  </li>
                  {/* <li>
                    <Link to="/influencer-creator">Influencers & Creators</Link>
                  </li> */}
                  <li
                    className={page === 'about' ? 'active' : ''}
                    onClick={() => setPage('about')}
                  >
                    <Link to="/about">About</Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="buttons-area">
              <Link
                to="/login"
                className="btn default-btn medium-btn rounded fw-bold"
              >
                Sign In
              </Link>
              <Link
                to="/register"
                className="btn default-btn medium-btn rounded fw-bold"
              >
                Join Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
