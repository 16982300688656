import React, { useEffect, useState } from 'react';
import { Form, Input, Avatar, Comment, List } from 'antd';
import moment from 'moment';
import {
  ChatRoom,
  DeleteMessageRequest,
  DisconnectUserRequest,
  SendMessageRequest,
} from 'amazon-ivs-chat-messaging';
import { connect, useDispatch } from 'react-redux';
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';
import '../../css/chat.scss';
import placeholder from '../../images/user.png';
import { saveChat } from '../../redux/actions/chat';
const { TextArea } = Input;

function RecordedChat({ chat, chatLoading }) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [comments, setComments] = useState([]);
  const [value, setValue] = useState('');

  //   const scrollToBottomMethod = useScrollToBottom();

  useEffect(() => {
    if (chat) {
      setComments(chat);
    }
  }, [chat]);

  if (chatLoading) {
    return (
      // <div className="chat-loading">
      //   <h3>...loading</h3>
      // </div>
         <div className='not-found-center'>
         <h3>...loading</h3>
         </div>
    );
  }

  return (
    <div className="live-chat-comp">
      <div id="frame">
        <div className="content">
          <ScrollToBottom className="messages">
            <ul className="message-ul">
              {comments.length === 0 ? (
                <>
                <div className='not-found-center'>
                 <h5>
                  No Chat Found
                  </h5>
                 </div>
                </>
              ) : (
                comments.map((it) => {
                  return (
                    <li
                      className={
                        userInfo?._id === it.user_id ? 'replies' : 'sent'
                      }
                    >
                      <img src={it.avatar ? it.avatar : placeholder} alt="" />
                      <div className="msg-c-box message-box-main">
                        {userInfo?._id !== it.user_id ? (
                          <>
                            <span class="user-name">{it.author}</span>
                            <p className="msg-content">
                              <span class="user-message">{it.content}</span>
                            </p>
                          </>
                        ) : (
                          <p className="msg-content">
                            <span class="user-message">{it.content}</span>
                            {/* <span class="user-name">{it.author}</span> */}
                          </p>
                        )}
                        <div className="msg-date-main">
                          <small className="msg-date">{it.datetime}</small>
                        </div>
                      </div>
                    </li>
                  );
                })
              )}
            </ul>
          </ScrollToBottom>
        </div>
      </div>
    </div>
  );
}

export default RecordedChat;
