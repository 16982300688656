import React, { useState } from "react";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrip, faBars } from "@fortawesome/free-solid-svg-icons";

// const { Option } = Select;
const handleChange = (value) => {
  console.log(`selected ${value}`);
};
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default function Analytics() {
  const [gridActive, setGridActive] = useState(true);
  return (
    <>
      <div className="grid-listing-area mb-30">
        <div className="grid-listing-left">
          {/* <Select
            className="select-style"
            defaultValue="Type: All"
            size="large"
            focus="false"
            style={{ width: 190 }}
            onChange={handleChange}
            onBlur={false}
          >
            <Option value="All">All</Option>
            <Option value="Live">Live</Option>
            <Option value="Test Mode">Test Mode</Option>
          </Select> */}
          <h1 className="hd2">Analytics</h1>
        </div>
        <div className="grid-listing-right">
          <div className={`grid-icon ${gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faGrip} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(true);
              }}
            ></a>
          </div>
          <div className={`grid-icon ${!gridActive ? "active" : ""}`}>
            <FontAwesomeIcon icon={faBars} />
            <a
              className="block-link"
              href="#"
              onClick={() => {
                setGridActive(false);
              }}
            ></a>
          </div>
        </div>
      </div>
      {/* <div className="title-area">
        <h1>Analytics</h1>
      </div> */}

      <div className="upcoming-events-wrapper mb-30">
        <div className="no-result-found">
          <div className="no-result-img">
            <img src={userInfo?.profile_image_url} alt="searchEventImg" />
          </div>
          <h3 className="no-event-hd"> No Analytics At This Time.</h3>
          <p className="event-para">Please check back later.</p>
        </div>
      </div>
    </>
  );
}
