import axios from 'axios';
import { GET_HASHTAGS, GET_HASHTAG, HASH_PAGINATION } from '../types/types';
import { BASEURL } from '../../config';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getHashtags = () => async (dispatch) => {
  axios
    .post(
      `${BASEURL}graph/hash/getall`,
      {},
      {
        headers: { Authorization: `Bearer ${userInfo?.token}` },
      }
    )
    .then((res) => {
      dispatch({
        type: GET_HASHTAGS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_HASHTAGS,
        payload: [],
      });
    });
};

export const createHashtag = (hashtag) => async (dispatch) => {
  const res = axios.post(
    `${BASEURL}graph/hash/create`,
    {
      hashtag: hashtag.toLocaleLowerCase(),
    },
    { headers: { Authorization: `Bearer ${userInfo?.token}` } }
  );
  return res;
};

export const deleteHash = (hashtag_id) => async (dispatch) => {
  return axios.post(
    `${BASEURL}graph/hash/delete`,
    {
      hashtag_id,
    },
    { headers: { Authorization: `Bearer ${userInfo?.token}` } }
  );
};

export const clearHashtag = () => async (dispatch) => {
  dispatch({
    type: 'clearHashtag',
  });
};

export const getHashtag = (data, page, pagination) => async (dispatch) => {
  const res = await axios.post(`${BASEURL}graph/hash/getone`, data, {
    params: {
      limit: 50,
      page,
    },

    headers: { Authorization: `Bearer ${userInfo?.token}` },
  });
  if (pagination) {
    dispatch({
      type: HASH_PAGINATION,
      payload: res.data,
    });
  } else {
    dispatch({
      type: GET_HASHTAG,
      payload: res.data,
    });
  }
};
