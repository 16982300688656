import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_ERROR,
  POST_LOGIN_OPEN_SUCCESS,
  POST_BRAND_REGISTER_REQUEST,
  POST_BRAND_REGISTER_SUCCESS,
  POST_BRAND_REGISTER_ERROR,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

export const postLogin = (data) => (dispatch) => {
  dispatch({ type: POST_LOGIN_REQUEST });
  return axios({
    method: "POST",
    url: `${BASEURL}signin/user`,
    headers: {
      Accept: "application/json",
    },
    data: {
      email: data.username,
      password: data.password,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_LOGIN_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_LOGIN_ERROR,
        payload: error.response,
      });
    });
};

export const getBrandRegister = (data) => (dispatch) => {
	dispatch({ type: POST_BRAND_REGISTER_REQUEST });
	return axios({
		method: "POST",
		url: `${BASEURL}signup/user`,
		headers: {
			Accept: "application/json",
		},
		data: {
			account_type: "brand",
			name: data.name,
			email: data.email,
			gender: data.gender,
			country: data.country,
			state: data.state,
			city: data.city,
			password: data.password,
			device: "web",
			referred_by: data.refferedBy,
			zip: data.zip,
			coming_from_app: false,
		},
	})
		.then((response) => {
			return dispatch({
				type: POST_BRAND_REGISTER_SUCCESS,
				payload: response.data,
			});
		})
		.catch((error) => {
			return dispatch({
				type: POST_BRAND_REGISTER_ERROR,
				payload: error.response,
			});
		});
};


export const openLogin = (status) => (dispatch) => {
  return dispatch({
    type: POST_LOGIN_OPEN_SUCCESS,
    payload: status,
  });
};
