import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useStepsForm } from 'sunflower-antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getShopifyDetail,
  getProducts,
  postShopify,
} from '../../../redux/actions/shopify.action';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import shopifyLogo from '../../../images/shopify-logo.png';
import oracleLogo from '../../../images/oracle-logo.png';
import magentoLogo from '../../../images/magento-logo.png';
import ordercloudLogo from '../../../images/ordercloud-logo.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { notification, Select, Form, Input, Spin, Button } from 'antd';
import { BASEURL } from '../../../config';

const { Option } = Select;

function EcommerceSetup() {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState('add');
  const [spinner, setSpin] = useState(false);
  const [store, setStore] = useState('');

  const { getShopifyDetails } = useSelector((state) => {
    return state;
  });
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const [formStore] = Form.useForm();

  const syncData = () => {
    Swal.fire({
      title: `Are You Sure You Want To Sync Products?`,
      icon: 'warning',
      cancelButtonText: 'No',
      showCancelButton: true,
      confirmButtonColor: '#010b40',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes`,
    }).then((result) => {
      if (result.isConfirmed) {
        setSpin(true);
        dispatch(getProducts())
          .then((res) => {
            notification.success({
              message: 'Product Sync',
              className: 'toast-success',
            });
            setSpin(false);
          })
          .catch((err) => {
            notification.error({
              message: 'Sync Failed',
              className: 'toast-error',
            });
            // toast.error(err.response.data.message);
            console.log(err.response, 'err');
          });
      }
    });
  };

  const {
    form,
    current,
    gotoStep,
    stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {
      let data = {
        apiKey: values.apiKey,
        password: values.token,
        shopName: values.shopUrl,
        storefront_access_token: values.storefrontKey,
      };

      dispatch(postShopify(data)).then((res) => {
        if (res.type === 'POST_SHOPIFY_SUCCESS') {
          notification.success({
            message: 'Successfull',
            className: 'toast-success',
          });
        } else {
          notification.error({
            message: 'Failed',
            className: 'toast-error',
          });
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getShopifyDetail()).then((res) => {
      if (res.type == 'GET_SHOPIFY_DETAIL_SUCCESS') {
        form.setFieldsValue({
          shopUrl: res?.payload?.message?.shopify?.shop_name,
          apiKey: res?.payload?.message?.shopify?.api_key,
          token: res?.payload?.message?.shopify?.password,
          storefrontKey:
            res?.payload?.message?.shopify?.storefront_access_token,
        });
        setFormState('edit');
        // handleClose();
      } else {
      }
    });
  }, []);

  const formList = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">Shopify Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={shopifyLogo} alt="shopifyLogo" />
              </div>
              <div></div>
            </div>
            <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
              <div className="row  d-flex align-items-center">
                <div className="col-sm-5 mb-30">
                  <h5 className="mb-0">Enter Shopify Detail</h5>
                </div>

                <div className="col-sm-7 mb-30 d-flex align-items-center justify-content-end">
                  {getShopifyDetails?.payload?.connection ? (
                    <Link
                      to="#"
                      className="default-btn small-btn-width d-inline-flex align-items-center justify-content-center"
                      onClick={() => syncData()}
                    >
                      <FontAwesomeIcon icon={faRotate} />
                      {/* <Spin spinning={spinner}> */}

                      <span className="nav-text">Sync</span>
                      {/* </Spin> */}
                    </Link>
                  ) : null}
                  {spinner && (
                    <div class="sync_loading">
                      <span className="loading_text">Loading</span>
                      &#8230;
                    </div>
                  )}
                  {getShopifyDetails?.payload?.message?.shopify ? (
                    <>
                      {getShopifyDetails?.payload?.connection ? (
                        <span className="connection-status-badge green-status">
                          Connected
                        </span>
                      ) : (
                        <span className="connection-status-badge red-status">
                          Not Connected
                        </span>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <Form.Item
                name="shopUrl"
                labelCol={{ span: 24 }}
                label="Enter Shop Url"
                initialValue={
                  formState === 'add' ? null : form.getFieldValue().shopUrl
                }
              >
                <Input
                  placeholder="Enter Shop Url"
                  //value={"test.myshopify.com"}
                  size="large"
                  // onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                name="apiKey"
                labelCol={{ span: 24 }}
                label="Enter API Key"
                initialValue={
                  formState === 'add' ? null : form.getFieldValue().apiKey
                }
              >
                <Input
                  placeholder="Enter API Key"
                  //value={"fdc316ef688dfdfae29ddfdf89651f584350"}
                  size="large"
                  // onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                name="token"
                labelCol={{ span: 24 }}
                label="Enter Admin API Access Token"
                initialValue={
                  formState === 'add' ? null : form.getFieldValue().token
                }
              >
                <Input.Password
                  placeholder="Enter Admin API Access Token"
                  //value={"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"}
                  size="large"
                  // onChange={onChange}
                />
              </Form.Item>
              <Form.Item
                name="storefrontKey"
                labelCol={{ span: 24 }}
                label="Enter StoreFront Access Token"
                initialValue={
                  formState === 'add'
                    ? null
                    : form.getFieldValue().storefrontKey
                }
              >
                <Input.Password
                  placeholder="Enter StoreFront Access Token"
                  //value={"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"}
                  size="large"
                  // onChange={onChange}
                />
              </Form.Item>
              <Link
                to="#"
                className="default-btn d-inline-flex m-0  align-items-center justify-content-center m-0"
                onClick={submit}
              >
                <span className="nav-text">Save</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const formOracle = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">Oracle Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={oracleLogo} alt="oracleLogo" />
              </div>
              <div></div>
            </div>
            <div
              className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling"
              style={{ height: 493 }}
            ></div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const formMagento = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">Magento Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={magentoLogo} alt="magentoLogo" />
              </div>
              <div></div>
            </div>
            <div
              className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling "
              style={{ height: 493 }}
            ></div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const formOrdercloud = [
    <div layout="vertical">
      <div className="create-event-wrapper  mb-30">
        <div className="create-event-box-left full-width">
          <div className="row d-fle">
            <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch d-flex justify-content-between flex-column">
              <h4 className="rounded">OrderCloud Setup</h4>
              <div className="text-center d-none d-lg-block">
                <img src={ordercloudLogo} alt="ordercloudLogo" className='min-w-200' />
              </div>
              <div></div>
            </div>
            <div
              className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling "
              style={{ height: 493 }}
            ></div>
          </div>
        </div>
      </div>
    </div>,
  ];

  const storeChange = (value) => {
    setStore(value);
  };

  const exit = () => {
    setStore('');
    formStore.resetFields();
  };
  return (
    <>
      <div class="title-area">
        <h1 class="page-title">Ecommerce Setup</h1>
      </div>
      <Form form={formStore}>
        <div className="row">
          <div className="col-xl-3 col-md-6 col-12">
            <Form.Item
              labelCol={{ span: 24 }}
              label="Select Store"
              name="store"
            >
              <Select
                className="select-style dark-field store-select"
                placeholder="Select Store"
                size="large"
                focus={false}
                onBlur={false}
                onChange={storeChange}
                // value={store}
                // defaultValue="shopify"
              >
                <Option value="shopify">Shopify</Option>
                <Option value="oracle">Oracle</Option>
                <Option value="magento">Magento</Option>
                <Option value="ordercloud">orderCloud</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end">
            <Form.Item className="d-flex align-items-end">
              {store && (
                // <div className="col-xl-3 col-md-6 col-12">
                <Button
                  className="default-btn medium-btn fltr-hpr m-0"
                  onClick={() => exit()}
                >
                  Exit
                </Button>
                // </div>
              )}
            </Form.Item>
          </div>
        </div>
      </Form>
      {store === 'shopify' && <Form {...formProps}>{formList[current]}</Form>}
      {store === 'oracle' && <Form {...formProps}>{formOracle[current]}</Form>}
      {store === 'ordercloud' && <Form {...formProps}>{formOrdercloud[current]}</Form>}
      {store === 'magento' && (<Form {...formProps}>{formMagento[current]}</Form>
      )}
    </>
  );
}
export default EcommerceSetup;
