import {
  GET_CHAT_TOKEN,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_ERROR,
  SAVE_CHAT_REQUEST,
  SAVE_CHAT_SUCCESS,
  SAVE_CHAT_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
export const createChatToken = (event_id) => (dispatch) => {
  return axios({
    method: 'POST',
    url: `${BASEURL}chat/gettoken`,
    data: { event_id },
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    // headers: {
    // 	Accept: "application/json",
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHAT_TOKEN,
        payload: response.data?.message,
      });
    })
    .catch((error) => {
      alert(JSON.stringify(error.response));
    });
};

export const getChat = (event_id) => (dispatch) => {
  dispatch({ type: GET_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}chat/messages`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { event_id },
  })
    .then((response) => {
      return dispatch({
        type: GET_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_CHAT_ERROR,
        payload: error.response,
      });
    });
};

export const saveChat = (event_id, message) => (dispatch) => {
  dispatch({ type: SAVE_CHAT_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}chat/save`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { event_id, message },
  })
    .then((response) => {
      return dispatch({
        type: SAVE_CHAT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SAVE_CHAT_ERROR,
        payload: error.response,
      });
    });
};
