import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import Login from '../pages/login/login';
import Menus from './menus';
import { createBrowserHistory } from 'history';
import ForgotPassword from '../pages/forgot-password/forgotPassword';
import ResetPassword from '../pages/reset-password/resetPassword';
import PublicHome from '../pages/public/public-home';
import PublicAbout from '../pages/public/public-about';
import PublicPrivacyPolicy from '../pages/public/public-privacy-policy';
import PublicTermsUse from '../pages/public/public-terms-use';
import Register from '../pages/register/register';

const history = createBrowserHistory();
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const Router = () => {
  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <>
      <HistoryRouter history={history}>
        {!userInfo?.token ? (
          <Routes>
            <Route path="/" element={<PublicHome />} />
            <Route path="/about" element={<PublicAbout />} />
            <Route path="/privacy-policy" element={<PublicPrivacyPolicy />} />
            <Route path="/terms-use" element={<PublicTermsUse />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
            <Route
              path="/password-reset/:id"
              exact
              element={<ResetPassword />}
            />
            <Route path="*" element={<Redirect to="/" />} />
          </Routes>
        ) : (
          <Menus />
        )}
      </HistoryRouter>
    </>
  );
};
export default Router;
