import { BASEURL } from "../../config";
import axios from "axios";
import {
  GET_SUB_CATEGORIES,
} from "../types/types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getSubCategories = (categoryId) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(`${BASEURL}users/receive/subcategories?category_id=${categoryId}`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((res) => {
        dispatch({
          type: GET_SUB_CATEGORIES,
          payload: res.data?.message,
        });
        resolve(res.data?.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};


