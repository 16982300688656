import {
    GET_SHOPIFY_DETAIL_REQUEST,
    GET_SHOPIFY_DETAIL_SUCCESS,
    GET_SHOPIFY_DETAIL_ERROR,
    GET_PRODUCT_REQUEST,
    GET_PRODUCT_SUCCESS,
    GET_PRODUCT_ERROR,
    POST_SHOPIFY_REQUEST,
    POST_SHOPIFY_SUCCESS,
    POST_SHOPIFY_ERROR,
  } from "../types/types";
  import { BASEURL } from "../../config";
  import axios from "axios";
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  export const getShopifyDetail = () => (dispatch) => {
    dispatch({ type: GET_SHOPIFY_DETAIL_REQUEST });
  
    return axios({
      method: "GET",
      url: `${BASEURL}users/receive/shopify`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      // data: {
      // 	parent_id: id,  
      // },
    })
      .then((response) => {
        return dispatch({
          type: GET_SHOPIFY_DETAIL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_SHOPIFY_DETAIL_ERROR,
          payload: error.response,
        });
      });
  };


  export const getProducts = () => (dispatch) => {
    dispatch({ type: GET_PRODUCT_REQUEST });
  
    return axios({
      method: "GET",
      url: `${BASEURL}campaigns/receive/getproducts`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      // data: {
      // 	parent_id: id,  
      // },
    })
      .then((response) => {
        return dispatch({
          type: GET_PRODUCT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_PRODUCT_ERROR,
          payload: error.response,
        });
      });
  };

  export const postShopify = (data) => (dispatch) => {
    dispatch({ type: POST_SHOPIFY_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}users/revise/shopify`,
      headers: {
        Authorization: `Bearer ${userInfo?.token}`
      },
      data: data,
    })
      .then((response) => {
        return dispatch({
          type: POST_SHOPIFY_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: POST_SHOPIFY_ERROR,
          payload: error.response,
        });
      });
  };
