import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Input, Form, Affix, Switch } from "antd";

// const handleChange = (value) => {
//   console.log(`selected ${value}`);
// };

function StoreSettings() {
  useEffect(() => {}, []);

  let top = 15;

  return (
    <>
      <Affix offsetTop={top}>
        <div className="sticky-header-bg"></div>
        <div className="grid-listing-area mb-20">
          <div className="grid-listing-left"></div>
          <div className="grid-listing-right">
            <Link
              to="#"
              className="default-btn d-flex align-items-center justify-content-center m-0"
            >
              <span className="nav-text">Save</span>
            </Link>
          </div>
        </div>
      </Affix>
      <Form layout="vertical">
        <div className="create-event-wrapper  mb-30">
          <div className="create-event-box-left full-width">
            <div className="row d-flex">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Store Information</h5>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <h6>Store Name</h6>
                <p className="color-light mb-30">needoo</p>

                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Store Contact Email"
                  className="mb-40"
                >
                  <Input
                    placeholder="testemail@gmail.com"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
                <h6>Store Currency</h6>
                <p className="color-light">USD</p>
              </div>
            </div>
          </div>
        </div>
        <div className="create-event-wrapper  mb-30">
          <div className="create-event-box-left full-width">
            <div className="row d-flex">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Links for Legal Pages</h5>
                <p>These links will appear at checkout</p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item labelCol={{ span: 24 }} label="Terms of Services">
                  <Input
                    placeholder="https://example.com/terms-of-services"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Privacy Policy">
                  <Input
                    placeholder="https://example.com/privacy-policy"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Refund Policy">
                  <Input
                    placeholder="https://example.com/refund-policy"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Shipping Policy">
                  <Input
                    placeholder="https://example.com/shipping-policy"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="create-event-wrapper  mb-30">
          <div className="create-event-box-left full-width">
            <div className="row d-flex">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Google Analytics</h5>
                <p>
                  Learn more about which Google Analytic Events we are using.
                </p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item
                  labelCol={{ span: 24 }}
                  label="Google Analytics Account"
                  className="mb-30"
                >
                  <Input
                    placeholder="Paste your code here (eg: UA-000000-2 or G-AAAAAAAAAA)"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
                <h6>Enhanced Ecommerce</h6>
                <p className="color-light">
                  You must upgrade to the latest version of Google Analytics in
                  order to use Enhanced Ecommerce.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="create-event-wrapper  mb-30">
          <div className="create-event-box-left full-width">
            <div className="row d-flex">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Facebook Pixel</h5>
                <p>Learn more about which Facebook Events we are using.</p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <Form.Item labelCol={{ span: 24 }} label="Facebook Pixel ID">
                  <Input
                    placeholder="Paste your code here (eg: 492123017076692)"
                    size="large"
                    // onChange={onChange}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="create-event-wrapper  mb-30">
          <div className="create-event-box-left full-width">
            <div className="row d-flex">
              <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                <h5 className="rounded">Closed Captions</h5>
                <p>Available only for Events that are in English</p>
              </div>
              <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                <h6>Offer Closed Captions in your Live Events</h6>
                {/* <p className="color-light mb-1">Countdown</p> */}
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
export default StoreSettings;
