import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../../css/public/public.scss";
import "../../css/public/brand.scss";
import "../../css/public/about_us.scss";
import PublicHeader from "../../components/public/header/header";
import PublicFooter from "../../components/public/footer/footer";

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export default function PublicTermsUse() {
  return (
    <>
      {!userInfo && <PublicHeader />}

      <div className={`general-section ${userInfo && "p-0"}`}>
        <div className="mid-width max-1140">
          <div className="privacy_header">
            <h3>Terms of Use</h3>
            <p>(Effective July 1, 2022)</p>
            <h5>1. Introduction, Acceptance, Definitions, and Modifications</h5>
            <p>Welcome to KBLIVE! We hope you will enjoy and appreciate using our “Services”, which may be visiting or using the website at <a className="highlight-txt" href="https://www.kblive.com/" target="_blank">https://www.kblive.com/</a> or any subdomain thereof (the “Website”), or participating in a Live Shopping “Event” (which you may be running) that may occur on a variety of platforms. Events may include the purchase of “Products” facilitated by the Services.</p>
            <p>
              <strong>
                By interacting with the Services in any way, by clicking or tapping the acceptance button upon signing up for an account, or by registering for an account, you hereby accept to be bound by these Terms of Use (“TOU”) without any reservations, modifications, additions or deletions. If you do not agree to all the provisions contained in the TOU, you are not authorized to use the Services. The TOU are a legal and binding agreement between you and us.
              </strong>
            </p>
            <p>
              <strong>
                If you accept or agree to these TOU on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these TOU and, in such event, “you” and “your” will refer and apply to that company or other legal entity.
              </strong>
            </p>
            <p>The Services are owned and operated by KBLIVE Technologies Inc., a corporation located in New York, USA at the address listed below. Where the present TOU refer to “<strong>KBLIVE</strong>” they may refer to KBLIVE Technologies Inc. and / or its affiliates, and their respective shareholders, officers, directors, employees, agents, partners, principals, representatives, successors and assigns (collectively “<strong>Representatives</strong>”), depending on the context. Any reference to “<strong>we</strong>”, “<strong>our</strong>”, or “<strong>us</strong>” in these TOU shall also refer to KBLIVE and / or its Representatives.</p>
            <p>In these TOU, a Services user may be referred to as “<strong>you</strong>” or “<strong>your</strong>”. When a Services user has created an account for the creation and operation of Events, they may be referred to as a “<strong>Merchant</strong>”. When a Services user is viewing or participating in an Event they may be referred to as a “<strong>Shopper</strong>”.</p>
            <p>KBLIVE reserves the right, at any time and without prior notice, to modify or replace any of the TOU. Any changes to the TOU can be found at this URL. It is your responsibility to check the TOU periodically for changes. Your use of the Services following the posting of any changes to the TOU constitutes acceptance of those changes. If we make any substantial changes to the TOU that materially affect your legal relationship with KBLIVE, we will use commercially reasonable efforts to notify you by posting a prominent notice when you use the Services for the first time following those changes, or if you have an account, by email to the email address associated with your account.</p>
            <p>The TOU should be read in conjunction with the Privacy Policy, as both these documents govern your use of the Services.</p>
            <h5>2. General Code of Conduct for Use of the Services</h5>
            <p>In addition to the more specific rules found elsewhere in these TOU, you agree that by interacting with the Services in any way, you will:</p>


            <ul className="list-simple">
              <li>Not use the Services in any manner that in any way violates these TOU or any other applicable policy posted on or in the Services by KBLIVE;</li>
              <li>Not use the Services in any manner that violates any intellectual property rights of KBLIVE or any third party;</li>
              <li>Not use the Services in any manner to propagate spam, including but not limited to unsolicited advertising or bulk electronic mail or messages, including to link to a spam or phishing website;</li>
              <li>Not use the Services in any manner to propagate software viruses, Trojan horses, worms, or any other malicious or non-malicious computer code, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment in any form whether belonging to KBLIVE or a third party, or to damage or obtain unauthorized access to any system, data, password or other information (whether personal data or not) of KBLIVE, other Services users, or any other third party;</li>
              <li>Not:
                <br />
                (1) take any action that imposes or may impose (as determined by KBLIVE in its sole discretion) an unreasonable or disproportionately large load on KBLIVE’s (or its third party providers’) infrastructures;
                <br />
                (2) interfere or attempt to interfere with the proper functioning of the Services or any activities conducted on or via the Services;
                <br />
                (3) bypass any measures KBLIVE may use to prevent or restrict access to the Services or any element thereof;
                <br />
                (4) use manual or automated software, devices, or other processes to “crawl” or “spider” any page or portion of the Services; or
                <br />
                (5) harvest or scrape any content from the Services in an unreasonable manner;</li>
              <li>Not take any action or use any process that removes, modifies, disables, blocks, obscures or otherwise impairs any advertising or other promotions in connection with the Services;</li>
              <li>Not use the Services to in any way collect information (whether personal information or not) of any third party or in violation of our Privacy Policy;</li>
              <li>Not use the Services to advertise or promote products or services that are not expressly approved in advance in writing by KBLIVE, or as permitted by these TOU and your account;</li>
              <li>Not interfere with any third party’s use or enjoyment of the Services;</li>
              <li>Not do anything or encourage conduct that would constitute a criminal offense or give rise to civil liability, or is any way unlawful, illegal, fraudulent or harmful to any third party;</li>
              <li>Not attempt to do any of the foregoing prohibitions;</li>
              <li>Use the Services in good faith, and in compliance with all applicable local, provincial or state, national, and international laws.</li>

            </ul>
          <h5>3. Accounts</h5>
          <h6>a. Accounts and Passwords</h6>
          <p>In order to be able to create ad operate Events through the Services, you will be required to create an account. Accounts are available to anyone who provides the requisite information, subject to the restrictions and conditions as outlined elsewhere in these TOU. Accounts may be approved or rejected by KBLIVE, which shall be at our sole discretion.</p>
          <p>As part of the account registration, you may be asked to choose a password. It is your responsibility to create a password of sufficient strength that cannot easily be discovered by third parties. You are responsible for maintaining the confidentiality of your password, and are responsible for all activities that occur under your account whether by you or any third party. You agree to immediately notify us of any unauthorized use of your account or any other breach of security regarding your account.</p>
          <p>If you are a Merchant, it is strongly suggested that you log out of your account at the end of every session, or not leave a logged-in account unattended for any period of time. KBLIVE and its Representatives will not be held liable for any losses or damages, direct or indirect, pecuniary or non-pecuniary, resulting from your failure to log out at the end of the session, an unattended logged-in session, or a third party using the Services with your Account Information and accessing your account through any means, and disclaims any responsibility in this regard.</p>
          <p>KBLIVE reserves the right to suspend or terminate your account, at its sole discretion, at any time and for any reason, including but not limited to whether you have violated the letter or spirit of the TOU, as more fully described here in below.</p>
          <h6>b. Age Requirements</h6>
          <p>In order to create an account on the Services, you affirm that you are at least eighteen (18) years of age or over, or the age of majority in the jurisdiction you reside and from which you access the Services where the age of majority is greater than eighteen (18) years of age.</p>
          <h6>c. Account Information</h6>
          <p>The information required to create an account is the following: first name, last name, company and email address. This information you submit as part of the sign-up process may be referred to in the present TOU or the Privacy Policy as “<strong>Account Information</strong>”.</p>
          <p>By submitting Account Information, you represent and warrant that the Account Information is true and accurate to the best of your knowledge. Submitting false or misleading Account Information may result in you being banned from using the Services, at our sole discretion. </p>
          <h5>4. Using the Services to Create Events for Merchants</h5>
          <h6>a. Free Trial</h6>
    <p>Under certain circumstances, you may begin your use of the Services to create Events with a free trial. The free trial period of your account lasts for seven days, or as otherwise specified during sign-up. Free trials are for new users only, and applies to the Subscription Fees.</p>
    <p>Transaction Fees still applies during free trial. KBLIVE reserves the right, in its absolute discretion, to determine your free trial eligibility. We will begin billing for the Fees at the end of the free trial period for your use of the Services, as more fully described further in this section of these TOU.</p>
    <h6>b. E-Commerce Platforms</h6>
    <p>As a Merchant, you may be accessing the Services to create Events through a third-party “<strong>E-Commerce Platform</strong>”, including Shopify and Salesforce Commerce Cloud, the full list of which is available on the Website or from KBLIVE. KBLIVE and the E-Commerce Platforms are unaffiliated companies and KBLIVE has no influence on the operations of the E-Commerce Platforms. KBLIVE and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary or otherwise, resulting from any error or failure on the part of the E-Commerce Platform through which you are accessing the Services.</p>
    <h6>c. Fees</h6>
    <p>Upon signing up for an account, you will be presented with the “Fees” that will be charged to you for use of the Services to create and run Events. The Fees are of two types:
      <br/>
       (1) “<strong>Subscription Fees</strong>” which are monthly or annual Fees paid for access to the Services; and
       <br/>
       (2) “<strong>Transaction Fees</strong>” which may be:
       <br/>
       (i) a percentage of the sales from an Event;
       <br/>
       (ii) a flat Fee for each Shopper at an Event; or
       <br/>
       (iii) a combination of both. The details of the different Fees and their payment schedules can be found further in this section.</p>
<p>The Fees displayed to you are firm and in United States dollars, which may be converted to other currencies at the time of payment processing, depending on your country of origin and/or credit card account agreement. Fees may not include applicable taxes, which shall be displayed when your credit card is charged, depending on your location.</p>
<p>By signing up for an account you agree to pay the Fees presented to you upon signing up, plus any applicable taxes.</p>
<h6>d. Billing Information and Payment Processing</h6>
<p>In order for you to pay the Fees to use the Services, you will be required to enter the credit card holder name, credit card number, credit card expiry date, card security code (CVV), and billing address. This may be referred to in these TOU or the Privacy Policy as “<strong>Billing Information</strong>”. Your Billing Information must match the information associated with your credit card for your payments to be processed.</p>
<p>All payments for Fees using a secure https:// connection, and payment processing is handled through the “<strong>Third-Party Payment Processors</strong>”, such as <a className="highlight-txt" href="https://stripe.com/" target="_blank">Stripe</a> though these are subject to change without notice. The Third-Party Payment Processors currently accept certain credit and debit cards as payment options, but these are subject to change without notice. Once transactions are accepted by a Third-Party Payment Processor, they are processed in accordance with their program rules and procedures and Terms of Use. KBLIVE and the Third-Party Payment Processors are unaffiliated companies and KBLIVE has no influence on the operations of the Third-Party Payment Processor. KBLIVE and / or its Representatives shall in no way be held responsible for any losses or damages, direct or indirect, pecuniary or otherwise, resulting from any error or failure on the part of a Third-Party Payment Processor.</p>
<p>All Billing Information is collected by the Third-Party Payment Processors, on their own secured servers. KBLIVE does not have access to any credit card information, nor can it be responsible for any breach of information caused by faulty programming or malicious users on the servers of the Third-Party Payment Processors. Non-financial information will, however, be available to KBLIVE for invoice-making and record-keeping purposes.</p>
<h6>e. Subscription Fees</h6>
<p>You will be charged the Subscription Fees on the day you sign up for an account or on the day following the end of your free trial period if a free trial period is applicable and clearly stated upon signing up (the “<strong>Initial Billing Date</strong>”). The Subscription Fees shall subsequently be charged on the monthly or yearly anniversary of the Initial Billing Date, as applicable. The payment period of your subscription, whether a month or a year, shall be referred to in the present TOU as a “<strong>Billing Period</strong>”.</p>
<p><strong>Your subscription automatically renews at the end of every Billing Period</strong>. If you wish to cancel your account and avoid paying the Subscription Fees for the next Billing Period, you must do so prior to the end of the current Billing Period so that your credit card or other payment method will not be charged again. You can cancel your account and avoid paying the Subscription Fees by emailing <a className="highlight-txt" href="mailto:admin@kbshop.com" target="_blank">admin@kbshop.com</a> or cancelling through your Services dashboard, in the account management section.</p>
<p>If you cancel your account prior to the end of the Billing Period, you shall not be entitled to any refund of any Subscription Fees already paid for that Billing Period.</p>
<h6>f. Transaction Fees</h6>
<p>The Transaction Fees are calculated automatically upon conclusion of an Event. Once the Event is completed, your credit card will be charged the amount calculated, and you will receive an email notification of the amount charged. By signing up for an account, you authorize KBLIVE to charge your credit card the amount of the Transaction Fees for each Event, plus any applicable taxes.</p>
<h6>g. Failure of Automatic Payment</h6>
<p>Should automatic payment of any Subscription Fees or Transaction Fees fail to occur for any reason, your account will be suspended, and KBLIVE will issue you an electronic invoice via email indicating that you must proceed manually, within a certain deadline date, with the full payment of the Fees as indicated on the invoice. Your account will be reactivated upon receipt of the payment.</p>
<h6>h. Modification of the Fees</h6>
<p>KBLIVE, in its sole discretion and at any time, may modify the Subscription Fees and/or the Transaction Fees. Any Subscription Fees and/or Transaction Fees modification(s) will become effective at the end of the then-current Billing Period. KBLIVE will provide you with a reasonable prior notice of any change in Subscription Fees and/or Transaction Fees to give you an opportunity to terminate your account before such modification(s) becomes effective. Your continued use of the Services after the Subscription Fees and/or Transaction Fees modification(s) comes into effect constitutes your agreement to pay the modified Subscription Fees and/or Transaction Fees.</p>
<h6>i. Monthly client sessions</h6>
<p>All sessions (webinar, 1-to-1 calls, and other expressly stated by KBLIVE) included in each client membership plan are valid for month of issuance only and should be redeemed during the 30 days in which they are allotted. After the 30-day period, a new number of sessions will be available, as determined in the client's monthly subscription plan. In no instance can monthly sessions be “banked,” or saved/compounded for later/future use. KBLIVE will ensure to communicate this urgency to clients upon initial issuance of membership, and it is the client's responsibility to sign up and use these sessions accordingly and in good time. </p>
<h5>5. Event Use Policy</h5>
<p>As a Merchant, when using the Services to create and run an Event, you acknowledge and agree that your Event will respect the prohibitions as outlined in this section, which may be referred to as the “<strong>Event Use Policy</strong>”.</p>
<p>As a Merchant, when using the Services to create and run an Event, you acknowledge and agree that your Event shall in no way be related to the following activities or that any Product sold relates to the following industries:</p>


<ul className="list-simple">
<li>The sale of narcotics, drugs, prescription drugs, research chemicals or any other controlled substances, or drug paraphernalia;</li>
<li>The sale of stolen or counterfeit goods, whether physical or virtual;</li>
<li>The sale of any items, whether physical or virtual, that infringe or violate any intellectual property rights including but not limited to copyrights, trademarks, trade secrets, or </li>patents, or any items (including any software or hardware) which facilitate the infringement or violation of intellectual property rights;
<li>The sale of ammunition, firearms, explosives (including fireworks), firearm parts, or other weapons regulated under applicable law or as determined by KBLIVE;</li>
<li>The sale of any Product that promotes hate, violence, or racial intolerance,</li>
<li>The sale of any Product that encourages, promotes, facilitates or instructs others to engage in an illegal activity;</li>
<li>The sale of any personal information (including but not limited to financial information) of any third party;</li>
<li>The sale or rental of any pornography or other adult content;</li>
<li>Any gambling or related activities, or Products related to same;</li>
<li>Any pyramid, Ponzi, or other “get rich quick” schemes, or certain multi-level marketing programs;</li>
<li>The sale or rental of any software, software service or hardware that includes malware, viruses, Trojan horses, worms, or any other malicious or non-malicious computer code, files, or programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications equipment in any form; or</li>
<li>Any activity or Product that violates any applicable law.</li>
</ul>
<p>KBLIVE and its Representatives will not be held liable for any damages, pecuniary or otherwise, to you or any third party arising from your failure to adhere to the Event Use Policy, and disclaims any responsibility in this regard. KBLIVE reserves the right to terminate your account, immediately and without notice, for any violation of the Event Use Policy. In the event that KBLIVE becomes aware that this Event Use Policy is violated during a live Event, KBLIVE reserves the right to immediately terminate the Event without notice.</p>
<p>In addition to the Event Use Policy as found in this section, as a Merchant your Events are also subject to the User Content Policy as found further in these TOU.</p>
<h5>6. Live Shopping Disclaimer for Shoppers</h5>
<p>If you are interacting with the Services as a Shopper during an Event, the purchase of Products is not controlled by KBLIVE but by the Merchant running the Event. If you have any issues with or questions about the Event, or the Products sold through the Event, or any Products you have purchased during an Event, you should contact the Merchant who ran the Event.</p>
<p>Without limiting the generality of the Disclaimer of Warranties further in these TOU, KBLIVE and / or its Representatives shall not be responsible for any loss or damage, pecuniary or otherwise, resulting from your interaction with a Merchant or your purchase of Products from the Merchant, and KBLIVE disclaims any responsibility thereto.</p>
<h5>7. Proprietary Rights and User Content Rights</h5>
<h6>a. Intellectual Property Rights</h6>
<p>You acknowledge that: (a) the Services contain proprietary and confidential information that are protected by applicable intellectual property and other laws, and (b) KBLIVE and/or third parties (via license) own all right, title and interest in and to the Services and content that may be presented or accessed through the Services (except for User Content as defined below), including without limitation all Intellectual Property Rights therein and thereto. All rights not specifically granted under these TOU are reserved to KBLIVE and its licensors. “<strong>Intellectual Property Rights</strong>” means any and all rights existing from time to time under patent law, copyright law, trade secret law, trademark law, unfair competition law, and any and all other proprietary rights, and any and all applications, renewals, extensions and restorations thereof, now or hereafter in force and effect worldwide, whether registered or not.</p>
<p>You agree that you will not, and will not allow any third party, to (i) copy, sell, license, distribute, transfer, modify, adapt, translate, prepare derivative works from, decompile, reverse engineer, disassemble or otherwise attempt to derive source code from, the Services, or content that may be presented or accessed through the Services for any purpose, unless otherwise permitted by these TOU or the functionalities of the Services; (ii) take any action to circumvent or defeat the security deployed or enforced by any functionality contained in the Services; or (iii) remove, obscure, or alter KBLIVE's or any third party's copyright notices, trademarks, or other proprietary rights notices affixed to or contained within or accessed in conjunction with or through the Services.</p>
<p>The content, arrangement and layout of the Services, including but not limited to the trademarks, photos, logos, videos, audio, images, text (in the form of plain text, HTML, or PDFs) and Computer Code are proprietary to KBLIVE, either owned or under license, and may not be copied, imitated, reproduced, displayed, distributed, transmitted, decompiled or otherwise used without the express permission of KBLIVE, or as permitted by the functionality of the Services or these TOU. Any unauthorized use of the content, arrangement or layout of the Services, Computer Code, images, logos, videos, audio or trademarks found in the Services or any portion of or derivative works thereof may violate civil or criminal laws, including but not limited to intellectual property laws, and KBLIVE may take action accordingly.</p>
<p>The above paragraph further applies to third party property used as part of the Services, including but not limited to third party Computer Code. For the purposes of the present section, “Computer Code” includes but is not limited to source code in any programming language, object code, frameworks, CSS, PHP, JavaScript or similar files, templates, modules, or any similar files, and related documentation.</p>
<h6>b. Submitted Information</h6>
<p>If you choose to communicate to KBLIVE (via any means) suggestions for improvements to the Services or any idea or proposal related to KBLIVE or its businesses or properties (collectively, “<strong>Feedback</strong>”), KBLIVE shall own all right, title, and interest in and to the Feedback and will be entitled to use the Feedback without restriction. You hereby irrevocably assign all right, title and interest in and to the Feedback to KBLIVE and waive in favor of KBLIVE, its successors and assigns all your moral rights in the Feedback; and agree to provide KBLIVE such assistance as KBLIVE may require to document, perfect, and maintain KBLIVE's rights to the Feedback. You acknowledge and agree that, by providing any Feedback to KBLIVE, you are not entitled to any compensation or reimbursement of any kind from KBLIVE under any circumstances.</p>
<h6>c. User Content Including Events</h6>
<p>As a Shopper, you may use the Services to create and submit text, including but not limited to chat, which may be visible to other Shoppers or third parties in various ways. As a Merchant creating Events, you may upload certain file types including but not necessarily limited to text, photographs, graphics, video, audio or any other type of media or content, and other interactive features generated, provided, or otherwise made accessible on or through the Events, in whatever form, which may be live or pre-recorded. All of the foregoing shall be deemed “<strong>User Content</strong>”. By creating, submitting and / or uploading User Content, you agree to be bound by the KBLIVE policy for User Content as described in this section (the “<strong>User Content Policy</strong>”).</p>
<p>When you submit User Content, or display User Content during an Event, you acknowledge and agree to the following:</p>
<ul className="list-simple">
  <li>User Content submitted or displayed by you does not violate any intellectual property right or other proprietary right of a third party. Without limiting the generality of the foregoing, you acknowledge that any User Content submitted or displayed is your own original works, or works for which you have been granted a valid license to reproduce or display. You affirm, represent and warrant that: (1) you own or have the necessary licenses, rights, consents, and permissions to use and authorize KBLIVE to use all intellectual property rights and other proprietary rights in and to the User Content (or the individual constituent elements thereof) as set out herein; and (2) have all necessary consents to collect, use and disclose any personally identifiable information and images (of yourself or third parties) contained or displayed in the User Content to enable inclusion and use of the User Content in the manner contemplated by the Services and these TOU;</li>
  <li>You retain all of your ownership rights in the User Content you submit or display. However, by submitting User Content to KBLIVE, you hereby grant KBLIVE a worldwide, non-exclusive, royalty-free, sublicenseable and transferable license to use, edit, translate, reproduce, distribute, display, and / or perform and otherwise fully exploit the User Content in connection with the Services and / or KBLIVE's (and its successors' and affiliates') business or activities, including without limitation for promoting and redistributing part or all of the Services (and derivative works thereof) in any media formats and through any media channels without any compensation to you. You understand and agree that KBLIVE may retain, but not display or distribute, server copies of User Content that have been removed or deleted;</li>
  <li>You also hereby waive any moral rights you may have in your User Content and grant other users of the Services a non-exclusive license to access your User Content, and to use, reproduce, distribute, display and / or perform such User Content as permitted through the functionality of the Services and under these TOU;</li>
  <li>The licenses granted by you in the previous two paragraphs in regards the User Content are perpetual and irrevocable, and apply throughout the world and in any and all media as applicable;</li>
  <li>You will not upload or display any User Content that is unlawful, such as User Content that is threatening, abusive, harassing, defamatory, libelous, fraudulent, invasive of another's privacy, or otherwise tortuous. Furthermore, you will not upload or display any User Content that is racist, sexist, homophobic, or otherwise offensive to any minority or group; contains any adult content or political militancy; or upload or display any User Content that contains any viruses, mass mailing, chain mailing or any other form of spam;</li>
  <li>You will not include in User Content links to any third-party website that is a spam or phishing website, or to any website that may violate any applicable law.</li>
</ul>
<p>Where you are accessing or downloading User Content, you acknowledge that any use of User Content accessed or downloaded by you while using the Services is at your own risk and you will be solely responsible for any damage or loss to you or any third party arising therefrom, and KBLIVE disclaims any responsibility in this regard.</p>
<p>If you as a Website visitor or Shopper believe that a user has posted User Content that violates these TOU, please contact us at <a className="highlight-txt" href="mailto:admin@kbshop.com" target="_blank">admin@kbshop.com</a> so that we may conduct an inquiry. KBLIVE retains absolute discretion whether to remove or modify any User Content or take any other action pursuant to your report.</p>
<p>Depending on the Services, User Content may or may not be edited, curated or otherwise modified or checked by KBLIVE prior to posting or display. KBLIVE reserves the right, in its sole discretion, to refuse to display any User Content, or to remove or modify immediately and without notice any User Content that violates the User Content Policy. In the event that KBLIVE becomes aware that this User Content Policy is violated during a live Event, KBLIVE reserves the right to immediately terminate the Event without notice.</p>
<p>KBLIVE and its Representatives will not be held liable for any damages, pecuniary or otherwise, to you or any third party arising from your submission or use of User Content, whether it violates the User Content Policy or not, our removal or modification thereof, or any termination of an Event, and disclaims any responsibility in this regard.</p>
<h5>8. External Links</h5>
<p>From time to time KBLIVE may provide links to other websites or services. Links from the Services may take you to websites or services not covered by these TOU. When you access third party resources on the Internet in this manner, you do so at your own risk. KBLIVE provides those links as a convenience to you and KBLIVE takes no responsibility for your use of those other websites or services or protection of your privacy (including collection of your personal information) on those other websites or services. We encourage you to check the Terms of Use and / or Privacy Policy of any website or service you visit. KBLIVE does not make any claim or warranty whatsoever about the content of those websites or services to which we link, or any products or services available through those websites or the third parties operating those websites.</p>
<p>In no way will KBLIVE be held responsible for any damages, direct or indirect, pecuniary or non-pecuniary:
  <br/>
   (1) for your use of websites or other services that may be linked to from the Services or the information thereon;
   <br/>
   (2) for any virus, Trojan horse, worm or other similar destructive file received as a result of your use of those websites or services;
   <br/>
   (3) caused by or in connection with, use of or reliance on any content, or products or services (whether free or for purchase) available on or through any linked-to website or service; or
   <br/>
   (4) for the actions of the operators of any such website or service.</p>
<h5>9. Interruption of Services</h5>
<p>From time to time, the Services may be unavailable for periods of time for maintenance and / or modifications to the Services. While we will endeavour to make this unavailability as brief as possible, KBLIVE shall not be held liable for any losses or damages, pecuniary or non-pecuniary, resulting from the interruption of the normal functioning of the Services, and disclaims any responsibility thereto.</p>
<h5>10. Termination of the Services or Your Access to the Services and the TOU</h5>
<p>You agree that KBLIVE, in their sole discretion, with or without cause, has the right (but not the obligation) to block your IP address, revoke your account credentials, or otherwise terminate your access to or use of the Services (or any part thereof), immediately and without notice, if KBLIVE believes that you have acted inconsistently with the letter or spirit of the TOU or the Privacy Policy. In the event of the termination of your account as per this paragraph, you shall not be entitled to any refund of any Subscription Fees already paid for the current Billing Period.</p>
<p>You may also end your access to the Services by contacting us at <a className="highlight-txt" href="mailto:admin@kbshop.com" target="_blank">admin@kbshop.com</a>, or cancelling through your Services dashboard, in the account management section.</p>
<p>KBLIVE may also, in their sole discretion and at any time, discontinue providing the Services, or any part thereof, with or without notice. You agree that KBLIVE shall not be liable to you or any third party for any losses or damages, pecuniary or non-pecuniary, resulting from termination of your access to the Services, or from KBLIVE’s termination of the Services or any part thereof.</p>
<p>Termination of the Services or your access to the Services shall terminate the present TOU as between you and KBLIVE. All provisions of these TOU which by their nature should survive termination of these TOU shall survive termination, including without limitation, intellectual property provisions, disclaimers, indemnity and limitations of liability.</p>
<h5>11. Disclaimer of Warranties</h5>
<p>You expressly understand and agree that your use of the Services, the information thereon (whether provided by KBLIVE or third parties) or any activity arising from your use of the Services or the information thereon or the materials downloaded therefrom is at your sole risk. The Services, any materials downloaded therefrom, or any third party materials, are provided on an “as is” and “as available” basis, and you will be solely responsible for any damage to your computer system or loss of data that results from the download, stream or access of any material obtained through the use of the Services or any other functionalities of the Services, or losses or damages (financial or otherwise) resulting from your use of the Services, the information thereon, any materials downloaded therefrom, or any activity arising from the use of the Services, the information thereon or any materials downloaded therefrom.</p>
<p>The information or resources provided through the Services, written or produced by KBLIVE staff, freelance writers or other subcontractors hired by KBLIVE are expected to be as accurate as possible at the time of writing or production, and every effort has been made to ensure that the information from the Services are as accurate and up-to-date as possible. However, certain information may change, and errors or omissions may occur. KBLIVE shall not be responsible for any loss or damage, financial or otherwise, resulting from changes or errors in information, or any omission, on the Services or the information thereon, or your reliance on such information whether the information is correct or not.</p>
<p>KBLIVE expressly disclaims all warranties of any kind, whether express or implied, including but not limited to: warranties of title and non-infringement; warranties that the Services, the information thereon or any materials downloaded therefrom, and any third-party materials will be uninterrupted, error-free, accurate, reliable and free from virus and other harmful components; and the implied warranties of merchantability and fitness for a particular purpose. KBLIVE, its affiliates and their respective Representatives, do not warrant that: 
</p>
<p>
(i) the Services will be secure or available at any particular time or location;
<br/>
(ii) any defects or errors or omissions will be corrected;
<br/>
(iii) any content (whether provided by KBLIVE, Merchants or third parties) available at or through the Services are free of viruses or other harmful components; or
<br/>
(iv) the results of using the Services  or any content downloaded will meet your requirements.</p>

<p>Some of the content displayed on the Services may include elements that belong to or are provided by third parties. You acknowledge that KBLIVE assumes no responsibility for such content.</p>
<p>To the extent that the law does not permit a disclaimer of warranties, all content accessible on the Services, or any other website to which we link, and all operations on this Services are warranted only to the minimum amount legally required.</p>
<h5>12. Limitation of Liability</h5>
<p>In no case will KBLIVE or its Representatives or licensees be liable for any indirect, special, consequential, exemplary, punitive damages or other damages, or for any losses, damages, liabilities, costs and expenses arising out of or relating to </p>
<p>
  (i) your access, use, misuse or inability to access or use the Services or
  <br/>
  (ii) the interruption, suspension or termination of any part of or all the Services;
  <br/>
   and in both cases (i) and (ii) regardless of the cause of action (whether in contract, warranty, delict, quasi-delict, tort, negligence, strict liability or any other theory of liability) and even if we have been advised of the possibility of such damages. Notwithstanding anything to the contrary in the TOU, in no event will our aggregate liability for any claims in connection with your use of the Services and exceed the amount of the Fee you have pad in the previous six months.
  </p>
  <p>You expressly understand and agree that KBLIVE or its Representatives or licensees shall not be liable for any direct, indirect, incidental, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, in contract, tort, strict liability, negligence, general civil liability or any other cause of action under legal or equitable theory, relating to the Services, the information on the Services, your use of the Services, activities arising from your use of the Services, any third party materials on the Services, or any materials downloaded from the Services. This limitation of liability applies, without limitation, to any damages or injury caused by any error, omission or other failure of performance by KBLIVE or its Representatives; any interruption, defect or delay in operation or transmission, including communication line failure; any computer virus; and any theft, destruction or alteration of, or unauthorized access to or use of, any electronic records.</p>
  <p>Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages, so the above exclusions and limitations may or may not apply to you.</p>
  <h5>13. Indemnity</h5>
  <p>Notwithstanding any other term of the TOU or any act or failure to act by KBLIVE or its Representatives, you agree to indemnify, defend and hold harmless KBLIVE and its Representatives where applicable, from and against any damages, liabilities, costs and expenses (including attorneys’ fees), claims or demands, arising out of
  </p>
  <p>
     (i) your use of or connection to the Services, the information thereon, the information downloaded therefrom;
     <br/>
     (ii) your participation in any activities arising from the Services or the information thereon;
     <br/>
     (iii) your violation of, or failure to perform your obligations under the TOU or the Privacy Policy; or
     <br/>
     (iv) your violation of any rights of a third party.
     </p>
     {/* <h5>14. Governing Law and Applicable Jurisdiction</h5>
     <p>These TOU and your use of the Services shall be governed by and construed in accordance with the laws of the Province of Quebec and the federal laws applicable therein in force at the time without regard to conflict of law provisions. You agree that any legal action or proceeding between you and KBLIVE shall be brought exclusively in the courts located in the Judicial District of Montreal, Canada. Notwithstanding the foregoing, KBLIVE shall have the right to bring action against you in courts of competent jurisdiction in the jurisdiction in which you reside or are located:</p>
    <p>
    (i) to seek injunctive relief against you;
    <br/>
    (ii) to obtain a judgment against you where a judgment by the Montreal court will, or may not be, enforced by the jurisdiction in which you reside or are located; or
    <br/>
    (iii) to enforce in your jurisdiction a judgment obtained against you in the Montreal court. */}
    {/* </p> */}
    <h5>14. Miscellaneous Provisions</h5>
    <ul className="list-simple">
    <li>  The TOU, in conjunction with the Privacy Policy, constitutes the entire agreement between you and KBLIVE with respect to your use of the Services, superseding any prior agreements between you and KBLIVE.</li>
    <li>KBLIVE shall not be liable for any failure to perform its obligations under the present TOU where such failure results from any cause beyond KBLIVE’s reasonable control, including, but not limited to, mechanical, electronic or communications failure or degradation, or any force majeure event.</li>
    <li>If any provision of the TOU or Privacy Policy is found by a court of competent jurisdiction to be invalid, you and KBLIVE nevertheless agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the TOU or Privacy Policy, as the case may be, shall remain in full force and effect.</li>
    <li>The failure of KBLIVE to exercise or enforce any right or provision of these TOU does not constitute a waiver of such right or provision, which will still be available to KBLIVE.</li>
    <li>The section titles in the TOU and Privacy Policy are for convenience only and have no legal or contractual effect.</li>
    <li>The parties acknowledge having expressly required that these TOU and the Privacy Policy are to be drawn up in the English language. Les parties aux présentes reconnaissent avoir expressément exigé que les présentes Conditions d’utilisation et la Politique de confidentialité soient rédigées en anglais.</li>

    </ul>
      
      

          </div>
        </div>
      </div>
      {!userInfo && <PublicFooter /> }
    </>
  );
}
