import {
  GET_INSTAGRAM_URL_REQUEST,
  GET_INSTAGRAM_URL_SUCCESS,
  GET_INSTAGRAM_URL_ERROR,
  GET_INSTAGRAM_DATA_REQUEST,
  GET_INSTAGRAM_DATA_SUCCESS,
  GET_INSTAGRAM_DATA_ERROR,
  UPDATE_ACCESS_TOKEN_REQUEST,
  UPDATE_ACCESS_TOKEN_SUCCESS,
  UPDATE_ACCESS_TOKEN_ERROR,
  DISCONNECT_INSTAGRAM_REQUEST,
  DISCONNECT_INSTAGRAM_SUCCESS,
  DISCONNECT_INSTAGRAM_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

export const getInstagramUrl = () => (dispatch) => {
  dispatch({ type: GET_INSTAGRAM_URL_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}social/ig/url/instagram`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_INSTAGRAM_URL_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INSTAGRAM_URL_ERROR,
        payload: error.response,
      });
    });
};

export const getInstagramData = (code, email) => (dispatch) => {
  dispatch({ type: GET_INSTAGRAM_DATA_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}social/ig/data/${code}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      email: email,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_INSTAGRAM_DATA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_INSTAGRAM_DATA_ERROR,
        payload: error.response,
      });
    });
};

export const updateAccessToken =
  (userId, username, accessToken) => (dispatch) => {
    dispatch({ type: UPDATE_ACCESS_TOKEN_REQUEST });
    return axios({
      method: 'PUT',
      url: `${BASEURL}users/revise/ig/instagram`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      data: {
        user_id: userId,
        username: username,
        access_token: accessToken,
      },
    })
      .then((response) => {
        return dispatch({
          type: UPDATE_ACCESS_TOKEN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        return dispatch({
          type: UPDATE_ACCESS_TOKEN_ERROR,
          payload: error.response,
        });
      });
  };

export const disconnectInstagram = (userId) => (dispatch) => {
  dispatch({ type: DISCONNECT_INSTAGRAM_REQUEST });
  return axios({
    method: 'PUT',
    url: `${BASEURL}users/revise/disconnectinstagram/${userId}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: DISCONNECT_INSTAGRAM_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DISCONNECT_INSTAGRAM_ERROR,
        payload: error.response,
      });
    });
};
