import {
  SAVE_ROLE_REQUEST,
  SAVE_ROLE_SUCCESS,
  SAVE_ROLE_ERROR,
} from "../../types/types";

export default function SaveRole(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_ROLE_REQUEST:
      return {
        loading: true,
      };
    case SAVE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    case SAVE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        payload: payload,
      };

    default:
      return state;
  }
}
