import {
  GET_STORY_INSIGHTS_REQUEST,
  GET_STORY_INSIGHTS_SUCCESS,
  GET_STORY_INSIGHTS_ERROR,
} from "../../types/types";
export default function getInsights(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case GET_STORY_INSIGHTS_REQUEST:
      return {
        loading: true,
      };
    case GET_STORY_INSIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: payload,
      };
    case GET_STORY_INSIGHTS_ERROR:
      return {
        ...state,
        loading: false,
        payload,
      };
    default:
      return state;
  }
}
