import React, { useEffect, useState } from 'react';
import '../../css/sales.scss';
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
} from 'antd';
import { useDispatch } from 'react-redux';
import { getEvents } from '../../redux/actions/event.action';
import { getSalesReport } from '../../redux/actions/salesReport.action';
import moment from 'moment';
import numeral from "numeral";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const { RangePicker } = DatePicker;

export default function OrderDetail() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(true);
  const [searchEvent, setSearchEvent] = useState('Upcoming');
  const [getEvent, setGetEvent] = useState([]);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setLoading(true);
    dispatch(getSalesReport(limit, 1)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  }, []);

  // const onFinish = (values) => {
  //   setLoading(true);
  //   dispatch(getSalesReport(limit, 1, values.event)).then((res) => {
  //     setLoading(false);
  //     setGetEvent(res?.payload?.message);
  //     setTotalEvent(res?.payload?.message?.total_records);
  //   });
  // };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getSalesReport(limit, page + 1)).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };


  return (
    <>
      <div className="sales-page-main">
        <div class="title-area mb-10">
          <h1>Total Sales</h1>
        </div>

        <div className="brand_container_main aff-payment">

          <div className="row">
            <div className="col-md-12">


              {!loading ? (
                <div class="table-responsive-sm">

                  <table class="transactions-box table">
                    <thead class="table_heading">
                      <tr>
                        <th>S.#</th>
                        <th>Date</th>
                        <th>Event</th>
                        <th>No. Of Orders</th>

                        <th>Total Amount</th>
                        <th>Total Discount</th>
                        <th>Total Paid Amount</th>

                      </tr>
                    </thead>
                    <tbody>

                      {!getEvent.data.length ? (
                        <tr className="text-center">
                          <td colspan="10">No Data Available</td>
                        </tr>
                      ) : (
                        getEvent.data.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {moment(item?.event?.start_date)
                                  .utc()
                                  .format('YYYY-MM-DD')}
                              </td>
                              <td>{item?.event_title}</td>
                              <td>{item?.order_count}</td>

                              <td

                              >  {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format("$0,0.00'")
                                : "$0.00"} </td>
                              <td

                              >  {item?.total_discounts
                                ? numeral(item?.total_discounts).format("$0,0.00'")
                                : "$0.00"} </td>

                              <td

                              >  {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : "$0.00"} </td>

                            </tr>
                          );
                        })
                      )
                      }
                    </tbody>
                  </table>
                </div>)
                :
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              }

              {totalEvent < 8 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getEvent?.next
                      ? getEvent?.next?.page
                      : getEvent?.previous
                        ? getEvent?.previous?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={'pagination custom-paginate'}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}

              {!getEvent.data?.length ? (
                <tr className="text-center">
                  <td colspan="10">No Data Available</td>
                </tr>
              ) : (
                getEvent.summary.map((item, i) => {
                  return (


                    <div class="sales-summery">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="summarry-box">
                            <h5>Report Summary</h5>
                            <div>Total Amount  : {item?.total_line_items_price
                              ? numeral(item?.total_line_items_price).format("$0,0.00'")
                              : "$0.00"}</div>
                            <div>Total Discount : {item?.total_discounts
                              ? numeral(item?.total_discounts).format("$0,0.00'")
                              : "$0.00"}</div>
                            <div>Total Paid Amount : {item?.total_price
                              ? numeral(item?.total_price).format("$0,0.00'")
                              : "$0.00"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )

              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
