import { combineReducers } from "redux";
import login from "./login/login.reducer";
import getBroadcastClient from "./broadcast/broadcastClient.reducer";
import parentCategory from "./category/parentCategory.reducer";
import subCategory from "./category/subCategory.reducer";
import creatEvent from "./event/createevent.reducer";
import getEvent from "./event/getEvent.reducer";
import getEventById from "./event/getEventById.reducer";
import deleteEvent from "./event/deleteevent.reducer";
import updateEvent from "./event/updateEvent.reducer";
import goLive from "./broadcast/goLive.reducer";
import stopLive from "./broadcast/stopLive.reducer";
import sendMetaData from "./broadcast/sendmetadata.reducer";
import getMetaData from "./broadcast/getmetadata.reducer";
import upcomingEvent from "./event/upcomingEvent.reducer";
import SaveControl from "./broadcast/savecontrol.reducer";
import getControl from "./broadcast/getcontrol.reducer";
import getVideoById from "./event/recordedEvent.reducer";
import SaveRole from "./role/saveRole.reducer";
import startMeeting from "./broadcast/startMeeting.reducer";
import endMeeting from "./broadcast/endMeeting.reducer";
import getShopifyDetails from "./shopify/shopify.reducer";
import getProducts from "./shopify/shopifyProduct.reducer";
import createShopify from "./shopify/shopifyPost.reducer";
import forgetPassword from "./forgetPassword/forgetPassword.reducer";
import resetPassword from "./resetPassword/resetPassword.reducer";
import shareUrls from "./shareUrls/liveUrl.reducer";
import country from "./country/country.reducer";
import state from "./state/state.reducer";
import city from "./city/city.reducer";
import brandRegister from "./register/register.reducer";
import getSales from "./sales/getSales.reducer";
import getOrder from "./sales/getOrder.reducer";
import createChat from "./chat/chatToken";
import publishEvent from "./event/publishevent.reducer";
import tags from "./mention/tag.reducer";
import hashtag from "./hashtags/hashtag";
import hashtags from "./hashtags/hashtags";
import posts from "./subscription/post.reducer";
import profile from "./searchProfile/addProfile.reducer";
import profiles from "./searchProfile/profiles.reducer";
import bioPosts from "./bioPost/bioPost.reducer";
import links from "./bioPost/bioShopLink.reducer";
import mobileDropdown from "./mobileDropDown/mobileDropDown.reducer";
import categories from "./category/categories.reducer";
import userInfo from "./userInfo/userInfo.reducer";
import promoRequest from "./promoRequest/promoRequest.reducer";
import subcategories from "./BioSubCategory/BioSubCategory.redcuer";
import allCategory from "./category/allCategory.reducer";
import saveCategory from "./category/saveCategory.reducer";
import verifyCategory from "./category/verifyCategory.reducer";
import getInstagramUrl from "./instagramConnection/getInstagramUrl.reducer";
import getInstagramData from "./instagramConnection/getInstagramData.reducer";
import updateAccessToken from "./instagramConnection/updateAccessToken.reducer";
import disconnectInstagram from "./instagramConnection/disconnectInstagram.reducer";
import getChat from "./chat/getChat";
import saveChat from "./chat/saveChat";
import getSocialStoreSales from "./sales/getSocialStoreSales.reducer";
import gallery from "./mediaGallery/gallery";
import schedulePosts from "./mediaGallery/schedulePost";
import campaign from "./mediaGallery/mediaSummary";
import getStream from "./streamSetting/getStream.reducer";
import saveStream from "./streamSetting/saveStream.reducer";
import hostcheck from "./event/hostcheck.reducer";
import getInsights from "./storyInsight/getInsights";

export default combineReducers({
  login,
  getBroadcastClient,
  parentCategory,
  gallery,
  campaign,
  schedulePosts,
  subCategory,
  creatEvent,
  getEvent,
  getEventById,
  deleteEvent,
  updateEvent,
  goLive,
  stopLive,
  sendMetaData,
  getMetaData,
  upcomingEvent,
  SaveControl,
  getControl,
  getVideoById,
  SaveRole,
  startMeeting,
  endMeeting,
  getShopifyDetails,
  getProducts,
  createShopify,
  forgetPassword,
  resetPassword,
  shareUrls,
  country,
  state,
  city,
  brandRegister,
  getSales,
  myChatToken: createChat,
  getOrder,
  publishEvent,
  tags,
  hashtag,
  hashtags,
  posts,
  profile,
  profiles,
  bioPosts,
  links,
  mobileDropdown,
  categories,
  userInfo,
  promoRequest,
  subcategories,
  allCategory,
  saveCategory,
  verifyCategory,
  getInstagramUrl,
  getInstagramData,
  updateAccessToken,
  disconnectInstagram,
  getChat,
  saveChat,
  getSocialStoreSales,
  getStream,
  saveStream,
  hostcheck,
  getInsights,
});
