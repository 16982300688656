import React, { useState, useEffect } from 'react';
import {  Tabs } from 'antd';
import PostingContentGallery from './postingContentGallery';
import CreatePostingContent from './createPostingContent';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const { TabPane } = Tabs;

export default function Events() {
  let query = new URL(window.location.href);
  let searchParams = new URLSearchParams(query.search);
  const [title, setTitle] = useState("");

  const callbackTabClicked = (key, event) => {
    // const url = new URL(window.location.href);
    // url.searchParams.set('type', key);
    // window.history.replaceState(null, null, url);
    setTitle(key);
  };
  
  // useEffect(() => {
  //   return () => {
  //     let url = new URL(window.location.href);
  //     console.log(url, 'url');
  //     url.searchParams.delete('type');
  //     setEventTitle('Upcoming');
  //   };
  // }, []);

  return (
    <>
      <div className="grid-listing-area flex-column  flex-sm-row align-items-start align-items-sm-center mb-30">
        <div className="grid-listing-left flex-grow-1 w-100">

          <Tabs
            // defaultActiveKey="Upcoming"
            defaultActiveKey={"create"}
            // onChange={onChange}
            className="events-tabs-main"
            onTabClick={callbackTabClicked}
          >
            <TabPane tab="Create" key="create"><CreatePostingContent title={title}/></TabPane>
            <TabPane tab="Gallery" key="gallery"><PostingContentGallery title={title}/></TabPane>
          </Tabs>
        </div>
      </div>

      {/* <div className="title-area">
        <h1>{eventTitle}</h1>
      </div> */}

      {/* <EventGrid
        className={gridActive}
        title={eventTitle}
        eventStatus={eventStatus}
      /> */}
    </>
  );
}
