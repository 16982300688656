import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader/Loader";

function Chat({ createMedia, messages, currentChat, sendMessage, uName }) {
  const [submit, setSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [items, setItem] = useState(messages);
  const [text, setText] = useState("");
  const [fields, setFields] = useState({
    title: "",
    image: "",
  });
  return (
    <div
      style={{
        border: "1px solid lightgrey",
        padding: 10,
        borderRadius: 7,
        margin: 10,
      }}
    >
      <h4>{currentChat.user.username}</h4>

      <div
        id="scrollableDiv"
        style={{
          height: 550,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
          dataLength={messages.length}
          next={false}
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true} //
          hasMore={false}
          loader={<Loader />}
          scrollableTarget="scrollableDiv"
        >
          {messages.map((item, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    item?.from === uName ? "flex-end" : "flex-start",
                }}
              >
                <div
                  style={{
                    ...style,
                    backgroundColor:
                      item?.from === uName ? "#010b40" : "#f7f7f7",
                  }}
                  key={index}
                >
                  <p
                    style={{ color: item?.from === uName ? "white" : "black" }}
                  >
                    {item.message}
                  </p>
                  <p
                    style={{
                      color: "#c9c9c9",
                      textAlign: "right",
                      margin: 0,
                      fontSize: 11,
                    }}
                  >
                    {new Date(item.created_time).toUTCString()}
                  </p>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(text).then(() => setText(""));
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="write here"
          />
          <button
            style={{ marginLeft: 10 }}
            type="submit"
            class="btn btn-primary"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
}

const style = {
  borderRadius: 10,
  margin: 6,
  padding: 8,
  maxWidth: 400,
};
export default connect(null, null)(Chat);
