import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Input,
  Form,
  Radio,
  Space,
  Button,
  Modal,
  Tabs,
  Spin,
  notification,
} from "antd";
import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faPlay,
  faUserTie,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import { useStepsForm } from "sunflower-antd";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import AsyncHost from "../create-event/scheduleEvent/asyncHost";
import AsyncHost2 from "../create-event/scheduleEvent/asyncHost2";
import { saveRoles } from "../../redux/actions/role.action";
const { TabPane } = Tabs;

export default () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [spinner, setSpin] = useState(false);
  const [host, setHost] = useState([]);
  const [mod, setModerator] = useState([]);
  const [modError, setModeratorError] = useState([]);
  const [hostError, setHostError] = useState(false);
  const [rolePicker, setRolepicker] = useState("All");
  const createUser = () => {
    //  setAddUser(true);
    setIsModalOpen(true);
  };
  const onChange = (key) => {
    setRolepicker(key);
  };


  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const getHost = (hostData) => {
    setHostError(false);
    setHost(hostData);
  };

  const getModerator = (modData) => {
    setModeratorError(false);

    console.log(modData, "modData");
    setModerator(modData);
  };

  const { form, formProps, submit, formLoading } = useStepsForm({
    async submit(values) {
      let data = {};
      setSpin(true);
      if (rolePicker === "Host") {
        data = {
          users: host,
          host: true,
        };
      } else {
        data = {
          users: mod,
          moderator: true,
        };
      }
      dispatch(saveRoles(data)).then((res) => {
        if (res.payload.success) {
          notification.success({
            message: res.payload?.message,
            className: "toast-success",
          });
          setSpin(false);
          // handleClose();
          //  history("/home");
        } else {
          setSpin(false);
          notification.error({
            message: res.payload?.data?.message,
            className: "toast-error",
          });
        }
        setHost([]);
        setModerator([]);
      });
    },
  });

  const event = () => {
    setAddUser(false);
    setRolepicker("Host");
  };

  console.log(rolePicker, "---")
  return (
    <>
      {!addUser ? (
        <>
          <div className="grid-listing-area mb-20">
            <div className="grid-listing-left flex-grow-1">
              <Tabs
                defaultActiveKey="1"
                onChange={onChange}
                className="events-tabs-main"
              >
                {/* <TabPane tab="Host" key="Host"></TabPane>
                <TabPane tab="Moderator" key="Moderator"></TabPane> */}

                <TabPane tab="All" key="All"></TabPane>
                <TabPane tab="Brand Host" key="Brand Host"></TabPane>
                <TabPane tab="Inventory Manager" key="Inventory Manager"></TabPane>
                <TabPane tab="Chat Moderator" key="Chat Moderator"></TabPane>

              </Tabs>
            </div>
            <div className="grid-listing-right">
              {/* <Link
            to="#"
            className="default-btn d-flex align-items-center justify-content-center m-0"
          >
            <span className="nav-text">Add User</span>
          </Link> */}
              {rolePicker != "All" && (
                <Button
                  className="default-btn d-flex align-items-center justify-content-center m-0"
                  type="primary"
                  size="large"
                  onClick={() => createUser()}
                >
                  Add {rolePicker}
                </Button>
              )}
            </div>
          </div>
          <div class="title-area"><h1 class="page-title">{rolePicker}</h1></div>

          <Table className="users-detail-table">
            <thead>
              <tr>
                <th>
                  <h5>Users</h5>
                </th>
                <th>
                  <h5>Last Login</h5>
                </th>
                <th>
                  <h5>Access</h5>
                </th>
                <th>
                  <h5>Status</h5>
                </th>
                <th>
                  <h5>&nbsp;</h5>
                </th>
              </tr>
            </thead>

            <tbody>
              {rolePicker === "All" && (
                <>
                  <tr>
                    <td>
                      <h5>Username </h5>
                      <h6>Brandhost@dL1961.COM</h6>
                    </td>
                    <td>Sunday, August 28, 10:38 PM</td>
                    <td>Brand Host</td>
                    <td>
                      <div className="status active">Active</div>
                    </td>
                    <td className="text-right">
                      {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                    </td>
                  </tr>


                  <tr>
                    <td>
                      <h5>Username </h5>
                      <h6>Inventorymanager@dl1961.com</h6>
                    </td>
                    <td>Sunday, August 28, 10:38 PM</td>
                    <td>Inventory Manager</td>
                    <td>
                      <div className="status active">Active</div>
                    </td>
                    <td className="text-right">
                      {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                    </td>
                  </tr>


                  <tr>
                    <td>
                      <h5>Username </h5>
                      <h6>Chatmoderator@dl1961.com</h6>
                    </td>
                    <td>Sunday, August 28, 10:38 PM</td>
                    <td>Chat Moderator</td>
                    <td>
                      <div className="status active">Active</div>
                    </td>
                    <td className="text-right">
                      {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                    </td>
                  </tr>
                </>
              )}

            </tbody>



            <tbody>
              {rolePicker === "Brand Host" && (
                <tr>
                  <td>
                    <h5>Username </h5>
                    <h6>Brandhost@dL1961.COM</h6>
                  </td>
                  <td>Sunday, August 28, 10:38 PM</td>
                  <td>Brand Host</td>
                  <td>
                    <div className="status active">Active</div>
                  </td>
                  <td className="text-right">
                    {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                  </td>
                </tr>
              )}





              {/* <tr>
                <td>2</td>
                <td>Monday, August 29, 10:38 PM</td>
                <td>Moderator</td>
                <td>
                  <div className="status notactive">Not Active</div>
                </td>
                <td>&nbsp;</td>
              </tr> */}
              {rolePicker === "Inventory Manager" && (
                <tr>
                  <td>
                    <h5>Username </h5>
                    <h6>Inventorymanager@dl1961.com</h6>
                  </td>
                  <td>Sunday, August 28, 10:38 PM</td>
                  <td>Inventory Manager</td>
                  <td>
                    <div className="status active">Active</div>
                  </td>
                  <td className="text-right">
                    {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                  </td>
                </tr>

              )}



              {rolePicker === "Chat Moderator" && (

                <tr>
                  <td>
                    <h5>Username </h5>
                    <h6>Chatmoderator@dl1961.com</h6>
                  </td>
                  <td>Sunday, August 28, 10:38 PM</td>
                  <td>Chat Moderator</td>
                  <td>
                    <div className="status active">Active</div>
                  </td>
                  <td className="text-right">
                    {/* <h5 className="this-is-you">THIS IS YOU</h5> */}
                  </td>
                </tr>
              )}




            </tbody>
          </Table>
          {/* <div className="create-event-wrapper  mb-30">
            <div className="create-event-box-left full-width">
              <div className="row d-flex no-gutters">
                <div className="col">
                  <div className="title-area mb-10 pt-4">
                    <h1 className="pb-0">Users</h1>
                  </div>





                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <>
          <div className="grid-listing-area mb-20">
            <div className="grid-listing-left">
              <span className="prev-next-link ff-nunito">
                <FontAwesomeIcon icon={faAngleLeft} />
                <span onClick={() => event()}>Go back to Users Permission</span>
              </span>
            </div>
            <div className="grid-listing-right">
              <Button
                className="default-btn d-flex align-items-center justify-content-center m-0"
                type="primary"
                size="large"
                disabled
              // onClick={() => createUser()}
              >
                Invite {rolePicker}
              </Button>
            </div>
          </div>

          <Form layout="vertical">
            <div className="create-event-wrapper  mb-30">
              <div className="create-event-box-left full-width">
                {rolePicker == "Moderator" ? (
                  <>
                    <div className="row d-flex mb-60">
                      <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                        <h5>Linked {rolePicker} </h5>
                        <p>
                          Give to a specific host access to the event. This will
                          send an invitation to notify the {rolePicker}.
                        </p>
                      </div>
                      <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                        <Form.Item
                          name="mod"
                          label="Link a Moderator"
                        // className="d-flex flex-column sku-field"
                        >
                          <AsyncHost2 getHost={getModerator} host={mod} />
                          {modError && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Please select a Moderator
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row d-flex mb-60">
                      <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                        <h5>Linked {rolePicker} </h5>
                        <p>
                          Give to a specific host access to the event. This will
                          send an invitation to notify the {rolePicker}.
                        </p>
                      </div>
                      <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                        <Form.Item
                          name="host"
                          label="Link a Host"
                        // className="d-flex flex-column sku-field"
                        >
                          <AsyncHost getHost={getHost} host={host} />
                          {hostError && (
                            <div
                              role="alert"
                              className="ant-form-item-explain-error"
                            >
                              Please select a host
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
                <div className="row d-flex mb-60">
                  <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                    <h5>User Information</h5>
                    <p>
                      The user will receive an invitation to access to the
                      Console and complete his profile.
                    </p>
                  </div>
                  <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                    <Form.Item
                      labelCol={{ span: 24 }}
                      label="Email"
                      name="email"
                    >
                      <Input
                        placeholder="Email"
                        size="large"
                      // onChange={onChange}
                      />
                    </Form.Item>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      label="Password"
                      className="mb-40"
                      name="password"
                    >
                      <Input.Password
                        placeholder="Password"
                        size="large"
                      // onChange={onChange}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="row d-flex mb-60">
                  <div className="col-lg-4 col-xl-3 pr-xl-4 guide-column  flex-stretch">
                    <h5>Permission</h5>
                    <p>
                      Select a permission for the user. It will define what the
                      user can see and do on the console.
                    </p>
                  </div>
                  <div className="col-lg-8 col-xl-9 pl-xl-5 flex-stretch form-styling ">
                    <h5>Select a permission</h5>
                    <Radio.Group
                      className="permission-options"
                      //  onChange={onChange}
                      //  value={value}
                      defaultValue={1}
                    >
                      <Space direction="vertical">
                        <Radio value={1}>
                          <h6> Producer</h6>

                          <span className="permission-txt">
                            Has access to settings, events, control rooms and
                            analytics.
                          </span>
                        </Radio>
                        <Radio value={2}>
                          <h6> Moderator</h6>
                          <span className="permission-txt">
                            Has access to control rooms.
                          </span>
                        </Radio>
                        {/* <Radio value={3}>
                      <h6> Host</h6>
                      <span className='permission-txt'>Has access to specific control rooms and contribution app.</span></Radio> */}
                      </Space>
                    </Radio.Group>
                  </div>
                </div>
                <Spin spinning={spinner}>
                  <Button
                    // disabled
                    className="default-btn d-flex align-items-center justify-content-center"
                    type="primary"
                    size="large"
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        className="event-icon"
                        icon={faCaretRight}
                      />
                    }
                    onClick={() => submit()}
                  >
                    Submit
                  </Button>
                </Spin>
              </div>
            </div>
          </Form>
        </>
      )}

      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={[
          // <Button key="back" onClick={handleCancel}>
          //   Cancel
          // </Button>,
          <Button key="submit" type="primary"
            className="d-table m-auto"
            // onClick={handleOk}
            onClick={handleCancel}
          >
            Submit
          </Button>,
        ]}

      >
        <h2 className="modal-hd1">Assign {rolePicker}</h2>
        {rolePicker === "Brand Host" && (
          <p>Please note these email addresses must be registered on kbshop.com as a Shopper.
            By submitting this, the role will have an access to control rooms.
          </p>
        )}
        {rolePicker === "Inventory Manager" && (
          <p>Please note these email addresses must be registered on kbshop.com as a Shopper.
            By submitting this, the role will have an access to products inventory management.
          </p>
        )}

        {rolePicker === "Chat Moderator" && (
          <p>Please note these email addresses must be registered on kbshop.com as a Shopper.
            By submitting this, the role will have an access to control room chat.
          </p>
        )}
        <Input
          placeholder="Enter email address"
          //value={"test.myshopify.com"}
          size="large"
        // onChange={onChange}
        />
      </Modal>


    </>
  );
};
