import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Button } from 'antd';
// import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import '../../css/public/public.scss';
import '../../css/public/brand.scss';
import PublicHeader from '../../components/public/header/header';
import PublicFooter from '../../components/public/footer/footer';
import publicBannerHome from '../../images/public/public-banner-home.png';
import featureImage from '../../images/public/feature-img.jpg';
import PhoneSpliteLive from '../../images/public/Phone-splite-live.png';
import shop1 from '../../images/public/shopify.png';
import shop2 from '../../images/public/magento.png';
import shop3 from '../../images/public/sales-force.png';
import CustomerVideo from '../../video/customer.mp4';

export default function PublicHome() {
  return (
    <>
      <PublicHeader />
      <div class="brand-sec-banner about-sec-banner">
        <div class="brn-bnr-box-left">
          <div class="bnr-content">
            <div className="sub-hd">Live Shopping Solution</div>
            <h1>
              <span>Go Live and Sell More</span>
            </h1>
            <p>
              Live Shopping made possible for all brands and retailers.
              Boost awareness, engagement, and sales by 3x and let your
              customers to see now buy now through live stream video.
            </p>
            <Link to="/register">Join Now</Link>
          </div>
        </div>
        <div class="brn-bnr-box-right">
          <img class="public-home-img" src={publicBannerHome} alt="" />
        </div>
      </div>
      <div class="brnd-sec-3">
        <div class="midd-width-ifti">
          <div class="boost-sale-main">
            <div class="boost-sale-header">
              <h2>Achieve incredible results</h2>
            </div>
            <div class="promote-brand-main">
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>5:1</h2>
                  <p>Return on investment</p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>10x</h2>
                  <p>More likely to buy </p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>26%</h2>
                  <p>Increase in upselling </p>
                </div>
              </div>
              <div class="promote-brand-box">
                <div class="promote-content">
                  <h2>x5</h2>
                  <p>Increase in sales</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bioshop-sec-main">
        <div class="mid-width">
          <div class="bioshop-container">
            <div class="bioshop-box">
              <div class="bioshop-box-inner max-initial">
                <h2>KBLIVE Features</h2>

                <div className="row">
                  <div className="col-lg-6 features-box">
                    <h3>GO LIVE FROM ANYWHERE</h3>
                    <p>
                      Stream live in minutes from desktop or mobile. KBLIVE
                      offers a control room for brands to manage live events.
                    </p>
                  </div>
                  <div className="col-lg-6 features-box">
                    <h3>PRODUCT SPOTLIGHT</h3>
                    <p>
                      Highlight a specific product during a live event and boost
                      its sale.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 features-box">
                    <h3>CHAT WITH YOUR AUDIENCE</h3>
                    <p>
                      Interact with viewers, answer questions & drive sales
                      through live chat.
                    </p>
                  </div>
                  <div className="col-lg-6 features-box">
                    <h3>DATA ANALYTICS</h3>
                    <p>
                      Our real-time KPIs and enhanced analytics tell you more
                      about orders, sales and viewers.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 features-box">
                    <h3>SEAMLESS CHECKOUT</h3>
                    <p>In-stream add-to-cart and frictionless checkout</p>
                  </div>
                  <div className="col-lg-6 features-box">
                    <h3>INSTANT STREAMING</h3>
                    <p>
                      Livestream directly from the web browser. No code to write
                      or third party app installation required to go live.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bioshop-box">
              <img class="public-home-img" src={featureImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="bnr-section-2 bg-white d-inline-block">
        <div class="mid-width">
          <div class="one-mark">
            <h2 className="mb-5 text-center">
              KBLIVE is designed to inspire, educate, and entertain customers
            </h2>
            <video className="customer-video" loop autoPlay muted>
              <source
                src="https://static.konnect.bio/publicwebsite/customer.mp4"
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      </div>
      <div class="bioshop-sec-main dark">
        <div class="mid-width">
          <div class="bioshop-container no-reverse">
            <div class="bioshop-box  d-flex justify-content-center">
              <img class="public-why-home-img" src={PhoneSpliteLive} alt="" />
            </div>
            <div class="bioshop-box">
              <div class="bioshop-box-inner max-initial">
                <h2 className="mb-40">Why choose us?</h2>
                <ul className="why-list">
                  <li>Brands can go Live with zero line of coding.</li>
                  <li>
                    Brands can have multiple hosts to do Live shows.
                  </li>
                  <li>No third-party app required to go live.</li>
                  <li>
                    Shoppers can make purchases through frictionless checkout.
                  </li>
                  <li>SKU selection direct from Merchant Shopify store.</li>
                  <li>Measurable ROI for Live Events.</li>
                  <li>Real-time Stats of Live Event.</li>
                  <li>High-quality video on desktop and mobile.</li>
                </ul>
                <div class="bioshop-link">
                  <Link to="/register">Join Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="brand-sec-6">
        <div className="midd-width-ifti">
          <div className="integrated-sec">
            <div className="boost-sale-header">
              <h2>Integrated By the Best</h2>
              <h4>
                KBLIVE platform is fully integrated with leading e-commerce
                platforms <br />
                giving access to real-time data.
              </h4>
            </div>
            <div className="plateforms">
              <div className="shop-logo">
                <img src={shop1} alt="" className="sl-1" />
              </div>
              <div className="shop-logo">
                <img src={shop2} alt="" className="sl-2" />
              </div>
              <div className="shop-logo">
                <img src={shop3} alt="" className="sl-3" />
              </div>
            </div>
            <div className="select-plan-main">
              <div className="boost-sale-header">
                <h2>Pricing</h2>
                <h4 className='col-lg-8'>
                The package features a video platform with integration to Shopify, and Live Shopping, includes live streaming Tools and Analytics.
                </h4>
              </div>
              <div className="package_parent">
                <div className="custom_pkg_main">
                  <h3>Brand/Retailer Package</h3>
                  <div className="custom_pkg">
                    <div className="pkg_price_ifti">
                      <sup>$</sup>
                      <span className="monthly display-5">500</span>
                      <small className="monthly">/Month</small>
                    </div>
                    <div className="incld-live-shoping">
                      <span className="includes-lv">Includes:</span>
                      <ul>



                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Integration With Shopify
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Live Shopping Integration
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Analytics
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                      Session Recording
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Multiple Hosts
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Audience Engagement
                        </li>
                      </ul>
                    </div>
                    <div className="incld-live-shoping">
                      <span className="includes-lv">Live Events Charges:</span>
                      <ul>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Unlimited Live Events/Month
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Unlimited Product Listings
                        </li>
                        <li>
                          <i
                            className="fa fa-angle-right"
                            aria-hidden="true"
                          ></i>{' '}
                          Live Audience: $1/500 Audiences/Minute
                        </li>
                      </ul>
                    </div>
                    <Button
                      // onClick={() => {
                      // 	if (!userInfo) dispatch(openRegister(true, "step2"));
                      // }}
                      // variant="primary"
                      className="select-pkg btn default-btn"
                    >
                      Start Plan
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PublicFooter />
    </>
  );
}
