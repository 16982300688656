import {
  POST_EVENT_REQUEST,
  POST_EVENT_SUCCESS,
  POST_EVENT_ERROR,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_ERROR,
  GET_EVENT_ID_REQUEST,
  GET_EVENT_ID_SUCCESS,
  GET_EVENT_ID_ERROR,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_ERROR,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_ERROR,
  UPCOMING_EVENT_REQUEST,
  UPCOMING_EVENT_SUCCESS,
  UPCOMING_EVENT_ERROR,
  PUBLISH_EVENT_REQUEST,
  PUBLISH_EVENT_SUCCESS,
  PUBLISH_EVENT_ERROR, HOST_EVENT_REQUEST, HOST_EVENT_SUCCESS, HOST_EVENT_ERROR
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
export const postEvent = (data) => (dispatch) => {
  dispatch({ type: POST_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}events/createnewscheduleevent`,
    headers: {
      Authorization: `Bearer ${userInfo?.token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
    .then((response) => {
      return dispatch({
        type: POST_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getEvents = (limit, page, title, sort) => (dispatch) => {
  let status = '';
  dispatch({ type: GET_EVENT_REQUEST });

  if (title === 'Live Events') {
    status = 'live';
  }
  if (title === 'Upcoming') {
    status = 'scheduled';
  }
  if (title === 'Recorded') {
    status = 'recorded';
  }
  if (title === 'scheduled,live') {
    status = 'scheduled,live';
  }

  return axios({
    method: 'GET',
    url: `${BASEURL}events/getallevents?limit=${limit}&page=${page}&status=${status}&sortby=${status === 'recorded' ? sort : ''
      }`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_SUCCESS,
        payload: response.data,
      });
      status = '';
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getEventsBy_Id = (id, room) => (dispatch) => {
  dispatch({ type: GET_EVENT_ID_REQUEST });

  return axios({
    method: 'GET',
    url: `${BASEURL}events/getoneevent/${id}?room=${room ? 1 : 0}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    // data: {
    // 	parent_id: id,
    // },
  })
    .then((response) => {
      return dispatch({
        type: GET_EVENT_ID_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_EVENT_ID_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEvent = (id) => (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });
  return axios({
    method: 'DELETE',
    url: `${BASEURL}events/deletescheduleevent/${id}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEventRecord = (id) => (dispatch) => {
  dispatch({ type: DELETE_EVENT_REQUEST });
  return axios({
    method: 'DELETE',
    url: `${BASEURL}events/deleterecordedevent/${id}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: DELETE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const UpdateEvents = (data, id) => (dispatch) => {
  console.log(data, id);
  dispatch({ type: UPDATE_EVENT_REQUEST });
  return axios({
    method: 'PUT',
    url: `${BASEURL}events/updatescheduleevent/${id}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    // headers: {
    //     Accept: "application/json",
    //     Authorization: "Bearer " + token,
    // },
    data: data,
  })
    .then((response) => {
      console.log('res: ', response);
      return dispatch({ type: UPDATE_EVENT_SUCCESS, payload: response.data });
    })
    .catch((error) => {
      return dispatch({
        type: UPDATE_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const getUpcomingEvents = (limit, page) => (dispatch) => {
  dispatch({ type: UPCOMING_EVENT_REQUEST });

  return axios({
    method: 'GET',
    url: `${BASEURL}events/getupcomingevents?limit=${limit}&page=${page}`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: UPCOMING_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: UPCOMING_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const publishEvent = (id, status) => (dispatch) => {
  dispatch({ type: PUBLISH_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}events/publishorunpublishevent`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { id: id, status: status },
  })
    .then((response) => {
      return dispatch({
        type: PUBLISH_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: PUBLISH_EVENT_ERROR,
        payload: error.response,
      });
    });
};

export const hostCheck = (id, host_status) => (dispatch) => {
  dispatch({ type: HOST_EVENT_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}events/updatehoststatus`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: { id: id, host_status: host_status },
  })
    .then((response) => {
      return dispatch({
        type: HOST_EVENT_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: HOST_EVENT_ERROR,
        payload: error.response,
      });
    });
};

