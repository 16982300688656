import {
  GET_CATEGORIES,
  GET_USER_CATEGORIES,
  GET_USER_CATEGORIES2,
} from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getCategories = () => async (dispatch) => {
  try {
    const res = await axios.get(`${BASEURL}/usercategory/receive`, {headers: { Authorization: `Bearer ${userInfo?.token}` },});
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data?.message,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getUserCategories = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(`${BASEURL}users/receive/categories`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((res) => {
        dispatch({
          type: GET_USER_CATEGORIES,
          payload: res.data?.message,
        });
        resolve(res.data?.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};

export const getUserCategories2 = (userId) => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(`${BASEURL}users/receive/categories?id=${userId}`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((res) => {
        dispatch({
          type: GET_USER_CATEGORIES2,
          payload: res.data?.message,
        });
        resolve(res.data?.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
