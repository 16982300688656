import {
    GET_URL_REQUEST,
    GET_URL_SUCCESS,
    GET_URL_ERROR,
  } from "../types/types";
  import { BASEURL,TEMPURL } from "../../config";
  import axios from "axios";
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const ShareUrl = (data) => (dispatch) => {
dispatch({ type: GET_URL_REQUEST });
return axios({
  method: "POST",
  url: `${TEMPURL}shorturl`,
  headers: { Authorization: `Bearer ${userInfo?.token}` },
  data: data,
})
  .then((response) => {
    return dispatch({ type: GET_URL_SUCCESS, payload: response.data });
  })
  .catch((error) => {
    return dispatch({
      type: GET_URL_ERROR,
      payload: error.response,
    });
  });
};