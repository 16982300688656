import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Modal, notification, Statistic } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEvents, deleteEvent } from '../../redux/actions/event.action';
import searchEventImg from '../../images/search-event.png';
import moment from 'moment';
import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faAngleLeft,
  faAngleRight,
  faPlay,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import NoEventFound from '../../reUsable/noEventFound';
import defaultImage from '../../images/user.png';

const { Countdown } = Statistic;
const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;

const { confirm } = Modal;

export default ({ className, title, eventStatus }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const { getEvent } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sortBy, setSortBy] = useState('end');

  const limit = 8;

  useEffect(() => {
    dispatch(getEvents(limit, 1, title, sortBy)).then((res) => {
      if (res.payload.success) {
        // setPageCount(res.payload.message?.next?.page);
      } else {
        console.log('err');
      }
    });
  }, [title, eventStatus]);

  const controlRoom = (val) => {
    history({
      pathname: `/control-room/${val?.event?._id}`,
    });
  };

  const recordedEvents = (val) => {
    history({
      pathname: `/recorded-event/${val?.event?._id}`,
    });
  };

  const handleButtonClick = (e, item) => {
    if (e.key === 'cancel') {
      confirm({
        wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
        title: 'Warning',
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: 'Are you sure you want to delete this event ?',
        okText: ' Yes, delete',
        okType: 'danger',
        cancelText: " No, I don't want to delete ",

        onOk() {
          return new Promise((resolve, reject) => {
            dispatch(deleteEvent(item?.event?._id)).then((res) => {
              if (res.payload.success) {
                notification.success({
                  message: res.payload?.message,
                  className: 'toast-success',
                });
                dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                setTimeout(resolve, 100);
              } else {
                console.log(res.payload.data.message, 'err');
                notification.error({
                  message: res.payload.data.message,
                  className: 'toast-error',
                });
              }
            });
          });
        },

        onCancel() {
          console.log('Cancel');
        },
      });
    }
    if (e.key === 'edit') {
      history({
        pathname: `/schedule-event-id/${item?.event?._id}`,
      });
    }
  };

  const load = getEvent.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    dispatch(getEvents(limit, page + 1, title, sortBy));
  };
  const isoStr = new Date().toISOString();

  function roomDisable(item) {
    const dateIsAfter = moment(item.start_date).isAfter(
      moment(moment.utc().format())
    );
    if (dateIsAfter) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      {!load ? (
        <>
          <div
            className={`upcoming-events-wrapper mb-30 ${
              className ? 'grid-block' : 'list-block'
            }`}
          >
            {!getEvent.loading ? (
              <>
                {getEvent?.payload?.message?.data.length > 0 ? (
                  <div className="upcoming-events-grid">
                    {getEvent?.payload?.message?.data.map((item, i) => {
                      return (
                        <>
                          <div className="upcoming-events-box">
                            {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                            <div className="event-banner">
                              <div className="event-box w-100 h-100 overflow-hidden">
                                <div className="event-banner-img">
                                  <img src={item?.event?.banner} alt="banner" />
                                </div>

                                <div className="d-flex justify-content-between align-items-center  flex-wrap">
                                  {/* {roomDisable(item) ? (
                                    <div className="schedule-badge expired">
                                      Expired
                                    </div>
                                  ) : (
                                    <> */}
                                  {item?.event?.event_status === 'live' ? (
                                    <div className="schedule-badge live">
                                      Live Now
                                    </div>
                                  ) : null}

                                  {moment(item?.event?.start_date)
                                    .utc()
                                    .format('YYYY-MM-DD') <
                                  moment(isoStr).utc().format('YYYY-MM-DD') ? (
                                    <div className="schedule-badge schedule">
                                      Past
                                    </div>
                                  ) : (
                                    <div className="schedule-badge schedule">
                                      New
                                    </div>
                                  )}

                                  {item?.event?.event_status === 'recorded' ? (
                                    <div className="schedule-badge Recorded">
                                      Recorded
                                    </div>
                                  ) : null}

                                  {item?.event?.event_status === 'scheduled' ? (
                                    <div className="schedule-badge schedule countdown-timer">
                                      <Countdown
                                        value={item?.event?.start_date}
                                        format="DD : HH : mm : ss"
                                      />
                                    </div>
                                  ) : null}

                                  {/* </>
                                  )} */}
                                </div>

                                <div className="d-flex justify-content-end">
                                  <div className="ribbon-small">
                                    <FontAwesomeIcon icon={faFlask} />
                                    <span>Event </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                              <div>
                                <div className="event-date-main top-date">
                                  <div className="event-date">
                                    <FontAwesomeIcon
                                      className="event-icon"
                                      icon={faCalendarDays}
                                    />
                                    <div className="event-txt">
                                      {moment(item?.event?.start_date)
                                        .utc()
                                        .format('YYYY-MM-DD')}
                                      {/* {new Date(item.start_date).toLocaleDateString()} */}
                                    </div>
                                  </div>
                                  <div className="event-date">
                                    <FontAwesomeIcon
                                      className="event-icon"
                                      icon={faClock}
                                    />
                                    <div className="event-txt">
                                      {moment(item?.event?.start_date).format(
                                        'hh:mm a'
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="event-date d-inline-flex">
                            <FontAwesomeIcon className="event-icon" icon={faCalendarDays} />
                            <div className="event-txt">
                                FRIDAY, JULY 29, 2022
                            </div>
                            <FontAwesomeIcon className="event-icon" icon={faClock} />
                            <div className="event-txt">
                                3:10 PM
                            </div>
                        </div> */}

                                <h3 className="event-hd">
                                  {item?.event?.title}
                                </h3>
                              </div>
                              <div className="event-date-main">
                                {item?.event?.hosts.map((it, i) => {
                                  return (
                                    <>
                                      {it?.producer && (
                                        <div className="event-date bottom-style">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faUserTie}
                                          />
                                          <div className="event-txt">
                                            Producer: {it.user_name}
                                          </div>
                                        </div>
                                      )}
                                      {it?.primary && (
                                        <div className="event-date bottom-style">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faUser}
                                          />
                                          <div className="event-txt">
                                            Primary: {it.user_name}
                                          </div>
                                        </div>
                                      )}
                                      {it?.host1 && (
                                        <div className="event-date bottom-style">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faUser}
                                          />
                                          <div className="event-txt">
                                            Host1: {it.user_name}
                                          </div>
                                        </div>
                                      )}
                                      {it?.host2 && (
                                        <div className="event-date bottom-style">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faUser}
                                          />
                                          <div className="event-txt">
                                            Host2: {it.user_name}
                                          </div>
                                        </div>
                                      )}
                                      {it?.host3 && (
                                        <div className="event-date bottom-style">
                                          <FontAwesomeIcon
                                            className="event-icon"
                                            icon={faUser}
                                          />
                                          <div className="event-txt">
                                            Host3: {it.user_name}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <div className="event-card-footer">
                              {item?.event?.recording_url ? (
                                <>
                                  <Button
                                    className="default-btn d-flex align-items-center justify-content-center"
                                    type="primary"
                                    size="large"
                                    onClick={() => recordedEvents(item)}
                                    icon={
                                      <FontAwesomeIcon
                                        size="sm"
                                        className="event-icon"
                                        // icon={faVideo}
                                        icon={faPlay}
                                      />
                                    }
                                  >
                                    Play
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="default-btn d-flex align-items-center justify-content-center"
                                    type="primary"
                                    size="large"
                                    onClick={() => controlRoom(item)}
                                    icon={
                                      <FontAwesomeIcon
                                        size="sm"
                                        className="event-icon"
                                        icon={faVideo}
                                      />
                                    }
                                    disabled={
                                      // roomDisable(item) ||
                                      item.event_status === 'recorded'
                                        ? true
                                        : false
                                    }
                                  >
                                    Control Room
                                  </Button>
                                </>
                              )}

                              {/* <>
                              
                                <Dropdown.Button
                                  trigger="click"
                                  overlayClassName="control-room-drowpdown"
                                  size="large"
                                  disabled={
                                    
                                    item.event_status !== "scheduled"
                                      ? true
                                      : false
                                  }
                                  // onClick={() => handleButtonClick(item)}
                                  // overlay={menu}
                                  overlay={
                                    <Menu
                                      onClick={(e) =>
                                        handleButtonClick(e, item)
                                      }
                                    >
                                     
                                      <Menu.Item
                                        key="edit"
                                        disabled={
                                          // roomDisable(item) ||
                                          item.event_status !== "scheduled"
                                            ? true
                                            : false
                                        }
                                      >
                                        <span>Edit</span>
                                      </Menu.Item>
                                      <Menu.Item key="cancel">
                                        <span>Cancel</span>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                ></Dropdown.Button>

                              </>
                               */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-result-found">
                    <div className="no-result-img">
                      <img
                        src={
                          userInfo?.profile_image_url
                            ? userInfo?.profile_image_url
                            : defaultImage
                        }
                        alt="searchEventImg"
                      />
                    </div>
                    <h3 className="no-event-hd"> No Event At This Time.</h3>
                    <p className="event-para">Please check back later.</p>
                    {/* <p>No Event At This Time. Please check back later.</p> */}
                  </div>
                )}
              </>
            ) : (
              <>{null}</>
            )}

            {getEvent?.payload?.message?.data.length > 0 && (
              <ReactPaginate
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                // marginPagesDisplayed={2}

                pageCount={
                  getEvent?.payload?.message?.next
                    ? getEvent?.payload?.message?.next?.page
                    : getEvent?.payload?.message?.previous
                    ? getEvent?.payload?.message?.previous?.page + 1
                    : 1
                }
                forcePage={currentPage}
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                pageClassName="page-item d-none"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item d-none"
                breakLinkClassName="page-link"
                containerClassName={'pagination custom-paginate'}
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </>
      ) : (
        // <Spin className="m-auto d-table" indicator={antIcon} />
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      )}
    </>
  );
};
