import React, { useEffect, useState } from 'react';
import '../../css/sales.scss';
import {
  Steps,
  Input,
  Button,
  Form,
  Result,
  Tabs,
  Space,
  DatePicker,
  TimePicker,
  Upload,
  message,
  notification,
  Select,
  Spin,
  Checkbox,
  Image,
  Affix,
  Modal,
  Table,
  Switch,
} from 'antd';
import { Route, Link, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEvents } from '../../redux/actions/event.action';
import { getSalesReport } from '../../redux/actions/salesReport.action';
import { getParentCategories } from '../../redux/actions/category.action';
import moment from 'moment';
import numeral from 'numeral';
import { useStepsForm } from "sunflower-antd";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

let groupKey = '';
export default function Sales() {
  const dispatch = useDispatch();
  const limit = 8;
  const [loading, setLoading] = useState(true);
  const [searchEvent, setSearchEvent] = useState('Upcoming');
  const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  const toDate = moment(new Date()).format('YYYY-MM-DD');
  const [getEvent, setGetEvent] = useState([]);
  const [parents, setParent] = useState('');
  const [groupBy, setGroupby] = useState('');
  const [defaults, setDefault] = useState('default');
  const [handler, setHandler] = useState('');
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const { parentCategory } = useSelector((state) => {
    return state;
  });
  useEffect(() => {
    setLoading(true);
    dispatch(
      getSalesReport(limit, 1, startDate, endDate, groupBy, parents)
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);

    });
  }, []);

  useEffect(() => {
    dispatch(getParentCategories()).then((res) => {
      if (res.payload.success) {
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    });
  }, []);

  // const onFinish = (values) => {
  //   setLoading(true);
  //   dispatch(getSalesReport(limit, 1, values.event)).then((res) => {
  //     setLoading(false);
  //     setGetEvent(res?.payload?.message);
  //     setTotalEvent(res?.payload?.message?.total_records);
  //   });
  // };

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(getSalesReport(limit, page + 1, startDate, endDate, groupBy, parents)).then(
      (res) => {
        setLoading(false);
        setGetEvent(res?.payload?.message);
        setTotalEvent(res?.payload?.message?.total_records);
      }
    );
  };

  const handleGroupBy = (e) => {
    groupKey = e;
    setHandler(e)
  };

  const handleCategory = (e) => {
    let parent = parentCategory?.payload?.message.filter(
      (item) => item.category_id == e
    );

    setCurrentPage(0);
    setLoading(true);
    setParent(parent[0]?.category_id);
    dispatch(
      getSalesReport(
        limit,
        1,
        startDate,
        endDate,
        groupKey,
        parent[0]?.category_id
      )
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };
  const handleReset = () => {
    setGroupby('');
    setHandler('')
    setParent('');
    groupKey = '';
    setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setLoading(true);
    dispatch(getSalesReport(limit, 1, fromDate, toDate, groupKey, '')).then(
      (res) => {
        setLoading(false);
        setGetEvent(res?.payload?.message);
        setTotalEvent(res?.payload?.message?.total_records);
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const page = e.selected;
    setCurrentPage(0);
    setLoading(true);
    setGroupby(groupKey);

    dispatch(getSalesReport(limit, 1, startDate, endDate, groupKey, parents)).then(
      (res) => {
        setLoading(false);
        setGetEvent(res?.payload?.message);
        setTotalEvent(res?.payload?.message?.total_records);
      }
    );
  };

  // const changeEvent = (e) => {
  //   setSearchEvent(e);
  // };

  const navigateOrder = (id) => {
    alert('sss');
    return (
      <>
        <Link to={`/account/orders/${id}/${startDate}/${endDate}`} />
      </>
    );
  };

  return (
    <>
      <div className="sales-page-main">
        <div class="title-area mb-10">
          <h1>Total Event Sales</h1>
        </div>

        <div className="brand_container_main aff-payment">


          <div className="row">

            <div className="col-xl-3 col-md-6 col-12 mb-20">

              <p>Select Start Date / End Date</p>
              <RangePicker
                size="large"
                className="w-100"
                key={4}
                value={
                  startDate && endDate
                    ? [moment(startDate), moment(endDate)]
                    : []
                }
                allowClear={false}
                ranges={{
                  Today: [moment(), moment()],
                  Tomorrow: [
                    moment().add(1, 'days'),
                    moment().add(1, 'days'),
                  ],
                  Yesterday: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                }}
                format={dateFormat}
                onChange={dateRangePickerChanger}
              />
            </div>

            <div className="col-xl-3 col-md-6 col-12 mb-20">
              <p>Category</p>
              <Select
                size="large"
                className="w-100"
                key={4}
                placeholder="Select Category"
                focus={false}
                onChange={handleCategory}
                value={parents === '' ? null : parents}
                onBlur={false}
                loading={parentCategory?.loading ? true : false}
                disabled={parentCategory?.loading ? true : false}
              >
                {parentCategory?.payload?.message.map((item, i) => {
                  return (
                    <Option value={item.category_id}>
                      {item.category_name}
                    </Option>
                  );
                })}
              </Select>

            </div>

            <div className="col-xl-3 col-md-6 col-12 mb-20">
              <p>Group By</p>
              <Select
                className="select-style dark-field w-100 "
                placeholder="Select Event"
                size="large"
                focus={false}
                onBlur={false}
                value={handler === '' ? null : handler}
                onChange={handleGroupBy}
              >
                <Option value="date">Date</Option>
                <Option value="event">Event</Option>
                <Option value="category">Category</Option>
              </Select>

            </div>

            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-20">

              <Button
                onClick={handleSubmit}
                className="default-btn fltr-hpr m-width-100 "
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Search
              </Button>


              <Button
                onClick={handleReset}
                className="default-btn fltr-hpr m-width-100 "
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Reset
              </Button>

            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              {!loading ? (
                <div className="table-responsive">
                  <table className="transactions-box table">
                    <thead className="table_heading">
                      <tr>
                        <th>S.#</th>
                        {groupBy === 'date' && <th>Date</th>}
                        {groupBy == '' && <th>Date</th>}

                        {groupBy === 'category' && <th>Category</th>}

                        {groupBy === '' && <th>Category</th>}
                        {groupBy === 'event' && <th>Event</th>}
                        {groupBy === '' && <th>Event</th>}

                        <th>No. Of Orders</th>

                        <th>Total Amount</th>
                        <th>Total Discount</th>
                        <th>Total Paid Amount</th>

                      </tr>
                    </thead>
                    <tbody>

                      {!getEvent.data.length ? (
                        <tr className="text-center">
                          <td colspan="10">No Data Available</td>
                        </tr>
                      ) : (
                        getEvent.data.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              {groupBy === 'date' && (
                                <td className="sales-event-link">
                                  <a
                                    href={`/account/orders/${item?.date}/${moment(item?.date).format('YYYY-MM-DD')}/${moment(item?.date).format('YYYY-MM-DD')}/${groupBy}`}
                                  >
                                    {' '}
                                    {moment(item?.date).format('YYYY-MM-DD')}
                                  </a>

                                </td>
                              )}
                              {groupBy == '' && (
                                <td>
                                  {moment(item?.date).format('YYYY-MM-DD')}
                                </td>
                              )}
                              {groupBy === 'category' && (
                                <td className="sales-event-link">
                                  <a
                                    href={`/account/orders/${item.category_id}/${startDate}/${endDate}/${groupBy}`}
                                  >
                                    {' '}
                                    {item?.category_name}
                                  </a>
                                </td>
                              )}
                              {groupBy == '' && <td>{item?.category_name}</td>}
                              {groupBy === 'event' && (
                                <td className="sales-event-link">
                                  <a
                                    href={`/account/orders/${item.event_id}/${startDate}/${endDate}/${groupBy}`}
                                  >
                                    {' '}
                                    {item?.event_title}
                                  </a>
                                </td>
                              )}
                              {groupBy == '' && (
                                <td className="sales-event-link">
                                  <a
                                    href={`/account/orders/${item.event_id}/${startDate}/${endDate}/${defaults}`}
                                  >
                                    {' '}
                                    {item?.event_title}
                                  </a>
                                </td>
                              )}
                              <td>{item?.order_count}</td>

                              <td
                              // style={{"text-align" : "right"}}
                              >
                                {' '}
                                {item?.total_line_items_price
                                  ? numeral(
                                    item?.total_line_items_price
                                  ).format("$0,0.00'")
                                  : '$0.00'}{' '}
                              </td>
                              <td
                              // style={{"text-align" : "right"}}
                              >
                                {' '}
                                {item?.total_discounts
                                  ? numeral(item?.total_discounts).format(
                                    "$0,0.00'"
                                  )
                                  : '$0.00'}{' '}
                              </td>

                              <td
                              // style={{"text-align" : "right"}}
                              >
                                {' '}
                                {item?.total_price
                                  ? numeral(item?.total_price).format(
                                    "$0,0.00'"
                                  )
                                  : '$0.00'}{' '}
                              </td>

                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}

              {totalEvent > 8 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getEvent?.next
                      ? getEvent?.next?.page
                      : getEvent?.previous
                        ? getEvent?.previous?.page + 1
                        : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={'pagination custom-paginate'}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}

              {!getEvent.data?.length ? (
                <tr className="text-center">
                  <td colspan="10"></td>
                </tr>
              ) : (
                getEvent.summary.map((item, i) => {
                  return (
                    <div class="sales-summery">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="summarry-box">
                            <h5>Report Summary</h5>
                            <div>
                              Total Amount :{' '}
                              {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format(
                                  "$0,0.00'"
                                )
                                : '$0.00'}
                            </div>
                            <div>
                              Total Discount :{' '}
                              {item?.total_discounts
                                ? numeral(item?.total_discounts).format(
                                  "$0,0.00'"
                                )
                                : '$0.00'}
                            </div>
                            <div>
                              Total Paid Amount :{' '}
                              {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : '$0.00'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
