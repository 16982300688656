import { ThemeProvider } from 'styled-components';
import {
  MeetingProvider,
  lightTheme,
  UserActivityProvider,
  useRemoteVideoTileState,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRosterState,
  useMeetingEvent,
  MeetingManager,
  VideoGrid,
  VideoTile,
  LocalVideo,
  ContentShare,
  useContentShareControls,
  Roster,
  useContentShareState,
  useMediaStreamMetrics,
  RosterGroup,
  RosterAttendee,
  useAudioVideo,
  AudioVideoProvider,
  useAudioInputs,
  // Modal,
  // ModalHeader,
  // ModalBody,
  Label,
  Select
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingManager,
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import {
  VideoTileGrid,
  BackgroundBlurProvider,
} from 'amazon-chime-sdk-component-library-react';
import Control from './Control';
import DeviceSetup from './deviceSetup/index';
import { NavigationProvider } from './NotificationProvider';
import Notifications from './Notification';
import { StyledLayout, StyledContent } from './MeetingStyled';
import { useEffect, useRef, useState } from 'react';
import { AudioOutlined, VideoCameraOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import NoRemote from './NoRemote';
import { notification, Button, Slider, Col, Row, Modal, Switch } from 'antd';
import stream from '../../images/stream-icon.png';
import streamcross from '../../images/stream-icon-cross.png';
import ReactRouterPrompt from 'react-router-prompt';
import { ErrorBoundary } from 'react-error-boundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoStreamMerger } from 'video-stream-merger'
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import video1 from "./videos/video1.mp4"
import video2 from "./videos/video2.mp4"
import grid1 from "./videos/1.jpg"
import grid2 from "./videos/2.jpg"
import grid22 from "./videos/2-2.jpg"
import grid3 from "./videos/3.jpg"
import grid32 from "./videos/3-2.jpg"
import grid4 from "./videos/4.jpg"
import IVSBroadcastClient, {
  Errors,
  BASIC_LANDSCAPE
} from 'amazon-ivs-web-broadcast';

const grids = [
  {
    img: grid1,
    name: "grid1"
  },
  {
    img: grid2,
    name: "grid2"
  },
  {
    img: grid22,
    name: "grid22"
  },
  {
    img: grid3,
    name: "grid3"
  },
  // {
  //   img: grid32,
  //   name: "grid32"
  // },
  {
    img: grid4,
    name: "grid4"
  },
]
const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const MyApp = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
  channel,
  stopLiveEvent
}) => {
  const history = useNavigate();
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [leavemet, setLeaveMet] = useState(false);
  const { tiles, attendeeIdToTileId, tileIdToAttendeeId } =
    useRemoteVideoTileState();
  console.log("faheem", tiles)
  const [ratio, setRatio] = useState({ w: 16, h: 9 });

  const [currentTileId, setCurrentTileId] = useState('');
  const { isVideoEnabled } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { sharingAttendeeId } = useContentShareState();
  const metrics = useMediaStreamMetrics();
  const audioVideo = useAudioVideo();
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [grayscale, setGrayscale] = useState(0);
  const [hue, setHue] = useState(0);
  const [blur, setBlur] = useState(0);

  const [contrast, setContrast] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const [aspectRatio, setAspectRatio] = useState('16:9');
  const [slotDrop, setSlotDrop] = useState("null")
  const [pipeline, setPipeline] = useState([])
  const { devices, selectedDevice } = useAudioInputs();
  const [currentGrid, setCurrentGrid] = useState({ name: "grid1", img: grid1 })
  const [submit, setSubmit] = useState(false)
  const videoRef = useRef()
  const mainMerger = useRef()
  const ivsSDK = useRef()
  const [current, setCurrent] = useState({
    brightness: 100,
    ratio: [16, 9],
    tileId: "",
    grayscale: 0,
    hue: 0,
    saturate: 100,
    blur: 0,
    contrast: 100

  })
  const [height, setHeight] = useState(495);

  // console.log("sele", selectedDevice)
  const tempTile = [video1, video2]

  const canvasRef = useRef()
  const capCanvas = useRef()
  const gridRef = useRef();

  // console.log(tiles,"tiles");
  // console.log(attendeeIdToTileId,"attendeeIdToTileId");

  const joinMeeting = async () => {
    // Fetch the meeting and attendee data from your server application
    // const response = await fetch('/my-attendees-endpoint');
    //   const user = await response.json();

    //   console.log(user,"user");

    // Initalize the `MeetingSessionConfiguration`
    // console.log("meeting",meetingData)

    // console.log("meeting",meetingData?.meeting_info?.Meeting)
    // console.log("meeting234324",meetingData?.meeting_info?.Attendees.filter(it=>it.ExternalUserId === userInfo?.user_id)[0])
    // console.log("meetingData23094709238409283094",userInfo?.user_id );

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
      meetingData[0]?.meeting_info?.Meeting,
      {
        Attendee: meetingData[0]?.meeting_info?.Attendees.filter(
          (it) => it.ExternalUserId === userInfo?._id
        )[0],
      }
      // {
      //   "MeetingId": "26cd743a-3091-44a2-b950-fc2ca6c62713",
      //   "MeetingHostId": null,
      //   "ExternalMeetingId": "talha123",
      //   "MediaRegion": "ap-south-1",
      //   "MediaPlacement": {
      //     "AudioHostUrl": "ac1c95950ffcbc0a216d9785df0b6f3c.k.m2.ao1.app.chime.aws:3478",
      //     "AudioFallbackUrl": "wss://haxrp.m2.ao1.app.chime.aws:443/calls/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "SignalingUrl": "wss://signal.m2.ao1.app.chime.aws/control/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "TurnControlUrl": "https://2713.cell.us-east-1.meetings.chime.aws/v2/turn_sessions",
      //     "ScreenDataUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/v2/screen/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "ScreenViewingUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "ScreenSharingUrl": "wss://bitpw.m2.ao1.app.chime.aws:443/v2/screen/26cd743a-3091-44a2-b950-fc2ca6c62713",
      //     "EventIngestionUrl": "https://data.svc.ue1.ingest.chime.aws/v1/client-events"
      //   },
      //   "PrimaryMeetingId": null,
      //   "TenantIds": [],
      //   "MeetingArn": "arn:aws:chime:us-east-1:210751258169:meeting/26cd743a-3091-44a2-b950-fc2ca6c62713"
      // },
      // {
      //   "Attendee":{
      //     "ExternalUserId": "e7014f93#talha",
      //     "AttendeeId": "d9927038-7af0-e34f-dedd-f56e4bbba4b2",
      //     "JoinToken": "ZDk5MjcwMzgtN2FmMC1lMzRmLWRlZGQtZjU2ZTRiYmJhNGIyOmJkM2ExM2FlLTIxZjUtNGE1Ni1iZmUyLThhYmZkY2YzYWU4Yg",
      //     "Capabilities": {
      //       "Audio": "SendReceive",
      //       "Video": "SendReceive",
      //       "Content": "SendReceive"
      //     }
      //   }
      // }
    );

    // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
    return meetingManager.join(meetingSessionConfiguration).then(() => { });

    // At this point you could let users setup their devices, or by default
    // the SDK will select the first device in the list for the kind indicated
    // by `deviceLabels` (the default value is DeviceLabels.AudioAndVideo)
    // ...

    // Start the `MeetingSession` to join the meeting
  };

  useEffect(() => {
    if (current.tileId !== "") {
      videoRef.current.stop()
      videoRef.current.destroy()
      const video = document.getElementById(`v${current.tileId}`).firstChild.captureStream(25)
      const outputVideo = document.getElementById('video1')
      videoRef.current = new VideoStreamMerger({
        width: current.ratio[0] * 100,
        height: current.ratio[1] * 100
      })
      //
      const videoElement = { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height }
      const width = videoRef.current.width;
      const height = videoRef.current.height;
      const aspectRatio = width / height;

      let newWidth;
      let newHeight;

      const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

      if (aspectRatio >= imageRatio) {
        newWidth = videoElement.videoWidth;
        newHeight = videoElement.videoWidth / aspectRatio;
      } else {
        newWidth = videoElement.videoHeight * aspectRatio;
        newHeight = videoElement.videoHeight;
      }
      var x = (videoElement.videoWidth - newWidth) / 2;
      var y = (videoElement.videoHeight - newHeight) / 2;
      //
      videoRef.current.addStream(video, {
        x: 0,
        y: 0,
        width: newWidth,
        height: newHeight,
        draw: (ctx, frame, done) => {
          if (current.tileId == "local") {
            ctx.scale(-1, 1);
            ctx.translate(-current.ratio[0] * 100, 0);
          }
          ctx.filter = `brightness(${current.brightness}%) contrast(${current.contrast}%) grayscale(${current.grayscale}%) saturate(${current.saturate}%) hue-rotate(${current.hue}deg) blur(${current.blur}px)`
          ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, current.ratio[0] * 100, current.ratio[1] * 100)
          if (current.tileId == "local") {
            ctx.setTransform(1, 0, 0, 1, 0, 0);
          }
          done()
        }
      })
      videoRef.current.start()
      outputVideo.srcObject = videoRef.current.result
      outputVideo.play()
    }
  }, [current.brightness, current.ratio, current.blur, current.grayscale, current.hue, current.saturate, current.contrast])

  useEffect(() => {
    if (meetingStatus != 0) {
      if (mainMerger.current?.addStream) {
        mainMerger.current.stop()
        mainMerger.current.destroy()
      }
      changeGrid()
    }
  }, [meetingStatus, currentGrid])

  function getGridRatio(s) {
    switch (currentGrid.name) {
      case "grid1":
        return [16, 9]
        break
      case "grid2":
        return [8, 9]
        break
      case "grid22":
        if (s == "1") {
          return [10, 9]
        } else {
          return [6, 9]
        }
        break
      case "grid3":
        if (s == "1") {
          return [8, 9]
        } else {
          return [16, 9]
        }
        break
      case "grid4":
        return [16, 9]
        break
    }
  }
  function getPipeForgrid() {
    switch (currentGrid.name) {
      case "grid1":
        return [...pipeline].map((it, i) => ({ ...it, slot: (i + 1).toString() })).slice(0, 1)
      case "grid2":
        return [...pipeline].map((it, i) => ({ ...it, slot: (i + 1).toString() })).slice(0, 2)
      case "grid22":
        return [...pipeline].map((it, i) => ({ ...it, slot: (i + 1).toString() })).slice(0, 2)
      case "grid3":
        return [...pipeline].map((it, i) => ({ ...it, slot: (i + 1).toString() })).slice(0, 3)
      case "grid4":
        return [...pipeline].map((it, i) => ({ ...it, slot: (i + 1).toString() })).slice(0, 4)
    }
  }
  function changeGrid() {
    var canvas = canvasRef?.current
    canvas.width = 1600
    canvas.height = 900
    const ctx = canvas.getContext('2d')
    const img = new Image()
    var videoElement = document.getElementById('mainFrame')
    videoElement.pause();
    videoElement.removeAttribute('src'); // empty source
    videoElement.load();
    const tileCombine = isVideoEnabled ? ["local", ...tiles] : [...tiles]
    if (tileCombine.length > 0 && pipeline[0] != "remove" && pipeline.length > 0) {
      setTimeout(() => {
        setPipeline(getPipeForgrid().map(it => {
          return {
            ...it,
            ratio: getGridRatio(it.slot)
          }
        }))
      }, 500)
    } else {
      setPipeline([])
    }

    setCurrent({
      brightness: 100,
      ratio: [16, 9],
      tileId: "",
      grayscale: 0,
      hue: 0,
      saturate: 100,
      blur: 0,
      contrast: 100
    })
    setSlotDrop("null")
    // outputVideo.clear(true)
    // canvas?.srcObject = ""
    // canvas?.pause()
    // canvas = null
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    img.src = currentGrid.img
    img.onload = function () {
      var scale = Math.max(canvas.width / img.width, canvas.height / img.height);
      // get the top left position of the image
      var x = (canvas.width / 2) - (img.width / 2) * scale;
      var y = (canvas.height / 2) - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      capCanvas.current = canvas.captureStream(25)
      // if (ivsSDK?.current?.addVideoInputDevice && pipeline.length > 0) {

      //   if (ivsSDK?.current?.getVideoInputDevice("camera1")?.name) {
      //     ivsSDK.current.removeVideoInputDevice('camera1')
      //     if (pipeline.length > 0) {
      //       ivsSDK.current.removeAudioInputDevice('mic1')
      //     }
      //   }
      //   audioVideo.getCurrentMeetingAudioStream().then(audioStream => {
      //     ivsSDK.current.addAudioInputDevice(audioStream, "mic1")
      //     ivsSDK.current.addVideoInputDevice(capCanvas.current, 'camera1', { index: 0 });
      //   })
      // }
    }

  }


  useEffect(() => {
    const tileCombine = isVideoEnabled ? ["local", ...tiles] : [...tiles]
    if (tileCombine.length > 0 && pipeline.length > 0) {
      console.log("updated1", pipeline)
      console.log("updated2", tiles)


      const updatedPipe = pipeline.filter(it => tileCombine.find(itSub => itSub == it.tileId))
      console.log("updated", updatedPipe)
      setPipeline(updatedPipe)
      videoRef.current?.stop()
      videoRef.current?.destroy()
      // [...tiles].forEach((it,i)=>{
      //   if([...pipeline].find(itSub=>itSub.tileId==it)){
      //     setPipeline([...pipeline].filter(it=>it.tileId==it))
      //   }
      // })
    }
  }, [tiles])
  useEffect(() => {
    if (meetingStatus != 0 && meetingData[0]?.event_status == 'live') {
      goLive(true)

    }
  }, [meetingStatus])

  useEffect(() => {
    // if (!host) {
    //   if (meetingData) {
    //     joinMeeting().then(async () => {
    //       await meetingManager.start()
    //     })
    //   }
    // } else {
    joinMeeting();
    // }
  }, [meetingData]);

  function ab() {
    audioVideo.getCurrentMeetingAudioStream().then(res => {
      // console.log("m", res)
    })
    // console.log(tiles[0])
    // console.log(audioVideo.captureVideoTile(tiles[0]))
  }

  useEffect(() => {
    if (meetingStatus != 0) {
      if (!isVideoEnabled) {
        toggleAttendee(isVideoEnabled, "local")
      }
    }
  }, [meetingStatus, isVideoEnabled])

  useEffect(() => {
    return () => {
      meetingManager.leave();
    };
  }, []);

  const leaveMeeting = () => {
    return meetingManager.leave();
  };

  useEffect(() => {
    if (pipeline[0] == "remove") {
      changeGrid()
    }
    if (pipeline.length > 0 && pipeline[0] != "remove") {
      if (mainMerger.current?.addStream) {
        mainMerger.current.stop()
        mainMerger.current.destroy()
      }
      mainMerger.current = new VideoStreamMerger({
        width: 1600,
        height: 900
      })
      var canvas = capCanvas.current
      mainMerger.current.addStream(canvas, {
        x: 0,
        y: 0,
        width: 1600,
        height: 900
      })
      pipeline.forEach((it, i) => {
        var mainFrame = document.getElementById('mainFrame')
        const video = document.getElementById(`v${it.tileId}`)?.firstChild.captureStream(25)
        //
        if (!(video?.getTracks()[0]?.getSettings)) {
          mainFrame.pause();
          mainFrame.removeAttribute('src'); // empty source
          mainFrame.load();
        }
        if (video) {
          const videoElement = (video?.getTracks()[0]?.getSettings) ? { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height } : video
          const width = it.ratio[0] * 100;
          const height = it.ratio[1] * 100;
          const aspectRatio = width / height;

          let newWidth;
          let newHeight;

          const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

          if (aspectRatio >= imageRatio) {
            newWidth = videoElement.videoWidth;
            newHeight = videoElement.videoWidth / aspectRatio;
          } else {
            newWidth = videoElement.videoHeight * aspectRatio;
            newHeight = videoElement.videoHeight;
          }
          var x = (videoElement.videoWidth - newWidth) / 2;
          var y = (videoElement.videoHeight - newHeight) / 2;
          switch (currentGrid.name) {
            case "grid1":
              mainMerger.current.addStream(video, {
                x: 0,
                y: 0,
                width: newWidth,
                height: newHeight,
                draw: (ctx, frame, done) => {
                  ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`
                  switch (it.slot) {
                    case "1":
                      if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-((it.ratio[0] * 100) + 2 * 500), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, 500, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + 2 * 400), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, 400, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + 2 * 0), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + 2 * 300), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, 300, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                  }
                }
              })
              break
            case "grid2":
              var margin = it.slot == 1 ? 0 : 800
              mainMerger.current.addStream(video, {
                x: margin,
                y: 0,
                width: newWidth,
                height: newHeight,
                draw: (ctx, frame, done) => {
                  ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`
                  if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(it.ratio[0] * 100 + (2 * (it.slot == 1 ? (margin + 200) : margin))), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, it.slot == 1 ? (margin + 200) : margin, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(it.ratio[0] * 100 + (2 * margin)), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 0, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(800 + (2 * margin)), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 225, 800, 450)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(800 + (2 * margin)), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 90, 800, 720)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                }
              })
              break
            case "grid22":
              var margin = it.slot == 1 ? 0 : 1000
              mainMerger.current.addStream(video, {
                x: margin,
                y: 0,
                width: newWidth,
                height: newHeight,
                draw: (ctx, frame, done) => {
                  ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`
                  switch (it.slot) {
                    case "1":
                      if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + (2 * (margin + 200))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 200, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-((it.ratio[0] * 100) + margin + (2 * 100)), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 100, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(1000 + margin), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 169, 1000, 562)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + margin + (2 * 0)), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                    case "2":
                      if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(600 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 180, 600, 540)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(600 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 112.5, 600, 675)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(600 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 281, 600, 338)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                  }
                }
              })
              break
            case "grid3":
              var margin = it.slot == "1" ? 0 : 800
              var marginY = it.slot == "3" ? 450 : 0
              mainMerger.current.addStream(video, {
                x: margin,
                y: 0,
                width: newWidth,
                height: newHeight,
                draw: (ctx, frame, done) => {
                  ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`
                  switch (it.slot) {
                    case "1":
                      if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + (2 * (margin + 100))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 100, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(it.ratio[0] * 100 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 0, 0, it.ratio[0] * 100, it.ratio[1] * 100)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(800 + (2 * margin)), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 225, 800, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(800 + (2 * margin)), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 90, 800, 720)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                    case "2":
                      if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(500 + (2 * (margin + 150))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 150, 0, 500, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(400 + (2 * (margin + 200))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 200, 0, 400, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(800 + (2 * (margin))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, 0, 800, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(300 + (2 * (margin + 250))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 250, 0, 300, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                    case "3":
                      if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(500 + (2 * (margin + 150))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 150, marginY, 500, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(400 + (2 * (margin + 200))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 200, marginY, 400, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(800 + (2 * (margin + 0))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin, marginY, 800, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      else if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                        if (it.tileId == "local") {
                          ctx.scale(-1, 1);
                          ctx.translate(-(300 + (2 * (margin + 250))), 0);
                        }
                        ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 250, marginY, 300, 450)
                        if (it.tileId == "local") {
                          ctx.setTransform(1, 0, 0, 1, 0, 0);
                        }
                        done()
                      }
                      break
                  }
                }
              })
              break
            case "grid4":
              var margin = (it.slot == "1" || it.slot == "3") ? 0 : 800
              var marginY = (it.slot == "3" || it.slot == "4") ? 450 : 0
              mainMerger.current.addStream(video, {
                x: margin,
                y: 0,
                width: newWidth,
                height: newHeight,
                draw: (ctx, frame, done) => {
                  ctx.filter = `brightness(${it.brightness}%) contrast(${it.contrast}%) grayscale(${it.grayscale}%) saturate(${it.saturate}%) hue-rotate(${it.hue}deg) blur(${it.blur}px)`
                  if ((it.ratio[0] == 10 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(500 + (2 * (margin + 150))), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 150, marginY, 500, 450)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 8 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(400 + (2 * (margin + 200))), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 200, marginY, 400, 450)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 16 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(800 + (2 * (margin + 0))), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin, marginY, 800, 450)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                  else if ((it.ratio[0] == 6 && it.ratio[1] == 9)) {
                    if (it.tileId == "local") {
                      ctx.scale(-1, 1);
                      ctx.translate(-(300 + (2 * (margin + 250))), 0);
                    }
                    ctx.drawImage(frame, x, y, newWidth, newHeight, margin + 250, marginY, 300, 450)
                    if (it.tileId == "local") {
                      ctx.setTransform(1, 0, 0, 1, 0, 0);
                    }
                    done()
                  }
                }
              })
              break
          }
        }
      })
      // mainMerger.current.setOutputSize(IVSBroadcastClient.BASIC_LANDSCAPE.maxResolution.width, IVSBroadcastClient.BASIC_LANDSCAPE.maxResolution.height)
      mainMerger.current.start()
      const outputVideo = document.getElementById('mainFrame')
      outputVideo.srcObject = mainMerger.current.result
      outputVideo.play()
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 0,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100
      })
      setSlotDrop("null")
      if (ivsSDK?.current?.addVideoInputDevice) {
        const outputVideo = document.getElementById('mainFrame').captureStream(25)

        if (ivsSDK?.current?.getVideoInputDevice("camera1")?.name) {
          ivsSDK.current.removeVideoInputDevice('camera1')
          ivsSDK.current.removeAudioInputDevice('mic1')
        }
        audioVideo.getCurrentMeetingAudioStream().then(audioStream => {
          ivsSDK.current.addAudioInputDevice(audioStream, "mic1")
          ivsSDK.current.addVideoInputDevice(outputVideo, 'camera1', { index: 0 });
        })
      }
    }
  }, [pipeline])

  useEffect(() => {
    if (meetingStatus == 3) {
      meetingManager.leave().then(() => {
        // notification.success({
        //   message: "Event has ended",
        //   className: "toast-success",
        // });
        history({
          pathname: `/events`,
        });
      });
    }
  }, [meetingStatus]);

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  if (meetingStatus == 0) {
    return (
      <UserActivityProvider>
        <DeviceSetup
          joinMeeting={async (cb) => {
            await meetingManager.start();
            // cb()
          }}
          checkEvent={checkEvent}
          meetingData={meetingData}
          ratio={ratio}
          setRatio={setRatio}
          meetingChecker={meetingData ? null : 'Event not started by producer'}
          leaveMeeting={leaveMeeting}
          host={host}
          endMeeting={endMeeting}
        />
      </UserActivityProvider>
    );
  }

  const changeBrightness = (value) => {
    setBrightness(value);
  };
  const changeSaturation = (value) => {
    setSaturation(value);
  };

  const closeSettingModal = () => {
    videoRef.current.stop()
    videoRef.current.destroy()
    setCurrent({
      brightness: 100,
      ratio: [16, 9],
      tileId: "",
      grayscale: 1,
      hue: 0,
      saturate: 100,
      blur: 0,
      contrast: 100
    })
    setAspectRatio('16:9')
    setBrightness(100)
    setContrast(100)
    setSaturation(100)
    setBlur(0)
    setHue(0)
    setSlotDrop("null")
    setGrayscale(0)
    setShowSettingModal(!showSettingModal);
    setSubmit(false)
  };

  const onSaveSetting = () => {
    // const attendeeIds=Object.entries(tileIdToAttendeeId)

    if (true) {
      var pipeClone = [...pipeline]
      // const exist = pipeline.find(({ tileId, slot }) => {
      //   // alert(JSON.stringify({ til: tileId == current.tileId, slo: slot == slotDrop }))
      //   // console.log(tileId, current.tileId, slot, slotDrop)
      //   return tileId == current.tileId || slot == slotDrop
      // })
      pipeClone = pipeClone.filter(it => {
        // console.log(it.slot != slotDrop)
        // console.log(slotDrop)
        // console.log(it.slot)

        return ((it.tileId != current.tileId) && (it.slot != slotDrop))
      })
      // console.log(pipeClone, "pip")
      // const exist = pipeline.find(({ tileId, slot }) => slot == slotDrop)

      // if (pipeline.find(({ tileId, slot }) => tileId == current.tileId && slot == slotDrop)) {
      //   alert("run")
      // }

      // pipeClone = pipeClone.filter(({ tileId, slot }) => {
      //   return !(tileId == current.tileId || slot != slotDrop)
      // })

      // if (exist) {
      //   alert("run")
      //   const pipRef = pipeClone.map(it => {
      //     if ((it.slot == exist.slot)) {
      //       console.log("slot", { ...current, slot: slotDrop })
      //       return { ...current, slot: slotDrop }
      //     } else {
      //       return it
      //     }
      //   })
      //   // console.log("pipeRef", pipRef)
      //   setPipeline(pipRef)
      // } else {
      setPipeline([...pipeClone, { ...current, slot: slotDrop }])
      // }

      videoRef.current.stop()
      videoRef.current.destroy()
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 1,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100
      })
      setAspectRatio('16:9')
      setBrightness(100)
      setContrast(100)
      setSaturation(100)
      setBlur(0)
      setHue(0)
      setGrayscale(0)
      setShowSettingModal(!showSettingModal);
    }
  };

  const onSaveSettingToggle = (tileId) => {
    if (currentGrid.name == "grid1") {
      setPipeline([{
        ...current,
        tileId: tileId,
        slot: "1",
        ratio: [16, 9]
      }])
      videoRef.current?.stop()
      videoRef.current?.destroy()
    }
    else if (currentGrid.name == "grid2") {
      if (pipeline.length > 0) {
        if (pipeline[0].slot == "1") {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [8, 9]
          }])
        } else {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [8, 9]
          }])
        }
      } else {
        setPipeline([{
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [8, 9]
        }])
      }
      videoRef.current?.stop()
      videoRef.current?.destroy()
    }
    else if (currentGrid.name == "grid22") {
      if (pipeline.length > 0) {
        if (pipeline[0].slot == "1") {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [6, 9]
          }])
        } else {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [10, 9]
          }])
        }
      } else {
        setPipeline([{
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [10, 9]
        }])
      }
      videoRef.current?.stop()
      videoRef.current?.destroy()
    }
    else if (currentGrid.name == "grid3") {
      if (pipeline.length > 0) {
        if (pipeline.find(it => it.slot == "1") && (!pipeline.find(it => it.slot == "2"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "2") && (!pipeline.find(it => it.slot == "3"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "3",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "3") && (!pipeline.find(it => it.slot == "1"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "3") && (!pipeline.find(it => it.slot == "2"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [16, 9]
          }])
        }

      } else {
        setPipeline([{
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [8, 9]
        }])
      }
      videoRef.current?.stop()
      videoRef.current?.destroy()
    }
    else if (currentGrid.name == "grid4") {
      if (pipeline.length > 0) {
        if (pipeline.find(it => it.slot == "1") && (!pipeline.find(it => it.slot == "2"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "2") && (!pipeline.find(it => it.slot == "3"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "3",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "3") && (!pipeline.find(it => it.slot == "4"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "4",
            ratio: [16, 9]
          }])
        }

        else if (pipeline.find(it => it.slot == "4") && (!pipeline.find(it => it.slot == "1"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "1",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "4") && (!pipeline.find(it => it.slot == "2"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "2",
            ratio: [16, 9]
          }])
        }
        else if (pipeline.find(it => it.slot == "4") && (!pipeline.find(it => it.slot == "3"))) {
          setPipeline([...pipeline, {
            ...current,
            tileId: tileId,
            slot: "3",
            ratio: [16, 9]
          }])
        }
      } else {
        setPipeline([{
          ...current,
          tileId: tileId,
          slot: "1",
          ratio: [16, 9]
        }])
      }
      videoRef.current?.stop()
      videoRef.current?.destroy()
    }
  };

  const goLive = async (initialStart) => {
    if (pipeline.length > 0 || initialStart) {
      const outputVideo = document.getElementById('mainFrame')?.captureStream(25)
      const imgCanvas = document.getElementById('imgGrid')?.captureStream(25)

      // document.getElementById("mainFrame").style.display = "none";
      // console.log("out", outputVideo.getVideoTracks())
      ivsSDK.current = IVSBroadcastClient.create({
        // Enter the desired stream configuration
        streamConfig: IVSBroadcastClient.BASIC_LANDSCAPE,
        // Enter the ingest endpoint from the AWS console or CreateChannel API
        ingestEndpoint: channel?.rtmp,
      });

      // const previewEl = document.getElementById('preview');
      // client.attachPreview(previewEl);
      if (meetingData[0]?.event_status == 'live' && initialStart) {
        ivsSDK.current.addVideoInputDevice(imgCanvas, 'camera1', { index: 0 });
      } else {
        ivsSDK.current.addVideoInputDevice(outputVideo, 'camera1', { index: 0 });
      }
      const audioStream = await audioVideo.getCurrentMeetingAudioStream()
      // const ss = new MediaStream(audioStream.getTracks())
      ivsSDK.current.addAudioInputDevice(audioStream, "mic1")
      ivsSDK.current
        .startBroadcast(channel.key)
        .then((result) => {
          onGolive()
          console.log('I am successfully broadcasting!');
        })
        .catch((error) => {
          console.error('Something drastically failed while broadcasting!', error);
        });
      // client.stopBroadcast()
    } else {
      alert("Add atleast one attendee")
    }

  }

  const toggleAttendee = (checked, tileId) => {
    if (checked) {
      onSaveSettingToggle(tileId)
    } else {
      const data = [...pipeline].filter(it => it.tileId != tileId)
      console.log("dddddddddd", data)
      if (data.length > 0) {
        setPipeline(data)
      } else {
        setPipeline(["remove"])
      }
      setCurrent({
        brightness: 100,
        ratio: [16, 9],
        tileId: "",
        grayscale: 0,
        hue: 0,
        saturate: 100,
        blur: 0,
        contrast: 100

      })
      setAspectRatio('16:9')
      setBrightness(100)
      setContrast(100)
      setSaturation(100)
      setBlur(0)
      setHue(0)
      setSlotDrop("null")
      setGrayscale(0)
      setSubmit(false)
      setShowSettingModal(false)
    }
  }


  function renderTopVideos() {
    console.log("myTies", tiles)
    const tileCombine = isVideoEnabled ? ["local", ...tiles] : [...tiles]
    return tileCombine.map((it, i) => {
      if (it == "local") {
        return (
          <div key={i} className="producer-input-info">
            <div className="producer-video-thumb-main dimension-fix" >
              <LocalVideo id={`v${it}`} />
            </div>
            <div className='producer-video-data'>
              <div className='producer-title'>
                Me
              </div>
              <div className="producer-input-control">
                <Switch
                  checkedChildren="On"
                  checked={pipeline.find(itSub => itSub.tileId == it) ? true : false}
                  unCheckedChildren="Off"
                  onChange={(checked) => toggleAttendee(checked, it)}
                  className="switch-toggle-color mb-1"
                />
                <button className="producer-btn default-btn small-btn"
                  onClick={() => {
                    setCurrent(pre => {
                      return {
                        ...pre,
                        tileId: it
                      }
                    })
                    setShowSettingModal(true)
                    setTimeout(() => {
                      if (videoRef.current?.stop) {
                        videoRef.current.stop()
                        videoRef.current.destroy()
                      }
                      const currentData = pipeline.filter(itSub => itSub.tileId == it)[0]

                      if (currentData) {
                        // alert(JSON.stringify(currentData))
                        setCurrent({ ...currentData, tileId: it })
                        setSlotDrop(currentData.slot)
                        setAspectRatio(`${currentData.ratio[0]}:${currentData.ratio[1]}`)
                        setBrightness(currentData.brightness)
                        setContrast(currentData.contrast)
                        setSaturation(currentData.saturate)
                        setGrayscale(currentData.grayscale)
                        setBlur(currentData.blur)
                        setHue(currentData.hue)
                        const video = document.getElementById(`v${it}`).firstChild.captureStream(25)
                        const outputVideo = document.getElementById('video1')
                        videoRef.current = new VideoStreamMerger({
                          width: currentData.ratio[0] * 100,
                          height: currentData.ratio[1] * 100
                        })
                        const videoElement = { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height }
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;
                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.scale(-1, 1);
                            ctx.translate(-videoRef.current.width, 0);
                            ctx.filter = `brightness(${currentData ? currentData.brightness : current.brightness}%) contrast(${currentData ? currentData.contrast : current.contrast}%) grayscale(${currentData ? currentData.grayscale : current.grayscale}%) saturate(${currentData ? currentData.saturate : current.saturate}%) hue-rotate(${currentData ? currentData.hue : current.hue}deg) blur(${currentData ? currentData.blur : current.blur}px)`
                            ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, videoRef.current.width, videoRef.current.height)
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                            done()
                          }
                        })
                        videoRef.current.start()
                        outputVideo.srcObject = videoRef.current.result
                        outputVideo.play()
                      } else {
                        const video = document.getElementById(`v${it}`)?.firstChild.captureStream(25)
                        const outputVideo = document.getElementById('video1')
                        videoRef.current = new VideoStreamMerger({
                          width: current.ratio[0] * 100,
                          height: current.ratio[1] * 100
                        })

                        const videoElement = { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height }
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;

                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.scale(-1, 1);
                            ctx.translate(-videoRef.current.width, 0);
                            ctx.filter = `brightness(${currentData ? currentData.brightness : current.brightness}%) contrast(${currentData ? currentData.contrast : current.contrast}%) grayscale(${currentData ? currentData.grayscale : current.grayscale}%) saturate(${currentData ? currentData.saturate : current.saturate}%) hue-rotate(${currentData ? currentData.hue : current.hue}deg) blur(${currentData ? currentData.blur : current.blur}px)`
                            ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, videoRef.current.width, videoRef.current.height)
                            ctx.setTransform(1, 0, 0, 1, 0, 0);
                            done()
                          }
                        })
                        videoRef.current.start()
                        outputVideo.srcObject = videoRef.current.result
                        outputVideo.play()
                      }

                    }, 200)
                  }}
                >
                  {/* <FontAwesomeIcon icon={faPlus} /> */}
                  Setting
                </button>

              </div>
            </div>

          </div>
        )
      } else {
        return (
          <div key={i} className="producer-input-info">
            <div className="producer-video-thumb-main dimension-fix" >
              <RemoteVideo tileId={it} id={`v${it}`} />
            </div>
            <div className='producer-video-data'>
              <div className='producer-title'>
                {`Host ${i + 1}`}
              </div>
              <div className="producer-input-control">
                <Switch
                  checkedChildren="On"
                  checked={pipeline.find(itSub => itSub.tileId == it) ? true : false}
                  unCheckedChildren="Off"
                  onChange={(checked) => toggleAttendee(checked, it)}
                  className="switch-toggle-color mb-1"
                />
                <button className="producer-btn default-btn small-btn"
                  onClick={() => {
                    setCurrent(pre => {
                      return {
                        ...pre,
                        tileId: it
                      }
                    })
                    setShowSettingModal(true)
                    setTimeout(() => {
                      if (videoRef.current?.stop) {
                        videoRef.current.stop()
                        videoRef.current.destroy()
                      }
                      const currentData = pipeline.filter(itSub => itSub.tileId == it)[0]

                      if (currentData) {
                        // alert(JSON.stringify(currentData))
                        setCurrent({ ...currentData, tileId: it })
                        setSlotDrop(currentData.slot)
                        setAspectRatio(`${currentData.ratio[0]}:${currentData.ratio[1]}`)
                        setBrightness(currentData.brightness)
                        setContrast(currentData.contrast)
                        setSaturation(currentData.saturate)
                        setGrayscale(currentData.grayscale)
                        setBlur(currentData.blur)
                        setHue(currentData.hue)
                        const video = document.getElementById(`v${it}`)?.firstChild.captureStream(25)
                        const outputVideo = document.getElementById('video1')
                        videoRef.current = new VideoStreamMerger({
                          width: currentData.ratio[0] * 100,
                          height: currentData.ratio[1] * 100
                        })
                        const videoElement = { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height }
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;
                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.filter = `brightness(${currentData ? currentData.brightness : current.brightness}%) contrast(${currentData ? currentData.contrast : current.contrast}%) grayscale(${currentData ? currentData.grayscale : current.grayscale}%) saturate(${currentData ? currentData.saturate : current.saturate}%) hue-rotate(${currentData ? currentData.hue : current.hue}deg) blur(${currentData ? currentData.blur : current.blur}px)`
                            ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, videoRef.current.width, videoRef.current.height)
                            done()
                          }
                        })
                        videoRef.current.start()
                        outputVideo.srcObject = videoRef.current.result
                        outputVideo.play()
                      } else {
                        const video = document.getElementById(`v${it}`)?.firstChild.captureStream(25)
                        const outputVideo = document.getElementById('video1')
                        videoRef.current = new VideoStreamMerger({
                          width: current.ratio[0] * 100,
                          height: current.ratio[1] * 100
                        })
                        console.log("select", video.getTracks()[0].getSettings().width, video.getTracks()[0].getSettings().height)
                        const videoElement = { videoWidth: video.getTracks()[0].getSettings().width, videoHeight: video.getTracks()[0].getSettings().height }
                        const width = videoRef.current.width;
                        const height = videoRef.current.height;
                        const aspectRatio = width / height;

                        let newWidth;
                        let newHeight;

                        const imageRatio = videoElement.videoWidth / videoElement.videoHeight;

                        if (aspectRatio >= imageRatio) {
                          newWidth = videoElement.videoWidth;
                          newHeight = videoElement.videoWidth / aspectRatio;
                        } else {
                          newWidth = videoElement.videoHeight * aspectRatio;
                          newHeight = videoElement.videoHeight;
                        }
                        var x = (videoElement.videoWidth - newWidth) / 2;
                        var y = (videoElement.videoHeight - newHeight) / 2;

                        videoRef.current.addStream(video, {
                          x: 0,
                          y: 0,
                          width: videoRef.current.width,
                          height: videoRef.current.height,
                          draw: (ctx, frame, done) => {
                            ctx.filter = `brightness(${currentData ? currentData.brightness : current.brightness}%) contrast(${currentData ? currentData.contrast : current.contrast}%) grayscale(${currentData ? currentData.grayscale : current.grayscale}%) saturate(${currentData ? currentData.saturate : current.saturate}%) hue-rotate(${currentData ? currentData.hue : current.hue}deg) blur(${currentData ? currentData.blur : current.blur}px)`
                            ctx.drawImage(frame, x, y, newWidth, newHeight, 0, 0, videoRef.current.width, videoRef.current.height)
                            done()
                          }
                        })
                        videoRef.current.start()
                        outputVideo.srcObject = videoRef.current.result
                        outputVideo.play()
                      }

                    }, 200)
                  }}
                >
                  Setting
                </button>

              </div>
            </div>

          </div>
        )
      }
    })
  }

  function getSlot() {
    if (currentGrid.name == "grid1") {
      return ["null", "1"]
    }
    switch (currentGrid.name) {
      case "grid1":
        return ["null", "1"]
        break
      case "grid2":
        return ["null", "1", "2"]
        break
      case "grid22":
        return ["null", "1", "2"]
        break
      case "grid3":
        return ["null", "1", "2", "3"]
        break
      case "grid32":
        return ["null", "1", "2", "3"]
        break
      case "grid4":
        return ["null", "1", "2", "3", "4"]
        break
    }
  }

  function getAspectRatioLabel() {
    if (currentGrid.name == "grid1") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9 (Auto fill)"
        },
      ]
    }
    else if (currentGrid.name == "grid2") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9 (Auto fill)",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9"
        },
      ]

    }
    else if (currentGrid.name == "grid22") {
      if (slotDrop == 1) {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9 (Auto fill)",
          },
          {
            value: "16:9",
            label: "16:9"
          },
        ]
      } else {
        return [
          {
            value: "6:9",
            label: "6:9 (Auto fill)",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9"
          },
        ]
      }
    }
    else if (currentGrid.name == "grid3") {
      if (slotDrop == 1) {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9 (Auto fill)",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9"
          },
        ]
      }
      else {
        return [
          {
            value: "6:9",
            label: "6:9",
          },
          {
            value: "8:9",
            label: "8:9",
          },
          {
            value: "10:9",
            label: "10:9",
          },
          {
            value: "16:9",
            label: "16:9 (Auto fill)"
          },
        ]
      }
    }
    else if (currentGrid.name == "grid4") {
      return [
        {
          value: "6:9",
          label: "6:9",
        },
        {
          value: "8:9",
          label: "8:9",
        },
        {
          value: "10:9",
          label: "10:9",
        },
        {
          value: "16:9",
          label: "16:9 (Auto fill)"
        },
      ]
    }
  }
  return (
    <>
      <UserActivityProvider>
        <div className="producer-input-box">
          {renderTopVideos()}
        </div>
        <div className={`host-box-wrapper`}>
          <div className='control-grid-views-left'>
            <div
              className={`video-box localvideosingle position-relative ${ratio.w === 8 ? 'ratio-4' : 'ratio-16'
                } ${tiles?.length > 0 && isVideoEnabled ? 'ratio-4-multiview' : ''
                } `} ref={gridRef}
            >
              {/* <div className='single-numbrer-view-main'> */}
              <div style={{ position: 'absolute', width: '100%' }}>
                <canvas style={{ width: '100%' }} id="imgGrid" ref={canvasRef} />
                {/* <img id='imgGrid' src={image} width="100%" height={"100%"} /> */}
              </div>


              {(meetingData[0]?.event_status == 'live'
                ? meetingData[0]?.event_status
                : live) == 'live' && (
                  <div style={{ position: 'absolute', zIndex: 10 }}>
                    <div
                      style={{ backgroundColor: 'red', color: 'white', padding: 5 }}
                    >
                      Live
                    </div>
                  </div>
                )}
              <div
                style={!host ? { height: '100%', boxSizing: 'border-box' } : {}}
              >
                <div
                  //  style={{ minHeight: 300, maxHeight: 300 }}
                  className="single-grid video-full-main row no-gutters">
                  <div className='col-12'>
                    <video className='video-full' id="mainFrame" >
                    </video>
                    {/* <canvas className='video-full' id="mainFrame"></canvas> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="control-grid-views-right scrollbar-style-grid"
            style={{ height: height }}
          >
            {grids.map((it, i) => {
              return (
                <button
                  onClick={() => {
                    setCurrentGrid(it)
                  }}
                  key={i} className='grid-views view1'>
                  <img className='grid-img' src={it.img} alt={it.name} />
                </button>
              )
            })}
          </div>
        </div>
        {showSettingModal && (
          <Modal
            className="modal-generic modal-474"
            centered
            visible={showSettingModal}
            // onOk={() => {
            //   endMeeting();
            //   onConfirm();
            // }}
            onCancel={closeSettingModal}
            closeIcon={<FontAwesomeIcon icon={faXmark} />}
            footer={false}
            maskClosable={false}
            keyboard={false}
          >
            <div className='producer-setting'>
              <h2 className="modal-hd1">Setting</h2>
              <div className='modal-video-box-main'>
                <video className='modal-video' id="video1" autoPlay controls={false} width={"100%"}></video>
              </div>
              <div className='mt-3'>
                <Label>Slot</Label>
                <Select
                  // value={JSON.stringify(ratio)}
                  options={getSlot().map(it => {
                    return {
                      value: it,
                      label: it == "null" ? "Select slot" : it
                    }
                  })}
                  placeholder="Select slot"
                  aria-label="Slot"
                  value={slotDrop}
                  onChange={(e) => {
                    // alert(e.target.value)
                    setSlotDrop(e.target.value)
                  }}
                />
              </div>
              {(submit && slotDrop == "null") && (
                <div>
                  <small style={{ color: 'red' }}>Please select</small>
                </div>
              )}
              <div>
                <Label>Aspect Ratio</Label>
                <Select
                  // value={JSON.stringify(ratio)}
                  // disabled={slotDrop == "null" ? true : false}
                  options={getAspectRatioLabel()}
                  aria-label="Aspect Ratio"
                  value={aspectRatio}
                  onChange={(e) => {
                    const d = e.target.value.split(":")
                    setAspectRatio(e.target.value)
                    setCurrent(pre => {
                      return {
                        ...pre,
                        ratio: [Number(d[0]), Number(d[1])]
                      }
                    })
                  }}
                />
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Brightness</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          value={brightness}
                          defaultValue={brightness}
                          onChange={changeBrightness}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                brightness: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {brightness}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Contrast</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          defaultValue={contrast}
                          value={contrast}
                          onChange={setContrast}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                contrast: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {contrast}
                      </Col>
                    </Row>
                  </div>
                </div>

              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Saturation</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          min={0}
                          max={200}
                          value={saturation}
                          onChange={changeSaturation}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                saturate: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {saturation}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Grayscale</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          defaultValue={grayscale}
                          onChange={setGrayscale}
                          value={grayscale}
                          min={0}
                          max={100}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                grayscale: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {grayscale}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Blur</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          defaultValue={blur}
                          onChange={setBlur}
                          value={blur}
                          min={0}
                          max={100}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                blur: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {blur}
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='mt-3'>
                    <Label>Hue</Label>
                    <Row className='d-flex align-items-center'>
                      <Col span={20}>
                        <Slider
                          defaultValue={hue}
                          onChange={setHue}
                          value={hue}
                          min={0}
                          max={360}
                          onAfterChange={(v) => {
                            setCurrent(pre => {
                              return {
                                ...pre,
                                hue: v
                              }
                            })
                          }}
                        />
                      </Col>
                      <Col span={4} className="text-right">
                        {hue}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <button type="button" className="default-btn medium-btn padb-3"
                  onClick={() => {
                    if (slotDrop != "null") {
                      if (currentGrid.name == "grid1") {
                        if (pipeline.length <= 1) {
                          onSaveSetting()
                        } else {
                          alert("You are exceeding the limit")
                        }
                      }
                      else if (currentGrid.name == "grid2") {
                        if (pipeline.length <= 2) {
                          onSaveSetting()
                        } else {
                          alert("You are exceeding the limit")
                        }
                      }
                      else if (currentGrid.name == "grid22") {
                        if (pipeline.length <= 2) {
                          onSaveSetting()
                        } else {
                          alert("You are exceeding the limit")
                        }
                      }
                      else if (currentGrid.name == "grid3") {
                        if (pipeline.length <= 3) {
                          onSaveSetting()
                        } else {
                          alert("You are exceeding the limit")
                        }
                      }
                      else if (currentGrid.name == "grid4") {
                        if (pipeline.length <= 4) {
                          onSaveSetting()
                        } else {
                          alert("You are exceeding the limit")
                        }
                      }
                    } else {
                      setSubmit(true)
                    }
                  }}
                >
                  <span>{pipeline.find(it => it.slot == slotDrop) ? "Update" : "Add"}</span>
                </button>
                {pipeline.find(it => it.tileId == current.tileId) && (
                  <button
                    onClick={() => {
                      const data = [...pipeline].filter(it => it.tileId != current.tileId)
                      if (data.length > 0) {
                        setPipeline(data)
                      } else {
                        setPipeline(["remove"])
                      }
                      setCurrent({
                        brightness: 100,
                        ratio: [16, 9],
                        tileId: "",
                        grayscale: 0,
                        hue: 0,
                        saturate: 100,
                        blur: 0,
                        contrast: 100

                      })
                      setAspectRatio('16:9')
                      setBrightness(100)
                      setContrast(100)
                      setSaturation(100)
                      setBlur(0)
                      setHue(0)
                      setSlotDrop("null")
                      setGrayscale(0)
                      setSubmit(false)
                      setShowSettingModal(false)
                    }}
                    type="button" className="default-btn medium-btn padb-3">
                    <span>Remove</span>
                  </button>
                )}
                <button
                  onClick={() => {
                    setBrightness(100)
                    setGrayscale(0)
                    setHue(0)
                    setBlur(0)
                    setContrast(100)
                    setSaturation(100)
                    setAspectRatio('16:9');
                    setSlotDrop("null")
                    setCurrent(pre => {
                      return {
                        ...pre,
                        brightness: 100,
                        ratio: [16, 9],
                        grayscale: 0,
                        hue: 0,
                        saturate: 100,
                        blur: 0,
                        contrast: 100
                      }
                    })
                  }}
                  type="button" className="default-btn medium-btn padb-3">
                  <span>Reset</span>
                </button>
                <button
                  onClick={() => setShowSettingModal(false)}
                  type="button" className="default-btn medium-btn padb-3">
                  <span>Exit</span>
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Control
          ratio={ratio}
          onLocalVideo={() => toggleAttendee(true, "local")}
          setRatio={setRatio}
          setLeaveMet={setLeaveMet}
          live={
            meetingData[0]?.event_status == 'live'
              ? meetingData[0]?.event_status
              : live
          }
          onGolive={onGolive}
          host={host}
          leaveMeeting={leaveMeeting}
          stopLiveEvent={stopLiveEvent}
          endMeeting={() => {
            if (ivsSDK.current?.stopBroadcast) ivsSDK.current.stopBroadcast()
            endMeeting()
          }}
          meetingData={meetingData}
          goLive={goLive}
        />

        {!endMeetingFlag &&
          meetingStatus != 3 &&
          !leavemet &&
          (meetingData[0]?.event_status == 'live'
            ? meetingData[0]?.event_status
            : live) == 'live' && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {host
                          ? 'Are you sure you want to leave event?'
                          : 'Are you sure you want to end event?'}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
        {(meetingData[0]?.event_status == 'live'
          ? meetingData[0]?.event_status
          : live) != 'live' &&
          meetingStatus != 3 && (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ReactRouterPrompt when={meetingData}>
                {({ isActive, onConfirm, onCancel }) =>
                  isActive && (
                    <Modal
                      className="modal-generic modal-500"
                      centered
                      visible={isActive}
                      onOk={() => {
                        endMeeting();
                        onConfirm();
                      }}
                      onCancel={onCancel}
                      closeIcon={<FontAwesomeIcon icon={faXmark} />}
                      footer={[
                        <Button type="primary" onClick={onCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          // onClick={handleOk}
                          onClick={() => {
                            endMeeting ? endMeeting() : meetingManager.leave();
                            // endMeeting()
                            onConfirm();
                          }}
                        >
                          Ok
                        </Button>,
                      ]}
                    >
                      <h2 className="modal-hd1">Warning</h2>
                      <h5>
                        {!host
                          ? 'Do you want to exit the event'
                          : 'Do you want to leave this event'}
                      </h5>
                    </Modal>
                    // <div className="lightbox">
                    //   <div className="container">
                    //     <p>Do you really want to leave?</p>
                    //     <button onClick={onCancel}>Cancel</button>
                    //     <button onClick={onConfirm}>Ok</button>
                    //   </div>
                    // </div>
                  )
                }
              </ReactRouterPrompt>
            </ErrorBoundary>
          )}
      </UserActivityProvider>
    </>
  );
};

const Root = ({
  meetingData,
  host,
  endMeeting,
  onGolive,
  live,
  checkEvent,
  endMeetingFlag,
  channel,
  stopLiveEvent
}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <NotificationProvider>
        <Notifications />
        <BackgroundBlurProvider>
          <MeetingProvider>
            <NavigationProvider>
              <AudioVideoProvider>
                <MyApp
                  channel={channel}
                  checkEvent={checkEvent}
                  meetingData={meetingData}
                  endMeetingFlag={endMeetingFlag}
                  onGolive={onGolive}
                  host={host}
                  endMeeting={endMeeting}
                  live={live}
                  stopLiveEvent={stopLiveEvent}
                />
              </AudioVideoProvider>
            </NavigationProvider>
          </MeetingProvider>
        </BackgroundBlurProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default Root;