import React from 'react';
import Router from './routing/routes';
import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fonts/flaticon/_flaticon.scss';
import './scss/custom.scss';
import './css/generic.scss';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { notification } from 'antd';

export const history = createBrowserHistory({
  forceRefresh: true,
});

const App = () => {
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response?.status === 401) {
        notification.error({
          message: 'Session Expired!!',
          className: 'toast-error',
        });
        localStorage.removeItem('userInfo');
        setTimeout(() => {
          history.push('/');
          window.location.reload();
        }, 1000);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
};
export default App;
