import React, { useEffect, useState } from 'react';
import '../../css/sales.scss';
import { Button, Form, DatePicker, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getSocialStoreSales } from '../../redux/actions/socialStoreSales.action';
import moment from 'moment';
import numeral from 'numeral';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function StoreSales() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const fromDate = moment().startOf('month').format('YYYY-MM-DD');
  const toDate = moment(new Date()).format('YYYY-MM-DD');
  const [getEvent, setGetEvent] = useState([]);
  const [totalEvent, setTotalEvent] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);
  const [groupBy, setGroupBy] = useState('');
  const [submit, setSubmit] = useState('');

  const limit = 8;
  const upperLimit = (currentPage + 1) * limit;
  const lowerLimit = upperLimit - limit + 1;

  useEffect(() => {
    setLoading(true);
    dispatch(
      getSocialStoreSales(groupBy, 1, limit, startDate, endDate, 'shop')
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  }, []);

  const dateRangePickerChanger = (value, dataString) => {
    const startDate = dataString[0];
    const endDate = dataString[1];
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    setLoading(true);
    dispatch(
      getSocialStoreSales(groupBy, page + 1, limit, startDate, endDate, 'shop')
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(0);
    setLoading(true);
    dispatch(
      getSocialStoreSales(groupBy, 1, limit, startDate, endDate, 'shop')
    ).then((res) => {
      setSubmit(groupBy);
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  const handleGroupBy = (e) => {
    setGroupBy(e);
  };

  const handleReset = () => {
    setGroupBy('');
    setCurrentPage(0);
    setLoading(true);
    setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
    setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    setSubmit('');
    dispatch(
      getSocialStoreSales(
        '',
        1,
        limit,
        moment().startOf('month').format('YYYY-MM-DD'),
        moment(new Date()).format('YYYY-MM-DD'),
        'shop'
      )
    ).then((res) => {
      setLoading(false);
      setGetEvent(res?.payload?.message);
      setTotalEvent(res?.payload?.message?.total_records);
    });
  };

  function allTable() {
    let data = getEvent?.data;
    if (data) {
      return (
        <>
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="table-responsive">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th>S.#</th>
                    <th>Date</th>
                    <th>Category</th>
                    <th>ProductSku</th>
                    <th>Order#</th>
                    <th>Qty</th>
                    <th>Promo</th>
                    <th>KBFee</th>
                    <th>KBCommission</th>
                    <th>Total Commission</th>
                    <th>Gross Sales</th>
                    <th>Discount</th>
                    <th>Net Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {moment(item?.created_date).format('YYYY-MM-DD')}
                        </td>
                        <td>{item?.category_name}</td>
                        <td>{item?.product_sku ? item?.product_sku : '-'}</td>

                        <td>{item?.order_id}</td>
                        <td>{item?.total_qty}</td>
                        <td>{item?.promo ? item?.promo : ''}</td>
                        <td>{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.kb_commission).format("$0,0.0'")}
                        </td>
                        <td>
                          {item?.total_commission
                            ? numeral(item?.total_commission).format("$0,0.0'")
                            : '$0.00'}
                        </td>
                        <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>

                        <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.order_totalprice).format("$0,0.0'")}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="total-sales">Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_fee ? b.kb_fee : 0.0);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.total_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.total_sale);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.discount);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.order_totalprice);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
  }

  function dateTable() {
    let data = getEvent?.data;
    if (data) {
      return (
        <>
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="table-responsive">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th>S.#</th>
                    <th>Date</th>
                    <th>No. Of Orders</th>
                    <th>Qty</th>
                    <th>KBFee</th>
                    <th>KBCommission</th>
                    <th>Total Commission</th>
                    <th>Gross Sales</th>
                    <th>Discount</th>
                    <th>Net Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{lowerLimit + i}</td>
                        <td>
                          {!item?.created_date
                            ? '-'
                            : moment(item?.created_date).format('YYYY-MM-DD')}
                        </td>
                        <td>{item?.order_count}</td>
                        <td>{item?.total_qty}</td>
                        <td>{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.kb_commission).format("$0,0.0'")}
                        </td>
                        <td>
                          {item?.total_commission
                            ? numeral(item?.total_commission).format("$0,0.0'")
                            : '$0.00'}
                        </td>
                        <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>

                        <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.order_totalprice).format("$0,0.0'")}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="total-sales">Total</td>
                    <td></td>
                    <td></td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_fee);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.total_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.total_sale);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.discount);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.order_totalprice);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
  }

  function productTable() {
    let data = getEvent?.data;
    if (data) {
      return (
        <>
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="table-responsive">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th>S.#</th>
                    <th>ProductSku</th>
                    <th>No. Of Orders</th>
                    <th>Qty</th>
                    <th>KBFee</th>
                    <th>KBCommission</th>
                    <th>Total Commission</th>
                    <th>Gross Sales</th>
                    <th>Discount</th>
                    <th>Net Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{lowerLimit + i}</td>

                        <td>{item?.product_sku}</td>
                        <td>{item?.order_count}</td>
                        <td>{item?.total_qty}</td>
                        <td>{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.kb_commission).format("$0,0.0'")}
                        </td>
                        <td>
                          {item?.total_commission
                            ? numeral(item?.total_commission).format("$0,0.0'")
                            : '$0.00'}
                        </td>
                        <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>

                        <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.order_totalprice).format("$0,0.0'")}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="total-sales">Total</td>
                    <td></td>
                    <td></td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_fee);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.total_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.total_sale);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.discount);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.order_totalprice);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
  }

  function categoryTable() {
    let data = getEvent?.data;
    if (data) {
      return (
        <>
          {loading ? (
            <Spin size="large" />
          ) : (
            <div className="table-responsive">
              <table className="transactions-box table">
                <thead className="table_heading">
                  <tr>
                    <th>S.#</th>
                    <th>Category</th>
                    <th>No. Of Orders</th>
                    <th>Qty</th>
                    <th>KBFee</th>
                    <th>KBCommission</th>
                    <th>Total Commission</th>
                    <th>Gross Sales</th>
                    <th>Discount</th>
                    <th>Net Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{lowerLimit + i}</td>

                        <td>{item?.category_name}</td>
                        <td>{item?.order_count}</td>
                        <td>{item?.total_qty}</td>
                        <td>{numeral(item?.kb_fee).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.kb_commission).format("$0,0.0'")}
                        </td>
                        <td>
                          {item?.total_commission
                            ? numeral(item?.total_commission).format("$0,0.0'")
                            : '$0.00'}
                        </td>
                        <td>{numeral(item?.total_sale).format("$0,0.0'")}</td>

                        <td>{numeral(item?.discount).format("$0,0.0'")}</td>
                        <td>
                          {numeral(item?.order_totalprice).format("$0,0.0'")}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td></td>
                    <td className="total-sales">Total</td>
                    <td></td>
                    <td></td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_fee);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.kb_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.total_commission);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        data.reduce((a, b) => {
                          return Number(a) + Number(b.total_sale);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.discount);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                    <td className="total-sales">
                      {numeral(
                        getEvent.data.reduce((a, b) => {
                          return Number(a) + Number(b.order_totalprice);
                        }, 0)
                      ).format("$0,0.0'")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </>
      );
    }
  }

  return (
    <>
      <div className="sales-page-main">
        <div class="title-area mb-10">
          <h1>Total Social Store Sales</h1>
        </div>

        <div className="brand_container_main aff-payment">
          {/* <div class="filter-date mb-3 row">
            <div class="col-md-12">
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                Today
              </Button>
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                MTD
              </Button>
              <Button className="default-btn filter-btns" type="primary" size="small"  >
                YTD
              </Button>
            </div>
          </div> */}

          <div className="row ">
            <div className="col-xl-3 col-md-6 col-12 mb-20">
              <p>Select Start Date / End Date</p>
              <RangePicker
                size="large"
                className="w-100"
                key={4}
                value={
                  startDate && endDate
                    ? [moment(startDate), moment(endDate)]
                    : []
                }
                allowClear={false}
                ranges={{
                  Today: [moment(), moment()],
                  Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
                  Yesterday: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  'Last Month': [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                }}
                format={dateFormat}
                onChange={dateRangePickerChanger}
              />
            </div>
            <div className="col-xl-3 col-md-6 col-12 mb-20">
              <p>Group By</p>
              <Select
                className="select-style dark-field w-100"
                placeholder="Group By"
                size="large"
                focus={false}
                onBlur={false}
                onChange={handleGroupBy}
                value={groupBy === '' ? null : groupBy}
              >
                <Option value="date">Date</Option>
                <Option value="product">Product</Option>
                <Option value="category">Category</Option>
              </Select>
            </div>
            <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end mb-20">
              <Button
                onClick={handleSubmit}
                className="default-btn fltr-hpr  medium-btn m-width-100"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Search
              </Button>

              <Button
                onClick={handleReset}
                className="default-btn fltr-hpr  medium-btn m-width-100"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Reset
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              {!loading ? (
                <>
                  {totalEvent === 0 ? (
                    <>
                      <div className="no-result-found">
                        <div class="result-inner">
                          <h2>No Data Available</h2>
                          <p className="text-muted">
                            Sorry But The Data You Are Looking For Does Not
                            Exist.
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {submit === '' || submit === undefined
                        ? allTable()
                        : null}
                      {submit === 'date' || submit === undefined
                        ? dateTable()
                        : null}

                      {submit === 'product' || submit === undefined
                        ? productTable()
                        : null}
                      {submit === 'category' || submit === undefined
                        ? categoryTable()
                        : null}
                    </>
                  )}
                </>
              ) : (
                <span className="spinclass loader-center position-relative">
                  <Spin size="large" />
                </span>
              )}

              {totalEvent < 8 && (
                <ReactPaginate
                  nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                  // marginPagesDisplayed={2}

                  pageCount={
                    getEvent?.next
                      ? getEvent?.next?.page
                      : getEvent?.previous
                      ? getEvent?.previous?.page + 1
                      : 1
                  }
                  forcePage={currentPage}
                  previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                  pageClassName="page-item d-none"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item d-none"
                  breakLinkClassName="page-link"
                  containerClassName={'pagination custom-paginate'}
                  activeClassName="active"
                  renderOnZeroPageCount={null}
                />
              )}
              {/* 
              {!getEvent.data?.length ? (
                <tr className="text-center">
                  <td colspan="10">No Data Available</td>
                </tr>
              ) : (
                getEvent.summary.map((item, i) => {
                  return (


                    <div class="sales-summery">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="summarry-box">
                            <h5>Report Summary</h5>
                            <div>Total Amount  : {item?.total_line_items_price
                                ? numeral(item?.total_line_items_price).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Discount : {item?.total_discounts
                                ? numeral(item?.total_discounts).format("$0,0.00'")
                                : "$0.00"}</div>
                            <div>Total Paid Amount : {item?.total_price
                                ? numeral(item?.total_price).format("$0,0.00'")
                                : "$0.00"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              )

              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
