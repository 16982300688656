import {
    GET_STREAM_SETTING_REQUEST,
    GET_STREAM_SETTING_SUCCESS,
    GET_STREAM_SETTING_ERROR,
    POST_STREAM_SETTING_REQUEST,
    POST_STREAM_SETTING_SUCCESS,
    POST_STREAM_SETTING_ERROR,
  } from "../types/types";
  import { BASEURL } from "../../config";
  import axios from "axios";
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  export const getStreamSetting = () => (dispatch) => {
    dispatch({ type: GET_STREAM_SETTING_REQUEST });
    return axios({
      method: "GET",
      url: `${BASEURL}users/cards/getIVSOutputSettings`,
      headers: { Authorization: `Bearer ${userInfo?.token}` },
      
    })
      .then((response) => {
        return dispatch({
          type: GET_STREAM_SETTING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_STREAM_SETTING_ERROR,
          payload: error.response,
        });
      });
  };

  export const saveStreamSetting = (data) => (dispatch) => {
    dispatch({ type: POST_STREAM_SETTING_REQUEST });
    return axios({
      method: "POST",
      url: `${BASEURL}users/cards/createIVSOutputSettings`,
      headers: {
        Authorization: `Bearer ${userInfo?.token}`
      },
      data: data,
    })
      .then((response) => {
        return dispatch({
          type: POST_STREAM_SETTING_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: POST_STREAM_SETTING_ERROR,
          payload: error.response,
        });
      });
  };
