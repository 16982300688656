import {
  GET_PARENT_CATEGORIES_REQUEST,
  GET_PARENT_CATEGORIES_SUCCESS,
  GET_PARENT_CATEGORIES_ERROR,
  GET_SUB_CATEGORIES_REQUEST,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUB_CATEGORIES_ERROR,
  GET_ALL_CATEGORIES_REQUEST,
  GET_ALL_CATEGORIES_SUCCESS,
  GET_ALL_CATEGORIES_ERROR,
  POST_SAVE_CATEGORIES_REQUEST,
  POST_SAVE_CATEGORIES_SUCCESS,
  POST_SAVE_CATEGORIES_ERROR,
  VERIFY_CATEGORIES_REQUEST,
  VERIFY_CATEGORIES_SUCCESS,
  VERIFY_CATEGORIES_ERROR,
} from '../types/types';
import { BASEURL } from '../../config';
import axios from 'axios';

const userInfo = JSON.parse(localStorage.getItem('userInfo'));
export const getParentCategories = () => (dispatch) => {
  dispatch({ type: GET_PARENT_CATEGORIES_REQUEST });

  return axios({
    method: 'GET',
    url: `${BASEURL}users/receive/categories`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_PARENT_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_PARENT_CATEGORIES_ERROR,
        payload: error.response,
      });
    });
};

export const getSubCategories = (id) => (dispatch) => {
  dispatch({ type: GET_SUB_CATEGORIES_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}mobile/category/subCategories`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      parent_id: id,
    },
  })
    .then((response) => {
      return dispatch({
        type: GET_SUB_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_SUB_CATEGORIES_ERROR,
        payload: error.response,
      });
    });
};

export const getAllCategories = () => (dispatch) => {
  dispatch({ type: GET_ALL_CATEGORIES_REQUEST });

  return axios({
    method: 'GET',
    url: `${BASEURL}usercategory/receive`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
  })
    .then((response) => {
      return dispatch({
        type: GET_ALL_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: GET_ALL_CATEGORIES_ERROR,
        payload: error.response,
      });
    });
};

export const postSaveCategories = (category, sort) => (dispatch) => {
  dispatch({ type: POST_SAVE_CATEGORIES_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}usercategory/reserve`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      categories: category,
      sort: sort,
    },
  })
    .then((response) => {
      return dispatch({
        type: POST_SAVE_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: POST_SAVE_CATEGORIES_ERROR,
        payload: error.response,
      });
    });
};

export const verifyCategories = (category_id) => (dispatch) => {
  dispatch({ type: VERIFY_CATEGORIES_REQUEST });
  return axios({
    method: 'POST',
    url: `${BASEURL}usercategory/verify`,
    headers: { Authorization: `Bearer ${userInfo?.token}` },
    data: {
      category_id: category_id,
    },
  })
    .then((response) => {
      return dispatch({
        type: VERIFY_CATEGORIES_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: VERIFY_CATEGORIES_ERROR,
        payload: error.response,
      });
    });
};

// export const getUserCategories2 = (userId) => async (dispatch) => {
//   let promise = new Promise((resolve, reject) => {
//     axios
//       .get(`${config.baseURLApi}/users/receive/categories?id=${userId}`)
//       .then((res) => {
//         dispatch({
//           type: GET_USER_CATEGORIES2,
//           payload: res.data?.message,
//         });
//         resolve(res.data?.message);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
//   return promise;
// };
