import React, { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  notification,
  Statistic,
  Select,
  Form,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEvents,
  deleteEvent,
  deleteEventRecord,
  publishEvent,
} from '../../redux/actions/event.action';
import { ShareUrl } from '../../redux/actions/shareUrl.action';
import searchEventImg from '../../images/search-event.png';
import moment from 'moment';
import {
  faFlask,
  faCalendarDays,
  faClock,
  faUser,
  faVideo,
  faXmark,
  faAngleLeft,
  faAngleRight,
  faPlay,
  faUserTie,
  faShareNodes,
} from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import NoEventFound from '../../reUsable/noEventFound';
import defaultImage from '../../images/user.png';
const { Option } = Select;

const { Countdown } = Statistic;

const { confirm } = Modal;
const isoStr = new Date().toISOString();

export default ({ className, title, eventStatus }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortBy, setSortBy] = useState('end');

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = () => {
    console.log('finished!');
  };

  const dispatch = useDispatch();
  const history = useNavigate();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const { getEvent, shareUrls } = useSelector((state) => {
    return state;
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 8;

  useEffect(() => {
    setCurrentPage(0);
    dispatch(getEvents(limit, 1, title, sortBy)).then((res) => {
      if (res.payload.success) {
        // setPageCount(res.payload.message?.next?.page);
      } else {
        console.log('err');
      }
    });
  }, [title, eventStatus]);

  const controlRoom = (val) => {
    history({
      pathname: `/control-room/${val?.event?._id}`,
    });
  };

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url);
    notification.success({
      message: 'Url Copied!',
      className: 'toast-success',
    });
    setIsModalOpen(false);
  };

  const shareKey = (id, title) => {
    let UrlKey = '';
    if (title === 'Live Events') {
      UrlKey = 'live-event';
    } else {
      UrlKey = 'upcoming-event';
    }
    let url = `https://www.kbshop.com/${UrlKey}/${id}/${userInfo?.username ? userInfo?.username : userInfo?.pixel_id
      }`;
    let data = { url: url };
    dispatch(ShareUrl(data)).then((res) => {
      if (res.payload.success) {
        setIsModalOpen(true);
      } else {
        console.log('err');
      }
    });
  };
  const recordedEvents = (val) => {
    history({
      pathname: `/recorded-event/${val?.event?._id}`,
    });
  };

  const handleButtonClick = (e, item) => {
    if (e.key === 'cancel') {
      confirm({
        wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
        title: 'Warning',
        centered: true,
        closable: true,
        icon: <ExclamationCircleOutlined />,
        closeIcon: <FontAwesomeIcon icon={faXmark} />,
        content: 'Are you sure you want to delete this event ?',
        okText: ' Yes, delete',
        okType: 'danger',
        cancelText: " No, I don't want to delete ",

        onOk() {
          return new Promise((resolve, reject) => {
            if (title === 'Upcoming') {
              dispatch(deleteEvent(item?.event?._id)).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: 'toast-success',
                  });
                  dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                  setTimeout(resolve, 100);
                } else {
                  console.log(res.payload.data.message, 'err');
                  notification.error({
                    message: res.payload.data.message,
                    className: 'toast-error',
                  });
                }
              });
            } else {
              dispatch(deleteEventRecord(item?.event?._id)).then((res) => {
                if (res.payload.success) {
                  notification.success({
                    message: res.payload?.message,
                    className: 'toast-success',
                  });
                  dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                  setTimeout(resolve, 100);
                } else {
                  console.log(res.payload.data.message, 'err');
                  notification.error({
                    message: res.payload.data.message,
                    className: 'toast-error',
                  });
                }
              });
            }
          });
        },

        onCancel() {
          console.log('Cancel');
        },
      });
    }
    if (e.key === 'edit') {
      history({
        pathname: `/schedule-event-id/${item?.event?._id}`,
      });
    }
    if (e.key === 'publish') {
      if (item.event.is_published) {
        confirm({
          wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
          // title: 'Warning',
          centered: true,
          closable: true,
          icon: <ExclamationCircleOutlined />,
          closeIcon: <FontAwesomeIcon icon={faXmark} />,
          content: 'Are you sure you want to unpublish this event ?',
          okText: ' Yes, Unpublish',
          okType: 'success',
          cancelText: " No, I don't want to unpublish",

          onOk() {
            return new Promise((resolve, reject) => {
              dispatch(publishEvent(item?.event?._id, 'unpublish')).then(
                (res) => {
                  if (res.payload.success) {
                    notification.success({
                      message: res.payload?.message,
                      className: 'toast-success',
                    });
                    dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                    setTimeout(resolve, 100);
                  } else {
                    notification.error({
                      message: res.payload.data.message,
                      className: 'toast-error',
                    });
                  }
                }
              );
            });
          },

          onCancel() {
            console.log('cancel UnPublish');
          },
        });
      } else {
        confirm({
          wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
          // title: 'Warning',
          centered: true,
          closable: true,
          icon: <ExclamationCircleOutlined />,
          closeIcon: <FontAwesomeIcon icon={faXmark} />,
          content: 'Are you sure you want to publish this event ?',
          okText: ' Yes, publish',
          okType: 'success',
          cancelText: " No, I don't want to publish",

          onOk() {
            return new Promise((resolve, reject) => {
              dispatch(publishEvent(item?.event?._id, 'publish')).then(
                (res) => {
                  if (res.payload.success) {
                    notification.success({
                      message: res.payload?.message,
                      className: 'toast-success',
                    });
                    dispatch(getEvents(limit, currentPage + 1, title, sortBy));
                    setTimeout(resolve, 100);
                  } else {
                    notification.error({
                      message: res.payload.data.message,
                      className: 'toast-error',
                    });
                  }
                }
              );
            });
          },

          onCancel() {
            console.log('cancel Publish');
          },
        });
      }
    }
  };

  const load = getEvent.loading;

  const handlePageClick = (e) => {
    const page = e.selected;
    setCurrentPage(page);
    dispatch(getEvents(limit, page + 1, title, sortBy));
  };

  function roomDisable(item) {
    const dateIsAfter = moment(item.start_date).isAfter(
      moment(moment.utc().format())
    );
    if (dateIsAfter) {
      return false;
    } else {
      return true;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getEvents(limit, 1, title, sortBy));
  };

  const handleChange = (value) => {
    setSortBy(value);
  };

  return (
    <>
      {title === 'Recorded' && (
        <div className="row">
          <div className="col-xl-3 col-md-6 col-12">
            <Form.Item labelCol={{ span: 24 }} label="Sort By" name="sort">
              <Select
                className="select-style dark-field"
                placeholder="Sort By"
                size="large"
                focus={false}
                onBlur={false}
                onChange={handleChange}
                defaultValue="end"
              >
                <Option value="end">End Date</Option>
                <Option value="Start">Start Date</Option>
                <Option value="scheduled">Scheduled Date</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-xl-3 col-md-6 col-12 d-flex align-items-end">
            <Form.Item className="d-flex align-items-end">
              <Button
                onClick={handleSubmit}
                className="default-btn fltr-hpr medium-btn"
                type="primary"
                htmlType="submit"
              // loading={loading}
              >
                Search
              </Button>
            </Form.Item>
          </div>
        </div>
      )}
      {!load ? (
        <>
          <div
            className={`upcoming-events-wrapper mb-30 ${className ? 'grid-block' : 'list-block'
              }`}
          >
            {!getEvent.loading ? (
              <>
                {getEvent?.payload?.message?.data.length > 0 ? (
                  <div className="upcoming-events-grid">
                    {getEvent?.payload?.message?.data.map((item, i) => {
                      return (
                        <>
                          <div className="upcoming-events-box">
                            {/* <div className="event-banner" style={{ backgroundImage: `url(${item.banner})` }} > */}
                            <div className="event-banner">
                              <div className="event-box w-100 h-100 overflow-hidden">
                                <div className="event-banner-img">
                                  <img src={item?.event?.banner} alt="banner" />
                                </div>

                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                  {/* {roomDisable(item) ? (
                                    <div className="schedule-badge expired">
                                      Expired
                                    </div>
                                  ) : (
                                    <> */}
                                  {item?.event?.event_status === 'live' ? (
                                    <div className="schedule-badge live">
                                      Live Now
                                    </div>
                                  ) : null}

                                  {moment(item?.event?.start_date)

                                    .format('YYYY-MM-DD hh:mm A') <= moment(isoStr).format('YYYY-MM-DD hh:mm A') && item?.event?.event_status === 'scheduled' ? (
                                    <div className="schedule-badge past">
                                      Past
                                    </div>
                                  ) : item?.event?.event_status ===
                                    'scheduled' ? (
                                    <div className="schedule-badge schedule ">
                                      Schedule
                                    </div>
                                  ) : null}
                                  {item?.event?.event_status === 'recorded' ? (
                                    <div className="schedule-badge Recorded ">
                                      Recorded
                                    </div>
                                  ) : null}
                                  {/* </>
                                  )} */}

                                  {item?.event?.event_status === 'scheduled' ? (
                                    <div className="schedule-badge schedule countdown-timer">
                                      <Countdown
                                        value={item?.event?.start_date}
                                        format="DD : HH : mm : ss"
                                      />
                                    </div>
                                  ) : null}
                                </div>

                                <div className="d-flex justify-content-end">
                                  <div className="ribbon-small">
                                    <FontAwesomeIcon icon={faFlask} />
                                    <span>Event </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="event-card-content w-100 col d-flex flex-column justify-content-between">
                              <div>
                                <div className="event-date-main top-date">
                                  {item?.event?.event_status === 'recorded' ? (
                                    <div className="event-date-recorded">
                                      Scheduled:
                                    </div>
                                  ) : null}

                                  <div className="event-date">
                                    {item?.event?.event_status != 'recorded' ? (
                                      <FontAwesomeIcon
                                        className="event-icon"
                                        icon={faCalendarDays}
                                      />
                                    ) : null}
                                    <div
                                      // className="event-txt"
                                      className={`event-txt ${item?.event?.event_status != 'recorded'
                                          ? ''
                                          : 'recorded-gap'
                                        }`}
                                    >
                                      {moment(item?.event?.start_date)
                                        .utc()
                                        .format('YYYY-MM-DD')}
                                    </div>
                                  </div>

                                  <div className="event-date">
                                    {item?.event?.event_status != 'recorded' ? (
                                      <FontAwesomeIcon
                                        className="event-icon"
                                        icon={faClock}
                                      />
                                    ) : null}

                                    <div className="event-txt">
                                      {moment(item?.event?.start_date).format(
                                        'hh:mm A'
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {item?.event?.event_status === 'recorded' ? (
                                  <div className="event-date-main top-date">
                                    {item?.event?.event_status ===
                                      'recorded' ? (
                                      <div className="event-date-recorded">
                                        Start:
                                      </div>
                                    ) : null}

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        'recorded' ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faCalendarDays}
                                        />
                                      ) : null}
                                      <div
                                        // className="event-txt"
                                        className={`event-txt ${item?.event?.event_status !=
                                            'recorded'
                                            ? ''
                                            : 'recorded-gap'
                                          }`}
                                      >
                                        {moment(item?.event?.started_at)
                                          .utc()
                                          .format('YYYY-MM-DD')}
                                      </div>
                                    </div>

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        'recorded' ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faClock}
                                        />
                                      ) : null}

                                      <div className="event-txt">
                                        {moment(item?.event?.started_at).format(
                                          'hh:mm A'
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {item?.event?.event_status === 'recorded' ? (
                                  <div className="event-date-main top-date">
                                    {item?.event?.event_status ===
                                      'recorded' ? (
                                      <div className="event-date-recorded">
                                        End:
                                      </div>
                                    ) : null}

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        'recorded' ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faCalendarDays}
                                        />
                                      ) : null}
                                      <div
                                        // className="event-txt"
                                        className={`event-txt ${item?.event?.event_status !=
                                            'recorded'
                                            ? ''
                                            : 'recorded-gap'
                                          }`}
                                      >
                                        {moment(item?.event?.ended_at)
                                          .utc()
                                          .format('YYYY-MM-DD')}
                                      </div>
                                    </div>

                                    <div className="event-date">
                                      {item?.event?.event_status !=
                                        'recorded' ? (
                                        <FontAwesomeIcon
                                          className="event-icon"
                                          icon={faClock}
                                        />
                                      ) : null}

                                      <div className="event-txt">
                                        {moment(item?.event?.ended_at).format(
                                          'hh:mm A'
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {item?.event?.event_status === 'recorded' ? (
                                  <div className="event-date-main top-date">
                                    <div className="event-date-recorded">
                                      Duration:
                                    </div>

                                    {item?.event?.event_status != 'recorded' ? (
                                      <FontAwesomeIcon
                                        className="event-icon"
                                        icon={faClock}
                                      />
                                    ) : null}
                                    <div className="event-txt">
                                      {moment
                                        .utc(
                                          moment(
                                            item?.event?.ended_at,
                                            'HH:mm:ss'
                                          ).diff(
                                            moment(
                                              item?.event?.started_at,
                                              'HH:mm:ss'
                                            )
                                          )
                                        )
                                        .format('HH:mm:ss')}
                                    </div>
                                  </div>
                                ) : null}

                                {/* <div className="event-date d-inline-flex">
                            <FontAwesomeIcon className="event-icon" icon={faCalendarDays} />
                            <div className="event-txt">
                                FRIDAY, JULY 29, 2022
                            </div>
                            <FontAwesomeIcon className="event-icon" icon={faClock} />
                            <div className="event-txt">
                                3:10 PM
                            </div>
                        </div> */}

                                <h3 className="event-hd">
                                  {item?.event?.title}
                                </h3>
                              </div>
                              <div className="event-users-listing">
                                <div className="event-date-main">
                                  {item?.event?.hosts.map((it, i) => {
                                    return (
                                      <>
                                        {it?.producer && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUserTie}
                                            />
                                            <div className="event-txt">
                                              Producer: {it.user_name}
                                            </div>
                                          </div>
                                        )}
                                        {it?.primary && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            <div className="event-txt">
                                              Primary: {it.user_name} {" "} { title === 'Recorded' ? null : it.host_status ? 
                                              
                                              <span class="status-flag-event-user green"></span>
                                              : 
                                              <span class="status-flag-event-user red"></span>
                                               }
                                            </div>
                                          </div>
                                        )}
                                        {it?.host1 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            {/* <div className="event-txt">
                                              Host1: {it.user_name}
                                            </div> */}
                                            
                                            <div className="event-txt">
                                            Host1: {it.user_name} {" "} {title === 'Recorded' ? null : it.host_status ? 
                                              
                                              <span class="status-flag-event-user green"></span>
                                              : 
                                              <span class="status-flag-event-user red"></span>
                                               }
                                            </div>
                                          </div>
                                        )}
                                        {it?.host2 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            {/* <div className="event-txt">
                                              Host2: {it.user_name}
                                            </div> */}
                                            <div className="event-txt">
                                            Host2: {it.user_name} {" "} {title === 'Recorded' ? null : it.host_status ? 
                                              
                                              <span class="status-flag-event-user green"></span>
                                              : 
                                              <span class="status-flag-event-user red"></span>
                                               }
                                            </div>
                                          </div>
                                        )}
                                        {it?.host3 && (
                                          <div className="event-date bottom-style">
                                            <FontAwesomeIcon
                                              className="event-icon"
                                              icon={faUser}
                                            />
                                            {/* <div className="event-txt">
                                              Host3: {it.user_name}
                                            </div> */}
                                            <div className="event-txt">
                                            Host3: {it.user_name} {" "} {title === 'Recorded' ? null : it.host_status ? 
                                              
                                              <span class="status-flag-event-user green"></span>
                                              : 
                                              <span class="status-flag-event-user red"></span>
                                               }
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="event-card-footer">
                              {item?.event?.recording_url &&
                                item?.event_status != 'live' ? (
                                <>
                                  <Button
                                    className="default-btn d-flex align-items-center justify-content-center"
                                    type="primary"
                                    size="large"
                                    onClick={() => recordedEvents(item)}
                                    icon={
                                      <FontAwesomeIcon
                                        size="sm"
                                        className="event-icon"
                                        // icon={faVideo}
                                        icon={faPlay}
                                      />
                                    }
                                  >
                                    Play
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    className="default-btn d-flex align-items-center justify-content-center"
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      if (item?.producer) {
                                        if (item?.is_joined) {
                                          confirm({
                                            wrapClassName: 'modal-generic cancel-event-modal min-modal-600',
                                            title: 'Warning',
                                            centered: true,
                                            closable: true,
                                            icon: <ExclamationCircleOutlined />,
                                            closeIcon: <FontAwesomeIcon icon={faXmark} />,
                                            content: 'Event is On, Do you want to end it?',
                                            okText: ' Yes, end it',
                                            okType: 'danger',
                                            cancelText: " No, I don't want to end it ",
                                            onOk() {
                                              controlRoom(item)
                                            },
                                            onCancel() {
                                              console.log('Cancel');
                                            },
                                          });
                                        } else {
                                          controlRoom(item)
                                        }
                                      } else {
                                        controlRoom(item)
                                      }
                                    }}
                                    icon={
                                      <FontAwesomeIcon
                                        size="sm"
                                        className="event-icon"
                                        icon={faVideo}
                                      />
                                    }
                                    disabled={
                                      // roomDisable(item) ||
                                      item.event_status === 'recorded'
                                        ? true
                                        : false
                                    }
                                  >
                                    Control Room
                                  </Button>
                                </>
                              )}

                              <div>
                                <Button
                                  className="default-btn share-btn d-flex align-items-center justify-content-center"
                                  type="primary"
                                  size="large"
                                  onClick={() =>
                                    shareKey(item?.event_id, title)
                                  }
                                  icon={
                                    // <ShareAltOutlined />
                                    <FontAwesomeIcon
                                      size="sm"
                                      className="event-icon"
                                      icon={faShareNodes}
                                    />
                                  }
                                  disabled={
                                    // roomDisable(item) ||
                                    item.event_status === 'recorded'
                                      ? true
                                      : false
                                  }
                                ></Button>
                              </div>

                              {item.event_status !== 'live' && (
                                <Dropdown.Button
                                  trigger="click"
                                  overlayClassName="control-room-drowpdown"
                                  size="large"
                                  disabled={
                                    item.event_status !== 'scheduled' ||
                                      item.event_status !== 'recorded'
                                      ? false
                                      : false
                                  }
                                  // onClick={() => handleButtonClick(item)}
                                  // overlay={menu}
                                  overlay={
                                    <Menu
                                      onClick={(e) =>
                                        handleButtonClick(e, item)
                                      }
                                    >
                                      <Menu.Item
                                        key="edit"
                                        disabled={
                                          // roomDisable(item) ||
                                          item.event_status == 'scheduled'
                                            ? false
                                            : true
                                        }
                                      >
                                        <span>Edit</span>
                                      </Menu.Item>
                                      <Menu.Item
                                        key="cancel"
                                        disabled={
                                          // roomDisable(item) ||
                                          item.event_status == 'live' &&
                                            item?.event?.recording_url &&
                                            item.event_status != 'scheduled'
                                            ? false
                                            : null
                                        }
                                      >
                                        <span>Delete</span>
                                      </Menu.Item>

                                      {item.event_status === 'recorded' && (
                                        <Menu.Item
                                          key="publish"
                                        // disabled={
                                        //   item?.event?.is_published
                                        //     ? true
                                        //     : false
                                        // }
                                        >
                                          <span>
                                            {item?.event?.is_published
                                              ? 'UnPublish'
                                              : 'Publish'}
                                          </span>
                                        </Menu.Item>
                                      )}
                                    </Menu>
                                  }
                                ></Dropdown.Button>
                              )}

                              {/* )} */}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-result-found">
                    <div className="no-result-img">
                      <img
                        src={
                          userInfo?.profile_image_url
                            ? userInfo?.profile_image_url
                            : defaultImage
                        }
                        alt="searchEventImg"
                      />
                    </div>
                    <h3 className="no-event-hd"> No Event At This Time.</h3>
                    <p className="event-para">Please check back later.</p>
                    {/* <p>No Event At This Time. Please check back later.</p> */}
                  </div>
                )}
              </>
            ) : (
              <>{null}</>
            )}

            {getEvent?.payload?.message?.data.length > 0 && (
              <ReactPaginate
                nextLabel={<FontAwesomeIcon icon={faAngleRight} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={window.innerWidth <= 760 ? 1 : 7}
                // marginPagesDisplayed={2}

                pageCount={
                  getEvent?.payload?.message?.next
                    ? getEvent?.payload?.message?.next?.page
                    : getEvent?.payload?.message?.previous
                      ? getEvent?.payload?.message?.previous?.page + 1
                      : 1
                }
                forcePage={currentPage}
                previousLabel={<FontAwesomeIcon icon={faAngleLeft} />}
                pageClassName="page-item d-none"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item d-none"
                breakLinkClassName="page-link"
                containerClassName={'pagination custom-paginate'}
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            )}
          </div>
        </>
      ) : (
        // <Spin className="m-auto d-table" indicator={antIcon} />
        <span className="spinclass loader-center position-relative">
          <Spin size="large" />
        </span>
      )}

      <Modal
        className="modal-generic modal-500"
        centered
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<FontAwesomeIcon icon={faXmark} />}
        footer={false}
      // footer={[
      //   <Button key="back" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="primary"
      //     className=""
      //     // onClick={handleOk}
      //     onClick={{}}
      //     >
      //     Next
      //   </Button>,
      // ]}
      >
        <h2 className="modal-hd1">Share</h2>
        <div class="your-copy-link">
          <div class="item-a">
            <a
              target="_blank"
              rel="noreferrer"
              href={shareUrls?.payload?.message}
            >
              {shareUrls?.payload?.message}
            </a>
          </div>
          <div class="item-b">
            {/* <div class="item-b copied"> */}
            <Button onClick={() => copyUrl(shareUrls?.payload?.message)}>
              Copy
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
