import { GET_USER_INFO } from "../types/types";
import { BASEURL } from "../../config";
import axios from "axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));
export const getUserInfo = () => async (dispatch) => {
  let promise = new Promise((resolve, reject) => {
    axios
      .get(`${BASEURL}users/receive/userinfo`,{headers: { Authorization: `Bearer ${userInfo?.token}` },})
      .then((res) => {
        dispatch({
          type: GET_USER_INFO,
          payload: res.data?.message,
        });
        resolve(res.data?.message);
      })
      .catch((error) => {
        reject(error);
      });
  });
  return promise;
};
